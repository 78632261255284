import {
  Button,
  Divider,
  message,
  Select,
  Spin,
  Typography,
  Switch,
} from "antd";
import { SetStateAction, useState } from "react";
import styled from "styled-components";
import {
  StudentsAttendancesQuery,
  useNewAttendanceMutation,
  useNewTemporaryAttendanceMutation,
  useStudentsAttendancesLazyQuery,
} from "../../../../generated/graphql";
import { classInfoStr } from "../../../../util/classInfo";

const { Option } = Select;
const { Text } = Typography;

const FooterContainer = styled.div`
  overflow: auto;
  white-space: nowrap;
  min-height: 25px;
`;

const AddToClassBtn = styled(Button)`
  margin-left: 10px;
  z-index: 1;
`;

const StudentSelector = styled(Select)`
  width: 400px;
  z-index: 1;
`;

const ShowDeletedStudentsContainer = styled.span`
  position: absolute;
  right: 10px;
`;

const AddToClassBtns: React.FC<{
  studentsAttendances?: StudentsAttendancesQuery;
  classId: number;
  selectedNewStudentId: number;
  setSelectedNewStudentId: (value: SetStateAction<number | undefined>) => void;
  onAddNewStudent: () => void;
}> = ({
  studentsAttendances,
  classId,
  onAddNewStudent,
  selectedNewStudentId,
  setSelectedNewStudentId,
}) => {
  const [
    newAttendance,
    { loading: newAttendanceLoading },
  ] = useNewAttendanceMutation();
  const [
    newTemporaryAttendance,
    { loading: newTemporaryAttendanceLoading },
  ] = useNewTemporaryAttendanceMutation();

  return (
    <>
      <AddToClassBtn
        loading={newAttendanceLoading || newTemporaryAttendanceLoading}
        type="primary"
        onClick={async () => {
          try {
            await newAttendance({
              variables: {
                input: { classId, studentId: selectedNewStudentId },
              },
            });
            onAddNewStudent();
            setSelectedNewStudentId(undefined);
            message.success("Successfully added student to class");
          } catch (error) {
            message.error(
              `Failed to add student to class: ${error.message}`,
              10
            );
          }
        }}
      >
        Add to class
      </AddToClassBtn>
      {studentsAttendances?.getStudents
        .find((student) => student.id === selectedNewStudentId)
        ?.attendances.filter(
          (attendance) =>
            attendance.primaryAttendance == null && !attendance.isDeleted
        )
        .map((fromAttendance) => (
          <AddToClassBtn
            key={fromAttendance.id}
            loading={newAttendanceLoading || newTemporaryAttendanceLoading}
            style={{ height: "60px" }}
            onClick={async () => {
              try {
                await newTemporaryAttendance({
                  variables: {
                    input: {
                      fromAttendanceId: fromAttendance.id,
                      toClassId: classId,
                    },
                  },
                });
                onAddNewStudent();
                setSelectedNewStudentId(undefined);
                message.success(
                  "Successfully added student temporarily to class from another class"
                );
              } catch (error) {
                message.error(
                  `Failed to add student temporarily to class from another class: ${error.message}`,
                  10
                );
              }
            }}
          >
            Temporarily add from
            <br />
            {classInfoStr(fromAttendance.class)}
          </AddToClassBtn>
        ))}
    </>
  );
};

const Footer: React.FC<{
  classId?: number;
  onAddNewStudent?: () => void;
  showDeletedAttendances: boolean;
  onChangeDeletedAttendances: (value: boolean) => void;
}> = ({
  classId,
  onAddNewStudent,
  showDeletedAttendances,
  onChangeDeletedAttendances,
}) => {
  const [
    getStudents,
    { data: studentsAttendances, loading: studentsAttendancesLoading },
  ] = useStudentsAttendancesLazyQuery();

  const [selectedNewStudentId, setSelectedNewStudentId] = useState<
    number | undefined
  >();
  const [timeoutObj, setTimeoutObj] = useState<NodeJS.Timeout | null>(null);

  const onSearch = (fullName: string) => {
    if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    if (fullName.length === 0) return;
    setTimeoutObj(
      setTimeout(
        () => getStudents({ variables: { filter: { fullName } } }),
        1000
      )
    );
  };

  const deletedStudentsContainer = (
    <ShowDeletedStudentsContainer>
      Show deleted &nbsp;
      <Switch
        onChange={onChangeDeletedAttendances}
        checked={showDeletedAttendances}
      />
    </ShowDeletedStudentsContainer>
  );

  if (!classId || !onAddNewStudent) {
    return <FooterContainer>{deletedStudentsContainer}</FooterContainer>;
  }

  return (
    <FooterContainer>
      <Text strong>Add student</Text>
      <Divider type="vertical" />
      <StudentSelector
        showSearch
        placeholder="Start typing their name..."
        notFoundContent={
          studentsAttendancesLoading ? <Spin size="small" /> : null
        }
        filterOption={false}
        onSearch={onSearch}
        onChange={(studentId) => setSelectedNewStudentId(Number(studentId))}
        value={selectedNewStudentId}
      >
        {studentsAttendances?.getStudents
          .filter((student) => !student.account.isDisabled)
          .map((student) => (
            <Option key={student.id} value={student.id}>
              {student.fullName} {student.school ? `(${student.school})` : ""}
            </Option>
          ))}
      </StudentSelector>
      {selectedNewStudentId && (
        <AddToClassBtns
          studentsAttendances={studentsAttendances}
          classId={classId}
          selectedNewStudentId={selectedNewStudentId}
          setSelectedNewStudentId={setSelectedNewStudentId}
          onAddNewStudent={onAddNewStudent}
        />
      )}
      {deletedStudentsContainer}
    </FooterContainer>
  );
};

export default Footer;
