import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { Layout } from "antd";
import Admin from "./pages/admin";
import Navbar from "./components/Navbar";
import Attendance from "./pages/attendance";
import ExtraActivities from "./pages/extraActivities";
import Timesheet from "./pages/timesheet";
import Memos from "./pages/memos";

const Cortex: React.FC = () => {
  const match = useRouteMatch();

  return (
    <Layout style={{ height: "100vh", backgroundColor: "white" }}>
      <Navbar />
      <Switch>
        <Route path={`${match.url}/attendance`} component={Attendance} />
        <Route path={`${match.url}/admin`} component={Admin} />
        <Route
          path={`${match.url}/extra-activities`}
          component={ExtraActivities}
        />
        <Route path={`${match.url}/timesheet`} component={Timesheet} />
        <Route path={`${match.url}/memos`} component={Memos} />
        <Redirect to={`${match.url}/attendance`} />
      </Switch>
    </Layout>
  );
};

export default Cortex;
