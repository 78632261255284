import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { DATE_TIME_DISPLAY_FORMAT } from "../../../util/constants";
import {
  classTypeSelectOptions,
  daysSelectOptions,
} from "../../../util/selectOptions";
import { FieldType, SelectOption } from "../SearchBar";

export interface Column extends ColumnType<Column> {
  dataIndex: string;
  searchBarType?: FieldType;
  selectOptions?: SelectOption[];
  editable?: boolean;
}

const commonColumn: ColumnType<Column> = {
  width: 170,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SortData = any;

export const columns: Column[] = [
  {
    ...commonColumn,
    width: 50,
    title: "Day",
    dataIndex: "day",
    searchBarType: FieldType.Select,
    selectOptions: daysSelectOptions,
    editable: true,
  },
  {
    ...commonColumn,
    title: "Time",
    dataIndex: "time",
  },
  {
    ...commonColumn,
    title: "Start Time",
    dataIndex: "startTime",
    searchBarType: FieldType.Time,
  },
  {
    ...commonColumn,
    title: "End Time",
    dataIndex: "endTime",
    searchBarType: FieldType.Time,
  },
  {
    ...commonColumn,
    width: 60,
    title: "Grade",
    dataIndex: "grade",
    searchBarType: FieldType.Text,
    editable: true,
  },
  {
    ...commonColumn,
    title: "Subject",
    dataIndex: "subject",
    searchBarType: FieldType.Text,
    editable: true,
  },
  {
    ...commonColumn,
    title: "Type",
    dataIndex: "type",
    searchBarType: FieldType.SelectRadio,
    selectOptions: classTypeSelectOptions,
  },
  {
    ...commonColumn,
    title: "Comment",
    dataIndex: "comment",
    searchBarType: FieldType.Text,
    editable: true,
  },
  {
    ...commonColumn,
    title: "Students",
    dataIndex: "students",
  },
  {
    ...commonColumn,
    title: "Teachers",
    dataIndex: "teachers",
  },
  {
    ...commonColumn,
    title: "Date Created",
    dataIndex: "dateCreated",
    sorter: (a: SortData, b: SortData): number =>
      moment(a.dateCreated, DATE_TIME_DISPLAY_FORMAT).unix() -
      moment(b.dateCreated, DATE_TIME_DISPLAY_FORMAT).unix(),
  },
  {
    ...commonColumn,
    title: "Date Modified",
    dataIndex: "dateModified",
    sorter: (a: SortData, b: SortData): number =>
      moment(a.dateModified, DATE_TIME_DISPLAY_FORMAT).unix() -
      moment(b.dateModified, DATE_TIME_DISPLAY_FORMAT).unix(),
  },
  {
    width: 100,
    title: "Actions",
    dataIndex: "actions",
    fixed: "right",
  },
];

export interface SearchFilterField extends Column {
  currentValue: string | null;
}
