import { Button, Form, InputNumber, message, Modal, Space } from "antd";
import { useEffect } from "react";
import {
  TeacherIntervalRate,
  usePayRatesLazyQuery,
  useUpdatePayRatesMutation,
} from "../../../../../../generated/graphql";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const PayRatesModal: React.FC<{
  teacherId?: number;
  onClose: () => void;
}> = ({ teacherId, onClose }) => {
  const [getPayRates, { data, error }] = usePayRatesLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const [updatePayRates, { loading: isSaving }] = useUpdatePayRatesMutation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [data, teacherId]);

  useEffect(() => {
    if (teacherId) {
      getPayRates({
        variables: {
          teacherId,
        },
      });
    }
  }, [teacherId]);

  useEffect(() => {
    if (error) {
      onClose();
      message.error(`Teacher's pay rates failed to load: ${error.message}`, 10);
    }
  }, [error]);

  const payField = (
    <InputNumber
      formatter={(value): string =>
        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
      parser={(value): string => value?.replace(/\$\s?|(,*)/g, "") ?? "0"}
    />
  );

  return (
    <Modal
      visible={teacherId !== undefined}
      title="Pay Rates"
      okText="Update"
      cancelText="Cancel"
      confirmLoading={isSaving}
      onCancel={onClose}
      onOk={async (): Promise<void> => {
        try {
          if (!teacherId) {
            return;
          }

          const values = await form.validateFields();
          values.intervalRates.forEach((intervalRate: TeacherIntervalRate) => {
            delete intervalRate.__typename;
          });
          console.log(values.intervalRates);
          const variables = {
            teacherId,
            input: values,
          };
          await updatePayRates({
            variables,
          });
          onClose();
          message.success("Teacher's pay rates successfully saved");
        } catch (error) {
          message.error(
            `Teacher's pay rates failed to save: ${error.message}`,
            10
          );
        }
      }}
    >
      <Form form={form} layout="vertical" initialValues={data?.getPayRates}>
        <Form.Item>
          All pay rates are in hours except where specified.
          <br />
          <br />
          Please note that the pay rates information is not shared between the
          differing instances of Cortex.
          <br />
          <br />
          It is not recommended changing the pay rates until the new term has
          started, as the timesheet history is calculated using the current pay
          rates.
        </Form.Item>
        <Form.Item
          name="privatePayRate"
          label="Private"
          rules={[
            {
              required: true,
            },
          ]}
        >
          {payField}
        </Form.Item>
        <Form.Item
          name="classRate"
          label="Class"
          rules={[
            {
              required: true,
            },
          ]}
        >
          {payField}
        </Form.Item>
        <Form.Item
          name="classExtraRate"
          label="Class - extra per extra student"
          rules={[
            {
              required: true,
            },
          ]}
        >
          {payField}
        </Form.Item>
        <Form.Item
          name="classExtraNumStudentsThreshold"
          label="Class - extra per extra student - kicks in at which student?"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="markingHwHelpRate"
          label="Marking and Homework Help"
          rules={[
            {
              required: true,
            },
          ]}
        >
          {payField}
        </Form.Item>
        <Form.Item
          name="trainingRate"
          label="Training"
          rules={[
            {
              required: true,
            },
          ]}
        >
          {payField}
        </Form.Item>
        <Form.Item
          name="receptionRate"
          label="Reception"
          rules={[
            {
              required: true,
            },
          ]}
        >
          {payField}
        </Form.Item>
        <Form.Item
          name="assistingRate"
          label="Assisting"
          rules={[
            {
              required: true,
            },
          ]}
        >
          {payField}
        </Form.Item>
        <Form.Item
          name="bookletsHrRate"
          label="Curriculum (per hour)"
          rules={[
            {
              required: true,
            },
          ]}
        >
          {payField}
        </Form.Item>
        <Form.Item
          name="bookletsBookRate"
          label="Curriculum (per booklet)"
          rules={[
            {
              required: true,
            },
          ]}
        >
          {payField}
        </Form.Item>
        <Form.Item
          name="interviewRate"
          label="Interview"
          rules={[
            {
              required: true,
            },
          ]}
        >
          {payField}
        </Form.Item>
        <Form.Item>
          <h3>Extra for students in class based on discrete intervals</h3>A
          discrete interval allows teachers to get paid extra per hour if the
          number of students in the class is within the range of <b>Start</b> to{" "}
          <b>End</b> inclusive. Please note that interval rates are only applied
          to classes.
          <br />
          If the interval you are looking at add has no upper bound (e.g. 4+
          students), then simply put a very large impossible number of students,
          such as 999, as the End value.
        </Form.Item>
        <Form.List name="intervalRates">
          {(intervalRates, { add, remove }): JSX.Element => {
            return (
              <>
                {intervalRates.map((intervalRate) => (
                  <Space key={intervalRate.key} align="start">
                    <Form.Item
                      {...intervalRate}
                      label="Start"
                      name={[intervalRate.name, "start"]}
                      fieldKey={[intervalRate.fieldKey, "start"]}
                      rules={[{ required: true }]}
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item
                      {...intervalRate}
                      label="End"
                      name={[intervalRate.name, "end"]}
                      fieldKey={[intervalRate.fieldKey, "end"]}
                      rules={[{ required: true }]}
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item
                      {...intervalRate}
                      label="Extra per hour"
                      name={[intervalRate.name, "rate"]}
                      fieldKey={[intervalRate.fieldKey, "rate"]}
                      rules={[{ required: true }]}
                    >
                      {payField}
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={(): void => remove(intervalRate.name)}
                    />
                  </Space>
                ))}

                <Form.Item>
                  <Button type="dashed" onClick={(): void => add()} block>
                    <PlusOutlined /> New interval
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default PayRatesModal;
