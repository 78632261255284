import { message, Modal, Alert, Divider } from "antd";

import {
  UpdateTeacherMutationVariables,
  useConfirmTimesheetMutation,
  useSelfQuery,
  useUpdateTeacherMutation,
  Week,
} from "../../../../generated/graphql";
import { weekDescriptions } from "../../../../util/descriptions";
import { wideModalWidth } from "../../../components/CommonStyles/page";
import EditableInput from "../../../components/Editable/EditableInput";
import MemosBody from "../../../components/MemosBody";

const AvailabilitiesForm: React.FC = () => {
  const { data } = useSelfQuery();
  const [updateTeacherMutation] = useUpdateTeacherMutation();
  const self = data?.getSelf;

  const saveToGraphQL = async <
    T extends keyof UpdateTeacherMutationVariables["input"]
  >(
    id: number,
    field: T,
    value: UpdateTeacherMutationVariables["input"][T]
  ): Promise<void> => {
    try {
      await updateTeacherMutation({
        variables: { input: { id, [field]: value } },
      });
      message.success("Automatically saved");
    } catch (error) {
      message.error(`Automatic save failed: ${error.message}`, 10);
    }
  };

  if (!self) {
    return <>Loading...</>;
  }

  return (
    <div>
      {
        "Availabilities (Days AND Times - Enter when you ARE available, not when you are not (e.g. 'Monday after 5pm' is CORRECT, but 'Cannot do monday before 5pm' is INCORRECT))"
      }
      <EditableInput
        onSave={(value): Promise<void> =>
          saveToGraphQL(self.id, "availabilities", value)
        }
        value={self.availabilities}
        isFocused={true}
      />
      Subjects
      <EditableInput
        onSave={(value): Promise<void> =>
          saveToGraphQL(self.id, "subjects", value)
        }
        value={self.subjects}
        isFocused={true}
      />
    </div>
  );
};

const ConfirmTimesheetModal: React.FC<{
  isVisible: boolean;
  comfirmableWeeks: Week[];
  onConfirm: () => void;
  onCancel: () => void;
}> = ({ isVisible, comfirmableWeeks, onConfirm, onCancel }) => {
  const [confirmTimesheet, { loading }] = useConfirmTimesheetMutation();

  return (
    <Modal
      visible={isVisible}
      title="Confirm Timesheet"
      okText="Confirm"
      cancelText="Cancel"
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={async (): Promise<void> => {
        try {
          await confirmTimesheet();
          onConfirm();
          message.success("Successfully confirmed timesheet");
        } catch (error) {
          message.error(`Failed to confirm timesheet: ${error.message}`, 10);
        }
      }}
      width={wideModalWidth}
    >
      <Alert
        message={
          <b style={{ color: "red" }}>
            To confirm your timesheet, read below carefully and press confirm at
            the end of this dialog box.
            <br />
            Confirming your timesheet now will confirm&nbsp;
            {comfirmableWeeks.map((week) => weekDescriptions[week]).join(", ")}.
          </b>
        }
        type="warning"
        showIcon
      />
      <Divider style={{ color: "red" }}>
        Please ensure the subjects you teach and your availabilities are up to
        date.
      </Divider>
      <AvailabilitiesForm />
      <Divider style={{ color: "red" }}>
        By agreeing to the memos below you confirm that you read them and fully
        understand what they say.
      </Divider>
      <MemosBody />
    </Modal>
  );
};

export default ConfirmTimesheetModal;
