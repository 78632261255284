import { Form, Modal, Alert, DatePicker, message } from "antd";
import {
  useCreateMakeupLessonMutation,
  Week,
} from "../../../../generated/graphql";
import { DATE_DISPLAY_FORMAT } from "../../../../util/constants";
import { weekDescriptions } from "../../../../util/descriptions";

interface Props {
  week?: Week;
  classId: number;
  onSuccessfulCreate: () => void;
  onCancel: () => void;
}

const CreateMakeupLessonModal: React.FC<Props> = ({
  week,
  classId,
  onSuccessfulCreate,
  onCancel,
}) => {
  const [createMakeupLesson, { loading }] = useCreateMakeupLessonMutation();
  const [form] = Form.useForm();

  return (
    <Modal
      visible={week != null}
      title={`Schedule makeup lesson (${week ? weekDescriptions[week] : "?"})`}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={async (): Promise<void> => {
        if (!week) {
          return;
        }

        try {
          const values = await form.validateFields();
          const input = {
            classId,
            lessonDate: values.lessonDate.format("YYYY-MM-DD"),
            week,
          };
          await createMakeupLesson({ variables: { input } });
          onSuccessfulCreate();
          form.resetFields();
          message.success("Makeup lesson scheduled successfully.");
        } catch (error) {
          message.error(
            `Server failed to schedule makeup lesson: ${error.message}`,
            10
          );
        }
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item>
          <Alert
            message={
              <>
                If the class was cancelled and it was due to teacher absence or
                all students were absent with notice, a makeup lesson should be
                conducted. Makeup lessons must be scheduled in advance.{" "}
                <b>
                  Holiday makeup lessons require a booking, please read the
                  Memos on how to do this.
                </b>
              </>
            }
            type="warning"
            showIcon
          />
        </Form.Item>
        <Form.Item
          name="lessonDate"
          label="Makeup lesson date"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker
            format={DATE_DISPLAY_FORMAT}
            placeholder={DATE_DISPLAY_FORMAT}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateMakeupLessonModal;
