import { useEffect, useState } from "react";
import { Button, Card, message } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import {
  ClassFieldsFragment,
  ClassFieldsFragmentDoc,
  TeacherClassLinkFieldsFragment,
  TeacherClassLinkFieldsFragmentDoc,
  UpdateClassMutationVariables,
  useClassesAttendanceRecordsLazyQuery,
  useUpdateClassMutation,
  SelfClassesQuery,
  CortexConfigurationQuery,
} from "../../../generated/graphql";

import { ApolloQueryResult, useApolloClient } from "@apollo/client";

import AttendanceTable from "../AttendanceTable";
import ModifyTeachersModal from "../ModifyTeachersModal";

import TopLeftPanel from "./components/TopLeftPanel";
import TopRightPanel from "./components/TopRightPanel";
import styled from "styled-components";
import {
  HEADER_BG_COLOUR,
  HEADER_DB_COLOUR_DELETED,
} from "../CommonStyles/card";
import useCortexPath from "../../../state/cortexPath";

export enum InitiatedContext {
  Admin = "Admin",
  Standard = "Standard",
}

const Body = styled.div`
  text-align: center;
`;

const ClassAttendanceCard: React.FC<{
  teacherClassLinkId?: number;
  classId: number;
  forceExpand?: boolean;
  initiatedContext: InitiatedContext;
  depth?: number;
  cortexConfiguration: CortexConfigurationQuery["getSystemConfiguration"];
  refetchAll?: () => Promise<ApolloQueryResult<SelfClassesQuery>>;
}> = ({
  teacherClassLinkId,
  classId,
  forceExpand,
  depth = 0,
  initiatedContext,
  cortexConfiguration,
  refetchAll,
}) => {
  const [updateClass] = useUpdateClassMutation();
  const [isExpand, setIsExpand] = useState(forceExpand || false);
  const [isModifyTeachers, setIsModifyTeachers] = useState(false);

  const [getClassesAttendanceRecords, { data, loading, refetch }] =
    useClassesAttendanceRecordsLazyQuery();

  useEffect(() => {
    if (classId && isExpand) {
      getClassesAttendanceRecords({
        variables: { filter: { id: classId } },
      });
    }
  }, [classId, isExpand]);

  const saveClassFieldToGraphQL = async <
    T extends keyof UpdateClassMutationVariables["input"]
  >(
    id: number,
    field: T,
    value: UpdateClassMutationVariables["input"][T]
  ): Promise<void> => {
    try {
      await updateClass({
        variables: { input: { id, [field]: value } },
      });
      message.success("Automatically saved");
    } catch (error) {
      message.error(`Automatic save failed: ${error.message}`, 10);
    }
  };

  const attendances = data?.getClasses[0].attendances;
  const teacherClassLinks = data?.getClasses[0].teacherClassLinks;
  const makeupLessons = data?.getClasses[0].makeupLessons;

  // Assume the teacher class link were already loaded before rendering the component.
  const client = useApolloClient();
  const klass: ClassFieldsFragment | null = client.readFragment({
    id: `Class:${classId}`,
    fragment: ClassFieldsFragmentDoc,
  });
  const teacherClassLink: TeacherClassLinkFieldsFragment | null =
    client.readFragment({
      id: `TeacherClassLink:${teacherClassLinkId}`,
      fragment: TeacherClassLinkFieldsFragmentDoc,
    });

  if (!klass || !cortexConfiguration) {
    // Should never happen in normal circumstances.
    return null;
  }

  const systemConfiguration = cortexConfiguration.system;
  const userConfiguration = cortexConfiguration.user;
  const isImpersonating =
    useCortexPath()?.params.impersonateTeacherId !== undefined;
  const isEditable =
    initiatedContext === InitiatedContext.Admin ||
    depth === 0 ||
    isImpersonating;

  return (
    <>
      <ModifyTeachersModal
        classId={klass.id}
        isVisible={isModifyTeachers}
        onClose={(): void => setIsModifyTeachers(false)}
      />
      <Card
        headStyle={{
          backgroundColor: !teacherClassLink?.isDeleted
            ? HEADER_BG_COLOUR
            : HEADER_DB_COLOUR_DELETED,
        }}
        title={
          <TopLeftPanel
            isEditable={isEditable}
            klass={klass}
            saveClassFieldToGraphQL={saveClassFieldToGraphQL}
          />
        }
        extra={
          <TopRightPanel
            teacherClassLink={
              !teacherClassLink?.isDeleted ? teacherClassLink : null
            }
            systemConfiguration={systemConfiguration}
            userConfiguration={userConfiguration}
            isEditableRole={isEditable}
            isEnableAllModifiableTaughtClassCheckboxes={isImpersonating}
            onModifyTeachers={(): void => setIsModifyTeachers(true)}
            klass={klass}
            refetchAll={refetchAll}
          />
        }
      >
        <Body>
          {forceExpand ? (
            <AttendanceTable
              attendances={attendances}
              teacherClassLinks={teacherClassLinks}
              viewableWeeks={systemConfiguration.viewableWeeks}
              modifiableWeeks={userConfiguration.modifiableWeeks}
              loading={loading}
              refetch={refetch!}
              classId={classId}
              makeupLessons={makeupLessons}
              initiatedContext={initiatedContext}
              depth={depth}
            />
          ) : isExpand ? (
            <>
              <Button
                icon={<UpOutlined />}
                type="primary"
                onClick={(): void => setIsExpand(false)}
              >
                Collapse
              </Button>
              <br />
              <br />
              <AttendanceTable
                attendances={attendances}
                teacherClassLinks={teacherClassLinks}
                viewableWeeks={systemConfiguration.viewableWeeks}
                modifiableWeeks={userConfiguration.modifiableWeeks}
                loading={loading}
                refetch={refetch!}
                classId={classId}
                makeupLessons={makeupLessons}
                initiatedContext={initiatedContext}
                depth={depth}
              />
            </>
          ) : (
            <Button
              icon={<DownOutlined />}
              type="primary"
              onClick={(): void => setIsExpand(true)}
            >
              Expand
            </Button>
          )}
        </Body>
      </Card>
    </>
  );
};

export default ClassAttendanceCard;
