import {
  Alert,
  Button,
  message,
  PageHeader,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useEffect } from "react";
import {
  GracePeriodStatus,
  TeacherFilterInput,
  useCortexConfigurationQuery,
  useResetViewStatusMutation,
  useTeachersLazyQuery,
  useTimesheetSummaryLazyQuery,
  useUpdateTeacherMutation,
} from "../../../../../../generated/graphql";
import { columns } from "./columns";
import {
  ClearOutlined,
  ExportOutlined,
  UserOutlined,
  UndoOutlined,
  RedoOutlined,
  RightOutlined,
} from "@ant-design/icons";
import download from "downloadjs";
import useCortexPath from "../../../../../../state/cortexPath";
import SearchBar, { Field } from "../../../../../components/SearchBar";

const { Title } = Typography;

const TimesheetProcessor: React.FC = () => {
  const cortexPath = useCortexPath();
  const database = cortexPath?.params.database;
  const {
    data: cortexConfiguration,
    loading: cortexConfigurationLoading,
    error: cortexConfigurationError,
  } = useCortexConfigurationQuery({
    fetchPolicy: "cache-first",
  });
  const [
    getTeachers,
    {
      data: teachersData,
      loading: teachersLoading,
      error: teachersError,
      refetch: refetchTeachers,
    },
  ] = useTeachersLazyQuery({
    variables: { filter: { isRecentlyWorkedOnly: true } },
  });
  const [
    getTimesheetSummary,
    {
      loading: exportLoading,
      data: timesheetSummaryData,
      error: timesheetSummaryError,
    },
  ] = useTimesheetSummaryLazyQuery();
  const [
    updateTeacher,
    { loading: updateTeacherLoading, error: updateTeacherError },
  ] = useUpdateTeacherMutation();
  const [
    resetViewStatus,
    { loading: viewStatusLoading, error: viewStatusError },
  ] = useResetViewStatusMutation();

  useEffect(() => {
    if (timesheetSummaryData && refetchTeachers) {
      const base64file = timesheetSummaryData.timesheetSummary;
      download(base64file, "cortex-nextgen-timesheet-processor-summary.xlsx");
      refetchTeachers();
    }
  }, [timesheetSummaryData]);

  useEffect(() => {
    getTeachers();
  }, []);

  useEffect(() => {
    const error =
      cortexConfigurationError ??
      teachersError ??
      timesheetSummaryError ??
      viewStatusError ??
      updateTeacherError;
    if (error) {
      message.error(error.message, 10);
    }
  }, [
    cortexConfigurationError,
    teachersError,
    timesheetSummaryError,
    viewStatusError,
    updateTeacherError,
  ]);

  const loading = cortexConfigurationLoading || teachersLoading;
  const isShowingCurrentPeriod =
    cortexConfiguration?.getSystemConfiguration.system
      .isTimesheetProcessorShowCurrentPeriod ?? true;

  const teacherViewRows = teachersData?.getTeachers
    .slice()
    .sort((a, b) => {
      let statusCmp;
      if (isShowingCurrentPeriod) {
        statusCmp =
          Number(b.isConfirmedCurrentPeriodTimesheet) -
          Number(a.isConfirmedCurrentPeriodTimesheet);
      } else {
        statusCmp =
          Number(b.isConfirmedCurrentPeriodTimesheet_TimesheetProcessorCache) -
          Number(a.isConfirmedCurrentPeriodTimesheet_TimesheetProcessorCache);
      }
      if (statusCmp === 0) {
        return a.surname.localeCompare(b.surname);
      } else {
        return statusCmp;
      }
    })
    .map((teacher) => ({
      dataIndex: String(teacher.id),
      firstName: teacher.firstName,
      surname: teacher.surname,
      timesheetStatus: isShowingCurrentPeriod ? (
        <>
          {teacher.isConfirmedPreviousPeriodTimesheet ? (
            <Tag color="green">✓ Previous period</Tag>
          ) : (
            <Tag color="magenta">✘ Previous period</Tag>
          )}
          {teacher.isConfirmedCurrentPeriodTimesheet ? (
            <Tag color="green">✓ Current period</Tag>
          ) : (
            <Tag color="magenta">✘ Current period</Tag>
          )}
        </>
      ) : (
        <>
          {teacher.isConfirmedPreviousPeriodTimesheet_TimesheetProcessorCache ? (
            <Tag color="green">✓ Previous period</Tag>
          ) : (
            <Tag color="magenta">✘ Previous period</Tag>
          )}
          {teacher.isConfirmedCurrentPeriodTimesheet_TimesheetProcessorCache ? (
            <Tag color="green">✓ Current period</Tag>
          ) : (
            <Tag color="magenta">✘ Current period</Tag>
          )}
        </>
      ),
      mistakeGracePeriod: teacher.isConfirmedCurrentPeriodTimesheet_TimesheetProcessorCache ? (
        teacher.gracePeriodStatus === GracePeriodStatus.Deactivated ? (
          <>
            <Tag color="#CDCDCD">Deactivated</Tag>{" "}
            <Tooltip title="Activate mistake grace period">
              <Button
                icon={<RightOutlined />}
                loading={updateTeacherLoading}
                onClick={() =>
                  updateTeacher({
                    variables: {
                      input: {
                        id: teacher.id,
                        gracePeriodStatus: GracePeriodStatus.Activated,
                      },
                    },
                  })
                }
              />
            </Tooltip>
          </>
        ) : teacher.gracePeriodStatus === GracePeriodStatus.Activated ? (
          <>
            <Tag color="orange">⊛ Waiting resubmission</Tag>{" "}
            <Tooltip title="Undo mistake grace period">
              <Button
                icon={<UndoOutlined />}
                loading={updateTeacherLoading}
                onClick={() =>
                  updateTeacher({
                    variables: {
                      input: {
                        id: teacher.id,
                        gracePeriodStatus: GracePeriodStatus.Deactivated,
                      },
                    },
                  })
                }
              />
            </Tooltip>
          </>
        ) : (
          <>
            <Tag color="green">✓ Resubmitted</Tag>{" "}
            <Tooltip title="Reactivate mistake grace period">
              <Button
                icon={<RedoOutlined />}
                loading={updateTeacherLoading}
                onClick={() =>
                  updateTeacher({
                    variables: {
                      input: {
                        id: teacher.id,
                        gracePeriodStatus: GracePeriodStatus.Activated,
                      },
                    },
                  })
                }
              />
            </Tooltip>
          </>
        )
      ) : (
        "N/A"
      ),
      viewStatus: teacher.isViewedInTimesheetProcessor ? (
        <>
          <Tag color="green">✓ Viewed</Tag>
          <Tooltip title="Reset view status">
            <Button
              icon={<ClearOutlined />}
              loading={updateTeacherLoading}
              onClick={() =>
                updateTeacher({
                  variables: {
                    input: {
                      id: teacher.id,
                      isViewedInTimesheetProcessor: false,
                    },
                  },
                })
              }
            />
          </Tooltip>
        </>
      ) : (
        <Tag color="magenta">✘ Not Viewed</Tag>
      ),
      actions: (
        <>
          <Tooltip title="Timesheet/Profile">
            <Button
              icon={<UserOutlined />}
              type="primary"
              loading={updateTeacherLoading}
              onClick={async () => {
                try {
                  await updateTeacher({
                    variables: {
                      input: {
                        id: teacher.id,
                        isViewedInTimesheetProcessor: true,
                      },
                    },
                  });
                } catch (error) {
                  // No-op
                }

                window.location.href = `/cortex/${database}/impersonate-timesheet-processor/${teacher.id}/timesheet`;
              }}
            >
              Timesheet/Profile
            </Button>
          </Tooltip>
        </>
      ),
    }));

  return (
    <PageHeader
      className="site-page-header"
      title={<Title level={2}>Timesheet Processor</Title>}
      extra={[
        <Button
          type="primary"
          loading={exportLoading}
          key="export1"
          icon={<ExportOutlined />}
          onClick={() => {
            getTimesheetSummary({
              variables: { isDeltaMode: true, isMarkAsViewed: true },
            });
          }}
        >
          Export and view unviewed confirmed timesheets
        </Button>,
        <Button
          key="export2"
          loading={exportLoading}
          icon={<ExportOutlined />}
          onClick={() => {
            getTimesheetSummary();
          }}
        >
          Export confirmed timesheets
        </Button>,
        <Button
          key="resetView"
          icon={<ClearOutlined />}
          loading={viewStatusLoading}
          onClick={() => resetViewStatus()}
        >
          Reset view status
        </Button>,
      ]}
    >
      {!isShowingCurrentPeriod && (
        <>
          <Alert
            showIcon
            type="warning"
            message="Previous period is being shown as the current period"
            description="As the previous timesheet period has finished, the previous timesheet period is being shown as the current period in the timesheet processor temporarily. The timesheet processor will begin showing the actual current period on the Thursday of the last week of the current period."
          />
          <br />
        </>
      )}
      <Alert
        showIcon
        type="info"
        message="Only those who have recently worked at The Brain are shown in the table."
      />
      <br />
      <SearchBar
        isLoading={loading}
        fields={columns
          .filter((column) => column.searchBarType !== undefined)
          .map(
            (column): Field => ({
              type: column.searchBarType!,
              name: column.dataIndex,
              displayName: column.title?.toString(),
            })
          )}
        onSearch={(fieldValues): void => {
          const filter: TeacherFilterInput = fieldValues.reduce(
            (variables, fieldValue) => ({
              ...variables,
              [fieldValue.field.name]: fieldValue.value,
            }),
            { isRecentlyWorkedOnly: true }
          );
          getTeachers({ variables: { filter } });
        }}
      />
      <Table
        size="small"
        columns={columns}
        dataSource={teacherViewRows}
        loading={loading ? { size: "large" } : undefined}
        pagination={false}
        sticky={true}
        scroll={{ x: "max-content" }}
      />
    </PageHeader>
  );
};

export default TimesheetProcessor;
