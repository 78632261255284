import { GlobalSessionActionTypes, GlobalSessionTypes } from "./types";

export const setAccessToken = (
  accessToken: string
): GlobalSessionActionTypes => ({
  type: GlobalSessionTypes.SetAccessToken,
  payload: {
    accessToken,
  },
});

export const logout = (): GlobalSessionActionTypes => ({
  type: GlobalSessionTypes.Logout,
});
