import { Modal } from "antd";
import { useCortexConfigurationQuery } from "../../../generated/graphql";
import ClassAttendanceCard, { InitiatedContext } from "../ClassAttendanceCard";
import { wideModalWidth } from "../CommonStyles/page";

const ClassViewerModal: React.FC<{
  classId?: number;
  initiatedContext: InitiatedContext;
  depth?: number;
  onClose: () => void;
}> = ({ classId, initiatedContext, depth = 0, onClose }) => {
  const { data: cortexConfiguration } = useCortexConfigurationQuery({
    fetchPolicy: "cache-first",
  });

  return (
    <Modal
      width={wideModalWidth}
      visible={classId != null}
      title="Class Viewer"
      confirmLoading={false}
      onCancel={onClose}
      footer={null}
    >
      {classId && cortexConfiguration && (
        <ClassAttendanceCard
          classId={classId}
          forceExpand={true}
          initiatedContext={initiatedContext}
          depth={depth}
          cortexConfiguration={cortexConfiguration.getSystemConfiguration}
        />
      )}
    </Modal>
  );
};

export default ClassViewerModal;
