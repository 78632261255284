import { TeacherFieldsFragment } from "../../../../../generated/graphql";
import { MessageComposeInput } from "../../../../components/Messaging/Compose";

export const mapTeacherToMessagingModalInput = (
  teacher: TeacherFieldsFragment
): MessageComposeInput => ({
  attachments: [],
  phones: [
    {
      name: teacher.fullName,
      phone: teacher.mobile,
      linkedEntity: { teacherId: teacher.id },
    },
  ].filter((phoneObj) => phoneObj.phone && phoneObj.phone.trim().length),
  emails: [
    {
      name: teacher.fullName,
      address: teacher.email,
      linkedEntity: { teacherId: teacher.id },
    },
  ].filter((emailObj) => emailObj.address && emailObj.address.trim().length),
});
