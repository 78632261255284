import { BackTop, Layout } from "antd";
import styled from "styled-components";

const { Content } = Layout;

const ContentArea = styled(Content)`
  height: 100%;
  background: #fff;
  overflow: auto;
`;

const RightContent: React.FC = (props): JSX.Element => {
  const { children } = props;

  return (
    <ContentArea id="contentArea">
      {children}
      <BackTop target={() => window.document.getElementById("contentArea")!} />
    </ContentArea>
  );
};

export default RightContent;
