export interface GlobalSessionState {
  accessToken: string | null;
}

export enum GlobalSessionTypes {
  SetAccessToken = "globalSession/SET_ACCESS_TOKEN",
  Logout = "globalSession/LOGOUT",
}

interface SetAccessTokenAction {
  type: typeof GlobalSessionTypes.SetAccessToken;
  payload: {
    accessToken: string;
  };
}

interface LogoutAction {
  type: typeof GlobalSessionTypes.Logout;
}

export type GlobalSessionActionTypes = SetAccessTokenAction | LogoutAction;
