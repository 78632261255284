import {
  Table,
  Card,
  Tooltip,
  Tag,
  Button,
  Popconfirm,
  message,
  Divider,
} from "antd";
import {
  ExtraActivityApprovalStatus,
  TimesheetFieldsFragment,
  UnitsType,
  useDeleteExtraActivityMutation,
} from "../../../../generated/graphql";
import { Moment } from "moment";

import { columns } from "../columns";

import { weekDescriptions } from "../../../../util/descriptions";
import { HEADER_BG_COLOUR } from "../../../components/CommonStyles/card";
import {
  GreenStatusText,
  RedStatusText,
  StatusText,
} from "../../../components/CommonStyles/week";
import { Body, TopRightContainer } from "../../../components/CommonStyles/card";
import { roundAndFix } from "../../../../util/maths";
import { EditOutlined, UserOutlined, DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";
import ClassViewerModal from "../../../components/ClassViewerModal";
import { InitiatedContext } from "../../../components/ClassAttendanceCard";
import NewAdjustmentModal from "./NewAdjustmentModal";
import styled from "styled-components";

const ErrorText = styled.div`
  color: red;
`;

const WarningText = styled.div`
  color: orange;
`;

const TimesheetWeekCard: React.FC<{
  isActive: boolean;
  timesheet: TimesheetFieldsFragment;
  startDate?: Moment;
  endDate?: Moment;
  refetch: () => void;
}> = ({ isActive, timesheet, startDate, endDate, refetch }) => {
  const [deleteExtraActivity] = useDeleteExtraActivityMutation();
  const [selectedClassViewerId, setSelectedClassViewerId] = useState<
    number | undefined
  >();
  const [selectedAdjustmentLinkId, setSelectedAdjustmentLinkId] = useState<
    number | undefined
  >();
  const itemRows = timesheet.items.map((item, idx) => ({
    key: idx,
    dataIndex: String(idx),
    activity: (
      <>
        {item.activity}
        <ErrorText>
          {item.errors.map((error) => (
            <>
              {error}
              <br />
            </>
          ))}
        </ErrorText>
        <WarningText>
          {item.warnings.map((warning) => (
            <>
              {warning}
              <br />
            </>
          ))}
        </WarningText>
      </>
    ),
    dateTime: item.dateTime,
    units:
      item.unitsType !== UnitsType.None ? (
        <>
          {item.units}{" "}
          <i>
            {item.unitsType !== UnitsType.Hours
              ? item.unitsType
              : item.units !== 1
              ? "Hours"
              : "Hour"}
          </i>
        </>
      ) : (
        "N/A"
      ),
    studentsPaymentStatus: item.studentsPaymentStatus ?? "N/A",
    teachers: item.teachers ? (
      <>
        {item.teachers}
        {item.teachersConflicts.map((conflictText, idx) => (
          <WarningText key={idx}>{conflictText}</WarningText>
        ))}
      </>
    ) : (
      "N/A"
    ),
    amount: `$${roundAndFix(item.amount)}`,
    approval:
      item.approvalStatus === ExtraActivityApprovalStatus.Approved ? (
        <Tag color="green">✓ Approved</Tag>
      ) : item.approvalStatus === ExtraActivityApprovalStatus.Pending ? (
        <Tooltip title="Your supervisor needs to approve the extra activity into order to be paid for it.">
          <Tag color="orange">⊛ Pending</Tag>
        </Tooltip>
      ) : item.approvalStatus === ExtraActivityApprovalStatus.Rejected ? (
        <Tooltip title="Your supervisor has rejected this extra activity and you won't be paid for it. Contact your supervisor for more information.">
          <Tag color="magenta">✘ Rejected</Tag>
        </Tooltip>
      ) : (
        <Tooltip title="Approvals are not applicable for teaching activities.">
          <Tag color="#CDCDCD">N/A</Tag>
        </Tooltip>
      ),
    actions:
      item.classId && item.teacherClassLinkId ? (
        <>
          <Tooltip title="View class">
            <Button
              icon={<UserOutlined />}
              onClick={() => setSelectedClassViewerId(item.classId!)}
            />
          </Tooltip>
          <Tooltip title="Adjust">
            <Button
              icon={<EditOutlined />}
              onClick={() =>
                setSelectedAdjustmentLinkId(item.teacherClassLinkId!)
              }
            />
          </Tooltip>
        </>
      ) : item.adjustmentId ? (
        <Tooltip title="Delete adjustment">
          <Popconfirm
            title="Are you sure you want to delete this adjustment?"
            okButtonProps={{ danger: true }}
            onConfirm={async () => {
              try {
                await deleteExtraActivity({
                  variables: { id: item.adjustmentId! },
                });
                refetch();
                message.success("Successfully deleted adjustment");
              } catch (error) {
                message.error(
                  `Failed to delete adjustment: ${error.message}`,
                  10
                );
              }
            }}
          >
            <Button icon={<DeleteOutlined />} />
          </Popconfirm>
        </Tooltip>
      ) : (
        "N/A"
      ),
  }));

  return (
    <>
      <NewAdjustmentModal
        teacherClassLinkId={selectedAdjustmentLinkId}
        week={timesheet.week}
        onSuccessfulCreate={() => {
          refetch();
          setSelectedAdjustmentLinkId(undefined);
        }}
        onCancel={() => setSelectedAdjustmentLinkId(undefined)}
      />
      <ClassViewerModal
        classId={selectedClassViewerId}
        onClose={() => setSelectedClassViewerId(undefined)}
        initiatedContext={InitiatedContext.Standard}
        depth={1}
      />
      <Card
        headStyle={{
          backgroundColor: HEADER_BG_COLOUR,
        }}
        title={
          <>
            {weekDescriptions[timesheet.week]}
            {startDate && endDate && (
              <>
                <Divider type="vertical" />
                <StatusText>
                  {startDate.format("D MMM").toUpperCase() +
                    " - " +
                    endDate.format("D MMM").toUpperCase()}
                </StatusText>
              </>
            )}
            <Divider type="vertical" />
            {timesheet.isConfirmed ? (
              <GreenStatusText>CONFIRMED</GreenStatusText>
            ) : (
              <RedStatusText>NOT CONFIRMED</RedStatusText>
            )}
            {isActive && (
              <>
                <Divider type="vertical" />
                <GreenStatusText>ACTIVE</GreenStatusText>
              </>
            )}
          </>
        }
        extra={
          <TopRightContainer>
            ${roundAndFix(timesheet.totalAmount)} ({timesheet.totalHours} Hour
            {timesheet.totalHours !== 1 ? "s" : ""})
          </TopRightContainer>
        }
      >
        <Body>
          {timesheet.items.length > 0 ? (
            <Table
              size="small"
              columns={columns}
              dataSource={itemRows}
              pagination={false}
              sticky={true}
              scroll={{ x: "max-content" }}
            />
          ) : (
            <>No activities in this week.</>
          )}
        </Body>
      </Card>
    </>
  );
};

export default TimesheetWeekCard;
