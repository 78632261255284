import { Alert, Button, message, Modal, Tooltip } from "antd";
import ClassesNavigator from "../ClassesNavigator";
import { UserAddOutlined } from "@ant-design/icons";
import { useNewTemporaryAttendanceMutation } from "../../../generated/graphql";
import { wideModalWidth } from "../CommonStyles/page";

export interface PushToClassAttendanceInfo {
  id: number;
  student: { id: number; fullName: string };
}

const ModalBody: React.FC<{
  fromAttendance: PushToClassAttendanceInfo;
  onClose: () => void;
}> = ({ fromAttendance, onClose }) => {
  const [
    newTemporaryAttendance,
    { loading },
  ] = useNewTemporaryAttendanceMutation();

  return (
    <>
      <Alert
        message={`${fromAttendance.student.fullName} will be added to the selected class for this timesheet period only. This feature is useful if the student temporarily attends another class instead of yours or does a makeup lesson in another class in the current timesheet period. The teacher in the other class will then be able mark the attendance for this student when they attend the class for this timesheet period only. Once the timesheet period ends, the student will be deleted from their class and attendance data merged into your class.`}
        type="warning"
        showIcon
      />
      <br />
      <ClassesNavigator
        actions={(klass): React.ReactNode =>
          !klass.isDeleted ? (
            <Tooltip title="Push to class">
              <Button
                icon={<UserAddOutlined />}
                type="primary"
                loading={loading}
                onClick={async (): Promise<void> => {
                  try {
                    await newTemporaryAttendance({
                      variables: {
                        input: {
                          fromAttendanceId: fromAttendance.id,
                          toClassId: klass.id,
                        },
                      },
                    });
                    onClose();
                    message.success("Successfully pushed student to class");
                  } catch (error) {
                    message.error(
                      `Failed to push student to class: ${error.message}`,
                      10
                    );
                  }
                }}
              />
            </Tooltip>
          ) : (
            "Deleted"
          )
        }
      />
    </>
  );
};

const PushToClassModal: React.FC<{
  fromAttendance?: PushToClassAttendanceInfo;
  onClose: () => void;
}> = ({ fromAttendance, onClose }) => {
  return (
    <Modal
      width={wideModalWidth}
      visible={fromAttendance != null}
      title={`Temporarily add ${fromAttendance?.student.fullName} to another class`}
      confirmLoading={false}
      onCancel={onClose}
      footer={null}
    >
      {fromAttendance && (
        <ModalBody fromAttendance={fromAttendance} onClose={onClose} />
      )}
    </Modal>
  );
};

export default PushToClassModal;
