import styled from "styled-components";

export const GreenStatusText = styled.span`
  font-size: 12px;
  color: green;
`;

export const RedStatusText = styled.span`
  font-size: 12px;
  color: red;
`;

export const StatusText = styled.span`
  font-size: 12px;
  color: ${(props) => props.color};
`;
