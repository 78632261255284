import { Button, Checkbox, message } from "antd";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import {
  TeacherClassLinkFieldsFragment,
  SystemConfiguration,
  UserConfiguration,
  ClassFieldsFragment,
  useUpdateTeacherClassLinkMutation,
  Week,
  SetPayParams,
  TeachingRole,
  useNewTeacherClassLinkMutation,
  SelfClassesQuery,
} from "../../../../generated/graphql";
import { IdcardOutlined } from "@ant-design/icons";
import DeleteBtn from "./DeleteBtn";
import styled from "styled-components";
import { TopRightContainer } from "../../CommonStyles/card";
import EditableDropdown from "../../Editable/EditableDropdown";
import { ApolloQueryResult } from "@apollo/client";
import { teacherRoleSelectOptions } from "../../../../util/selectOptions";

const defaultCheckboxOptions: CheckboxOptionType[] = [
  { label: "Brought forward", value: Week.Wk0, style: { display: "none" } },
  { label: "1", value: Week.Wk1 },
  { label: "2", value: Week.Wk2 },
  { label: "3", value: Week.Wk3 },
  { label: "4", value: Week.Wk4 },
  { label: "5", value: Week.Wk5 },
  { label: "6", value: Week.Wk6 },
  { label: "7", value: Week.Wk7 },
  { label: "8", value: Week.Wk8 },
  { label: "9", value: Week.Wk9 },
  { label: "10", value: Week.Wk10 },
  { label: "11", value: Week.Wk11 },
  { label: "H1", value: Week.WkH1 },
  { label: "H2", value: Week.WkH2 },
  { label: "H3", value: Week.WkH3 },
  { label: "H4", value: Week.WkH4 },
  { label: "H5", value: Week.WkH5 },
  { label: "H6", value: Week.WkH6 },
  { label: "H7", value: Week.WkH7 },
  { label: "H8", value: Week.WkH8 },
  { label: "H9", value: Week.WkH9 },
  { label: "H10", value: Week.WkH10 },
  { label: "Next term", value: Week.WkNextTerm },
];

const TopRow = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
`;

const BottomRow = styled.div`
  display: flex;
  float: right;
`;

const TeacherRole = styled.span`
  margin-right: 10px;
`;

const TopRightPanel: React.FC<{
  isEditableRole: boolean;
  teacherClassLink: TeacherClassLinkFieldsFragment | null;
  systemConfiguration: SystemConfiguration;
  userConfiguration: UserConfiguration;
  isEnableAllModifiableTaughtClassCheckboxes: boolean;
  onModifyTeachers: () => void;
  klass: ClassFieldsFragment;
  refetchAll?: () => Promise<ApolloQueryResult<SelfClassesQuery>>;
}> = ({
  isEditableRole,
  teacherClassLink,
  systemConfiguration,
  userConfiguration,
  isEnableAllModifiableTaughtClassCheckboxes,
  onModifyTeachers,
  klass,
  refetchAll,
}) => {
  const [newTeacherClassLink] = useNewTeacherClassLinkMutation();
  const [updateTeacherClassLink] = useUpdateTeacherClassLinkMutation();

  const taughtClassCheckboxOptions = defaultCheckboxOptions.map(
    (checkboxOption) => {
      if (
        !systemConfiguration.viewableWeeks.find(
          (viewableWeek) => viewableWeek === checkboxOption.value
        )
      ) {
        return {
          ...checkboxOption,
          disabled: true,
          style: { display: "none" },
        };
      } else if (
        !userConfiguration.modifiableWeeks.find(
          (modifiableWeek) => modifiableWeek === checkboxOption.value
        )
      ) {
        return {
          ...checkboxOption,
          disabled: !isEnableAllModifiableTaughtClassCheckboxes,
        };
      } else {
        return checkboxOption;
      }
    }
  );

  const onChange = async (
    checkedValues: CheckboxValueType[]
  ): Promise<void> => {
    if (!teacherClassLink) {
      return;
    }

    const oldPays = teacherClassLink.pays.map((pay) => ({
      week: pay.week,
      isPay: false,
    }));
    const newPays = checkedValues.map((checkedValue) => ({
      week: Week[checkedValue as keyof typeof Week],
      isPay: true,
    }));

    let setPayParam: SetPayParams | undefined;
    if (oldPays.length < newPays.length) {
      setPayParam = newPays.find(
        (newPay) => !oldPays.find((oldPay) => oldPay.week === newPay.week)
      );
    } else {
      setPayParam = oldPays.find(
        (oldPay) => !newPays.find((newPay) => newPay.week === oldPay.week)
      );
    }

    if (!setPayParam) {
      throw Error("Unknown error occurred finding the pay checkbox clicked.");
    }
    try {
      await updateTeacherClassLink({
        variables: {
          input: {
            id: teacherClassLink.id,
            setPays: [setPayParam],
          },
        },
      });
      message.success("Automatically saved");
    } catch (error) {
      message.error(
        `Failed to save when you taught the class: ${error.message}`,
        10
      );
    }
  };

  return (
    <TopRightContainer>
      <TopRow>
        {teacherClassLink && (
          <TeacherRole>
            <EditableDropdown
              disabled={!isEditableRole}
              onSave={async (newValue): Promise<void> => {
                await newTeacherClassLink({
                  variables: {
                    input: {
                      classId: klass.id,
                      teachingRole:
                        TeachingRole[newValue as keyof typeof TeachingRole],
                    },
                  },
                });
                if (refetchAll) {
                  await refetchAll();
                }
                message.success("Automatically saved");
              }}
              selectOptions={teacherRoleSelectOptions}
              value={teacherClassLink.teachingRole}
              required
            />
          </TeacherRole>
        )}
        <Button key="1" icon={<IdcardOutlined />} onClick={onModifyTeachers}>
          Modify teachers
        </Button>
        {teacherClassLink && (
          <>
            &nbsp;&nbsp;
            <DeleteBtn teacherClassLinkId={teacherClassLink.id} />
          </>
        )}
      </TopRow>
      {teacherClassLink && (
        <BottomRow>
          <i>Taught in weeks:</i>&nbsp;&nbsp;&nbsp;
          <Checkbox.Group
            options={taughtClassCheckboxOptions}
            value={teacherClassLink.pays.map((pay) => pay.week)}
            onChange={onChange}
          />
        </BottomRow>
      )}
    </TopRightContainer>
  );
};

export default TopRightPanel;
