import moment from "moment";
import styled from "styled-components";
import {
  ClassFieldsFragment,
  ClassType,
  Day,
} from "../../../../generated/graphql";
import {
  classTypeSelectOptions,
  daysSelectOptions,
} from "../../../../util/selectOptions";
import { TIME_DISPLAY_FORMAT } from "../../../../util/constants";
import EditableDropdown from "../../Editable/EditableDropdown";
import EditableInput from "../../Editable/EditableInput";
import EditableTimeRange from "../../Editable/EditableTimeRange";
import { SaveClassFieldToGraphQLFn } from "./common";
import { UserOutlined, TeamOutlined } from "@ant-design/icons";

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const ClassTypeContainer = styled.div`
  text-align: center;
  margin-right: 17px;
  min-width: 55px;
`;

const TopRow = styled.div`
  padding-right: 50px;
  display: flex;
`;

const BottomRow = styled.div`
  display: flex;
`;

const TopLeftPanel: React.FC<{
  isEditable: boolean;
  klass: ClassFieldsFragment;
  saveClassFieldToGraphQL: SaveClassFieldToGraphQLFn;
}> = ({ isEditable, klass, saveClassFieldToGraphQL }) => {
  return (
    <Container>
      <ClassTypeContainer>
        {klass.type === ClassType.Class ? (
          <TeamOutlined style={{ fontSize: "40px" }} />
        ) : (
          <UserOutlined style={{ fontSize: "40px" }} />
        )}
        <EditableDropdown
          disabled={!isEditable}
          onSave={(newValue: string): Promise<void> =>
            saveClassFieldToGraphQL(
              klass.id,
              "type",
              ClassType[newValue as keyof typeof ClassType]
            )
          }
          selectOptions={classTypeSelectOptions}
          value={klass.type}
          required
        />
      </ClassTypeContainer>
      <div>
        <TopRow>
          <EditableDropdown
            disabled={!isEditable}
            onSave={(newValue: string): Promise<void> =>
              saveClassFieldToGraphQL(
                klass.id,
                "day",
                Day[newValue as keyof typeof Day]
              )
            }
            selectOptions={daysSelectOptions}
            value={klass.day}
            required
          />
          <EditableTimeRange
            disabled={!isEditable}
            onSave={(value): Promise<void> =>
              saveClassFieldToGraphQL(klass.id, "time", {
                startTime: value[0],
                endTime: value[1],
              })
            }
            timeFormat={TIME_DISPLAY_FORMAT}
            value={[moment(klass.startTime), moment(klass.endTime)]}
          />
        </TopRow>
        <BottomRow>
          <EditableInput
            onSave={() => {
              /* no-op */
            }}
            value="Year"
            disabled
          />
          <EditableInput
            disabled={!isEditable}
            onSave={(newValue: string): Promise<void> =>
              saveClassFieldToGraphQL(klass.id, "grade", newValue)
            }
            value={klass.grade}
            required
          />
          <EditableInput
            disabled={!isEditable}
            onSave={(newValue: string): Promise<void> =>
              saveClassFieldToGraphQL(klass.id, "subject", newValue)
            }
            value={klass.subject}
            required
          />
          &nbsp;-&nbsp;
          <EditableInput
            disabled={!isEditable}
            onSave={(newValue: string): Promise<void> =>
              saveClassFieldToGraphQL(klass.id, "comment", newValue)
            }
            value={klass.comment}
          />
        </BottomRow>
      </div>
    </Container>
  );
};

export default TopLeftPanel;
