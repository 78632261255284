import { Modal } from "antd";
import { useEffect } from "react";
import {
  useCortexConfigurationQuery,
  useStudentsAttendancesLazyQuery,
} from "../../../generated/graphql";
import AttendanceTable from "../AttendanceTable";
import { InitiatedContext } from "../ClassAttendanceCard";
import { wideModalWidth } from "../CommonStyles/page";

const StudentAttendanceModal: React.FC<{
  studentId?: number;
  primaryAttendanceId?: number;
  initiatedContext: InitiatedContext;
  depth?: number;
  onClose: () => void;
}> = ({
  studentId,
  primaryAttendanceId,
  initiatedContext,
  depth = 0,
  onClose,
}) => {
  const { data: cortexConfiguration } = useCortexConfigurationQuery({
    fetchPolicy: "cache-first",
  });

  const [
    getStudentsAttendances,
    { data, loading, refetch },
  ] = useStudentsAttendancesLazyQuery();
  const student = data?.getStudents[0];

  useEffect(() => {
    if (studentId) {
      getStudentsAttendances({
        variables: { filter: { id: studentId } },
      });
    }
  }, [studentId]);

  const titleStr = `${student?.fullName}'s ${
    primaryAttendanceId == null
      ? "Attendance"
      : "other classes visited from selected class"
  }`;

  if (!cortexConfiguration) {
    return null;
  }

  const systemConfiguration = cortexConfiguration.getSystemConfiguration.system;
  const userConfiguration = cortexConfiguration.getSystemConfiguration.user;

  return (
    <Modal
      width={wideModalWidth}
      visible={studentId != null}
      title={titleStr}
      confirmLoading={false}
      onCancel={onClose}
      footer={null}
    >
      {studentId && refetch && (
        <AttendanceTable
          attendances={student?.attendances.filter(
            (attendance) =>
              primaryAttendanceId == null ||
              attendance.primaryAttendance?.id === primaryAttendanceId
          )}
          loading={loading}
          viewableWeeks={systemConfiguration.viewableWeeks}
          modifiableWeeks={userConfiguration.modifiableWeeks}
          refetch={refetch}
          initiatedContext={initiatedContext}
          depth={depth}
        />
      )}
    </Modal>
  );
};

export default StudentAttendanceModal;
