import { ColumnType } from "antd/lib/table";
import { FieldType } from "../../../../../components/SearchBar";

interface Column extends ColumnType<Column> {
  dataIndex: string;
  searchBarType?: FieldType;
}

export const columns: Column[] = [
  {
    title: "First Name",
    dataIndex: "firstName",
    searchBarType: FieldType.Text,
  },
  {
    title: "Surname",
    dataIndex: "surname",
    searchBarType: FieldType.Text,
  },
  {
    title: "Timesheet Status",
    dataIndex: "timesheetStatus",
  },
  {
    title: "Mistake Grace Period",
    dataIndex: "mistakeGracePeriod",
  },
  {
    title: "View Status",
    dataIndex: "viewStatus",
  },

  {
    title: "Actions",
    dataIndex: "actions",
  },
];
