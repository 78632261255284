import { match, matchPath } from "react-router-dom";

interface CortexParams {
  database?: string;
  impersonateTeacherId?: string;
}

export const cortexPaths = [
  "/cortex/:database/impersonate-timesheet-processor/:impersonateTeacherId",
  "/cortex/:database/impersonate/:impersonateTeacherId",
  "/cortex/:database",
];

const useCortexPath = (): match<CortexParams> | null =>
  matchPath<CortexParams>(window.location.pathname, {
    path: cortexPaths,
  });

export const isImpersonating_TimesheetProcessor = (): boolean =>
  useCortexPath()?.path.includes("impersonate-timesheet-processor") ?? false;

export default useCortexPath;
