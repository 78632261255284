import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Button,
  Typography,
  Radio,
  Tag,
  Checkbox,
  Tooltip,
  Row,
  Col,
  Table,
  Popconfirm,
  message,
} from "antd";
import {
  UserOutlined,
  DeleteOutlined,
  DollarOutlined,
  ExportOutlined,
  RollbackOutlined,
  PlusOutlined,
  OrderedListOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import moment from "moment";

import {
  ElevatedPrivileges,
  EntityType,
  NewTeacherMutation,
  TeacherFieldsFragment,
  TeacherFilterInput,
  UpdateTeacherMutationVariables,
  useDeleteTeacherMutation,
  useExportAllTeachersLazyQuery,
  useResetAccountMutation,
  useSelfQuery,
  useTeachersLazyQuery,
  useUpdateTeacherMutation,
} from "../../../../../generated/graphql";
import { columns } from "./columns";
import EditableInput from "../../../../components/Editable/EditableInput";
import { FetchResult } from "@apollo/client";
import NewTeacherModal from "./components/NewTeacherModal";
import PayRatesModal from "./components/PayRatesModal";
import SearchBar, { Field, FieldType } from "../../../../components/SearchBar";
import {
  DATE_DISPLAY_FORMAT,
  DATE_TIME_DISPLAY_FORMAT,
} from "../../../../../util/constants";
import useCortexPath from "../../../../../state/cortexPath";
import ProfilePicture from "../../../../components/ProfilePicture";
import MessageComposeModal, {
  MessageComposeInput,
} from "../../../../components/Messaging/Compose";
import EditableDate from "../../../../components/Editable/EditableDate";
import { mapTeacherToMessagingModalInput } from "./util";
import download from "downloadjs";
import AuditLogViewerModal from "../../../../components/Audit/AuditLogViewerModal";

const { Title } = Typography;

const Teachers: React.FC = () => {
  const cortexPath = useCortexPath();
  const database = cortexPath?.params.database;
  const [isShowNewTeacherModal, setIsShowNewTeacherModal] = useState(false);
  const [payRatesTeacherId, setPayRatesTeacherId] = useState<number>();
  const [messagingModalInput, setMessagingModalInput] = useState<
    MessageComposeInput | undefined
  >();
  const [selectedTeachers, setSelectedTeachers] = useState<
    TeacherFieldsFragment[]
  >([]);
  const [auditLogTeacherId, setAuditLogTeacherId] = useState<
    number | undefined
  >();

  const [getTeachers, { data, loading, error, variables, refetch }] =
    useTeachersLazyQuery();
  const [
    exportAllTeachers,
    {
      data: exportTeachersData,
      loading: exportTeachersLoading,
      error: exportTeachersError,
    },
  ] = useExportAllTeachersLazyQuery();
  const [deleteTeacher] = useDeleteTeacherMutation();
  const [resetAccount] = useResetAccountMutation();
  const [updateTeacherMutation] = useUpdateTeacherMutation();
  const { data: selfData } = useSelfQuery({
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (error) {
      message.error(`Connection failed: ${error.message}`, 10);
    }
  }, [error]);

  useEffect(() => {
    if (exportTeachersData) {
      const base64file = exportTeachersData.exportAllTeachers;
      download(base64file, "cortex-teachers.csv");
    }
  }, [exportTeachersData]);

  useEffect(() => {
    if (exportTeachersError) {
      message.error(
        `Export all teachers failed: ${exportTeachersError.message}`,
        10
      );
    }
  }, [exportTeachersError]);

  if (!selfData) {
    return null;
  }

  const self = selfData.getSelf;

  // Disable features on this page if the user doesn't have permission
  // or they are impersonating someone else.
  const isShowFullPage =
    self.elevatedPrivileges !== ElevatedPrivileges.None &&
    !cortexPath?.params.impersonateTeacherId;

  useEffect(() => {
    if (!isShowFullPage) {
      getTeachers({ variables: { filter: { id: self.id } } });
    }
  }, []);

  const saveToGraphQL = async <
    T extends keyof UpdateTeacherMutationVariables["input"]
  >(
    id: number,
    field: T,
    value: UpdateTeacherMutationVariables["input"][T]
  ) => {
    try {
      await updateTeacherMutation({
        variables: { input: { id, [field]: value } },
      });
      message.success("Automatically saved");
    } catch (error) {
      message.error(`Automatic save failed: ${error.message}`, 10);
    }
  };

  const teacherViewRows = (data?.getTeachers ?? []).map((teacher) => ({
    id: teacher.id,
    dataIndex: String(teacher.id),
    key: teacher.id,
    firstName: (
      <EditableInput
        required
        onSave={(value) => saveToGraphQL(teacher.id, "firstName", value)}
        value={teacher.firstName}
      />
    ),
    surname: (
      <EditableInput
        required
        onSave={(value) => saveToGraphQL(teacher.id, "surname", value)}
        value={teacher.surname}
      />
    ),
    profilePicture: (
      <ProfilePicture
        accountId={teacher.account.id}
        imageURL={teacher.account.profilePictureURL}
        onModifyImage={refetch!}
      />
    ),
    theBrainNetworkID: (
      <EditableInput
        onSave={(value) =>
          saveToGraphQL(teacher.id, "theBrainNetworkID", value)
        }
        disabled={!teacher.account.username}
        value={teacher.account.username}
      />
    ),
    email: (
      <EditableInput
        onSave={(value) => saveToGraphQL(teacher.id, "email", value)}
        value={teacher.email}
      />
    ),
    mobile: (
      <EditableInput
        onSave={(value) => saveToGraphQL(teacher.id, "mobile", value)}
        value={teacher.mobile}
      />
    ),
    subjects: (
      <EditableInput
        onSave={(value) => saveToGraphQL(teacher.id, "subjects", value)}
        value={teacher.subjects}
        rows={4}
      />
    ),
    academics: (
      <EditableInput
        onSave={(value) => saveToGraphQL(teacher.id, "academics", value)}
        value={teacher.academics}
        rows={4}
      />
    ),
    dateOfBirth: (
      <EditableDate
        onSave={(value) =>
          saveToGraphQL(teacher.id, "dateOfBirth", value.toISOString(true))
        }
        dateFormat={DATE_DISPLAY_FORMAT}
        value={moment(teacher.dateOfBirth)}
      />
    ),
    wwc: (
      <EditableInput
        onSave={(value) => saveToGraphQL(teacher.id, "wwc", value)}
        value={teacher.wwc}
      />
    ),
    wwcExpiry: (
      <EditableDate
        onSave={(value) =>
          saveToGraphQL(teacher.id, "wwcExpiry", value.toISOString(true))
        }
        dateFormat={DATE_DISPLAY_FORMAT}
        value={moment(teacher.wwcExpiry)}
      />
    ),
    availabilities: (
      <>
        <EditableInput
          onSave={(value) => saveToGraphQL(teacher.id, "availabilities", value)}
          value={teacher.availabilities}
          rows={4}
        />
        <Tooltip
          title={`Last updated ${moment(
            teacher.availabilitiesLastUpdated
          ).format(DATE_TIME_DISPLAY_FORMAT)}`}
        >
          {teacher.availabilitiesIsValid ? (
            <Tag color="green">✓ Up to date</Tag>
          ) : (
            <Tag color="magenta">✘ Out of date</Tag>
          )}
        </Tooltip>
      </>
    ),
    languages: (
      <EditableInput
        onSave={(value) => saveToGraphQL(teacher.id, "languages", value)}
        value={teacher.languages}
        rows={4}
      />
    ),
    elevatedPrivileges: (
      <Row style={{ width: 260 }}>
        <Col flex={1}>
          <Radio.Group
            value={teacher.elevatedPrivileges}
            onChange={(event) =>
              saveToGraphQL(
                teacher.id,
                "elevatedPrivileges",
                event.target.value
              )
            }
          >
            <Radio value={ElevatedPrivileges.None}>None</Radio>
            <Radio value={ElevatedPrivileges.Reception}>Reception</Radio>
            <Radio value={ElevatedPrivileges.Super}>Super</Radio>
          </Radio.Group>
        </Col>
        <Col flex={1}>
          <Checkbox
            checked={teacher.isExtrasApprover}
            onChange={(event) =>
              saveToGraphQL(
                teacher.id,
                "isExtrasApprover",
                event.target.checked
              )
            }
          >
            Extra approver
          </Checkbox>
          <Checkbox
            checked={teacher.isManageStudentPaymentInfo}
            onChange={(event) =>
              saveToGraphQL(
                teacher.id,
                "isManageStudentPaymentInfo",
                event.target.checked
              )
            }
          >
            Payment status
          </Checkbox>
        </Col>
      </Row>
    ),
    timesheetStatus: (
      <>
        {teacher.isConfirmedPreviousPeriodTimesheet ? (
          <Tag color="green">✓ Previous period</Tag>
        ) : (
          <Tag color="magenta">✘ Previous period</Tag>
        )}
        {teacher.isConfirmedCurrentPeriodTimesheet ? (
          <Tag color="green">✓ Current period</Tag>
        ) : (
          <Tag color="magenta">✘ Current period</Tag>
        )}
      </>
    ),
    gracePeriodStatus: teacher.gracePeriodStatus,
    secretNotes: (
      <EditableInput
        onSave={(value) => saveToGraphQL(teacher.id, "secretNotes", value)}
        value={teacher.secretNotes}
        rows={4}
      />
    ),
    disableAccount: (
      <Checkbox
        checked={teacher.account.isDisabled}
        onChange={(event) =>
          saveToGraphQL(teacher.id, "isDisabled", event.target.checked)
        }
      />
    ),
    dateCreated: moment(teacher.dateCreated).format(DATE_TIME_DISPLAY_FORMAT),
    dateModified: moment(teacher.dateModified).format(DATE_TIME_DISPLAY_FORMAT),
    actions: (
      <>
        <Tooltip title="Timesheet/Profile">
          <Button
            icon={<UserOutlined />}
            type="primary"
            href={`/cortex/${database}/impersonate/${teacher.id}`}
          />
        </Tooltip>
        <Tooltip title="Audit Log">
          <Button
            icon={<OrderedListOutlined />}
            onClick={() => setAuditLogTeacherId(teacher.id)}
          />
        </Tooltip>
        <Tooltip title="Pay Rates">
          <Button
            icon={<DollarOutlined />}
            onClick={() => setPayRatesTeacherId(teacher.id)}
          />
        </Tooltip>
        <Tooltip title="Message">
          <Button
            icon={<MessageOutlined />}
            onClick={() =>
              setMessagingModalInput(mapTeacherToMessagingModalInput(teacher))
            }
          />
        </Tooltip>
        <Tooltip title="Reset Account">
          <Popconfirm
            title="Are you sure you want to reset this account? The user can reactivate their account again via the login page."
            onConfirm={async () => {
              try {
                await resetAccount({
                  variables: { id: teacher.account.id },
                });
                getTeachers({ variables });
                message.success("Teacher's account successfully reset");
              } catch (error) {
                message.error(
                  `Failed to reset teacher's account: ${error.message}`,
                  10
                );
              }
            }}
          >
            <Button icon={<RollbackOutlined />} />
          </Popconfirm>
        </Tooltip>
        <Tooltip title="Delete Account">
          <Popconfirm
            title="Are you sure you want to delete this account? The account will be completely deleted from The Brain Network."
            okButtonProps={{ danger: true }}
            onConfirm={async () => {
              try {
                await deleteTeacher({
                  variables: { id: teacher.id },
                });
                getTeachers({ variables });
                message.success("Teacher's account successfully deleted");
              } catch (error) {
                message.error(
                  `Failed to delete teacher's account: ${error.message}`,
                  10
                );
              }
            }}
          >
            <Button icon={<DeleteOutlined />} />
          </Popconfirm>
        </Tooltip>
      </>
    ),
  }));

  return (
    <>
      <MessageComposeModal
        input={messagingModalInput}
        onClose={() => setMessagingModalInput(undefined)}
      />
      <PayRatesModal
        teacherId={payRatesTeacherId}
        onClose={() => setPayRatesTeacherId(undefined)}
      />
      <NewTeacherModal
        isVisible={isShowNewTeacherModal}
        onSuccessfulCreate={(teacher: FetchResult<NewTeacherMutation>) => {
          const newTeacher = teacher.data?.newTeacher;
          if (!newTeacher) {
            throw Error("Something went wrong creating teacher's account");
          }

          const filter = {
            firstName: newTeacher.firstName,
            surname: newTeacher.surname,
            email: newTeacher.email,
          };
          getTeachers({ variables: { filter } });
          setIsShowNewTeacherModal(false);
          message.success("Teacher's account successfully created");
        }}
        onCancel={() => setIsShowNewTeacherModal(false)}
      />
      <AuditLogViewerModal
        onClose={() => setAuditLogTeacherId(undefined)}
        teacherId={auditLogTeacherId}
        entity={
          auditLogTeacherId
            ? { entityType: EntityType.Teacher, entityId: auditLogTeacherId }
            : undefined
        }
      />
      <PageHeader
        title={
          <Title level={2}>{isShowFullPage ? "Teachers" : "Profile"}</Title>
        }
        extra={
          isShowFullPage
            ? [
                <Button
                  key="1"
                  icon={<ExportOutlined />}
                  onClick={() => exportAllTeachers()}
                  loading={exportTeachersLoading}
                >
                  Export all teachers
                </Button>,
                <Button
                  key="2"
                  icon={<MessageOutlined />}
                  onClick={() => {
                    const finalMessagingModalInput: MessageComposeInput = {
                      attachments: [],
                      phones: [],
                      emails: [],
                    };

                    selectedTeachers
                      .map(mapTeacherToMessagingModalInput)
                      .forEach((messagingModalInput) => {
                        finalMessagingModalInput.attachments.push(
                          ...messagingModalInput.attachments
                        );
                        finalMessagingModalInput.emails.push(
                          ...messagingModalInput.emails
                        );
                        finalMessagingModalInput.phones.push(
                          ...messagingModalInput.phones
                        );
                      });

                    setMessagingModalInput(finalMessagingModalInput);
                  }}
                >
                  Message selected
                </Button>,
                <Button
                  key="3"
                  icon={<PlusOutlined />}
                  type="primary"
                  onClick={() => setIsShowNewTeacherModal(true)}
                >
                  New
                </Button>,
              ]
            : undefined
        }
      >
        {isShowFullPage && (
          <SearchBar
            isLoading={loading}
            fields={columns
              .filter((column) => column.searchBarType !== undefined)
              .map(
                (column): Field => ({
                  type: column.searchBarType!,
                  name: column.dataIndex,
                  displayName: column.title?.toString(),
                })
              )
              .concat([
                {
                  type: FieldType.Toggle,
                  name: "isRecentlyWorkedOnly",
                  default: true,
                  displayName: "Show only those who've recently worked",
                },
              ])}
            onSearch={(fieldValues) => {
              const filter: TeacherFilterInput = fieldValues.reduce(
                (variables, fieldValue) => ({
                  ...variables,
                  [fieldValue.field.name]: fieldValue.value,
                }),
                {}
              );
              getTeachers({ variables: { filter } });
            }}
          />
        )}
        <Table
          size="small"
          columns={columns}
          dataSource={teacherViewRows}
          loading={loading ? { size: "large" } : undefined}
          pagination={false}
          sticky={true}
          scroll={{ x: "max-content" }}
          rowSelection={
            isShowFullPage
              ? {
                  type: "checkbox",
                  onChange: (selectedRowKeys) =>
                    setSelectedTeachers(
                      selectedRowKeys.map(
                        (teacherId) =>
                          (data?.getTeachers ?? []).find(
                            (teacher) => teacher.id === teacherId
                          )!
                      )
                    ),
                }
              : undefined
          }
        />
      </PageHeader>
    </>
  );
};

export default Teachers;
