import { useApolloClient } from "@apollo/client";
import { Form, Input, message, Modal, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import React, { useEffect, useState } from "react";

import {
  MessageTemplateFieldsFragment,
  MessageTemplateFieldsFragmentDoc,
  useCreateMessageTemplateMutation,
  useUpdateMessageTemplateMutation,
} from "../../../../../../../generated/graphql";
import { wideModalWidth } from "../../../../../../components/CommonStyles/page";
import { InboxOutlined } from "@ant-design/icons";

import ContextVariablesExplanation from "../../../../../../components/Messaging/Compose/ContextVariablesExplanation";
import { ALREADY_UPLOADED_TYPE } from "../../../../../../../util/constants";

const { Dragger } = Upload;

const CreateOrUpdateMessageTemplateModal: React.FC<{
  isVisible: boolean;
  existingMessageTemplateId?: number;
  onSuccessfulCreate: () => void;
  onCancel: () => void;
}> = ({
  isVisible,
  existingMessageTemplateId,
  onSuccessfulCreate,
  onCancel,
}) => {
  // Assume message template was already loaded on the Message Templates page, so grab it from the cache.
  const client = useApolloClient();
  const existingMessageTemplate: MessageTemplateFieldsFragment | null = client.readFragment(
    {
      id: `MessageTemplate:${existingMessageTemplateId}`,
      fragment: MessageTemplateFieldsFragmentDoc,
      fragmentName: "MessageTemplateFields",
    }
  );

  const [
    createMessageTemplate,
    { loading: createMessageTemplateLoading },
  ] = useCreateMessageTemplateMutation();

  const [
    updateMessageTemplate,
    { loading: updateMessageTemplateLoading },
  ] = useUpdateMessageTemplateMutation();

  const [form] = Form.useForm();
  const [attachments, setAttachments] = useState<UploadFile[]>([]);

  useEffect(() => {
    form.resetFields();
    setAttachments(
      (existingMessageTemplate?.attachments ?? []).map((attachment) => ({
        uid: attachment.id.toString(),
        name: attachment.filename,
        size: 0,
        type: ALREADY_UPLOADED_TYPE,
      }))
    );
  }, [existingMessageTemplateId, isVisible]);

  return (
    <Modal
      visible={isVisible}
      width={wideModalWidth}
      title={`${
        existingMessageTemplateId ? "Update" : "Create"
      } Message Template`}
      okText={existingMessageTemplateId ? "Update" : "Create"}
      cancelText="Cancel"
      confirmLoading={
        createMessageTemplateLoading || updateMessageTemplateLoading
      }
      onCancel={onCancel}
      onOk={async (): Promise<void> => {
        try {
          const values = await form.validateFields();
          const input = {
            name: values.name,
            subject: values.subject,
            templateMessage: values.templateMessage,
          };
          if (existingMessageTemplateId) {
            await updateMessageTemplate({
              variables: {
                input: {
                  ...input,
                  id: existingMessageTemplateId,
                  newAttachments: attachments.filter(
                    (attachment) => attachment.type !== ALREADY_UPLOADED_TYPE
                  ),
                  deletedAttachmentIds: (
                    existingMessageTemplate?.attachments ?? []
                  )
                    .filter(
                      (templateAttachment) =>
                        !attachments.find(
                          (attachment) =>
                            attachment.uid === templateAttachment.id.toString()
                        )
                    )
                    .map((templateAttachment) => templateAttachment.id),
                },
              },
            });
          } else {
            await createMessageTemplate({
              variables: { input: { ...input, attachments } },
            });
          }
          onSuccessfulCreate();
          form.resetFields();
        } catch (error) {
          message.error(
            `Server failed to create/update message template: ${error.message}`,
            10
          );
        }
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={existingMessageTemplate || undefined}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="subject" label="Subject (applies to emails only)">
          <Input />
        </Form.Item>
        <Form.Item
          name="attachments"
          label="Attachments (applies to emails only)"
        >
          <Dragger
            multiple
            customRequest={(options) => {
              if (
                !attachments.find(
                  (attachment) => attachment.name === options.file.name
                )
              ) {
                setAttachments((attachments) => [options.file, ...attachments]);
              }
            }}
            fileList={attachments}
            onRemove={(removedAttachment) =>
              setAttachments((attachments) =>
                attachments.filter(
                  (attachment) => attachment !== removedAttachment
                )
              )
            }
          >
            <p>
              <InboxOutlined />
            </p>
            <p>Click or drag file to this area to upload email attachments.</p>
          </Dragger>
        </Form.Item>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Form.Item
            name="templateMessage"
            label="Template Message"
            rules={[{ required: true }]}
            style={{ flex: 1 }}
          >
            <Input.TextArea rows={12} />
          </Form.Item>
          <ContextVariablesExplanation
            style={{
              width: "300px",
              padding: "15px",
              maxHeight: "300px",
              overflow: "auto",
            }}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default CreateOrUpdateMessageTemplateModal;
