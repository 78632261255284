import { Button, Image, message, Upload } from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import {
  useAddProfilePictureMutation,
  useDeleteProfilePictureMutation,
} from "../../../generated/graphql";
import "./index.css";
import styled from "styled-components";

const UploadText = styled.div`
  margin-top: 8;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ProfileImage = styled(Image)`
  max-height: 90px;
  width: auto;
  margin-right: 5px;
`;

const ProfilePicture: React.FC<{
  accountId: number;
  imageURL: string;
  onModifyImage: () => void;
}> = ({ accountId, imageURL, onModifyImage }) => {
  const [
    addProfilePicture,
    { loading: addProfilePictureLoading },
  ] = useAddProfilePictureMutation();
  const [deleteProfilePicture] = useDeleteProfilePictureMutation();

  const [showUpload, setShowUpload] = useState(false);

  const uploadButton = (
    <div>
      {addProfilePictureLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <UploadText>Upload</UploadText>
    </div>
  );

  return showUpload || addProfilePictureLoading ? (
    <Upload
      className="profile-picture-upload"
      listType="picture-card"
      showUploadList={false}
      customRequest={async (options) => {
        try {
          await addProfilePicture({
            variables: { accountId, picture: options.file },
          });
          setShowUpload(false);
          onModifyImage();
          message.success("Profile picture successfully uploaded");
        } catch (error) {
          message.error(
            `Failed to upload profile picture: ${error.message}`,
            10
          );
        }
      }}
    >
      {uploadButton}
    </Upload>
  ) : (
    <ImageContainer>
      <ProfileImage
        src={imageURL}
        preview={false}
        onError={() => setShowUpload(true)}
      />
      <Button
        size="small"
        icon={<DeleteOutlined />}
        onClick={async () => {
          try {
            await deleteProfilePicture({ variables: { accountId } });
            onModifyImage();
            message.success("Profile picture successfully deleted");
          } catch (error) {
            message.error(
              `Failed to delete profile picture: ${error.message}`,
              10
            );
          }
        }}
      />
    </ImageContainer>
  );
};

export default ProfilePicture;
