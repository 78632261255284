import { TimesheetRange } from "../generated/graphql";
import moment, { Moment } from "moment";

export const isDisabledDate = (
  date: Moment,
  timesheetRange: TimesheetRange
): boolean => {
  const midnightDate = moment(date).startOf("day");
  if (moment().isBefore(midnightDate)) {
    // Do not allow dates in the future to be selected.
    return true;
  }

  const start = moment(timesheetRange.start).startOf("day");
  const end = moment(timesheetRange.end).startOf("day");
  // Disable date if it
  // is not within the timesheet range. Do this for this year, last year, and last last year
  // since we don't know which year the user may want to select.
  return (
    (midnightDate.isBefore(start) || midnightDate.isAfter(end)) &&
    (midnightDate.isBefore(start.subtract(1, "year")) ||
      midnightDate.isAfter(end.subtract(1, "year"))) &&
    (midnightDate.isBefore(start.subtract(2, "years")) ||
      midnightDate.isAfter(end.subtract(2, "years")))
  );
};
