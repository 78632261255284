import { Button } from "antd";

import { BackwardOutlined } from "@ant-design/icons";
import { isImpersonating_TimesheetProcessor } from "../../../../state/cortexPath";

const ImpersonatorProfile = (
  self: {
    firstName: string;
    surname: string;
  },
  database: string
): JSX.Element => (
  <>
    Viewing&nbsp;
    <b>
      {self.firstName} {self.surname}&apos;s
    </b>
    Profile{" "}
    {isImpersonating_TimesheetProcessor() && "(via Timesheet Processor)"}&nbsp;
    <Button
      icon={<BackwardOutlined />}
      type="primary"
      href={
        isImpersonating_TimesheetProcessor()
          ? `/cortex/${database}/admin/timesheets/processor`
          : `/cortex/${database}/admin/teachers`
      }
    >
      Exit profile viewer
    </Button>
  </>
);

export default ImpersonatorProfile;
