import { ExtraActivityType } from "../../../generated/graphql";

export const extraActivityTypeDescriptions = {
  [ExtraActivityType.Reception]: "Reception",
  [ExtraActivityType.CurriculumBooklet]: "Curriculum (Per Booklet)",
  [ExtraActivityType.CurriculumByHour]: "Curriculum (Per Hour)",
  [ExtraActivityType.HomeworkHelp]: "Homework Help",
  [ExtraActivityType.MarkingCovering]: "Marking (Covering)",
  [ExtraActivityType.MarkingRostered]: "Marking (Rostered)",
  [ExtraActivityType.MarkingUnrostered]: "Marking (Unrostered)",
  [ExtraActivityType.Interview]: "Interview",
  [ExtraActivityType.Other]: "Other",
  [ExtraActivityType.Adjustment]: "Adjustment",
};
