import moment from "moment";
import {
  ClassFieldsFragment,
  TeacherClassLinkFieldsFragment,
  TeacherFieldsFragment,
  TeachingRole,
} from "../generated/graphql";

interface TeacherClassLinkWithTeacherFields
  extends TeacherClassLinkFieldsFragment {
  teacher: TeacherFieldsFragment;
}

interface ClassWithTeachersFields extends ClassFieldsFragment {
  teacherClassLinks?: TeacherClassLinkWithTeacherFields[];
}

export const classInfoStr = (klass: ClassWithTeachersFields): string => {
  const teacherClassLinks = klass.teacherClassLinks?.filter(
    (teacherClassLink) => !teacherClassLink.isDeleted
  );
  const teachersString =
    teacherClassLinks
      ?.filter(
        (teacherClassLink) =>
          teacherClassLink.teachingRole === TeachingRole.Teaching
      )
      .map((teacherClassLink) => teacherClassLink.teacher)
      .map((teacher) => teacher.fullName)
      .join(", ") ?? "";
  const noTeachersInClassMsg =
    !teacherClassLinks || teacherClassLinks.length > 0
      ? ""
      : " (No teachers in class)";

  const prefix = teachersString.length === 0 ? "" : teachersString + "'s ";
  const startTimeFmtString = moment(klass.startTime).format("hh:mm a");
  const endTimeFmtString = moment(klass.endTime).format("hh:mm a");
  const day = klass.day.slice(0, 3);

  return `${prefix}Year ${klass.grade} ${klass.subject} ${klass.type} (${day} ${startTimeFmtString} - ${endTimeFmtString})${noTeachersInClassMsg}`;
};
