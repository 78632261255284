import { Typography } from "antd";
import { CSSProperties } from "react";

const { Title, Paragraph } = Typography;

const ContextVariablesExplanation: React.FC<{ style?: CSSProperties }> = ({
  style,
}) => (
  <div style={style}>
    <Title level={5}>Context variables</Title>
    <Paragraph>
      Use context variables in the message to customise the message based on the
      recipient and context. For example -
      <br />
      <i>
        {
          "Dear {{ student.fullName }}, your {{ class.day }} {{ class.subject }} class is cancelled."
        }
      </i>
    </Paragraph>
    <Paragraph>
      <ul>
        <li>
          <b>parentGuardianName</b> (for parents, this will be their name, but
          for students, this will be one of the parents names)
        </li>
        <li>
          <b>student</b> (available on students and student parents only)
          <ul>
            <li>firstName</li>
            <li>surname</li>
            <li>fullName</li>
            <li>school</li>
            <li>phone</li>
            <li>phone2</li>
            <li>email</li>
            <li>isEmailPreferred</li>
            <li>dateOfBirth</li>
            <li>gender</li>
            <li>address</li>
            <li>grade</li>
            <li>languages</li>
            <li>medicalConditions</li>
            <li>parentGuardianName</li>
            <li>parentGuardianEmail</li>
            <li>parentGuardianPhone</li>
            <li>parentGuardianRelationship</li>
            <li>parentGuardianName2</li>
            <li>parentGuardianEmail2</li>
            <li>parentGuardianPhone2</li>
            <li>parentGuardianRelationship2</li>
            <li>notes</li>
            <li>dateCreated</li>
            <li>dateModified</li>
          </ul>
        </li>
        <li>
          <b>invoice</b> (available on invoices only)
          <ul>
            <li>generatedInvoiceId</li>
            <li>type</li>
            <li>typeHumanReadable</li>
            <li>isInvoice</li>
            <li>isReceipt</li>
            <li>isRefund</li>
            <li>dateCreated</li>
            <li>dateModified</li>
          </ul>
        </li>
        <li>
          <b>class</b> (available when message is composed from a class)
          <ul>
            <li>comment</li>
            <li>day</li>
            <li>type</li>
            <li>subject</li>
            <li>startTime</li>
            <li>endTime</li>
            <li>duration</li>
            <li>isDeleted</li>
            <li>grade</li>
            <li>dateCreated</li>
            <li>dateModified</li>
          </ul>
        </li>
        <li>
          <b>teacher</b> (available on teachers only)
          <ul>
            <li>firstName</li>
            <li>surname</li>
            <li>fullName</li>
            <li>email</li>
            <li>mobile</li>
            <li>subjects</li>
            <li>academics</li>
            <li>dateOfBirth</li>
            <li>wwc</li>
            <li>languages</li>
            <li>availabilities</li>
            <li>secretNotes</li>
            <li>elevatedPrivileges</li>
            <li>isExtrasApprover</li>
            <li>isManageStudentPaymentInfo</li>
            <li>isConfirmedPreviousPeriodTimesheet</li>
            <li>isConfirmedCurrentPeriodTimesheet</li>
            <li>isConfirmedPreviousPeriodTimesheet_TimesheetProcessorCache</li>
            <li>isConfirmedCurrentPeriodTimesheet_TimesheetProcessorCache</li>
            <li>isViewedInTimesheetProcessor</li>
            <li>dateCreated</li>
            <li>dateModified</li>
          </ul>
        </li>
      </ul>
    </Paragraph>
  </div>
);

export default ContextVariablesExplanation;
