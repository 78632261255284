import { useMemo, useState } from "react";
import { Button, message, Popconfirm, Table, Tooltip } from "antd";

import "./index.css";

import { getTableColumns, MakeupLessons, TeacherClassLinks } from "./columns";
import {
  ClassesAttendanceRecordsQuery,
  EntityType,
  StudentsAttendancesQuery,
  useDeleteAttendanceMutation,
  useDeleteMakeupLessonMutation,
  useUpdateAttendanceForEntireClassMutation,
  useUpdateAttendanceMutation,
  Week,
} from "../../../generated/graphql";
import AttendanceField from "./components/AttendanceField";
import {
  DeleteOutlined,
  OrderedListOutlined,
  UserOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import PushToClassModal, {
  PushToClassAttendanceInfo,
} from "../PushToClassModal";
import StudentAttendanceModal from "../StudentAttendanceModal";
import ClassViewerModal from "../ClassViewerModal";
import { ApolloQueryResult } from "@apollo/client";
import { classInfoStr } from "../../../util/classInfo";
import { InitiatedContext } from "../ClassAttendanceCard";
import Footer from "./components/Footer";
import Payment from "./components/Payment";
import styled from "styled-components";
import useCortexPath from "../../../state/cortexPath";
import AuditLogViewerModal from "../Audit/AuditLogViewerModal";
import { getParts } from "./components/AttendanceField/attendanceData";
import CreateMakeupLessonModal from "./components/CreateMakeupLessonModal";

type Attendances = (
  | ClassesAttendanceRecordsQuery["getClasses"][0]
  | StudentsAttendancesQuery["getStudents"][0]
)["attendances"];

type RefetchAttendanceFn = () => Promise<
  ApolloQueryResult<ClassesAttendanceRecordsQuery | StudentsAttendancesQuery>
>;

const NoRecordsFoundText = styled.div`
  text-align: center;
`;

const AttendanceTable: React.FC<{
  attendances?: Attendances;
  teacherClassLinks?: TeacherClassLinks;
  viewableWeeks: Week[];
  modifiableWeeks: Week[];
  loading: boolean;
  classId?: number;
  makeupLessons?: MakeupLessons;
  initiatedContext: InitiatedContext;
  depth?: number;
  refetch: RefetchAttendanceFn;
}> = ({
  attendances,
  loading,
  classId,
  makeupLessons,
  teacherClassLinks,
  modifiableWeeks,
  viewableWeeks,
  initiatedContext,
  depth = 0,
  refetch,
}) => {
  const isImpersonating =
    useCortexPath()?.params.impersonateTeacherId !== undefined;
  const isEditable =
    initiatedContext === InitiatedContext.Admin ||
    depth === 0 ||
    isImpersonating;

  const [isShowDeletedAttendances, setIsShowDeletedAttendances] = useState(
    initiatedContext === InitiatedContext.Admin || isImpersonating || depth > 0
  );
  const [updateAttendance] = useUpdateAttendanceMutation();
  const [updateAttendanceForEntireClass] =
    useUpdateAttendanceForEntireClassMutation();
  const [deleteAttendance] = useDeleteAttendanceMutation();
  const [deleteMakeupLesson] = useDeleteMakeupLessonMutation();

  const [pushToClassSelectedAttendance, setPushToClassSelectedAttendance] =
    useState<PushToClassAttendanceInfo>();
  const [studentAttendanceModalId, setStudentAttendanceModalId] =
    useState<number>();
  const [
    studentAttendanceModalPrimaryAttendanceId,
    setStudentAttendanceModalPrimaryAttendanceId,
  ] = useState<number>();
  const [linkedClassViewerId, setLinkedClassViewerId] = useState<number>();
  const [auditLogAttendanceId, setAuditLogAttendanceId] = useState<number>();
  const [createMakeupLessonWeek, setCreateMakeupLessonWeek] = useState<Week>();

  const saveAttendanceToGraphQL = async (
    id: number,
    week: Week,
    attendanceData: string
  ): Promise<void> => {
    try {
      await updateAttendance({
        variables: {
          input: { id, setAttendance: { week, attendanceData } },
        },
      });
      message.success("Automatically saved");
    } catch (error) {
      message.error(`Automatic save failed: ${error.message}`, 10);
    }
  };

  const saveAttendanceForEntireClassToGraphQL = async (
    week: Week,
    attendanceData: string
  ): Promise<void> => {
    try {
      if (!classId) {
        throw Error(
          "Cannot update attendance for entire class when attendance table is not shown in a class context."
        );
      }

      await updateAttendanceForEntireClass({
        variables: {
          input: { id: classId, setAttendance: { week, attendanceData } },
        },
      });
      message.success("Automatically saved for entire class");
    } catch (error) {
      message.error(
        `Automatic save for entire class failed: ${error.message}`,
        10
      );
    }
  };

  const pastWeeksWithNoAttendances = useMemo(() => {
    if (loading || !classId || modifiableWeeks.length === 0) {
      return [];
    }

    const weeksInOrder = Object.values(Week);
    const latestModifiableWeek = weeksInOrder.indexOf(
      modifiableWeeks[modifiableWeeks.length - 1]
    );
    const pastWeeks = weeksInOrder.filter(
      (week) => weeksInOrder.indexOf(week) <= latestModifiableWeek
    );
    const pastWeeksSet = new Set<Week>(pastWeeks);
    attendances
      ?.filter((attendance) => !attendance.isDeleted)
      .filter((attendance) => !attendance.primaryAttendance)
      .forEach((attendance) => {
        attendance.weekAttendanceInfos.forEach((weekAttendanceInfo) => {
          if (
            getParts(weekAttendanceInfo.attendanceData).attendanceData.length
          ) {
            pastWeeksSet.delete(weekAttendanceInfo.week);
          }
        });
      });
    return [...pastWeeksSet];
  }, [attendances, modifiableWeeks, loading, classId]);

  const attendanceRows = attendances
    ?.slice() // Since original array is immutable and cannot be sorted
    .sort((a, b) => {
      if (a.isDeleted != b.isDeleted) {
        return a.isDeleted ? 1 : -1;
      } else {
        return a.student.fullName.localeCompare(b.student.fullName);
      }
    })
    .filter((attendance) => isShowDeletedAttendances || !attendance.isDeleted)
    .map((attendance) => {
      const attendanceDatas = Object.keys(Week).reduce((acc, week) => {
        let disabledMsg;
        if (!isImpersonating) {
          if (initiatedContext === InitiatedContext.Standard) {
            if (depth > 0) {
              disabledMsg =
                "The editor is disabled as attendance can only be edited from the primary attendance page or via admin pages.";
            } else if (
              !modifiableWeeks.find((modifiableWeek) => modifiableWeek === week)
            ) {
              disabledMsg =
                "The editor is disabled due to trying to edit a week outside the current timesheet period.";
            }
          }
        }

        return {
          ...acc,
          [week]: (
            <AttendanceField
              onSave={(newAttendanceString): Promise<void> =>
                saveAttendanceToGraphQL(
                  attendance.id,
                  Week[week as Week],
                  newAttendanceString
                )
              }
              disabledMsg={disabledMsg}
              depth={depth}
              initiatedContext={initiatedContext}
              onCopyToAll={
                classId
                  ? (newAttendanceString): Promise<void> =>
                      saveAttendanceForEntireClassToGraphQL(
                        Week[week as Week],
                        newAttendanceString
                      )
                  : undefined
              }
              value={
                attendance.weekAttendanceInfos.find(
                  (weekAttendanceInfo) =>
                    weekAttendanceInfo.week === Week[week as Week]
                )?.attendanceData
              }
              tempAttendances={attendance.tempAttendances}
              week={Week[week as Week]}
              classDuration={attendance.class.duration}
              onPushToClass={() => setPushToClassSelectedAttendance(attendance)}
            />
          ),
        };
      }, {} as Record<Week, React.ReactNode>);

      return {
        id: attendance.id,
        dataIndex: String(attendance.id),
        key: attendance.id,
        payment: (
          <Payment
            attendance={attendance}
            setLinkedClassViewerId={setLinkedClassViewerId}
            setStudentAttendanceModalPrimaryAttendanceId={
              setStudentAttendanceModalPrimaryAttendanceId
            }
            setStudentAttendanceModalId={setStudentAttendanceModalId}
          />
        ),
        class: classInfoStr(attendance.class),
        student: `${attendance.student.fullName} (Yr${attendance.student.grade})`,
        actions: (
          <>
            <Tooltip title={classId == null ? "View Class" : "Attendance"}>
              <Button
                icon={<UserOutlined />}
                onClick={() =>
                  classId == null
                    ? setLinkedClassViewerId(attendance.class.id)
                    : setStudentAttendanceModalId(attendance.student.id)
                }
              />
            </Tooltip>
            <Tooltip title="Audit Log">
              <Button
                icon={<OrderedListOutlined />}
                onClick={() => setAuditLogAttendanceId(attendance.id)}
              />
            </Tooltip>
            <br />
            {!attendance.isDeleted ? (
              <>
                <Tooltip title="Push to class">
                  <Button
                    icon={<ExportOutlined />}
                    onClick={() => setPushToClassSelectedAttendance(attendance)}
                  />
                </Tooltip>
                {isEditable && (
                  <Tooltip title="Delete">
                    <Popconfirm
                      title="Are you really sure you want to delete this student from the class? Their attendance record won't be deleted, you'll be able to add the student back later if you wish."
                      okButtonProps={{ danger: true }}
                      onConfirm={async (): Promise<void> => {
                        try {
                          await deleteAttendance({
                            variables: { id: attendance.id },
                          });
                          message.success(
                            "Successfully deleted student from class"
                          );
                        } catch (error) {
                          message.error(
                            `Failed to delete student from class: ${error.message}`,
                            10
                          );
                        }
                      }}
                    >
                      <Button icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                )}
              </>
            ) : (
              <>&nbsp;Deleted</>
            )}
          </>
        ),
        ...attendanceDatas,
      };
    });

  const footer = (
    <Footer
      classId={classId != null && isEditable ? classId : undefined}
      onAddNewStudent={refetch}
      showDeletedAttendances={isShowDeletedAttendances}
      onChangeDeletedAttendances={setIsShowDeletedAttendances}
    />
  );

  if (attendanceRows?.length === 0) {
    // If no students in class, don't render the empty table
    return (
      <>
        <NoRecordsFoundText>
          No {classId != null ? "students" : "classes"} found.
        </NoRecordsFoundText>
        <br />
        {footer}
      </>
    );
  }

  return (
    <>
      <PushToClassModal
        onClose={() => {
          refetch();
          setPushToClassSelectedAttendance(undefined);
        }}
        fromAttendance={pushToClassSelectedAttendance}
      />
      <ClassViewerModal
        classId={linkedClassViewerId}
        onClose={() => setLinkedClassViewerId(undefined)}
        initiatedContext={initiatedContext}
        depth={depth + 1}
      />
      <StudentAttendanceModal
        primaryAttendanceId={studentAttendanceModalPrimaryAttendanceId}
        studentId={studentAttendanceModalId}
        onClose={() => {
          setStudentAttendanceModalId(undefined);
          setStudentAttendanceModalPrimaryAttendanceId(undefined);
        }}
        initiatedContext={initiatedContext}
        depth={depth + 1}
      />
      <AuditLogViewerModal
        onClose={() => setAuditLogAttendanceId(undefined)}
        entity={
          auditLogAttendanceId
            ? {
                entityType: EntityType.Attendance,
                entityId: auditLogAttendanceId,
              }
            : undefined
        }
      />
      {classId && (
        <CreateMakeupLessonModal
          week={createMakeupLessonWeek}
          classId={classId}
          onSuccessfulCreate={async () => {
            setCreateMakeupLessonWeek(undefined);
            await refetch();
          }}
          onCancel={() => setCreateMakeupLessonWeek(undefined)}
        />
      )}
      <Table
        rowClassName={(record): string =>
          // Show deleted rows as red
          attendances?.find((attendance) => attendance.id === record.key)
            ?.isDeleted
            ? "table-row-deleted"
            : ""
        }
        size="small"
        columns={getTableColumns(
          classId != null,
          viewableWeeks,
          modifiableWeeks,
          initiatedContext,
          depth,
          pastWeeksWithNoAttendances,
          (week) => setCreateMakeupLessonWeek(week),
          async (ids) => {
            await Promise.all(
              ids.map((id) => deleteMakeupLesson({ variables: { id } }))
            );
            await refetch();
          },
          teacherClassLinks,
          makeupLessons
        )}
        dataSource={attendanceRows}
        loading={loading ? { size: "large" } : undefined}
        pagination={false}
        sticky={true}
        scroll={{ x: "max-content" }}
        footer={(): React.ReactNode => footer}
      />
    </>
  );
};

export default AttendanceTable;
