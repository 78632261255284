import { ColumnType } from "antd/lib/table";

interface Column extends ColumnType<Column> {
  dataIndex: string;
  editable?: boolean;
}

export const columns: Column[] = [
  {
    title: "Activity",
    dataIndex: "activity",
    width: 400,
  },
  { title: "Date / Time", dataIndex: "dateTime", width: 185 },
  { title: "Units", dataIndex: "units", width: 85 },
  {
    width: 150,
    title: "Students' Payment Status",
    dataIndex: "studentsPaymentStatus",
  },
  {
    width: 250,
    title: "Teachers",
    dataIndex: "teachers",
  },
  { title: "Amount", dataIndex: "amount", width: 80 },
  { title: "Approval", dataIndex: "approval", width: 80 },
  { title: "Actions", dataIndex: "actions", width: 80 },
];
