import { PageHeader, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useInvoiceToMessageMutation } from "../../../../../generated/graphql";
import MessageComposeModal, {
  MessageComposeInput,
} from "../../../../components/Messaging/Compose";
import { mapStudentAndInvoiceToMessagingModalInput } from "./util";

const { Title } = Typography;

const InvoicingServiceConnectorMessage: React.FC = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const [messagingModalInput, setMessagingModalInput] = useState<
    MessageComposeInput | undefined
  >();

  const [
    invoiceToMessage,
    { data: invoiceToMessageData, error: invoiceToMessageError },
  ] = useInvoiceToMessageMutation({
    variables: { invoiceId: Number(invoiceId) },
    errorPolicy: "all",
  });

  useEffect(() => {
    invoiceToMessage();
  }, []);

  const student = invoiceToMessageData?.invoiceToMessage.student;
  const messageTemplateAttachment =
    invoiceToMessageData?.invoiceToMessage.messageTemplateAttachment;

  useEffect(() => {
    if (student && messageTemplateAttachment) {
      setMessagingModalInput({
        ...mapStudentAndInvoiceToMessagingModalInput(
          Number(invoiceId),
          student
        ),
        attachments: [messageTemplateAttachment],
        templateId: 1,
      });
    }
  }, [student, messageTemplateAttachment]);

  return (
    <>
      <MessageComposeModal
        input={messagingModalInput}
        onClose={() => setMessagingModalInput(undefined)}
      />
      <PageHeader title={<Title level={2}>Invoicing Service Platform</Title>}>
        {invoiceToMessageError
          ? invoiceToMessageError.message
          : !invoiceToMessageData && (
              <>
                <Spin size="large" />
                <br />
                Please wait...
              </>
            )}
      </PageHeader>
    </>
  );
};

export default InvoicingServiceConnectorMessage;
