import { PageHeader, Typography } from "antd";
import MemosBody from "../../components/MemosBody";

const { Title } = Typography;

const Memos: React.FC = () => (
  <PageHeader title={<Title level={2}>Memos</Title>}>
    <MemosBody />
  </PageHeader>
);

export default Memos;
