import { ColumnType } from "antd/lib/table";

interface Column extends ColumnType<Column> {
  dataIndex: string;
}

export const columns: Column[] = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    fixed: "right",
    width: 100,
  },
];
