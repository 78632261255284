import { Modal, Typography } from "antd";

const { Title, Paragraph } = Typography;

const InstructionsModal: React.FC<{
  isVisible: boolean;
  onClose: () => void;
}> = ({ isVisible, onClose }) => (
  <Modal
    visible={isVisible}
    title="Instructions"
    confirmLoading={false}
    onCancel={onClose}
    footer={null}
  >
    <Title level={3}>Teaching</Title>
    <Paragraph>
      You need to mark the roll for the classes you taught in order to be paid
      (all via the <b>Attendance</b> page). Cortex will use this information to
      calculate your <b>Timesheet</b>.
    </Paragraph>
    <Paragraph>
      <ul>
        <li>
          Add classes to your attendance record by selecting{" "}
          <b>Add existing class</b> or <b>Create new class</b>. You should use
          add existing class if the class already exists (e.g.
          training/assisting/covering someone&apos;s class, or became the
          teacher of an already existing class).
        </li>
        <li>
          To mark a student as attended, write the date in the field for that
          week (click on the empty field to edit it).
        </li>
        <li>
          Ensure you check the box for the weeks you taught the class for each
          class.
        </li>
      </ul>
    </Paragraph>
    <Title level={3}>Extra Activities</Title>
    <Paragraph>
      These are essentially any non-teaching activities such as marking,
      reception, curriculum and anything else. Go to the <b>Extras</b> page
      (accessible from the navigation bar) and add your activities there.
      They&apos;ll be displayed on the timesheet.
    </Paragraph>
    <Title level={3}>Confirming your timesheet</Title>
    <Paragraph>
      You must confirm your timesheet to get paid. Go to the <b>Timesheet</b>{" "}
      page, review the timesheet carefully then you may confirm the timesheet
      there. Please read the <b>Memos</b> carefully as those are
      instructions/terms and conditions regularly updated by management and is
      relevant to ensuring you get paid correctly.
    </Paragraph>
  </Modal>
);

export default InstructionsModal;
