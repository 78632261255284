import { ColumnType } from "antd/lib/table";

interface Column extends ColumnType<Column> {
  dataIndex: string;
  editable?: boolean;
}

const commonColumn: ColumnType<Column> = {
  width: 250,
};

export const columns: Column[] = [
  {
    ...commonColumn,
    title: "Type",
    dataIndex: "type",
  },
  { ...commonColumn, title: "Description / Subject", dataIndex: "description" },
  { ...commonColumn, title: "Date / Time", dataIndex: "dateTime" },
  { ...commonColumn, title: "Units", dataIndex: "units" },
  { ...commonColumn, title: "Approval", dataIndex: "approval" },
  { title: "Actions", dataIndex: "actions", fixed: "right" },
];
