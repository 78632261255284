import { FetchResult } from "@apollo/client";
import { SignIn as Logout } from "@atlaskit/atlassian-navigation";

import { LogoutMutation } from "../../../../generated/graphql";

const Profile = (
  self: { firstName: string; surname: string },
  logout: () => Promise<FetchResult<LogoutMutation>>
): JSX.Element => (
  <>
    <b>
      {self.firstName} {self.surname}
    </b>
    <Logout
      tooltip="Logout"
      onClick={(): void => {
        logout();
        window.location.href = `${process.env.REACT_APP_LEGACY_SERVICE_URL}/TheBrainNetworkServicesManager/?logout`;
      }}
    />
  </>
);

export default Profile;
