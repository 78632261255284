import { FetchResult } from "@apollo/client";
import {
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  InputNumber,
  Alert,
} from "antd";

import {
  ExtraActivityType,
  NewExtraActivityMutation,
  TimesheetRange,
  useGetExtraActivityTypesQuery,
  useNewExtraActivityMutation,
} from "../../../../generated/graphql";

import {
  DATE_NO_YEAR_FORMAT,
  TIME_DISPLAY_FORMAT,
} from "../../../../util/constants";
import { Week } from "../../../../generated/graphql";
import { useState, useEffect } from "react";
import { extraActivityTypeDescriptions } from "../util";
import { weekDescriptions } from "../../../../util/descriptions";
import TimePickerAutoAccept from "../../../components/Time/TimePickerAutoAccept";
import { isDisabledDate } from "../../../../util/date";
import useCortexPath from "../../../../state/cortexPath";

const { Option } = Select;

const NewActivityModal: React.FC<{
  isVisible: boolean;
  week: Week;
  onSuccessfulCreate: (klass: FetchResult<NewExtraActivityMutation>) => void;
  onCancel: () => void;
  timesheetRange: TimesheetRange;
}> = ({ isVisible, week, onSuccessfulCreate, onCancel, timesheetRange }) => {
  const cortexPath = useCortexPath();
  const database = cortexPath?.params.database;
  const cortexLocation = database?.includes("burwood")
    ? "burwood"
    : "hurstville";
  const {
    data: extraActivityTypesData,
    loading: extraActivityTypesLoading,
  } = useGetExtraActivityTypesQuery();
  const [newActivity, { loading }] = useNewExtraActivityMutation();
  const [form] = Form.useForm();
  const [type, setType] = useState<ExtraActivityType | undefined>();

  useEffect(() => {
    form.resetFields(["units"]);
  }, [type]);

  const onTypeChange = (value: ExtraActivityType) => {
    setType(value);
  };

  return (
    <Modal
      visible={isVisible}
      title={`New Activity (${weekDescriptions[week]})`}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={async (): Promise<void> => {
        try {
          const values = await form.validateFields();
          const input = {
            dateTime: {
              date: values.date,
              startTime: values.startTime,
              endTime: values.endTime,
            },
            type: values.type,
            week,
            description: values.description,
            units: values.units,
            pay: values.pay,
          };
          const extraActivity = await newActivity({
            variables: { input },
          });
          onSuccessfulCreate(extraActivity);

          form.resetFields();
          message.success("Successfully created extra activity");
        } catch (error) {
          message.error(
            `Server failed to create extra activity: ${error.message}`,
            10
          );
        }
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item>
          <Alert
            message="Only add non-teaching activities as Extra Activities. If you want to add a teaching activity, you should instead add/create the class via the Attendance page and mark the roll."
            type="warning"
            showIcon
          />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            onChange={onTypeChange}
            loading={extraActivityTypesLoading}
            disabled={extraActivityTypesLoading}
          >
            {(extraActivityTypesData?.getExtraActivityTypes ?? []).map(
              (extraActivityType) => (
                <Option key={extraActivityType} value={extraActivityType}>
                  {extraActivityTypeDescriptions[extraActivityType]}
                </Option>
              )
            )}
          </Select>
        </Form.Item>
        {(type === ExtraActivityType.MarkingCovering ||
          type === ExtraActivityType.MarkingRostered ||
          type === ExtraActivityType.MarkingUnrostered ||
          type === ExtraActivityType.HomeworkHelp ||
          type === ExtraActivityType.Reception) &&
          cortexLocation === "hurstville" && (
            <Form.Item>
              <Alert
                message="The selected activity requires clocking in and clocking out using the fingerprint scanner. See Memos for more info."
                type="info"
                showIcon
              />
            </Form.Item>
          )}
        <Form.Item
          name="date"
          label="Date"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker
            format={DATE_NO_YEAR_FORMAT}
            placeholder={DATE_NO_YEAR_FORMAT}
            disabledDate={(date) => isDisabledDate(date, timesheetRange)}
          />
        </Form.Item>
        <Form.Item
          name="startTime"
          label="Start Time"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TimePickerAutoAccept
            showNow={false}
            format={TIME_DISPLAY_FORMAT}
            placeholder={TIME_DISPLAY_FORMAT}
          />
        </Form.Item>
        <Form.Item
          name="endTime"
          label="End Time"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TimePickerAutoAccept
            showNow={false}
            format={TIME_DISPLAY_FORMAT}
            placeholder={TIME_DISPLAY_FORMAT}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label={
            type === ExtraActivityType.MarkingCovering
              ? "Description / Subject (Please also enter the name of the person you are covering)"
              : type === ExtraActivityType.HomeworkHelp
              ? "Description / Subject (Please enter the names and subjects of students you helped. If there is no one booked in and you did marking instead, mention this here)"
              : "Description / Subject"
          }
          rules={[
            {
              required: type !== ExtraActivityType.Reception,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {type === ExtraActivityType.CurriculumBooklet && (
          <Form.Item
            name="units"
            label="No. Booklets"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
        )}
        {type === ExtraActivityType.Other && (
          <Form.Item
            name="pay"
            label="Amount ($)"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default NewActivityModal;
