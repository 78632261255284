import {
  Button,
  message,
  PageHeader,
  Result,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { CheckOutlined, UndoOutlined, CloseOutlined } from "@ant-design/icons";

import {
  ExtraActivityApprovalStatus,
  ExtraActivityType,
  useGetApprovalsQuery,
  useUpdateApprovalStatusMutation,
} from "../../../../../generated/graphql";
import {
  DATE_NO_YEAR_FORMAT,
  TIME_DISPLAY_FORMAT,
} from "../../../../../util/constants";
import { roundAndFix } from "../../../../../util/maths";
import { extraActivityTypeDescriptions } from "../../../extraActivities/util";
import { columns } from "./columns";

const { Title } = Typography;

const Approvals: React.FC = () => {
  const {
    data: approvalsData,
    loading: approvalsLoading,
    error: approvalsError,
  } = useGetApprovalsQuery();
  const [
    updateApprovalStatus,
    { loading: updateExtraActivityLoading },
  ] = useUpdateApprovalStatusMutation();
  const [isShowAllApprovals, setIsShowAllApprovals] = useState(false);

  const saveExtraActivityApprovalStatus = async (
    id: number,
    approvalStatus: ExtraActivityApprovalStatus
  ) => {
    try {
      await updateApprovalStatus({
        variables: {
          id,
          approvalStatus,
        },
      });
      message.success("Approval status successfully saved");
    } catch (error) {
      message.error(`Failed to save approval status: ${error.message}`, 10);
    }
  };

  const approvalRows =
    approvalsData?.getApprovals
      .filter(
        (approval) =>
          isShowAllApprovals ||
          approval.approvalStatus === ExtraActivityApprovalStatus.Pending
      )
      .map((extraActivity) => ({
        key: extraActivity.id,
        dataIndex: String(extraActivity.id),
        name: extraActivity.teacher.fullName,
        type: extraActivityTypeDescriptions[extraActivity.type],
        description: extraActivity.description,
        dateTime:
          extraActivity.dateTime.startTime &&
          extraActivity.dateTime.endTime &&
          extraActivity.dateTime.date ? (
            <>
              {moment(extraActivity.dateTime.date).format(
                DATE_NO_YEAR_FORMAT
              )}
              <br />
              {moment(extraActivity.dateTime.startTime).format(
                TIME_DISPLAY_FORMAT
              )}{" "}
              -{" "}
              {moment(extraActivity.dateTime.endTime).format(
                TIME_DISPLAY_FORMAT
              )}
            </>
          ) : (
            extraActivity.dateTime.freeText
          ),
        week: extraActivity.week,
        units:
          extraActivity.type === ExtraActivityType.CurriculumBooklet ? (
            <>
              {extraActivity.units} <i>Booklets</i>
            </>
          ) : extraActivity.type === ExtraActivityType.Other ? (
            "N/A"
          ) : (
            <>
              {extraActivity.duration} <i>Hours</i>
            </>
          ),
        approval:
          extraActivity.approvalStatus ===
          ExtraActivityApprovalStatus.Approved ? (
            <Tag color="green">✓ Approved</Tag>
          ) : extraActivity.approvalStatus ===
            ExtraActivityApprovalStatus.Pending ? (
            <Tag color="orange">⊛ Pending</Tag>
          ) : (
            <Tag color="magenta">✘ Rejected</Tag>
          ),
        amount: `$${roundAndFix(extraActivity.pay)}`,
        actions:
          extraActivity.approvalStatus ===
          ExtraActivityApprovalStatus.Pending ? (
            <>
              <Button
                loading={updateExtraActivityLoading}
                onClick={() =>
                  saveExtraActivityApprovalStatus(
                    extraActivity.id,
                    ExtraActivityApprovalStatus.Approved
                  )
                }
                icon={<CheckOutlined />}
                style={{ backgroundColor: "green", color: "white" }}
              >
                Approve
              </Button>
              &nbsp;
              <Button
                loading={updateExtraActivityLoading}
                onClick={() =>
                  saveExtraActivityApprovalStatus(
                    extraActivity.id,
                    ExtraActivityApprovalStatus.Rejected
                  )
                }
                icon={<CloseOutlined />}
                style={{ backgroundColor: "red", color: "white" }}
              >
                Reject
              </Button>
            </>
          ) : (
            <Button
              loading={updateExtraActivityLoading}
              onClick={() =>
                saveExtraActivityApprovalStatus(
                  extraActivity.id,
                  ExtraActivityApprovalStatus.Pending
                )
              }
              icon={<UndoOutlined />}
              style={{ backgroundColor: "orange", color: "white" }}
            >
              Undo
            </Button>
          ),
      })) ?? [];

  return (
    <>
      <PageHeader
        title={<Title level={2}>Approvals</Title>}
        extra={
          <span>
            Show all approvals &nbsp;
            <Switch
              checked={isShowAllApprovals}
              onChange={setIsShowAllApprovals}
            />
          </span>
        }
      >
        {approvalsError ? (
          <Result status="error" title={approvalsError.message} />
        ) : !approvalsLoading && approvalRows.length === 0 ? (
          <Result status="info" title="No approvals found" />
        ) : (
          <Table
            size="small"
            columns={columns}
            dataSource={approvalRows}
            loading={approvalsLoading ? { size: "large" } : undefined}
            pagination={false}
            sticky={true}
            scroll={{ x: "max-content" }}
          />
        )}
      </PageHeader>
    </>
  );
};

export default Approvals;
