import {
  ClassesAttendanceRecordsQuery,
  EmailAddressInput,
} from "../../../../../generated/graphql";
import { MessageComposeInput } from "../../../../components/Messaging/Compose";

export const mapClassToMessagingModalInput = (
  klass: ClassesAttendanceRecordsQuery["getClasses"][0]
): MessageComposeInput => {
  const studentPhones = klass.attendances
    .filter((attendance) => !attendance.isDeleted)
    .flatMap((attendance) => [
      {
        name: attendance.student.fullName,
        phone: attendance.student.phone,
        linkedEntity: {
          student: { studentId: attendance.student.id },
          classId: klass.id,
        },
      },
      {
        name: attendance.student.fullName,
        phone: attendance.student.phone2,
        linkedEntity: {
          student: { studentId: attendance.student.id },
          classId: klass.id,
        },
      },
      {
        name: `${attendance.student.parentGuardianName} (Parent of ${attendance.student.fullName})`,
        phone: attendance.student.parentGuardianPhone,
        linkedEntity: {
          student: {
            studentId: attendance.student.id,
            parentNo: 1,
          },
          classId: klass.id,
        },
      },
      {
        name: `${attendance.student.parentGuardianName2} (Parent of ${attendance.student.fullName})`,
        phone: attendance.student.parentGuardianPhone2,
        linkedEntity: {
          student: {
            studentId: attendance.student.id,
            parentNo: 2,
          },
          classId: klass.id,
        },
      },
    ])
    .filter((phoneObj) => phoneObj.phone && phoneObj.phone.trim().length);
  const teacherPhones = klass.teacherClassLinks
    .filter((teacherClassLink) => !teacherClassLink.isDeleted)
    .map((teacherClassLink) => ({
      name: `${teacherClassLink.teacher.fullName} (Teacher)`,
      phone: teacherClassLink.teacher.mobile,
      linkedEntity: {
        teacherId: teacherClassLink.teacher.id,
        classId: klass.id,
      },
    }))
    .filter((phoneObj) => phoneObj.phone && phoneObj.phone.trim().length);

  const studentEmails = klass.attendances
    .filter((attendance) => !attendance.isDeleted)
    .flatMap((attendance) => [
      {
        name: attendance.student.fullName,
        address: attendance.student.email,
        linkedEntity: {
          student: {
            studentId: attendance.student.id,
          },
          classId: klass.id,
        },
      },
      {
        name: `${attendance.student.parentGuardianName} (Parent of ${attendance.student.fullName})`,
        address: attendance.student.parentGuardianEmail,
        linkedEntity: {
          student: {
            studentId: attendance.student.id,
            parentNo: 1,
          },
          classId: klass.id,
        },
      },
      {
        name: `${attendance.student.parentGuardianName2} (Parent of ${attendance.student.fullName})`,
        address: attendance.student.parentGuardianEmail2,
        linkedEntity: {
          student: {
            studentId: attendance.student.id,
            parentNo: 2,
          },
          classId: klass.id,
        },
      },
    ])
    .filter(
      (emailObj) => emailObj.address && emailObj.address.trim().length
    ) as EmailAddressInput[];
  const teacherEmails = klass.teacherClassLinks
    .filter((teacherClassLink) => !teacherClassLink.isDeleted)
    .map((teacherClassLink) => ({
      name: `${teacherClassLink.teacher.fullName} (Teacher)`,
      address: teacherClassLink.teacher.email,
      linkedEntity: {
        teacherId: teacherClassLink.teacher.id,
        classId: klass.id,
      },
    }))
    .filter(
      (emailObj) => emailObj.address && emailObj.address.trim().length
    ) as EmailAddressInput[];

  return {
    phones: [...studentPhones, ...teacherPhones],
    emails: [...studentEmails, ...teacherEmails],
    attachments: [],
  };
};
