import { useState } from "react";
import { Select, message } from "antd";
import { TransferDirection, TransferItem } from "antd/lib/transfer";
import TransferTable from "../../../components/TransferTable";
import {
  ClassesWithTeachersQuery,
  SelfClassesDocument,
  TeachingRole,
  useClassesWithTeachersQuery,
  useDeleteTeacherClassLinkMutation,
  useNewTeacherClassLinkMutation,
  useTeachersLazyQuery,
} from "../../../../generated/graphql";

const { Option } = Select;

const ModifyTeachersTransferTable: React.FC<{
  classId: number;
}> = ({ classId }) => {
  const [timeoutObj, setTimeoutObj] = useState<NodeJS.Timeout | null>(null);
  const [newteachingRoles, setNewteachingRoles] = useState<
    { teacherId: number; teachingRole: TeachingRole }[]
  >([]);
  const [
    newTeacherClassLink,
    { loading: newLinkLoading },
  ] = useNewTeacherClassLinkMutation({
    fetchPolicy: "no-cache",
    refetchQueries: [{ query: SelfClassesDocument }],
  });
  const [
    deleteTeacherClassLink,
    { loading: deleteLinkLoading },
  ] = useDeleteTeacherClassLinkMutation({
    fetchPolicy: "no-cache",
  });
  const [
    getTeachers,
    { data: teachersQuery, loading: teachersLoading },
  ] = useTeachersLazyQuery();
  const {
    data: klass,
    loading: classesLoading,
    refetch: refetchClasses,
  } = useClassesWithTeachersQuery({
    variables: { filter: { id: classId } },
  });

  const searchedTeachers =
    teachersQuery?.getTeachers.map((teacher) => ({
      ...teacher,
      teacherClassLink: { teacher: { id: teacher.id } },
    })) ?? [];
  const existingTeachers =
    klass?.getClasses[0].teacherClassLinks
      .filter((teacherClassLink) => !teacherClassLink.isDeleted)
      .map((teacherClassLink) => ({
        teacherClassLink,
        ...teacherClassLink.teacher,
      }))
      .sort((a, b) => a.firstName.localeCompare(b.fullName)) ?? [];

  const allTeachers = searchedTeachers
    .filter(
      (searchedTeacher) =>
        !existingTeachers.find(
          (existingTeacher) => existingTeacher.id === searchedTeacher.id
        )
    )
    .concat(existingTeachers)
    .map((teacher) => ({
      ...teacher,
      key: teacher.id.toString(),
    }))
    .sort((a, b) => a.firstName.localeCompare(b.fullName));

  const onChange = async (
    _targetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ): Promise<void> => {
    try {
      await Promise.all(
        moveKeys.map(async (teacherIdStr) => {
          const teacherId = Number(teacherIdStr);
          if (direction === "right") {
            return newTeacherClassLink({
              variables: {
                input: {
                  classId,
                  teacherId: Number(teacherIdStr),
                  teachingRole:
                    newteachingRoles.find(
                      (newteachingRole) =>
                        newteachingRole.teacherId === teacherId
                    )?.teachingRole ?? TeachingRole.Teaching,
                },
              },
            });
          } else {
            const teacherClassLinkId = existingTeachers.find(
              (teacher) => teacher.id === teacherId
            )?.teacherClassLink.id;
            if (!teacherClassLinkId) {
              throw Error("TeacherClassLink ID was null.");
            }
            return deleteTeacherClassLink({
              variables: {
                id: teacherClassLinkId,
              },
            });
          }
        })
      );
      message.success("Teachers in class successfully modified");
    } catch (error) {
      message.error(`Failed to modify teachers: ${error.message}`, 10);
    }
    refetchClasses();
  };

  const onSearch = (direction: TransferDirection, value: string): void => {
    if (direction === "left") {
      if (timeoutObj) {
        clearTimeout(timeoutObj);
      }
      if (value.length === 0) return;
      setTimeoutObj(
        setTimeout(
          () => getTeachers({ variables: { filter: { fullName: value } } }),
          1000
        )
      );
    }
  };

  const columns = [
    {
      dataIndex: "fullName",
      title: "Name",
    },
    {
      dataIndex: "academics",
      title: "Academic Background",
    },
    {
      dataIndex: "teacherClassLink",
      title: "Role",
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, react/display-name
      render: (
        teacherClassLink: ClassesWithTeachersQuery["getClasses"][0]["teacherClassLinks"][0]
      ) => (
        <Select
          value={
            teacherClassLink.teachingRole ??
            newteachingRoles.find(
              (task) => task.teacherId === teacherClassLink.teacher.id
            )?.teachingRole ??
            TeachingRole.Teaching
          }
          onClick={(e): void => e.stopPropagation()}
          onChange={async (value): Promise<void> => {
            if (teacherClassLink.teachingRole) {
              try {
                await newTeacherClassLink({
                  variables: {
                    input: {
                      classId,
                      teacherId: teacherClassLink.teacher.id,
                      teachingRole: value,
                    },
                  },
                });
                message.success("Teachers in class successfully modified");
              } catch (error) {
                message.error(
                  `Failed to modify teachers: ${error.message}`,
                  10
                );
              }
              refetchClasses();
            } else {
              setNewteachingRoles((newteachingRoles) => {
                const existing = newteachingRoles.find(
                  (task) => task.teacherId === teacherClassLink.teacher.id
                );
                if (existing) {
                  existing.teachingRole = value;
                  return [...newteachingRoles];
                } else {
                  return [
                    ...newteachingRoles,
                    {
                      teacherId: teacherClassLink.teacher.id,
                      teachingRole: value,
                    },
                  ];
                }
              });
            }
          }}
        >
          {Object.keys(TeachingRole).map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      ),
    },
  ];

  return (
    <TransferTable
      dataSource={allTeachers}
      targetKeys={existingTeachers.map((existingTeacher) =>
        existingTeacher.id.toString()
      )}
      showSearch={true}
      onSearch={onSearch}
      loading={
        newLinkLoading || deleteLinkLoading || teachersLoading || classesLoading
      }
      listStyle={{ width: "50%" }}
      onChange={onChange}
      operations={["Add", "Remove"]}
      filterOption={(inputValue: string, item: TransferItem): boolean =>
        item.fullName.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
      leftColumns={columns}
      rightColumns={columns}
      titles={["Teachers not in class", "Existing teachers in class"]}
    />
  );
};

export default ModifyTeachersTransferTable;
