import { Button, Input, Space, Switch, Form, Select, Radio } from "antd";
import { SelectValue } from "antd/lib/select";
import { useState } from "react";
import styled from "styled-components";
import { Moment } from "moment";
import { SearchOutlined } from "@ant-design/icons";
import { TIME_DISPLAY_FORMAT } from "../../../util/constants";
import TimePickerAutoAccept from "../Time/TimePickerAutoAccept";

const { Option } = Select;

export enum FieldType {
  Text,
  Toggle,
  Select,
  Time,
  TimeRange,
  SelectRadio,
}

export type SelectOptionType = string | number;
export type FieldValueType = SelectOptionType | boolean | Moment;

export interface SelectOption {
  value: SelectOptionType;
  displayName: string;
}

export interface Field {
  type: FieldType;
  name: string;
  displayName?: string;
  default?: FieldValueType;
  selectOptions?: SelectOption[];
}

export interface FieldValue {
  field: Field;
  value?: FieldValueType;
}

const SearchInputContainer = styled.div`
  display: flex;
  overflow-x: auto;
`;

const SearchActionContainer = styled(Space)`
  float: right;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const SearchInput = styled(Input)`
  margin-left: 5px;
  margin-right: 5px;
  min-width: 125px;
`;

const SelectInput = styled(Select)`
  margin-left: 5px;
  margin-right: 5px;
  min-width: 125px;
`;

const TimeInput = styled(TimePickerAutoAccept)`
  margin-left: 5px;
  margin-right: 5px;
  min-width: 125px;
`;

const SearchButton = styled(Button)`
  margin-left: 5px;
  margin-right: 5px;
  min-width: 125px;
`;

const RadioGroupText = styled.i`
  padding-right: 5px;
`;

const SearchBar: React.FC<{
  isLoading: boolean;
  fields: Field[];
  onSearch: (fieldValues: FieldValue[]) => void;
}> = ({ isLoading, fields, onSearch }) => {
  const [fieldValues, setFieldValues] = useState<FieldValue[]>(
    fields.map((field) => ({ field, value: field.default }))
  );

  const updateFieldValue = (
    fieldName: string,
    value: typeof fieldValues[0]["value"]
  ): void =>
    setFieldValues((fieldValues: FieldValue[]): FieldValue[] => {
      const fieldValue = fieldValues.find(
        (fieldValue) => fieldValue.field.name === fieldName
      );
      if (!fieldValue) {
        throw Error("Invalid search filter configuration.");
      }
      fieldValue.value = value;
      return [...fieldValues];
    });

  return (
    <Form>
      <SearchInputContainer>
        {fields.map((field) => (
          <Form.Item noStyle key={field.name}>
            {field.type === FieldType.Text ? (
              <SearchInput
                placeholder={field.displayName ?? field.name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                  updateFieldValue(field.name, event.target.value)
                }
              />
            ) : field.type === FieldType.Select ? (
              <SelectInput
                allowClear
                placeholder={field.displayName ?? field.name}
                onChange={(selectValue: SelectValue): void =>
                  updateFieldValue(field.name, selectValue as string)
                }
              >
                {(field.selectOptions ?? []).map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.displayName}
                  </Option>
                ))}
              </SelectInput>
            ) : field.type === FieldType.Time ? (
              <TimeInput
                allowClear
                placeholder={field.displayName ?? field.name}
                format={TIME_DISPLAY_FORMAT}
                onChange={(value: Moment | null): void => {
                  updateFieldValue(field.name, value ?? undefined);
                }}
              />
            ) : null}
          </Form.Item>
        ))}
      </SearchInputContainer>
      <SearchActionContainer>
        {fields.map((field) =>
          field.type === FieldType.SelectRadio ? (
            <>
              <RadioGroupText>
                {(field.displayName ?? field.name) + ":"}
              </RadioGroupText>
              <Radio.Group
                key={field.name}
                defaultValue="$Any$"
                onChange={(event): void => {
                  const value =
                    event.target.value !== "$Any$"
                      ? event.target.value
                      : undefined;
                  updateFieldValue(field.name, value);
                }}
              >
                {(field.selectOptions ?? []).map((option) => (
                  <Radio key={option.value} value={option.value}>
                    {option.displayName}
                  </Radio>
                ))}
                <Radio key="$Any$" value="$Any$">
                  Any
                </Radio>
              </Radio.Group>
            </>
          ) : field.type === FieldType.Toggle ? (
            <>
              {field.displayName ?? field.name}
              <Switch
                key={field.name}
                defaultChecked={(field.default ?? false) as boolean}
                onChange={(value: boolean): void =>
                  updateFieldValue(field.name, value)
                }
              />
            </>
          ) : null
        )}
        <SearchButton
          htmlType="submit"
          loading={isLoading}
          icon={<SearchOutlined />}
          onClick={(): void =>
            onSearch(
              fieldValues.map((fieldValue) => {
                if (fieldValue.field.type === FieldType.Text) {
                  if (
                    ((fieldValue.value ?? "") as string).trim().length === 0
                  ) {
                    fieldValue.value = undefined;
                  }
                } else if (fieldValue.field.type === FieldType.Toggle) {
                  if (!fieldValue.value) {
                    fieldValue.value = false;
                  }
                }

                return fieldValue;
              })
            )
          }
          type="primary"
        >
          Search
        </SearchButton>
      </SearchActionContainer>
    </Form>
  );
};

export default SearchBar;
