import {
  Button,
  message,
  PageHeader,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";

import {
  useDeleteMessageTemplateMutation,
  useGetMessageTemplatesQuery,
} from "../../../../../../generated/graphql";
import { columns } from "./columns";
import { EyeOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import CreateOrUpdateMessageTemplateModal from "./components/CreateOrUpdateMessageTemplateModal";

const { Title } = Typography;

const MessagingTemplates: React.FC = () => {
  const [
    isShowCreateMessageTemplateModal,
    setIsShowCreateMessageTemplateModal,
  ] = useState(false);
  const [existingMessageTemplateId, setExistingMessageTemplateId] = useState<
    number | undefined
  >();

  const {
    data: messageTemplatesData,
    loading: messageTemplatesLoading,
    refetch: messageTemplatesRefetch,
  } = useGetMessageTemplatesQuery();
  const [
    deleteMessageTemplate,
    { loading: messageTemplatesDeleteLoading },
  ] = useDeleteMessageTemplateMutation();

  const loading = messageTemplatesLoading || messageTemplatesDeleteLoading;

  const messageTemplatesRows = messageTemplatesData?.getMessageTemplates.map(
    (messageTemplate) => ({
      dataIndex: String(messageTemplate.id),
      name: (
        <>
          {messageTemplate.name}{" "}
          {messageTemplate.isSystemDefault && (
            <Tag color="orange">System Default</Tag>
          )}
        </>
      ),
      actions: (
        <>
          <Tooltip title="View/Edit">
            <Button
              icon={<EyeOutlined />}
              type="primary"
              onClick={() => {
                setIsShowCreateMessageTemplateModal(true);
                setExistingMessageTemplateId(messageTemplate.id);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you really sure you want to delete this message template?"
              okButtonProps={{ danger: true }}
              onConfirm={async (): Promise<void> => {
                try {
                  await deleteMessageTemplate({
                    variables: { id: messageTemplate.id },
                  });
                  messageTemplatesRefetch();
                  message.success("Message template deleted successfully");
                } catch (error) {
                  message.error(
                    `Failed to delete message template: ${error.message}`,
                    10
                  );
                }
              }}
            >
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    })
  );

  return (
    <>
      <CreateOrUpdateMessageTemplateModal
        isVisible={isShowCreateMessageTemplateModal}
        existingMessageTemplateId={existingMessageTemplateId}
        onSuccessfulCreate={() => {
          setIsShowCreateMessageTemplateModal(false);
          setExistingMessageTemplateId(undefined);
          messageTemplatesRefetch();
        }}
        onCancel={() => {
          setIsShowCreateMessageTemplateModal(false);
          setExistingMessageTemplateId(undefined);
        }}
      />
      <PageHeader
        className="site-page-header"
        title={<Title level={2}>Message Templates</Title>}
        extra={[
          <Button
            key="new"
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => setIsShowCreateMessageTemplateModal(true)}
          >
            New
          </Button>,
        ]}
      >
        <Table
          size="small"
          columns={columns}
          dataSource={messageTemplatesRows}
          loading={loading ? { size: "large" } : undefined}
          pagination={false}
          sticky={true}
          scroll={{ x: "max-content" }}
        />
      </PageHeader>
    </>
  );
};

export default MessagingTemplates;
