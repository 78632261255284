import { Button } from "antd";
import { useState } from "react";
import styled from "styled-components";
import { QuestionCircleOutlined } from "@ant-design/icons";
import InstructionsModal from "./InstructionsModal";
import { StatusText } from "../../CommonStyles/week";
import { GracePeriodStatus } from "../../../../generated/graphql";
import moment from "moment";

const Container = styled.div`
  padding: 10px;
`;

const TimesheetNotifier = (
  dueDateTimeInfo: {
    nextTimesheetDueDateTime: string;
    isTimesheetDueThisWeek: boolean;
  },
  self: { gracePeriodStatus: GracePeriodStatus }
): JSX.Element | null => {
  const [isShowInstructions, setIsShowInstructions] = useState(false);

  const instructionsBtn = (
    <Button
      icon={<QuestionCircleOutlined />}
      onClick={() => setIsShowInstructions(true)}
      style={{ backgroundColor: "#695391", color: "white" }}
    >
      Instructions
    </Button>
  );

  const dueDateTimeStr = moment(
    dueDateTimeInfo.nextTimesheetDueDateTime
  ).format("h:mma ddd D MMMM");

  return (
    <>
      <InstructionsModal
        isVisible={isShowInstructions}
        onClose={() => setIsShowInstructions(false)}
      />
      <Container>
        {instructionsBtn}
        &nbsp;&nbsp;&nbsp;
        {self.gracePeriodStatus === GracePeriodStatus.Activated ? (
          <StatusText color="red" style={{ fontSize: "14px" }}>
            Grace Period
          </StatusText>
        ) : dueDateTimeInfo.isTimesheetDueThisWeek ? (
          <StatusText color="#ffa500" style={{ fontSize: "14px" }}>
            Timesheet due this week: {dueDateTimeStr}
          </StatusText>
        ) : (
          <StatusText color="#B4CC36" style={{ fontSize: "14px" }}>
            Timesheet due: {dueDateTimeStr}
          </StatusText>
        )}
      </Container>
    </>
  );
};

export default TimesheetNotifier;
