import { Modal } from "antd";
import { wideModalWidth } from "../CommonStyles/page";
import ModifyTeachersTransferTable from "./components/ModifyTeachersTransferTable";

const ModifyTeachersModal: React.FC<{
  classId: number;
  isVisible: boolean;
  onClose: () => void;
}> = ({ classId, isVisible, onClose }) => (
  <Modal
    width={wideModalWidth}
    visible={isVisible}
    title="Modify Teachers"
    confirmLoading={false}
    onCancel={onClose}
    footer={null}
  >
    {isVisible && <ModifyTeachersTransferTable classId={classId} />}
  </Modal>
);

export default ModifyTeachersModal;
