import { SelectOption } from "../Cortex/components/SearchBar";

export const daysSelectOptions: SelectOption[] = [
  { displayName: "Monday", value: "Monday" },
  { displayName: "Tuesday", value: "Tuesday" },
  { displayName: "Wednesday", value: "Wednesday" },
  { displayName: "Thursday", value: "Thursday" },
  { displayName: "Friday", value: "Friday" },
  { displayName: "Saturday", value: "Saturday" },
  { displayName: "Sunday", value: "Sunday" },
];

export const classTypeSelectOptions: SelectOption[] = [
  { displayName: "Private", value: "Private" },
  { displayName: "Class", value: "Class" },
];

export const teacherRoleSelectOptions: SelectOption[] = [
  { displayName: "Teaching", value: "Teaching" },
  { displayName: "Assisting", value: "Assisting" },
  { displayName: "Covering", value: "Covering" },
  { displayName: "Training", value: "Training" },
];

export const gradeSelectOptions: SelectOption[] = [
  { displayName: "0", value: "0" },
  { displayName: "K", value: "K" },
  { displayName: "1", value: "1" },
  { displayName: "2", value: "2" },
  { displayName: "3", value: "3" },
  { displayName: "4", value: "4" },
  { displayName: "5", value: "5" },
  { displayName: "6", value: "6" },
  { displayName: "7", value: "7" },
  { displayName: "8", value: "8" },
  { displayName: "9", value: "9" },
  { displayName: "10", value: "10" },
  { displayName: "11", value: "11" },
  { displayName: "12", value: "12" },
  { displayName: "13", value: "13" },
];

export const genderSelectOptions: SelectOption[] = [
  { displayName: "N/A", value: "N/A" },
  { displayName: "Male", value: "Male" },
  { displayName: "Female", value: "Female" },
];
