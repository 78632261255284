import { CustomProductHome } from "@atlaskit/atlassian-navigation";
import cortexLogo from "../cortex.png";

const ProductHome = (database: string): JSX.Element => {
  let siteTitle;
  if (database === "hurstville") {
    siteTitle = "Hurstville";
  } else if (database === "burwood") {
    siteTitle = "Burwood";
  } else {
    siteTitle = `Time Machine - ${database}`;
  }

  return (
    <CustomProductHome
      href={process.env.REACT_APP_LEGACY_SERVICE_URL}
      iconAlt="The Brain Cortex"
      iconUrl={cortexLogo}
      logoAlt="The Brain Cortex"
      logoUrl={cortexLogo}
      siteTitle={siteTitle}
    />
  );
};

export default ProductHome;
