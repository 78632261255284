import { Spin } from "antd";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

const Container = styled(Spin)`
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoadingSpinner: React.FC = () => (
  <Container indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />} />
);

export default LoadingSpinner;
