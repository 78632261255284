import {
  Popover,
  Typography,
  Button,
  Form,
  Alert,
  Tooltip,
  AutoComplete,
} from "antd";
import { useState, useRef, useEffect } from "react";
import { useClickAway } from "react-use";
import Input from "antd/lib/input/Input";
import styled from "styled-components";
import moment from "moment";
import AttendanceInnerDateRow from "./AttendanceInnerDateRow";
import { InitiatedContext } from "../../../ClassAttendanceCard";
import {
  AttendanceFieldsFragment,
  TimesheetRange,
  useCortexConfigurationQuery,
  Week,
} from "../../../../../generated/graphql";
import { classInfoStr } from "../../../../../util/classInfo";
import { UserOutlined } from "@ant-design/icons";
import { getParts, getTempAttendanceDataDisplayValue } from "./attendanceData";
import ClassViewerModal from "../../../ClassViewerModal";
import { DATE_NO_YEAR_FORMAT } from "../../../../../util/constants";

const { Title, Paragraph } = Typography;

const PopupDialog = styled.div`
  width: 325px;
`;

const ActionsButtonsContainer = styled.div`
  text-align: right;
`;

const ActionButton = styled(Button)`
  margin-bottom: 5px;
  width: 25%;
`;

const NewDateButton = styled(Button)`
  width: 100%;
`;

const Spacer = styled.div`
  margin-top: 7px;
`;

const DialogFormItem = styled(Form.Item)`
  padding: 0;
  margin: 0;
`;

const DialogWentAnotherClassItem = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const WentAnotherClassText = styled.div`
  margin-right: 8px;
`;

const InfoItem = styled.div`
  margin-bottom: 10px;
`;

const DisplayText = styled.div<{ isHoverable?: boolean }>`
  padding: 2px;
  &:hover {
    background-color: ${(props): string =>
      props.isHoverable ? "#f0f0f0;" : "none"};
  }
`;

// Make sure this is in sync with the service.
const DEFAULT_COMMENT_ONLINE = "Student attended online";
const DEFAULT_COMMENT_STUDENT_ABSENT_WITHOUT_NOTICE =
  "Student absent without notice";
const DEFAULT_COMMENT_STUDENT_ABSENT_WITH_NOTICE_SICK =
  "Student absent with notice - Sick or isolating";
const DEFAULT_COMMENT_STUDENT_ABSENT_WITH_NOTICE_OTHER =
  "Student absent with notice - Other reason";
const DEFAULT_COMMENT_TEACHER_ABSENT_SICK =
  "Class cancelled due to teacher absent - Sick or isolating";
const DEFAULT_COMMENT_TEACHER_ABSENT_OTHER =
  "Class cancelled due to teacher absent - Other reason";
const DEFAULT_ABSENT_COMMMENTS = [
  DEFAULT_COMMENT_STUDENT_ABSENT_WITHOUT_NOTICE,
  DEFAULT_COMMENT_STUDENT_ABSENT_WITH_NOTICE_SICK,
  DEFAULT_COMMENT_STUDENT_ABSENT_WITH_NOTICE_OTHER,
  DEFAULT_COMMENT_TEACHER_ABSENT_SICK,
  DEFAULT_COMMENT_TEACHER_ABSENT_OTHER,
];

const ActionsButtons: React.FC<{
  onCopyToAll?: () => void;
  isDisabled?: boolean;
}> = ({ onCopyToAll, isDisabled }) => (
  <ActionsButtonsContainer>
    {onCopyToAll && (
      <>
        <ActionButton size="small" onClick={onCopyToAll} disabled={isDisabled}>
          Copy to all
        </ActionButton>
        &nbsp;
      </>
    )}
    <ActionButton
      size="small"
      type="primary"
      htmlType="submit"
      disabled={isDisabled}
    >
      Save
    </ActionButton>
  </ActionsButtonsContainer>
);

const LegacyRules: React.FC = () => (
  <PopupDialog>
    <Title level={5}>Cortex legacy format</Title>
    The Cortex legacy format simply counts the number of slashes (/) and ensures
    multiple slashes are seperated by a comma (,) somewhere in between them
    (e.g. 23/03 blah blah, 23/04 blah2 blah2).
    <br />
    <br />
    Like displayed in the next-gen table, asterisk (*) and plus (+) symbols are
    used to subtract and add 30 mins respectively, placed adjacent to a date
    (e.g. ++23/03).
    <br />
    <br />
    However, legacy symbols such as exclamation (!), hash (#) and ampersand (&)
    could also be shown. Exclamation and hash are same as asterisk and plus
    except they do not affect the timesheet. Ampersands deduct an entire lesson
    of pay, but marks the student as attended (so it is counted as part of
    calculating how many weeks they have left). Exclamation, hash and ampersand
    are typically automatically inserted by Cortex when appropriate and
    employees should not have to worry about this in normal circumstances.
  </PopupDialog>
);

const LegacyFormBody: React.FC<{ isDisabled?: boolean }> = ({ isDisabled }) => (
  <>
    The field was saved in Cortex legacy format, and is incompatible with the
    next-gen editor, so the raw data is shown instead.&nbsp;
    <Popover content={<LegacyRules />} trigger="click">
      <a>Learn more.</a>
    </Popover>
    <DialogFormItem name="legacyValue">
      <Input disabled={isDisabled} />
    </DialogFormItem>
  </>
);

const NextGenFormBody: React.FC<{
  isDisabled?: boolean;
  onPushToClass: () => void;
  timesheetRange: TimesheetRange;
}> = ({ isDisabled, onPushToClass, timesheetRange }) => (
  <>
    <Title level={5}>Dates</Title>
    <InfoItem>
      Mark students as attended by entering the date(s) the student attended
      this week. If any class duration was not the typical duration, make sure
      to add/remove any time to the date(s).
    </InfoItem>
    <Form.List name="dates">
      {(dates, { add, remove }): JSX.Element => (
        <>
          {dates.map((date, index) => (
            <DialogFormItem
              key={index}
              name={[date.name, "date"]}
              fieldKey={[date.fieldKey, "date"]}
            >
              <AttendanceInnerDateRow
                onRemove={() => remove(date.name)}
                isDisabled={isDisabled}
                timesheetRange={timesheetRange}
              />
            </DialogFormItem>
          ))}
          <NewDateButton
            size="small"
            type="primary"
            onClick={() => {
              let date = moment();
              const start = moment(timesheetRange.start);
              const end = moment(timesheetRange.end);
              if (date.isBefore(start) || date.isAfter(end)) {
                date = start;
              }
              add({ date: date.format(DATE_NO_YEAR_FORMAT) });
            }}
            disabled={isDisabled}
          >
            + New Date
          </NewDateButton>
        </>
      )}
    </Form.List>
    <Spacer />
    <Title level={5}>Comment</Title>
    <DialogFormItem name="comment">
      <AutoComplete
        disabled={isDisabled}
        options={[
          { value: DEFAULT_COMMENT_ONLINE },
          { value: DEFAULT_COMMENT_STUDENT_ABSENT_WITHOUT_NOTICE },
          { value: DEFAULT_COMMENT_STUDENT_ABSENT_WITH_NOTICE_SICK },
          { value: DEFAULT_COMMENT_STUDENT_ABSENT_WITH_NOTICE_OTHER },
          { value: DEFAULT_COMMENT_TEACHER_ABSENT_SICK },
          { value: DEFAULT_COMMENT_TEACHER_ABSENT_OTHER },
        ]}
      />
    </DialogFormItem>
    <Spacer />
    <DialogWentAnotherClassItem>
      <WentAnotherClassText>
        Student visited another class?
      </WentAnotherClassText>
      <Button
        type="primary"
        size="small"
        onClick={onPushToClass}
        disabled={isDisabled}
      >
        Push to class
      </Button>
    </DialogWentAnotherClassItem>
  </>
);

const AttendanceField: React.FC<{
  onSave: (newValue: string) => void;
  onCopyToAll?: (newValue: string) => void;
  value?: string | null;
  disabledMsg?: string;
  initiatedContext: InitiatedContext;
  depth: number;
  tempAttendances: AttendanceFieldsFragment["tempAttendances"];
  week: Week;
  classDuration: number;
  onPushToClass: () => void;
}> = ({
  onSave,
  onCopyToAll,
  value,
  disabledMsg,
  initiatedContext,
  depth,
  tempAttendances,
  week,
  classDuration,
  onPushToClass,
}) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [error, setError] = useState<string>();
  const [visitedClassModalClassId, setVisitedClassModalClassId] = useState<
    number | undefined
  >();
  const { data: cortexConfiguration } = useCortexConfigurationQuery({
    fetchPolicy: "cache-first",
  });
  const popupDialogRef = useRef(null);
  const isDisabled = disabledMsg !== undefined;

  const onFinish = async (copyToAll = false) => {
    if (!isDisabled && !(await save(copyToAll === true))) {
      return;
    }
    setError(undefined);
    setIsDialogVisible(false);
  };

  // To ensure clicking outside the dialog box saves and closes the data.
  useClickAway(popupDialogRef, (e) => {
    // For some reason clicks in the date picker are detected,
    // we are going to manually ignore them here.
    const className = (
      e.target as {
        className: string;
      } | null
    )?.className;
    if (
      !className ||
      !className.includes ||
      (!className.includes("ant-picker") &&
        !className.includes("ant-select-item-option"))
    ) {
      onFinish();
    }
  });

  const [form] = Form.useForm();
  useEffect(() => {
    // Reset the form when the current value has changed, most likely due
    // to attendance records being updated in the attendance table.
    form.resetFields();
  }, [value]);

  let allowedDateRange =
    cortexConfiguration?.getSystemConfiguration.system.timesheetRanges.find(
      (range) => range.week === week
    );
  if (!allowedDateRange) {
    // If the server didn't provide the date range, be safe and enable the whole year.
    // (Need to do this due to Week 0)
    allowedDateRange = {
      week,
      start: moment().startOf("year"),
      end: moment().endOf("year"),
    };
  }

  const attendanceValue = value ?? "";

  // Next-gen data comes in form "{DATES} | {COMMENT}" or just "{DATES}".
  // ("{DATES}" is also a legacy format, so many legacy data is mostly compatible)
  // Any extra | symbols are ignored.
  // Note strings which look like "{COMMENT}" will be regarded as a legacy format.
  const parts = attendanceValue.split("|").map((str) => str.trim());
  const dateStr = parts.length >= 1 ? parts[0] : "";
  const comment = parts.length >= 2 ? parts[1] : "";

  // Grab all the dates as strings and put into an array
  const dates =
    dateStr
      ?.split(",")
      .filter((date) => date.length > 0)
      .map((date) => ({ date: date.trim() })) ?? [];

  // Determine whether the current attendance data is compatible
  // with the next-gen editor.
  const isNextGen =
    attendanceValue.length === 0 || // No data is always compatible.
    dates.reduce(
      (acc, curr) => acc && curr.date.match(/^[+*]*\d+\/\d+$/) !== null,
      Boolean(true)
    );

  const save = async (copyToAll?: boolean): Promise<boolean> => {
    const values = await form.validateFields();
    if (values.legacyValue) {
      // For Cortex legacy format, where the raw attendance data
      // is entered by the user.
      if (copyToAll && onCopyToAll) {
        onCopyToAll(values.legacyValue);
      } else if (values.legacyValue !== value) {
        onSave(values.legacyValue);
      }
    } else {
      // For Cortex next-gen format, we will construct the attendance data
      // for the user into the correct format based on the fields.
      const comment = values.comment;
      const dates =
        values.dates?.map((date: { date: string }) => date.date).join(", ") ??
        "";

      if (dates && DEFAULT_ABSENT_COMMMENTS.includes(comment)) {
        setError(
          "Save failed as the student was marked as attending while the comment says they were absent. Please fix this or clarify in the comment how this is possible."
        );
        return false;
      }

      let finalValue;
      if (comment?.length > 0) {
        finalValue = dates + " | " + comment.replaceAll("/", "-"); // Replace / with - in comment as it is illegal character but commonly used.
      } else {
        finalValue = dates;
      }
      if (copyToAll && onCopyToAll) {
        onCopyToAll(finalValue);
      } else if (finalValue !== value) {
        onSave(finalValue);
      }
    }
    form.resetFields();
    return true;
  };

  // Display the details of the other classes visited link to this attendance
  // in this particular week.
  const otherClassVisitedListItems = tempAttendances
    .map((tempAttendance) => {
      const attendanceDataStr = tempAttendance.weekAttendanceInfos.find(
        (weekAttendanceInfo) => weekAttendanceInfo.week === week
      )!.attendanceData;
      const { attendanceData, comment } = getParts(attendanceDataStr);

      const adjustedDate = attendanceData
        .map((date) => date.adjustedDate)
        .join(", ");
      let dateCommentStr;
      if (adjustedDate.length > 0 && comment.length > 0) {
        dateCommentStr = `${adjustedDate} (${comment})`;
      } else {
        // This will show either the date or comment by itself, since at least one of them is empty.
        dateCommentStr = `${adjustedDate}${comment}`;
      }

      if (dateCommentStr.length === 0) {
        // The case where the entire string is empty, don't bother listing this class as a visited one.
        return null;
      }

      return (
        <li key={tempAttendance.id}>
          <b>{dateCommentStr}</b> - {classInfoStr(tempAttendance.class)}
          &nbsp;
          <Tooltip title="View class">
            <Button
              icon={<UserOutlined />}
              onClick={() => {
                setVisitedClassModalClassId(tempAttendance.class.id);
                onFinish();
              }}
            />
          </Tooltip>
        </li>
      );
    })
    .filter((listItem) => listItem !== null);

  // The whole popup dialog that appears when the AttendanceField is clicked
  const popupDialog = (
    <PopupDialog ref={popupDialogRef}>
      <Form
        form={form}
        layout="vertical"
        initialValues={
          isNextGen ? { dates, comment } : { legacyValue: attendanceValue }
        }
        onFinish={onFinish}
      >
        <ActionsButtons
          onCopyToAll={onCopyToAll ? () => onFinish(true) : undefined}
          isDisabled={isDisabled}
        />
        {error && <Alert message={error} type="error" showIcon />}
        {isDisabled && <Alert message={disabledMsg} type="warning" showIcon />}
        {isNextGen ? (
          <NextGenFormBody
            isDisabled={isDisabled}
            onPushToClass={() => {
              onPushToClass();
              onFinish();
            }}
            timesheetRange={allowedDateRange}
          />
        ) : (
          <LegacyFormBody isDisabled={isDisabled} />
        )}
        {otherClassVisitedListItems.length > 0 && (
          <>
            <Spacer />
            <Title level={5}>Other classes visited</Title>
            <Paragraph>
              <ul>{otherClassVisitedListItems}</ul>
            </Paragraph>
          </>
        )}
      </Form>
    </PopupDialog>
  );

  // The string displayed on the attendance table
  let displayValue = dateStr;

  const tempAttendancesDisplayValue = getTempAttendanceDataDisplayValue(
    tempAttendances,
    week,
    classDuration
  );

  displayValue =
    displayValue.length > 0 && tempAttendancesDisplayValue.length > 0
      ? `${displayValue}, ${tempAttendancesDisplayValue}`
      : `${displayValue}${tempAttendancesDisplayValue}`;

  // Display comment in formatted style if comment exists.
  if (comment.length > 0) {
    if (displayValue.length === 0) {
      // If comment only, display comment only.
      displayValue = comment;
    } else {
      // If date and comment, display both.
      displayValue += ` (${comment})`;
    }
  }

  // If the display value ends up being empty,
  // put a dash there to make it clear the field is empty.
  if (displayValue.length === 0) {
    displayValue = "–";
  }

  return (
    <>
      <ClassViewerModal
        classId={visitedClassModalClassId}
        onClose={() => setVisitedClassModalClassId(undefined)}
        initiatedContext={initiatedContext}
        depth={depth + 1}
      />
      <Popover content={popupDialog} visible={isDialogVisible}>
        <DisplayText
          isHoverable={true}
          onClick={() => setIsDialogVisible(true)}
        >
          {displayValue}
        </DisplayText>
      </Popover>
    </>
  );
};

export default AttendanceField;
