import { Typography } from "antd";
import { useCortexConfigurationQuery } from "../../../generated/graphql";

const { Paragraph } = Typography;

const MemosBody: React.FC = () => {
  const { data: cortexConfiguration } = useCortexConfigurationQuery({
    fetchPolicy: "cache-first",
  });
  const memos = cortexConfiguration?.getSystemConfiguration.system.memos;

  return (
    <Paragraph>
      {memos?.split("\n").map((line, idx) => (
        <span key={idx}>
          {line}
          <br />
        </span>
      ))}
    </Paragraph>
  );
};

export default MemosBody;
