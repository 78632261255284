import {
  Table,
  Card,
  Button,
  Tooltip,
  Popconfirm,
  message,
  Tag,
  Divider,
} from "antd";
import {
  ExtraActivityApprovalStatus,
  ExtraActivityFieldsFragment,
  ExtraActivityType,
  TimesheetRange,
  UpdateExtraActivityMutationVariables,
  useDeleteExtraActivityMutation,
  useUpdateExtraActivityMutation,
  Week,
} from "../../../../generated/graphql";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import { columns } from "../columns";
import { useState } from "react";
import NewActivityModal from "./NewActivityModal";
import EditableInput from "../../../components/Editable/EditableInput";
import EditableDate from "../../../components/Editable/EditableDate";
import {
  DATE_NO_YEAR_FORMAT,
  TIME_DISPLAY_FORMAT,
} from "../../../../util/constants";
import moment from "moment";
import EditableTimeRange from "../../../components/Editable/EditableTimeRange";
import { extraActivityTypeDescriptions } from "../util";
import { weekDescriptions } from "../../../../util/descriptions";
import { HEADER_BG_COLOUR } from "../../../components/CommonStyles/card";
import { StatusText } from "../../../components/CommonStyles/week";
import { Body, TopRightContainer } from "../../../components/CommonStyles/card";
import { roundAndFix } from "../../../../util/maths";
import { isDisabledDate } from "../../../../util/date";

const ExtraActivitiesWeekCard: React.FC<{
  week: Week;
  isActive: boolean;
  isEditable: boolean;
  extraActivities: ExtraActivityFieldsFragment[];
  timesheetRange: TimesheetRange;
  refetch: () => void;
}> = ({
  week,
  isActive,
  isEditable,
  extraActivities,
  timesheetRange,
  refetch,
}) => {
  const [isShowNewActivityModal, setIsShowNewActivityModal] = useState(false);
  const [updateExtraActivity] = useUpdateExtraActivityMutation();
  const [
    deleteExtraActivity,
    { loading: deleting },
  ] = useDeleteExtraActivityMutation();

  const saveToGraphQL = async <
    T extends keyof UpdateExtraActivityMutationVariables["input"]
  >(
    id: number,
    field: T,
    value: UpdateExtraActivityMutationVariables["input"][T]
  ): Promise<void> => {
    try {
      await updateExtraActivity({
        variables: { input: { id, [field]: value } },
      });
      message.success("Automatically saved");
    } catch (error) {
      message.error(`Automatic save failed: ${error.message}`, 10);
    }
  };

  const activityRows = extraActivities.map((extraActivity) => ({
    key: extraActivity.id,
    dataIndex: String(extraActivity.id),
    type: extraActivityTypeDescriptions[extraActivity.type],
    description: (
      <>
        <EditableInput
          disabled={!isEditable}
          required={
            extraActivity.type !== ExtraActivityType.Reception &&
            extraActivity.type !== ExtraActivityType.HomeworkHelp
          }
          value={extraActivity.description}
          onSave={(value) =>
            saveToGraphQL(extraActivity.id, "description", value)
          }
        />
      </>
    ),
    dateTime:
      extraActivity.dateTime.startTime &&
      extraActivity.dateTime.endTime &&
      extraActivity.dateTime.date ? (
        <>
          <EditableDate
            required
            disabled={!isEditable}
            value={moment(extraActivity.dateTime.date)}
            onSave={(value) =>
              saveToGraphQL(extraActivity.id, "dateTime", {
                startTime: extraActivity.dateTime.startTime,
                endTime: extraActivity.dateTime.endTime,
                date: moment(value),
              })
            }
            guessYear
            dateFormat={DATE_NO_YEAR_FORMAT}
            disabledDate={(date) => isDisabledDate(date, timesheetRange)}
          />
          <EditableTimeRange
            required
            disabled={!isEditable}
            value={[
              moment(extraActivity.dateTime.startTime),
              moment(extraActivity.dateTime.endTime),
            ]}
            onSave={(value) =>
              saveToGraphQL(extraActivity.id, "dateTime", {
                date: extraActivity.dateTime.date,
                startTime: moment(value[0]),
                endTime: moment(value[1]),
              })
            }
            timeFormat={TIME_DISPLAY_FORMAT}
          />
        </>
      ) : (
        <>
          <Tooltip title="The activity was created in Cortex legacy and this field is not compatible with the next-gen editable field. Please delete the activity and create it again to be able to edit it here.">
            {extraActivity.dateTime.freeText}
          </Tooltip>
        </>
      ),
    units:
      extraActivity.type === ExtraActivityType.CurriculumBooklet ? (
        <>
          <EditableInput
            required
            disabled={!isEditable}
            value={String(extraActivity.units)}
            onSave={(value) =>
              saveToGraphQL(extraActivity.id, "units", Number(value))
            }
          />
          <i>Booklets</i>
        </>
      ) : extraActivity.type === ExtraActivityType.Other ? (
        <>
          <i>Amount ($):</i>
          <EditableInput
            required
            disabled={!isEditable}
            value={roundAndFix(extraActivity.pay)}
            onSave={(value) =>
              saveToGraphQL(extraActivity.id, "pay", Number(value))
            }
          />
        </>
      ) : (
        <Tooltip title="Change the Date / Time to modify the hours.">
          {extraActivity.duration}{" "}
          <i>Hour{extraActivity.duration !== 1 ? "s" : ""}</i>
        </Tooltip>
      ),
    approval:
      extraActivity.approvalStatus === ExtraActivityApprovalStatus.Approved ? (
        <Tag color="green">✓ Approved</Tag>
      ) : extraActivity.approvalStatus ===
        ExtraActivityApprovalStatus.Pending ? (
        <Tooltip title="Your supervisor needs to approve the extra activity into order to be paid for it.">
          <Tag color="orange">⊛ Pending</Tag>
        </Tooltip>
      ) : (
        <Tooltip title="Your supervisor has rejected this extra activity and you won't be paid for it. Contact your supervisor for more information.">
          <Tag color="magenta">✘ Rejected</Tag>
        </Tooltip>
      ),
    actions: (
      <Tooltip title="Delete">
        <Popconfirm
          title="Are you really sure you want to delete this extra activity?"
          okButtonProps={{ danger: true }}
          disabled={!isEditable}
          onConfirm={async (): Promise<void> => {
            try {
              await deleteExtraActivity({
                variables: { id: extraActivity.id },
              });
              refetch();
              message.success("Successfully deleted extra activity");
            } catch (error) {
              message.error(
                `Failed to delete extra activity: ${error.message}`,
                10
              );
            }
          }}
        >
          <Button icon={<DeleteOutlined />} disabled={!isEditable} />
        </Popconfirm>
      </Tooltip>
    ),
  }));

  return (
    <>
      <NewActivityModal
        isVisible={isShowNewActivityModal}
        week={week}
        onSuccessfulCreate={(): void => {
          setIsShowNewActivityModal(false);
          refetch();
        }}
        onCancel={(): void => setIsShowNewActivityModal(false)}
        timesheetRange={timesheetRange}
      />
      <Card
        headStyle={{
          backgroundColor: HEADER_BG_COLOUR,
        }}
        title={
          <>
            {weekDescriptions[week]}
            <Divider type="vertical" />
            <StatusText>
              {moment(timesheetRange.start).format("D MMM").toUpperCase() +
                " - " +
                moment(timesheetRange.end).format("D MMM").toUpperCase()}
            </StatusText>
            {isActive && (
              <>
                <Divider type="vertical" />
                <StatusText color="green">ACTIVE</StatusText>
              </>
            )}
          </>
        }
        extra={
          <TopRightContainer>
            <Button
              key="1"
              disabled={!isEditable}
              type="primary"
              icon={<PlusOutlined />}
              onClick={(): void => setIsShowNewActivityModal(true)}
            >
              New
            </Button>
          </TopRightContainer>
        }
      >
        <Body>
          {extraActivities.length > 0 ? (
            <Table
              size="small"
              columns={columns}
              dataSource={activityRows}
              pagination={false}
              loading={deleting}
              sticky={true}
              scroll={{ x: "max-content" }}
            />
          ) : (
            <>No extra activities found.</>
          )}
        </Body>
      </Card>
    </>
  );
};

export default ExtraActivitiesWeekCard;
