import { FetchResult } from "@apollo/client";
import { Form, Input, message, Modal } from "antd";
import {
  NewTeacherMutation,
  useNewTeacherMutation,
} from "../../../../../../generated/graphql";

const NewTeacherModal: React.FC<{
  isVisible: boolean;
  onSuccessfulCreate: (teacher: FetchResult<NewTeacherMutation>) => void;
  onCancel: () => void;
}> = ({ isVisible, onSuccessfulCreate, onCancel }) => {
  const [newTeacher, { loading }] = useNewTeacherMutation();
  const [form] = Form.useForm();

  return (
    <Modal
      visible={isVisible}
      title="New Teacher"
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={async (): Promise<void> => {
        try {
          const values = await form.validateFields();
          const input = {
            firstName: values.firstName,
            surname: values.surname,
            email: values.email,
          };
          const teacher = await newTeacher({
            variables: { input },
          });
          onSuccessfulCreate(teacher);
          form.resetFields();
        } catch (error) {
          message.error(
            `Server failed to create teacher: ${error.message}`,
            10
          );
        }
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item>
          The new teacher will need to activate their account by clicking
          &apos;Activate Account&apos; on the single sign on login page,
          inputting their email and then following the instructions.
        </Form.Item>
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="surname"
          label="Surname"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewTeacherModal;
