import { Day, PaymentStatus, Week } from "../generated/graphql";

export const days = [
  Day.Monday,
  Day.Tuesday,
  Day.Wednesday,
  Day.Thursday,
  Day.Friday,
  Day.Saturday,
  Day.Sunday,
];

export const weekDescriptions = {
  [Week.Wk0]: "Holiday lessons brought forward",
  [Week.Wk1]: "Week 1",
  [Week.Wk2]: "Week 2",
  [Week.Wk3]: "Week 3",
  [Week.Wk4]: "Week 4",
  [Week.Wk5]: "Week 5",
  [Week.Wk6]: "Week 6",
  [Week.Wk7]: "Week 7",
  [Week.Wk8]: "Week 8",
  [Week.Wk9]: "Week 9",
  [Week.Wk10]: "Week 10",
  [Week.Wk11]: "Week 11",
  [Week.WkH1]: "Week H1",
  [Week.WkH2]: "Week H2",
  [Week.WkH3]: "Week H3",
  [Week.WkH4]: "Week H4",
  [Week.WkH5]: "Week H5",
  [Week.WkH6]: "Week H6",
  [Week.WkH7]: "Week H7",
  [Week.WkH8]: "Week H8",
  [Week.WkH9]: "Week H9",
  [Week.WkH10]: "Week H10",
  [Week.WkNextTerm]: "Holiday lessons for next term",
};

export const paymentStatusDescriptions = {
  [PaymentStatus.NotEnrolled]: "Not Enrolled",
  [PaymentStatus.PaymentOk]: "Payment OK",
  [PaymentStatus.PaymentOkNoLessonsRemaining]:
    "Payment OK (No lessons remaining / unpaid)",
  [PaymentStatus.NeedsUpdate]: "Needs Update",
  [PaymentStatus.UnpaidLessonsCompleted]: "UNPAID LESSONS COMPLETED",
};
