import { Layout } from "antd";
import LeftSiderMenu from "./components/LeftSiderMenu";
import RightContent from "./components/RightContent";

const CortexLayout: React.FC<{
  leftSiderMenuItems?: JSX.Element[];
}> = ({ leftSiderMenuItems, children }): JSX.Element => {
  return (
    <Layout>
      {leftSiderMenuItems && (
        <LeftSiderMenu>{leftSiderMenuItems}</LeftSiderMenu>
      )}
      <Layout>
        <RightContent>{children}</RightContent>
      </Layout>
    </Layout>
  );
};

export default CortexLayout;
