import {
  Button,
  Divider,
  message,
  PageHeader,
  Popconfirm,
  Result,
  Switch,
  Typography,
} from "antd";
import {
  GracePeriodStatus,
  useCortexConfigurationQuery,
  useExportTimesheetsLazyQuery,
  useSelfQuery,
  useTimesheetsQuery,
  useUnconfirmTimesheetMutation,
  Week,
} from "../../../generated/graphql";
import {
  CheckOutlined,
  ExportOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import CortexLayout from "../../components/CortexLayout";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useEffect, useState } from "react";
import { FixedWidthContent } from "../../components/CommonStyles/page";
import TimesheetWeekCard from "./components/TimesheetWeekCard";
import ConfirmTimesheetModal from "./components/ConfirmTimesheetModal";
import { StatusText } from "../../components/CommonStyles/week";
import CortexAlerter from "../../components/CortexAlerter";
import { roundAndFix } from "../../../util/maths";
import download from "downloadjs";
import { isImpersonating_TimesheetProcessor } from "../../../state/cortexPath";
import moment from "moment";

const { Title } = Typography;

const Timesheet: React.FC = () => {
  const { data: selfData, refetch: refetchSelf } = useSelfQuery({
    fetchPolicy: "cache-first",
  });
  const {
    data: cortexConfiguration,
    refetch: refetchCortexConfiguration,
  } = useCortexConfigurationQuery({ fetchPolicy: "cache-first" });
  const [
    exportTimesheets,
    { data: exportTimesheetsData, loading: exportLoading },
  ] = useExportTimesheetsLazyQuery();
  const [unconfirmTimesheet] = useUnconfirmTimesheetMutation();
  const [isShowAllWeeks, setIsShowAllWeeks] = useState(false);
  const [isShowConfirmTimesheet, setIsShowConfirmTimesheet] = useState(false);

  const viewableWeeks =
    cortexConfiguration?.getSystemConfiguration.system.viewableWeeks.filter(
      (week) => week !== Week.Wk0
    ) ?? [];

  const modifiableWeeks =
    cortexConfiguration?.getSystemConfiguration.user.modifiableWeeks ?? [];

  const weeks = isShowAllWeeks ? viewableWeeks : modifiableWeeks;

  const {
    data: timesheetsData,
    loading,
    refetch: refetchTimesheets,
    error: timesheetsError,
  } = useTimesheetsQuery({
    variables: {
      weeks: viewableWeeks,
    },
  });

  useEffect(() => {
    if (exportTimesheetsData) {
      const base64file = exportTimesheetsData.exportTimesheets;
      download(base64file, "cortex-nextgen-timesheet.xlsx");
    }
  }, [exportTimesheetsData]);

  if (!cortexConfiguration) {
    return null;
  }

  if (timesheetsError) {
    return <Result status="error" title={timesheetsError.message} />;
  }

  const self = selfData?.getSelf;
  const isCurrentPeriodConfirmed =
    ((!isImpersonating_TimesheetProcessor() &&
      self?.isConfirmedCurrentPeriodTimesheet) ||
      (isImpersonating_TimesheetProcessor() &&
        self?.isConfirmedCurrentPeriodTimesheet_TimesheetProcessorCache)) &&
    self?.gracePeriodStatus !== GracePeriodStatus.Activated;

  const timesheets =
    timesheetsData?.getTimesheets.filter((timesheet) =>
      weeks.includes(timesheet.week)
    ) ?? [];
  const totalHours = timesheets.reduce((acc, cur) => acc + cur.totalHours, 0);
  const totalAmount = timesheets.reduce((acc, cur) => acc + cur.totalAmount, 0);

  const timesheetConfirmer =
    !isShowAllWeeks &&
    timesheetsData &&
    refetchTimesheets &&
    (!isCurrentPeriodConfirmed ? (
      <Button
        key="timesheetConfirmer"
        type="primary"
        icon={<CheckOutlined />}
        onClick={() => setIsShowConfirmTimesheet(true)}
      >
        Confirm timesheet - ${roundAndFix(totalAmount)} ({totalHours} Hour
        {totalHours !== 1 ? "s" : ""})
      </Button>
    ) : (
      <Popconfirm
        key="timesheetConfirmer"
        title="Are you sure you want to unconfim your timesheet? Please remember to confirm it again before the deadline!"
        okButtonProps={{ danger: true }}
        onConfirm={async () => {
          try {
            await unconfirmTimesheet();
            refetchTimesheets();
            refetchCortexConfiguration();
            refetchSelf();
            message.success("Successfully unconfirmed timesheet");
          } catch (error) {
            message.error(
              `Failed to unconfirm timesheet: ${error.message}`,
              10
            );
          }
        }}
      >
        <Button style={{ backgroundColor: "orange" }} icon={<CloseOutlined />}>
          Unconfirm timesheet - ${roundAndFix(totalAmount)} ({totalHours} Hours)
        </Button>
      </Popconfirm>
    ));

  return (
    <>
      <ConfirmTimesheetModal
        isVisible={isShowConfirmTimesheet}
        onCancel={() => setIsShowConfirmTimesheet(false)}
        onConfirm={() => {
          if (refetchTimesheets) {
            refetchTimesheets();
            refetchCortexConfiguration();
            refetchSelf();
            setIsShowConfirmTimesheet(false);
          }
        }}
        comfirmableWeeks={modifiableWeeks}
      />
      <CortexLayout>
        <FixedWidthContent>
          <CortexAlerter />
          <PageHeader
            title={
              <Title level={2}>
                Timesheet
                <Divider type="vertical" />
                <StatusText>
                  TERM {cortexConfiguration.getSystemConfiguration.system.term}{" "}
                  {cortexConfiguration.getSystemConfiguration.system.year}
                </StatusText>
                {!isShowAllWeeks && (
                  <>
                    <Divider type="vertical" />
                    {isCurrentPeriodConfirmed ? (
                      <StatusText color="green">CONFIRMED</StatusText>
                    ) : (
                      <StatusText color="red">NOT CONFIRMED</StatusText>
                    )}
                  </>
                )}
              </Title>
            }
            extra={[
              <span key="termHistory">
                Show term history &nbsp;
                <Switch checked={isShowAllWeeks} onChange={setIsShowAllWeeks} />
              </span>,
              <Button
                key="export"
                loading={exportLoading}
                icon={<ExportOutlined />}
                onClick={() => {
                  exportTimesheets({ variables: { weeks } });
                }}
              >
                Export
              </Button>,
              timesheetConfirmer,
            ]}
          >
            {loading || !refetchTimesheets ? (
              <LoadingSpinner />
            ) : (
              timesheets.map((timesheet) => {
                const isActive =
                  modifiableWeeks.find(
                    (modifiableWeek) => modifiableWeek === timesheet.week
                  ) !== undefined;

                const range = cortexConfiguration.getSystemConfiguration.system.timesheetRanges.find(
                  (range) => range.week === timesheet.week
                );

                return (
                  <>
                    <TimesheetWeekCard
                      key={timesheet.week}
                      startDate={range ? moment(range.start) : undefined}
                      endDate={range ? moment(range.end) : undefined}
                      isActive={isActive}
                      timesheet={timesheet}
                      refetch={refetchTimesheets}
                    />
                    <br />
                  </>
                );
              })
            )}
          </PageHeader>
        </FixedWidthContent>
      </CortexLayout>
    </>
  );
};

export default Timesheet;
