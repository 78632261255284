import { Popconfirm, message, Button } from "antd";

import { useDeleteTeacherClassLinkMutation } from "../../../../generated/graphql";
import { DeleteOutlined } from "@ant-design/icons";

const DeleteBtn: React.FC<{ teacherClassLinkId: number }> = ({
  teacherClassLinkId,
}) => {
  const [deleteTeacherClassLink] = useDeleteTeacherClassLinkMutation();

  return (
    <Popconfirm
      title="Are you really sure you want to delete this class from your attendance record? It will not delete the class, its students, other teachers or timesheet history. You may simply restore the class back to your profile by adding it again."
      okButtonProps={{ danger: true }}
      onConfirm={async (): Promise<void> => {
        try {
          await deleteTeacherClassLink({
            variables: { id: teacherClassLinkId },
          });
          message.success(
            "Successfully deleted class from your attendance record"
          );
        } catch (error) {
          message.error(
            `Failed to delete class from your attendance record: ${error.message}`,
            10
          );
        }
      }}
    >
      <Button key="2" icon={<DeleteOutlined />}>
        Delete
      </Button>
    </Popconfirm>
  );
};

export default DeleteBtn;
