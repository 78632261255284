import { Select } from "antd";
import EditableBase from "./components/EditableBase";

const { Option } = Select;

type SelectOptionType = string | number;

interface SelectOption {
  value: SelectOptionType;
  displayName: string;
}

const EditableDropdown: React.FC<{
  onSave: (newValue: string) => void;
  value?: string | null;
  required?: boolean;
  disabled?: boolean;
  selectOptions: SelectOption[];
}> = ({ onSave, value, required, disabled, selectOptions }) => (
  <EditableBase
    onSave={onSave}
    value={value}
    required={required}
    disabled={disabled}
  >
    {(inputRef, save, inputValue): React.ReactNode => (
      <Select
        open={true}
        ref={inputRef}
        value={inputValue ?? ""}
        onBlur={() => save()}
        onChange={save}
      >
        {selectOptions.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.displayName}
          </Option>
        ))}
      </Select>
    )}
  </EditableBase>
);

export default EditableDropdown;
