import {
  EmailAddressInput,
  StudentFieldsFragment,
} from "../../../../../generated/graphql";
import { MessageComposeInput } from "../../../../components/Messaging/Compose";

export const mapStudentToMessagingModalInput = (
  student: StudentFieldsFragment
): MessageComposeInput => ({
  attachments: [],
  phones: [
    {
      name: student.fullName,
      phone: student.phone,
      linkedEntity: { student: { studentId: student.id } },
    },
    {
      name: student.fullName,
      phone: student.phone2,
      linkedEntity: { student: { studentId: student.id } },
    },
    {
      name: `${student.parentGuardianName} (Parent of ${student.fullName})`,
      phone: student.parentGuardianPhone,
      linkedEntity: {
        student: { studentId: student.id, parentNo: 1 },
      },
    },
    {
      name: `${student.parentGuardianName2} (Parent of ${student.fullName})`,
      phone: student.parentGuardianPhone2,
      linkedEntity: {
        student: { studentId: student.id, parentNo: 2 },
      },
    },
  ].filter((phoneObj) => phoneObj.phone && phoneObj.phone.trim().length),
  emails: [
    {
      name: student.fullName,
      address: student.email,
      linkedEntity: { student: { studentId: student.id } },
    },
    {
      name: `${student.parentGuardianName} (Parent of ${student.fullName})`,
      address: student.parentGuardianEmail,
      linkedEntity: {
        student: { studentId: student.id, parentNo: 1 },
      },
    },
    {
      name: `${student.parentGuardianName2} (Parent of ${student.fullName})`,
      address: student.parentGuardianEmail2,
      linkedEntity: {
        student: { studentId: student.id, parentNo: 2 },
      },
    },
  ].filter(
    (emailObj) => emailObj.address && emailObj.address.trim().length
  ) as EmailAddressInput[],
});
