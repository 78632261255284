import { useState } from "react";
import { Button, PageHeader, Typography, Result } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  ClassType,
  useCortexConfigurationQuery,
  useSelfClassesQuery,
} from "../../../generated/graphql";

import CortexLayout from "../../components/CortexLayout";
import ClassAttendanceCard, {
  InitiatedContext,
} from "../../components/ClassAttendanceCard";
import moment from "moment";
import AddExistingClassModal from "./components/AddExistingClassModal";
import NewClassModal from "../../components/NewClassModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import CortexAlerter from "../../components/CortexAlerter";
import { days } from "../../../util/descriptions";
import useCortexPath from "../../../state/cortexPath";
import _ from "lodash";

const { Title } = Typography;

const Attendance: React.FC = () => {
  const cortexPath = useCortexPath();
  const { data: cortexConfiguration } = useCortexConfigurationQuery({
    fetchPolicy: "cache-first",
  });
  const [isShowCreateNewClass, setIsShowCreateNewClass] = useState(false);
  const [isShowAddExistingClass, setIsShowAddExistingClass] = useState(false);
  const { data: classesData, loading, refetch } = useSelfClassesQuery();
  const classes = _.uniqBy(
    classesData?.getSelf.teacherClassLinks
      .filter(
        (teacherClassLink) =>
          cortexPath?.params.impersonateTeacherId || !teacherClassLink.isDeleted
      )
      .sort((a, b) => {
        if (a.isDeleted === b.isDeleted) {
          const startTimeA = moment(a.class.startTime);
          const endTimeA = moment(a.class.endTime);
          const startTimeB = moment(b.class.startTime);
          const endTimeB = moment(b.class.endTime);
          if (a.class.day === b.class.day) {
            if (startTimeA.isSame(startTimeB)) {
              return endTimeA.valueOf() - endTimeB.valueOf();
            }
            return startTimeA.valueOf() - startTimeB.valueOf();
          }

          return days.indexOf(a.class.day) - days.indexOf(b.class.day);
        }

        return Number(a.isDeleted) - Number(b.isDeleted);
      }) ?? [],
    (teacherClassLink) => teacherClassLink.class.id
  );
  const addClassBtn = (
    <Button
      key="addClassBtn"
      icon={<PlusOutlined />}
      type="primary"
      onClick={(): void => setIsShowAddExistingClass(true)}
    >
      Add existing class
    </Button>
  );

  const createNewClassBtn = (
    <Button
      key="createNewClassBtn"
      icon={<PlusOutlined />}
      type="primary"
      onClick={(): void => setIsShowCreateNewClass(true)}
    >
      Create new class
    </Button>
  );

  return (
    <>
      <NewClassModal
        isVisible={isShowCreateNewClass}
        createWithLoggedInTeacher={true}
        onCancel={(): void => setIsShowCreateNewClass(false)}
        onSuccessfulCreate={(): void => {
          refetch();
          setIsShowCreateNewClass(false);
        }}
      />
      <AddExistingClassModal
        isVisible={isShowAddExistingClass}
        onClose={(): void => {
          refetch();
          setIsShowAddExistingClass(false);
        }}
      />
      <CortexLayout>
        <CortexAlerter />
        <PageHeader
          title={<Title level={2}>Attendance</Title>}
          extra={[addClassBtn, createNewClassBtn]}
        >
          {loading || !cortexConfiguration ? (
            <LoadingSpinner />
          ) : classes.length === 0 ? (
            <>
              <Result
                title="Welcome! You're not linked to any classes."
                status="info"
              />
            </>
          ) : (
            classes.map((teacherClassLink) => (
              <>
                <ClassAttendanceCard
                  initiatedContext={InitiatedContext.Standard}
                  key={teacherClassLink.class.id}
                  classId={teacherClassLink.class.id}
                  teacherClassLinkId={teacherClassLink.id}
                  forceExpand={
                    teacherClassLink.class.type === ClassType.Private
                  }
                  cortexConfiguration={
                    cortexConfiguration.getSystemConfiguration
                  }
                  refetchAll={refetch}
                />
                <br />
              </>
            ))
          )}
        </PageHeader>
      </CortexLayout>
    </>
  );
};

export default Attendance;
