import { Alert } from "antd";
import styled from "styled-components";
import {
  useCortexConfigurationQuery,
  useSelfQuery,
  Week,
} from "../../../generated/graphql";

const Container = styled.div`
  padding: 25px 25px 5px 25px;
`;

const CortexAlerter: React.FC = () => {
  const { data: selfData } = useSelfQuery({ fetchPolicy: "cache-first" });
  const { data: cortexConfigurationData } = useCortexConfigurationQuery({
    fetchPolicy: "cache-first",
  });

  if (!cortexConfigurationData || !selfData) {
    return null;
  }

  const cortexConfiguration = cortexConfigurationData.getSystemConfiguration;
  const self = selfData.getSelf;

  let alert;
  if (cortexConfiguration.user.isGracePeriodMode) {
    alert = (
      <Alert
        showIcon
        type="error"
        message="Mistake Grace Period"
        description="You have been notified by the accountant that you made a mistake on your timesheet for the previous period and you have been given a grace period to resolve the issue. Please do so and resubmit your timesheet."
      />
    );
  } else if (!self.isConfirmedPreviousPeriodTimesheet) {
    alert = (
      <Alert
        showIcon
        type="warning"
        message="Previous timesheet was not confirmed"
        description="The previous period was not confirmed on time. It has been merged with the current period - you may confirm both once you have finished your work for the current period. The two periods will be paid seperately on different dates after the entire timesheet is processed. If you have missed more than one timesheet period, you must see the accountant as soon as possible."
      />
    );
  } else if (cortexConfiguration.system.activeWeeks.includes(Week.Wk1)) {
    alert = (
      <Alert
        showIcon
        type="info"
        message="A new term has started"
        description="The term has rolled over. If you wish to submit a timesheet from the previous term please contact the accountant."
      />
    );
  } else {
    return null;
  }

  return <Container>{alert}</Container>;
};

export default CortexAlerter;
