import { PageHeader, Spin, Typography } from "antd";
import download from "downloadjs";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useInvoiceToPdfQuery } from "../../../../../generated/graphql";

const { Title } = Typography;

const InvoicingServiceConnectorPDF: React.FC = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { data: invoiceData, error: invoiceError } = useInvoiceToPdfQuery({
    variables: { invoiceId: Number(invoiceId) },
  });

  useEffect(() => {
    if (invoiceData) {
      const base64file = invoiceData.invoiceToPDF;
      download(base64file, "invoice.pdf");
    }
  }, [invoiceData]);

  return (
    <PageHeader title={<Title level={2}>Invoicing Service Platform</Title>}>
      {invoiceError ? (
        invoiceError.message
      ) : invoiceData ? (
        <>A popup to download the PDF should have appeared in your browser.</>
      ) : (
        <>
          <Spin size="large" />
          <br />
          The PDF is being generated and a download will popup in your browser
          shortly.
        </>
      )}
    </PageHeader>
  );
};

export default InvoicingServiceConnectorPDF;
