import { Alert, Form, Input, message, Modal, Select, Upload } from "antd";
import { LabeledValue } from "antd/lib/select";
import { useEffect, useState } from "react";
import {
  EmailAddressInput,
  PhoneNumberInput,
  useGetMessageTemplatesQuery,
  useSendEmailMutation,
  useSendSmsMutation,
} from "../../../../generated/graphql";
import { InboxOutlined } from "@ant-design/icons";
import { wideModalWidth } from "../../CommonStyles/page";
import { UploadFile } from "antd/lib/upload/interface";
import ContextVariablesExplanation from "./ContextVariablesExplanation";
import { ALREADY_UPLOADED_TYPE } from "../../../../util/constants";

const { Dragger } = Upload;
const { Option } = Select;

interface MessageTemplateAttachmentInput {
  id: number;
  filename: string;
}

export interface MessageComposeInput {
  phones: PhoneNumberInput[];
  emails: EmailAddressInput[];
  attachments: MessageTemplateAttachmentInput[];
  templateId?: number;
}

const MessageComposeModal: React.FC<{
  input?: MessageComposeInput;
  onClose: () => void;
}> = ({ input, onClose }) => {
  const [form] = Form.useForm();
  const [attachments, setAttachments] = useState<UploadFile[]>([]);
  const {
    data: messageTemplatesData,
    loading: messageTemplatesLoading,
  } = useGetMessageTemplatesQuery();

  const mapAttachmentToUploadFile = (
    attachment: MessageTemplateAttachmentInput
  ) => ({
    uid: attachment.id.toString(),
    name: attachment.filename,
    size: 0,
    type: ALREADY_UPLOADED_TYPE,
  });

  useEffect(() => {
    form.resetFields();
    if (input?.templateId) {
      onChangeTemplate(input.templateId);
    }
    setAttachments((input?.attachments ?? []).map(mapAttachmentToUploadFile));
  }, [input, messageTemplatesData]);

  const [sendSMS, { loading: smsLoading }] = useSendSmsMutation();
  const [sendEmail, { loading: emailLoading }] = useSendEmailMutation();

  const onSend = async () => {
    const values = await form.validateFields();
    try {
      if (
        values.emails.length > 0 &&
        (!values.subject || values.subject.trim().length === 0)
      ) {
        throw Error(
          "When sending an email, a subject is required. Operation aborted."
        );
      } else if (values.phones.length === 0 && values.emails.length === 0) {
        throw Error(
          "You have to specify at least one email address or phone number."
        );
      }

      const smsRequest = {
        variables: {
          input: {
            message: values.message,
            phones: values.phones.map((selectValue: LabeledValue) => {
              try {
                const parsedValue = JSON.parse(selectValue.value as string);
                const student = parsedValue.linkedEntity?.student?.studentId
                  ? {
                      studentId: parsedValue.linkedEntity.student.studentId,
                      parentNo: parsedValue.linkedEntity.student.parentNo,
                    }
                  : undefined;
                return {
                  name: parsedValue.name ?? parsedValue,
                  phone: parsedValue.phone ?? parsedValue,
                  linkedEntity: {
                    student,
                    teacherId: parsedValue.linkedEntity.teacherId,
                    classId: parsedValue.linkedEntity.classId,
                    invoiceId: parsedValue.linkedEntity.invoiceId,
                  },
                };
              } catch {
                return { name: selectValue.value, phone: selectValue.value };
              }
            }),
          },
        },
      };
      const emailRequest = {
        variables: {
          input: {
            message: values.message,
            emails: values.emails.map((selectValue: LabeledValue) => {
              try {
                const parsedValue = JSON.parse(selectValue.value as string);
                return parsedValue.address
                  ? parsedValue
                  : {
                      name: parsedValue,
                      address: parsedValue,
                    };
              } catch {
                return {
                  name: selectValue.value,
                  address: selectValue.value,
                };
              }
            }),
            subject: values.subject ?? "",
            attachments: attachments.filter(
              (attachment) => attachment.type !== ALREADY_UPLOADED_TYPE
            ),
            alreadyUploadedAttachments: attachments
              .filter((attachment) => attachment.type === ALREADY_UPLOADED_TYPE)
              .map((attachment) => Number(attachment.uid)),
          },
        },
      };

      const [smsResult] = await Promise.all([
        sendSMS(smsRequest),
        sendEmail(emailRequest),
      ]);
      message.success("Message sent successfully");

      const smsErrors = smsResult.data?.sendSMS ?? [];
      if (smsErrors.length > 0) {
        message.error(
          `Some or all of the SMS messages failed to send: ${JSON.stringify(
            smsErrors
          )}`,
          20
        );
      }
      onClose();
    } catch (error) {
      message.error(
        `Some or all of the messages failed to send: ${error.message}`,
        10
      );
    }
  };

  const onChangeTemplate = (selectedMessageTemplateId: number) => {
    const messageTemplate = messageTemplatesData?.getMessageTemplates.find(
      (messageTemplate) => messageTemplate.id === selectedMessageTemplateId
    );
    if (messageTemplate) {
      form.setFieldsValue({
        subject: messageTemplate.subject,
        message: messageTemplate.templateMessage,
      });
      setAttachments(
        messageTemplate.attachments.map(mapAttachmentToUploadFile)
      );
    }
  };

  return (
    <Modal
      width={wideModalWidth}
      visible={input != null}
      title="Compose Message"
      confirmLoading={smsLoading || emailLoading}
      onCancel={onClose}
      onOk={onSend}
      okText="Send"
      cancelText="Cancel"
    >
      <Form form={form} layout="vertical">
        <Form.Item>
          <Alert
            type="info"
            showIcon
            message="Messages to phone numbers incur a small cost - see https://aws.amazon.com/sns/sms-pricing/."
          />
        </Form.Item>
        <Form.Item
          name="phones"
          label="Phones"
          initialValue={input?.phones.map((phoneObj) => ({
            label: `${phoneObj.name} (${phoneObj.phone})`,
            value: JSON.stringify(phoneObj),
          }))}
        >
          <Select labelInValue mode="tags" optionLabelProp="label" allowClear />
        </Form.Item>
        <Form.Item
          name="emails"
          label="Emails"
          initialValue={input?.emails.map((emailObj) => ({
            label: `${emailObj.name} (${emailObj.address})`,
            value: JSON.stringify(emailObj),
          }))}
        >
          <Select labelInValue mode="tags" optionLabelProp="label" allowClear />
        </Form.Item>
        <Form.Item
          name="messageTemplate"
          label="Template"
          initialValue={input?.templateId}
        >
          <Select loading={messageTemplatesLoading} onChange={onChangeTemplate}>
            {(messageTemplatesData?.getMessageTemplates ?? []).map(
              (messageTemplate) => (
                <Option key={messageTemplate.id} value={messageTemplate.id}>
                  {messageTemplate.name}
                </Option>
              )
            )}
          </Select>
        </Form.Item>
        <Form.Item name="subject" label="Subject (applies to emails only)">
          <Input />
        </Form.Item>
        <Form.Item
          name="attachments"
          label="Attachments (applies to emails only)"
        >
          <Dragger
            multiple
            customRequest={(options) =>
              setAttachments((attachments) => [options.file, ...attachments])
            }
            fileList={attachments}
            onRemove={(removedAttachment) =>
              setAttachments((attachments) =>
                attachments.filter(
                  (attachment) => attachment !== removedAttachment
                )
              )
            }
          >
            <p>
              <InboxOutlined />
            </p>
            <p>Click or drag file to this area to upload email attachments.</p>
          </Dragger>
        </Form.Item>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: true }]}
            style={{ flex: 1 }}
          >
            <Input.TextArea rows={12} />
          </Form.Item>
          <ContextVariablesExplanation
            style={{
              width: "300px",
              padding: "15px",
              maxHeight: "300px",
              overflow: "auto",
            }}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default MessageComposeModal;
