import { Button, PageHeader, Switch, Typography } from "antd";
import _ from "lodash";
import {
  useCortexConfigurationQuery,
  useGetExtraActivitiesQuery,
  Week,
} from "../../../generated/graphql";
import { LikeOutlined } from "@ant-design/icons";
import CortexLayout from "../../components/CortexLayout";
import ExtraActivitiesWeekCard from "./components/ExtraActivitiesWeekCard";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useState } from "react";
import { FixedWidthContent } from "../../components/CommonStyles/page";
import useCortexPath from "../../../state/cortexPath";
import CortexAlerter from "../../components/CortexAlerter";

const { Title } = Typography;

const ExtraActivities: React.FC = () => {
  const cortexPath = useCortexPath();
  const database = cortexPath?.params.database;
  const isImpersonating =
    useCortexPath()?.params.impersonateTeacherId !== undefined;
  const { data: cortexConfiguration } = useCortexConfigurationQuery({
    fetchPolicy: "cache-first",
  });
  const [isShowAllWeeks, setIsShowAllWeeks] = useState(false);
  const viewableWeeks =
    cortexConfiguration?.getSystemConfiguration.system.viewableWeeks.filter(
      (week) => week !== Week.Wk0 && week !== Week.WkNextTerm
    ) ?? [];

  const modifiableWeeks =
    cortexConfiguration?.getSystemConfiguration.user.modifiableWeeks.filter(
      (week) => week !== Week.WkNextTerm
    ) ?? [];

  const weeks = isShowAllWeeks ? viewableWeeks : modifiableWeeks;

  const { data, loading, refetch } = useGetExtraActivitiesQuery({
    variables: {
      weeks: viewableWeeks,
    },
  });

  if (!cortexConfiguration) {
    return null;
  }

  const extraActivitiesByWeek = _(data?.getExtraActivities)
    .groupBy((x) => x.week)
    .map((value, key) => ({ week: key, extraActivities: value }))
    .value();

  return (
    <CortexLayout>
      <FixedWidthContent>
        <CortexAlerter />
        <PageHeader
          title={<Title level={2}>Extra Activities</Title>}
          extra={[
            <span key="showTermHistory">
              Show term history &nbsp;
              <Switch checked={isShowAllWeeks} onChange={setIsShowAllWeeks} />
            </span>,
            <Button
              icon={<LikeOutlined />}
              key="approvals"
              href={`/cortex/${database}/admin/approvals`}
            >
              Approvals
            </Button>,
          ]}
        >
          {loading ? (
            <LoadingSpinner />
          ) : (
            weeks.map((week) => {
              const activities =
                extraActivitiesByWeek.find(
                  (extraActivitiesInWeek) => extraActivitiesInWeek.week === week
                )?.extraActivities ?? [];

              const isActive =
                modifiableWeeks.find(
                  (modifiableWeek) => modifiableWeek === week
                ) !== undefined;

              const range = cortexConfiguration.getSystemConfiguration.system.timesheetRanges.find(
                (range) => range.week === week
              );
              if (!range) {
                throw Error(
                  "Internal Server Error: Each extra activity week must have a timesheet range"
                );
              }

              return (
                <>
                  <ExtraActivitiesWeekCard
                    key={week}
                    week={week}
                    timesheetRange={range}
                    isActive={isActive}
                    isEditable={isImpersonating || isActive}
                    extraActivities={activities}
                    refetch={refetch}
                  />
                  <br />
                </>
              );
            })
          )}
        </PageHeader>
      </FixedWidthContent>
    </CortexLayout>
  );
};

export default ExtraActivities;
