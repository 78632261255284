import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import {
  AtlassianNavigation,
  generateTheme,
  PrimaryButton,
} from "@atlaskit/atlassian-navigation";
import {
  ElevatedPrivileges,
  useCortexConfigurationQuery,
  useLogoutMutation,
  useSelfQuery,
} from "../../../generated/graphql";
import ProductHome from "./components/ProductHome";
import Profile from "./components/Profile";
import TimesheetNotifier from "./components/TimesheetNotifier";
import ImpersonatorProfile from "./components/ImpersonatorProfile";
import { useEffect } from "react";
import useCortexPath from "../../../state/cortexPath";

const theme = generateTheme({
  name: "high-contrast",
  backgroundColor: "#56388E",
  highlightColor: "#1da57a",
});

const Navbar: React.FC = () => {
  const cortexPath = useCortexPath();
  const database = cortexPath?.params.database;
  const cortexLocation = database?.includes("burwood")
    ? "burwood"
    : "hurstville";
  const impersonateTeacherId = cortexPath?.params.impersonateTeacherId;
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const { data: selfData, refetch: refetchSelf } = useSelfQuery({
    fetchPolicy: "cache-first",
  });
  const { data: cortexConfiguration } = useCortexConfigurationQuery({
    fetchPolicy: "cache-first",
  });
  const [logout] = useLogoutMutation();
  const primaryPath = location.pathname.split("/")[3];
  const impersonatePath = location.pathname.split("/")[5];

  useEffect(() => {
    refetchSelf();
  }, [database, impersonateTeacherId]);

  const self = selfData?.getSelf;
  const isShowAdmin =
    self &&
    self.elevatedPrivileges !== ElevatedPrivileges.None &&
    !cortexPath?.params.impersonateTeacherId;

  const primaryItems = [
    <PrimaryButton
      key="attendance"
      isHighlighted={[primaryPath, impersonatePath].includes("attendance")}
      onClick={(): void => history.push(`${match.url}/attendance`)}
    >
      Attendance
    </PrimaryButton>,
    <PrimaryButton
      key="extra-activities"
      isHighlighted={[primaryPath, impersonatePath].includes(
        "extra-activities"
      )}
      onClick={(): void => history.push(`${match.url}/extra-activities`)}
    >
      Extras
    </PrimaryButton>,
    <PrimaryButton
      key="timesheet"
      isHighlighted={[primaryPath, impersonatePath].includes("timesheet")}
      onClick={(): void => history.push(`${match.url}/timesheet`)}
    >
      Timesheet
    </PrimaryButton>,
    <PrimaryButton
      key="marking"
      isHighlighted={[primaryPath, impersonatePath].includes("marking")}
      onClick={(): Window | null =>
        window.open(
          `${process.env.REACT_APP_LEGACY_SERVICE_URL}/TheBrainNetworkServicesManager/?Cortex${cortexLocation}=thth7246_thebrain_${database}&redirectTo=marking`,
          "_blank"
        )
      }
    >
      Marking
    </PrimaryButton>,
    <PrimaryButton
      key="memos"
      isHighlighted={[primaryPath, impersonatePath].includes("memos")}
      onClick={(): void => history.push(`${match.url}/memos`)}
    >
      Memos
    </PrimaryButton>,
    <PrimaryButton
      key="admin"
      isHighlighted={[primaryPath, impersonatePath].includes("admin")}
      onClick={(): void => history.push(`${match.url}/admin`)}
    >
      {isShowAdmin ? "Admin" : "Profile"}
    </PrimaryButton>,
  ];

  if (!database || !self || !cortexConfiguration) {
    return (
      <AtlassianNavigation
        label="The Brain Network Client"
        theme={theme}
        renderProductHome={(): JSX.Element =>
          ProductHome(database ?? "Loading")
        }
        primaryItems={primaryItems}
      />
    );
  }

  return (
    <AtlassianNavigation
      primaryItems={primaryItems}
      label="The Brain Network Client"
      theme={theme}
      renderProductHome={(): JSX.Element => ProductHome(database)}
      renderSignIn={(): JSX.Element | null =>
        impersonateTeacherId
          ? ImpersonatorProfile(self, database)
          : Profile(self, logout)
      }
      renderSearch={
        !impersonateTeacherId
          ? () =>
              TimesheetNotifier(
                cortexConfiguration.getSystemConfiguration.system,
                self
              )
          : undefined
      }
    />
  );
};

export default Navbar;
