import qs from "qs";
import { Switch, Route, Redirect } from "react-router-dom";
import Cortex from "./Cortex";
import {
  useLoginWithTokenMutation,
  useSelfLazyQuery,
} from "./generated/graphql";
import { setAccessToken } from "./state/globalSession/actions";

import { connect, ConnectedProps } from "react-redux";
import { useEffect } from "react";
import LoadingSpinner from "./Cortex/components/LoadingSpinner";
import { cortexPaths } from "./state/cortexPath";

const mapDispatchToProps = {
  setAccessToken,
};
const connector = connect(null, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const App: React.FC<Props> = ({ setAccessToken }) => {
  const queryString = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const jwtToken = queryString.jwt as string;
  const [getSelf, { data: self, loading, error, client }] = useSelfLazyQuery();
  const [login] = useLoginWithTokenMutation({
    variables: { jwtToken },
  });

  const start = (): void => {
    client?.resetStore();
    getSelf();
  };

  useEffect(() => {
    login()
      .then((response) => {
        const newAccessToken = response.data?.loginWithToken.accessToken;
        if (newAccessToken) {
          setAccessToken(jwtToken);
          start();
        } else {
          throw Error("Failed to login using jwt token.");
        }
      })
      .catch(() => {
        fetch(`${process.env.REACT_APP_GATEWAY_URL}/rest/refresh_token`, {
          method: "POST",
          credentials: "include",
        })
          .then(async (response) => {
            const { access_token: accessToken } = await response.json();
            setAccessToken(accessToken);
            start();
          })
          .catch(start);
      });
  }, []);

  if (error) {
    return (
      <>
        <h1>The Brain Network Identity Platform</h1>
        <p>
          Unable to retrieve single sign on information. Please login via The
          Brain website.
        </p>
        <p>Error: {error.message}</p>
      </>
    );
  }

  return !self || loading ? (
    <LoadingSpinner />
  ) : (
    <Switch>
      <Route path={cortexPaths} component={Cortex} />
      <Redirect to="/cortex/hurstville" />
    </Switch>
  );
};

export default connector(App);
