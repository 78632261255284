import { Alert, Button, Divider, message, Modal, Select, Tooltip } from "antd";
import { useState } from "react";
import ClassesNavigator from "../../../components/ClassesNavigator";
import { UserAddOutlined } from "@ant-design/icons";
import {
  TeachingRole,
  useNewTeacherClassLinkMutation,
} from "../../../../generated/graphql";
import { teacherRoleSelectOptions } from "../../../../util/selectOptions";
import styled from "styled-components";
import { wideModalWidth } from "../../../components/CommonStyles/page";

const RoleSelector = styled.div`
  text-align: center;
`;

const ModalBody: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [role, setRole] = useState(TeachingRole.Assisting);
  const [newTeacherClassLink, { loading }] = useNewTeacherClassLinkMutation();

  return (
    <>
      <Alert
        message="Add an existing class allows you to join a class which already exists. This may be useful if you are covering, assisting or training with an existing class, or you just became the teacher of an already existing class. If the class does not exist, you can create a new one by selecting 'Create new class'."
        type="warning"
        showIcon
      />
      <Divider>Your role</Divider>
      <RoleSelector>
        <Select
          value={role}
          onChange={setRole}
          options={teacherRoleSelectOptions}
        />
      </RoleSelector>
      <Divider>Class</Divider>
      <ClassesNavigator
        actions={(klass): React.ReactNode =>
          !klass.isDeleted ? (
            <Tooltip title="Add Class">
              <Button
                icon={<UserAddOutlined />}
                type="primary"
                loading={loading}
                onClick={async (): Promise<void> => {
                  try {
                    await newTeacherClassLink({
                      variables: {
                        input: {
                          classId: klass.id,
                          teachingRole: role,
                        },
                      },
                    });
                    onClose();
                    message.success(
                      "Successfully linked existing class to your attendance record"
                    );
                  } catch (error) {
                    message.error(
                      `Failed to link existing class to your attendance record: ${error.message}`,
                      10
                    );
                  }
                }}
              />
            </Tooltip>
          ) : (
            "Deleted"
          )
        }
      />
    </>
  );
};

const AddExistingClassModal: React.FC<{
  isVisible: boolean;
  onClose: () => void;
}> = ({ isVisible, onClose }) => {
  return (
    <Modal
      width={wideModalWidth}
      visible={isVisible}
      title="Add Existing Class"
      confirmLoading={false}
      onCancel={onClose}
      footer={null}
    >
      {isVisible && <ModalBody onClose={onClose} />}
    </Modal>
  );
};

export default AddExistingClassModal;
