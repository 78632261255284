import { Moment } from "moment";
import { TimePicker, TimePickerProps } from "antd";
import { useEffect, useState } from "react";

/**
 * Wrapper for TimePicker that doesn't require pressing OK button to confirm the time.
 */
const TimePickerAutoAccept: React.FC<
  TimePickerProps & React.RefAttributes<unknown>
> = (props) => {
  // We need to keep track of the value so that we can update the displayed
  // time when onSelect is called.
  const [value, setValue] = useState(props.value);

  // This is so that if the parent value changes then it forces the
  // TimePicker to show the updated time.
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  /**
   * Called when a time is selected.
   * @param value new time value.
   */
  const onSelect = (value: Moment) => {
    if (value && value.isValid() && props.onChange) {
      props.onChange(value, value.format(props.format as string));
      setValue(value);
    }
  };

  return <TimePicker {...props} value={value} onSelect={onSelect} />;
};

export default TimePickerAutoAccept;
