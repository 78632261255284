import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { DATE_TIME_DISPLAY_FORMAT } from "../../../../../../util/constants";

export interface Column extends ColumnType<Column> {
  dataIndex: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SortData = any;

export const columns: Column[] = [
  {
    title: "Teacher",
    dataIndex: "teacher",
  },
  {
    title: "Entity Type",
    dataIndex: "entityType",
  },
  {
    title: "Entity ID",
    dataIndex: "entityId",
  },
  {
    title: "Message",
    dataIndex: "message",
  },
  {
    title: "Timestamp",
    dataIndex: "timestamp",
    sorter: (a: SortData, b: SortData): number =>
      moment(a.timestamp, DATE_TIME_DISPLAY_FORMAT).unix() -
      moment(b.timestamp, DATE_TIME_DISPLAY_FORMAT).unix(),
    defaultSortOrder: "ascend",
  },
];
