import ReactDOM from "react-dom";
import "moment/locale/en-gb";
import "moment-timezone";
import locale from "antd/es/locale/en_GB";
import moment from "moment";
import App from "./App";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { store } from "./state";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import jwtDecode from "jwt-decode";
import { ConfigProvider, message } from "antd";
import { LogoutDocument } from "./generated/graphql";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { logout, setAccessToken } from "./state/globalSession/actions";
import useCortexPath, {
  isImpersonating_TimesheetProcessor,
} from "./state/cortexPath";

moment.locale("en-gb", {
  week: {
    dow: 1,
  },
});
moment.tz.setDefault("Australia/Sydney");

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_GATEWAY_URL}/graphql`,
  credentials: "include",
});

const contextLink = setContext((_, { headers }) => {
  const { globalSession } = store.getState();
  const accessToken = globalSession.accessToken;
  const cortexPath = useCortexPath();
  const database = cortexPath?.params.database;
  const impersonateTeacherId = cortexPath?.params.impersonateTeacherId;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
      // Cortex specific headers
      impersonateTeacherId: impersonateTeacherId ?? "",
      isImpersonating_TimesheetProcessor: isImpersonating_TimesheetProcessor(),
      database: `thth7246_thebrain_${database ?? "burwood"}`, // Every request needs a valid Cortex DB even if it won't be used.
    },
  };
});

const tokenRefreshLink = new TokenRefreshLink({
  fetchAccessToken: (): Promise<Response> =>
    fetch(`${process.env.REACT_APP_GATEWAY_URL}/rest/refresh_token`, {
      method: "POST",
      credentials: "include",
    }),
  handleFetch: (accessToken): void => {
    if (useCortexPath()?.params.database) {
      store.dispatch(setAccessToken(accessToken));
    }
  },
  isTokenValidOrUndefined: (): boolean => {
    if (!document.cookie.includes("THEBRAINNETWORK_SESSIONID_LEGACY")) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      client.setLink(contextLink.concat(httpLink));

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      client.mutate({ mutation: LogoutDocument });

      store.dispatch(logout());
    }

    const token = store.getState().globalSession.accessToken;
    if (!token) {
      return true;
    }

    try {
      const { exp } = jwtDecode<{ exp: number }>(token);
      if (Date.now() < exp * 1000) {
        return true;
      }
    } catch {
      // Token decode failed for some reason if reached here.
    }

    return false;
  },
  handleError: (): void => {
    message.error(
      "The Brain Network Identity Platform - Token refresh error",
      10
    );
  },
});

const client = new ApolloClient({
  link: tokenRefreshLink.concat(contextLink).concat(httpLink),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    },
  },
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <ConfigProvider locale={locale}>
        <Router>
          <App />
        </Router>
      </ConfigProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById("the-brain-network-client")
);
