import {
  Tooltip,
  Button,
  Popover,
  Form,
  InputNumber,
  Input,
  Alert,
  message,
} from "antd";
import { useRef, useState, useEffect } from "react";
import {
  AttendanceFieldsFragment,
  InvoiceLineWeeksFieldsFragment,
  InvoiceType,
  PaymentStatus,
  useCortexConfigurationQuery,
  useLinkOrUnlinkInvoiceLineWeekToAttendanceMutation,
  useUpdateAttendanceMutation,
} from "../../../../generated/graphql";
import { StatusText } from "../../CommonStyles/week";
import { UserOutlined, MoreOutlined } from "@ant-design/icons";
import styled from "styled-components";
import Title from "antd/lib/typography/Title";
import { useClickAway } from "react-use";
import _ from "lodash";
import useCortexPath from "../../../../state/cortexPath";

const NoRecordsFoundText = styled.div`
  text-align: center;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PopupDialog = styled.div`
  width: 325px;
`;

const ActionsButtonsContainer = styled.div`
  text-align: right;
`;

const ActionButton = styled(Button)`
  margin-bottom: 5px;
  width: 25%;
`;

const InvoiceLineWeeksContainer = styled.div`
  max-height: 200px;
  overflow: auto;
  margin-bottom: 10px;
`;

const InvoiceLineWeek = styled(Alert)`
  margin-bottom: 5px;
  font-size: 12px;
`;

const WeeksPaidText: React.FC<{
  weeksPaid: number;
  weeksPaidComment: string;
}> = ({ weeksPaid, weeksPaidComment }) => {
  if (weeksPaid === -1) {
    return <>Unknown</>;
  } else {
    let text = `${weeksPaid} week${weeksPaid !== 1 ? "s" : ""}`;
    if (weeksPaidComment.length > 0) {
      text += ` (${weeksPaidComment})`;
    }
    return <>{text}</>;
  }
};

const PaymentStatusText: React.FC<{
  paymentStatus: PaymentStatus;
  weeksRemaining: number;
}> = ({ paymentStatus, weeksRemaining }) => {
  switch (paymentStatus) {
    case PaymentStatus.NeedsUpdate:
      return <StatusText color="orange">CHECK WITH RECEPTION</StatusText>;
    case PaymentStatus.PaymentOk:
      return (
        <StatusText>
          {weeksRemaining} WEEK{weeksRemaining !== 1 ? "S" : ""} REMAINING
        </StatusText>
      );
    case PaymentStatus.PaymentOkNoLessonsRemaining:
      return <StatusText color="orange">NO LESSONS REMAINING</StatusText>;
    case PaymentStatus.UnpaidLessonsCompleted:
      return (
        <StatusText color="red">{-weeksRemaining} UNPAID LESSONS</StatusText>
      );
    case PaymentStatus.NotEnrolled:
      // This is theoretically impossible as we wouldn't be displaying this in the attendance table if the student wasn't in the class in the first place.
      return <StatusText>NOT ENROLLED</StatusText>;
  }
};

const RightBtn = styled(Button)`
  margin-left: 7px;
`;

const Payment: React.FC<{
  attendance: AttendanceFieldsFragment;
  setLinkedClassViewerId: (classId: number) => void;
  setStudentAttendanceModalPrimaryAttendanceId: (attendanceId: number) => void;
  setStudentAttendanceModalId: (attendanceId: number) => void;
}> = ({
  attendance: {
    id,
    weeksPaid,
    weeksPaidComment,
    paymentStatus,
    weeksRemaining,
    student,
    primaryAttendance,
    tempAttendances,
  },
  setLinkedClassViewerId,
  setStudentAttendanceModalPrimaryAttendanceId,
  setStudentAttendanceModalId,
}) => {
  const cortexPath = useCortexPath();
  const database = cortexPath?.params.database;
  const cortexLocation = database?.includes("burwood")
    ? "burwood"
    : "hurstville";
  const [updateAttendance] = useUpdateAttendanceMutation();
  const [
    linkOrUnlinkInvoiceLineWeekToAttendance,
  ] = useLinkOrUnlinkInvoiceLineWeekToAttendanceMutation();
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const popupDialogRef = useRef(null);
  const [form] = Form.useForm();

  const { data: cortexConfiguration } = useCortexConfigurationQuery({
    fetchPolicy: "cache-first",
  });
  const currentYear =
    cortexConfiguration?.getSystemConfiguration.system.year ?? 0;
  const currentTerm =
    cortexConfiguration?.getSystemConfiguration.system.term ?? 0;

  useEffect(() => {
    // Reset the form when the current value has changed, most likely due
    // to attendance records being updated in the attendance table.
    form.resetFields();
  }, [weeksPaid, weeksPaidComment, isDialogVisible]);

  const onFinish = async () => {
    setIsDialogVisible(false);
    try {
      const values = await form.validateFields();
      const originalInput = {
        id,
        weeksPaid,
        weeksPaidComment,
      };
      const input = {
        id,
        weeksPaid: values.weeksPaid,
        weeksPaidComment: values.weeksPaidComment,
      };
      if (!_.isEqual(originalInput, input)) {
        await updateAttendance({ variables: { input } });
        message.success("Automatically saved");
      }
    } catch (error) {
      message.error(`Failed to update attendance: ${error.message}`, 10);
    }
  };

  // To ensure clicking outside the dialog box saves and closes the data.
  useClickAway(popupDialogRef, onFinish);

  const linkOrUnlink = async (
    invoiceLineWeekId: number,
    attendanceId?: number
  ) => {
    try {
      await linkOrUnlinkInvoiceLineWeekToAttendance({
        variables: { invoiceLineWeekId, attendanceId },
      });
      message.success("Automatically saved");
    } catch (error) {
      message.error(
        `Failed to link/unlink week on invoice line to attendance: ${error.message}`,
        10
      );
    }
  };

  const isInvoiceLineWeekValid = (
    invoiceLineWeek: InvoiceLineWeeksFieldsFragment
  ) =>
    (invoiceLineWeek.term >= currentTerm &&
      invoiceLineWeek.year === currentYear) ||
    invoiceLineWeek.year > currentYear;

  const invoiceLineWeekItems = student.currentInvoices.flatMap((invoice) =>
    invoice.invoiceLines.flatMap((invoiceLine) =>
      invoiceLine.invoiceLineWeeks
        .filter(isInvoiceLineWeekValid)
        .map((invoiceLineWeek) => {
          return (
            <InvoiceLineWeek
              key={invoiceLineWeek.id}
              type={invoiceLineWeek.attendanceId === id ? "warning" : undefined}
              message={
                <FlexContainer>
                  <div>
                    <b>
                      <a
                        href={`${process.env.REACT_APP_LEGACY_SERVICE_URL}/TheBrainNetworkServicesManager/?Cortex${cortexLocation}=thth7246_thebrain_${database}&redirectTo=admin/invoicing/build?invoiceId=${invoice.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {invoice.type} {invoice.generatedInvoiceId}
                      </a>
                    </b>
                    <br />
                    <b>{invoiceLine.subject}</b>: {invoiceLineWeek.weeks} Wks T
                    {invoiceLineWeek.term} {invoiceLineWeek.year}
                  </div>
                  <div>
                    {invoiceLineWeek.attendanceId === id ? (
                      <RightBtn
                        onClick={() => linkOrUnlink(invoiceLineWeek.id)}
                      >
                        <StatusText>Unlink</StatusText>
                      </RightBtn>
                    ) : !invoiceLineWeek.attendanceId ? (
                      <RightBtn
                        onClick={() => linkOrUnlink(invoiceLineWeek.id, id)}
                      >
                        <StatusText>Link</StatusText>
                      </RightBtn>
                    ) : (
                      <StatusText>Other class</StatusText>
                    )}
                  </div>
                </FlexContainer>
              }
            />
          );
        })
    )
  );

  const autofill = () => {
    let weeksPaid = 0;
    const termsStrings: string[] = [];
    for (const invoice of student.currentInvoices) {
      if (invoice.type === InvoiceType.Receipt) {
        for (const invoiceLine of invoice.invoiceLines) {
          for (const invoiceLineWeek of invoiceLine.invoiceLineWeeks) {
            if (
              invoiceLineWeek.attendanceId === id &&
              isInvoiceLineWeekValid(invoiceLineWeek)
            ) {
              weeksPaid += invoiceLineWeek.weeks;
              termsStrings.push(
                `${invoiceLineWeek.weeks} (T${invoiceLineWeek.term})`
              );
            }
          }
        }
      }
    }

    form.setFieldsValue({
      weeksPaid,
      weeksPaidComment: termsStrings.join(", "),
    });
  };

  const popupDialog = (
    <PopupDialog ref={popupDialogRef}>
      <Form
        form={form}
        layout="horizontal"
        initialValues={{ weeksPaid, weeksPaidComment }}
        onFinish={onFinish}
      >
        <ActionsButtonsContainer>
          <ActionButton size="small" type="primary" htmlType="submit">
            Save
          </ActionButton>
        </ActionsButtonsContainer>
        <Title level={5}>Invoices</Title>
        <InvoiceLineWeeksContainer>
          {invoiceLineWeekItems.length > 0 ? (
            invoiceLineWeekItems
          ) : (
            <NoRecordsFoundText>No invoices found.</NoRecordsFoundText>
          )}
        </InvoiceLineWeeksContainer>
        <Title level={5}>Payment Status</Title>

        <FlexContainer>
          <div>
            <Form.Item
              name="weeksPaid"
              label="Weeks Paid"
              style={{ marginBottom: "5px" }}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="weeksPaidComment"
              label="Comment"
              style={{ margin: 0 }}
            >
              <Input />
            </Form.Item>
          </div>
          <RightBtn onClick={autofill}>Autofill</RightBtn>
        </FlexContainer>
      </Form>
    </PopupDialog>
  );

  if (primaryAttendance) {
    return (
      <FlexContainer>
        <Tooltip title="The student is temporarily in this class for this timesheet period only. Their attendance info will be merged back into the normal class when the timesheet period ends. Click on the button to view the normal class' attendance record.">
          Temporary
        </Tooltip>
        <Tooltip title="View linked class">
          <RightBtn
            icon={<UserOutlined />}
            onClick={() => setLinkedClassViewerId(primaryAttendance.class.id)}
          />
        </Tooltip>
      </FlexContainer>
    );
  }

  return (
    <>
      <FlexContainer>
        <div>
          <WeeksPaidText
            weeksPaid={weeksPaid}
            weeksPaidComment={weeksPaidComment}
          />
          <br />
          <PaymentStatusText
            paymentStatus={paymentStatus}
            weeksRemaining={weeksRemaining}
          />
        </div>
        <Popover content={popupDialog} visible={isDialogVisible}>
          <RightBtn
            icon={<MoreOutlined />}
            onClick={() => setIsDialogVisible(true)}
          />
        </Popover>
      </FlexContainer>
      {tempAttendances.length > 0 && (
        <FlexContainer>
          Visited classes
          <Tooltip title="View visited classes">
            <RightBtn
              icon={<UserOutlined />}
              onClick={() => {
                setStudentAttendanceModalPrimaryAttendanceId(id);
                setStudentAttendanceModalId(student.id);
              }}
            />
          </Tooltip>
        </FlexContainer>
      )}
    </>
  );
};

export default Payment;
