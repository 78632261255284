import { FetchResult } from "@apollo/client";
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
} from "antd";

import {
  Gender,
  NewStudentMutation,
  useNewStudentMutation,
} from "../../../../../../generated/graphql";
import { DATE_DISPLAY_FORMAT } from "../../../../../../util/constants";
import styled from "styled-components";

const { Option } = Select;

const WideDatePicker = styled(DatePicker)`
  width: 100%;
`;

const NewStudentModal: React.FC<{
  isVisible: boolean;
  onSuccessfulCreate: (student: FetchResult<NewStudentMutation>) => void;
  onCancel: () => void;
}> = ({ isVisible, onSuccessfulCreate, onCancel }) => {
  const [newStudent, { loading }] = useNewStudentMutation();
  const [form] = Form.useForm();

  return (
    <Modal
      visible={isVisible}
      title="New Student"
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={async (): Promise<void> => {
        try {
          const values = await form.validateFields();
          const input = {
            firstName: values.firstName,
            surname: values.surname,
            email: values.email,
            school: values.school,
            phone: values.phone,
            phone2: values.phone2,
            gender: values.gender === "N/A" ? Gender.Na : values.gender,
            grade: values.grade,
            address: values.address,
            dateOfBirth: values.dateOfBirth?.format("YYYY-MM-DD"),
            isEmailPreferred: values.isEmailPreferred,
            languages: values.languages,
            medicalConditions: values.medicalConditions,
            notes: values.notes,
            parentGuardianName: values.parentGuardianName,
            parentGuardianEmail: values.parentGuardianEmail,
            parentGuardianPhone: values.parentGuardianPhone,
            parentGuardianRelationship: values.parentGuardianRelationship,
            parentGuardianName2: values.parentGuardianName2,
            parentGuardianEmail2: values.parentGuardianEmail2,
            parentGuardianPhone2: values.parentGuardianPhone2,
            parentGuardianRelationship2: values.parentGuardianRelationship2,
          };
          console.log(input);
          const student = await newStudent({
            variables: { input },
          });
          onSuccessfulCreate(student);
          form.resetFields();
        } catch (error) {
          message.error(
            `Server failed to create student: ${error.message}`,
            10
          );
        }
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="surname"
          label="Surname"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="school"
          label="School"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="phone2" label="Phone 2">
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email (Required if Synapse access is desired)"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="gender"
          label="Gender"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue="N/A"
        >
          <Select defaultValue="N/A">
            {["N/A", "Male", "Female"].map((gender) => (
              <Option key={gender} value={gender}>
                {gender}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="dateOfBirth" label="Date of Birth">
          <WideDatePicker
            format={DATE_DISPLAY_FORMAT}
            placeholder={DATE_DISPLAY_FORMAT}
          />
        </Form.Item>
        <Form.Item
          name="grade"
          label="Grade"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            {[
              "0",
              "K",
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
            ].map((grade) => (
              <Option key={grade} value={grade}>
                {grade}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="address" label="Address">
          <Input />
        </Form.Item>
        <Form.Item name="languages" label="Languages">
          <Input />
        </Form.Item>
        <Form.Item name="medicalConditions" label="Medical Conditions">
          <Input />
        </Form.Item>
        <Form.Item
          name="parentGuardianName"
          label="Parent/Guardian's Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="parentGuardianRelationship"
          label="Parent/Guardian's Relationship"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="parentGuardianPhone"
          label="Parent/Guardian's Phone"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="parentGuardianEmail"
          label="Parent/Guardian's Email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="parentGuardianName2" label="Parent/Guardian's Name 2">
          <Input />
        </Form.Item>
        <Form.Item
          name="parentGuardianRelationship2"
          label="Parent/Guardian's Relationship 2"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="parentGuardianPhone2"
          label="Parent/Guardian's Phone 2"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="parentGuardianEmail2"
          label="Parent/Guardian's Email 2"
        >
          <Input />
        </Form.Item>
        <Form.Item name="notes" label="Notes">
          <Input />
        </Form.Item>
        <Form.Item
          name="isEmailPreferred"
          valuePropName="checked"
          label="Email Preferences"
        >
          <Checkbox>Email invoices preferred</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewStudentModal;
