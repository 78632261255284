import { Form } from "antd";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

export interface EditableBaseCommonProps<T> {
  onSave: (newValue: T) => void;
  value?: T | null;
  required?: boolean;
  disabled?: boolean;
  isFocused?: boolean;
}

interface EditableBaseProps<T> extends EditableBaseCommonProps<T> {
  children: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputRef: React.MutableRefObject<any | null>,
    save: (value?: T | null | undefined) => Promise<void>,
    inputValue: T | null | undefined,
    setInputValue: (value: React.SetStateAction<T | null | undefined>) => void
  ) => React.ReactNode;
}

const DisplayText = styled.div<{ isHoverable?: boolean }>`
  padding: 2px;
  &:hover {
    background-color: ${(props): string =>
      props.isHoverable ? "#f0f0f0;" : "none"};
  }
`;

const EditableBase = <T,>(props: EditableBaseProps<T>): JSX.Element => {
  const { onSave, value, required, disabled, isFocused, children } = props;
  const [isEditing, setIsEditing] = useState(isFocused);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any | null>(null);
  const [inputValue, setInputValue] = useState(value);
  const [form] = Form.useForm();

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    setInputValue(value);
    form.setFieldsValue({ field: value });
  }, [value]);

  const save = async (newInputValue?: T | null | undefined) => {
    try {
      const newValue = newInputValue !== undefined ? newInputValue : inputValue;
      setInputValue(newValue);
      form.setFieldsValue({ field: newValue });
      await form.validateFields();

      if (!isFocused) {
        setIsEditing(!isEditing);
      }

      if (newValue !== value && newValue !== null && newValue !== undefined) {
        onSave(newValue);
      }
    } catch (e) {
      // nothing
    }
  };

  const toggleEdit = () => {
    if (!disabled) {
      setIsEditing((isEditing) => !isEditing);
    }
  };

  const displayValue = !value ? "–" : value;

  return isEditing ? (
    <Form form={form}>
      <Form.Item
        style={{
          margin: 0,
        }}
        name="field"
        rules={[
          {
            required,
            message: "Value is required.",
          },
        ]}
        valuePropName="SENTINEL_VALUE"
      >
        {children(inputRef, save, inputValue, setInputValue)}
      </Form.Item>
    </Form>
  ) : (
    <DisplayText onClick={toggleEdit} isHoverable={!disabled}>
      {displayValue}
    </DisplayText>
  );
};

export default EditableBase;
