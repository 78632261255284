import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** Represents date and time in ISO format */
  DateTimeISO: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['Int'];
  username?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  surname: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  isTeacher: Scalars['Boolean'];
  isActivated: Scalars['Boolean'];
  isDisabled: Scalars['Boolean'];
  profilePictureURL: Scalars['String'];
};

export type AdjustmentNewInput = {
  teacherClassLinkId: Scalars['Int'];
  week: Week;
  hoursToSubtract: Scalars['Float'];
  oldAttendanceData: Scalars['String'];
  reason: Scalars['String'];
};

export type Attendance = {
  __typename?: 'Attendance';
  id: Scalars['Int'];
  weeksPaid: Scalars['Float'];
  weeksPaidComment: Scalars['String'];
  /** Weeks remaining for this attendance. Note that a value of -1 means 'Unknown */
  weeksRemaining: Scalars['Float'];
  paymentStatus: PaymentStatus;
  weekAttendanceInfos: Array<WeekAttendanceInfo>;
  weekAttendanceInfo: WeekAttendanceInfo;
  isDeleted: Scalars['Boolean'];
  dateCreated: Scalars['DateTime'];
  dateModified: Scalars['DateTime'];
  class: Class;
  student: Student;
  primaryAttendance?: Maybe<Attendance>;
  tempAttendances: Array<Attendance>;
};


export type AttendanceWeekAttendanceInfoArgs = {
  week: Week;
};

export type AttendanceNewInput = {
  studentId: Scalars['Int'];
  classId: Scalars['Int'];
};

export type AttendanceTemporaryNewInput = {
  /** The existing attendance which will be linked to the new temporary attendance. */
  fromAttendanceId: Scalars['Int'];
  /** The target class where the new temporary attendance will be created. */
  toClassId: Scalars['Int'];
};

export type AttendanceUpdateInput = {
  id: Scalars['Int'];
  weeksPaid?: Maybe<Scalars['Float']>;
  weeksPaidComment?: Maybe<Scalars['String']>;
  setAttendance?: Maybe<SetAttendanceParams>;
};

export type AuditLogEntry = {
  __typename?: 'AuditLogEntry';
  id: Scalars['Int'];
  teacherId: Scalars['Int'];
  teacher: Teacher;
  entityType: EntityType;
  entityId: Scalars['Int'];
  message: Scalars['String'];
  elevatedPrivileges: ElevatedPrivileges;
  dateCreated: Scalars['DateTime'];
  dateModified: Scalars['DateTime'];
};

export type Class = {
  __typename?: 'Class';
  id: Scalars['Int'];
  classProgress: Scalars['String'];
  comment: Scalars['String'];
  day: Day;
  type: ClassType;
  subject: Scalars['String'];
  /** Ignore the date. */
  startTime: Scalars['DateTimeISO'];
  /** Ignore the date. */
  endTime: Scalars['DateTimeISO'];
  duration: Scalars['Float'];
  isDeleted: Scalars['Boolean'];
  grade: Scalars['String'];
  dateCreated: Scalars['DateTime'];
  dateModified: Scalars['DateTime'];
  attendances: Array<Attendance>;
  paymentStatus: PaymentStatus;
  teacherClassLinks: Array<TeacherClassLink>;
  makeupLessons: Array<MakeupLesson>;
};

export type ClassFilterInput = {
  id?: Maybe<Scalars['Int']>;
  day?: Maybe<Day>;
  grade?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<ClassType>;
  comment?: Maybe<Scalars['String']>;
  /** Pass in an arbitrary date, it will be ignored, as only time is important. */
  startTime?: Maybe<Scalars['DateTimeISO']>;
  /** Pass in an arbitrary date, it will be ignored, as only time is important. */
  endTime?: Maybe<Scalars['DateTimeISO']>;
  teacherName?: Maybe<Scalars['String']>;
  studentName?: Maybe<Scalars['String']>;
};

export type ClassNewInput = {
  day: Day;
  type: ClassType;
  subject: Scalars['String'];
  /** Pass in an arbitrary date, it will be ignored, as only time is important. */
  startTime: Scalars['DateTimeISO'];
  /** Pass in an arbitrary date, it will be ignored, as only time is important. */
  endTime: Scalars['DateTimeISO'];
  grade: Scalars['String'];
  classProgress?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export enum ClassType {
  Class = 'Class',
  Private = 'Private'
}

export type ClassUpdateInput = {
  id: Scalars['Int'];
  day?: Maybe<Day>;
  type?: Maybe<ClassType>;
  subject?: Maybe<Scalars['String']>;
  time?: Maybe<TimeRange>;
  grade?: Maybe<Scalars['String']>;
  classProgress?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type Configuration = {
  __typename?: 'Configuration';
  /** @deprecated This data can be retrieved from Teacher class. */
  user: UserConfiguration;
  system: SystemConfiguration;
};


export type DateTimeDto = {
  __typename?: 'DateTimeDTO';
  /** Ignore the date */
  startTime?: Maybe<Scalars['DateTimeISO']>;
  /** Ignore the date */
  endTime?: Maybe<Scalars['DateTimeISO']>;
  /** Ignore the time */
  date?: Maybe<Scalars['DateTimeISO']>;
  /** Allows free text input. Not generally used in Cortex next-gen other than for displaying Adjustment Date/Time in timesheet. */
  freeText?: Maybe<Scalars['String']>;
};


export enum Day {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday'
}

export enum ElevatedPrivileges {
  None = 'None',
  Reception = 'Reception',
  Super = 'Super'
}

export type EmailAddressInput = {
  name: Scalars['String'];
  address: Scalars['String'];
  linkedEntity?: Maybe<LinkedEntityInput>;
};

export type EntityInput = {
  entityType: EntityType;
  entityId: Scalars['Int'];
};

export enum EntityType {
  Attendance = 'Attendance',
  Teacher = 'Teacher',
  Class = 'Class'
}

export type ExtraActivity = {
  __typename?: 'ExtraActivity';
  id: Scalars['Int'];
  type: ExtraActivityType;
  description: Scalars['String'];
  dateTime: DateTimeDto;
  duration: Scalars['Float'];
  units?: Maybe<Scalars['Float']>;
  pay: Scalars['Float'];
  week: Week;
  approvalStatus: ExtraActivityApprovalStatus;
  dateCreated: Scalars['DateTime'];
  dateModified: Scalars['DateTime'];
  teacher: Teacher;
};

export enum ExtraActivityApprovalStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export type ExtraActivityNewInput = {
  type: ExtraActivityType;
  description?: Maybe<Scalars['String']>;
  dateTime: ExtraActivityTimeInput;
  units?: Maybe<Scalars['Float']>;
  pay?: Maybe<Scalars['Float']>;
  week: Week;
};

export type ExtraActivityTimeInput = {
  startTime: Scalars['DateTimeISO'];
  endTime: Scalars['DateTimeISO'];
  date: Scalars['DateTimeISO'];
};

export enum ExtraActivityType {
  Reception = 'Reception',
  MarkingUnrostered = 'MarkingUnrostered',
  MarkingRostered = 'MarkingRostered',
  MarkingCovering = 'MarkingCovering',
  HomeworkHelp = 'HomeworkHelp',
  CurriculumBooklet = 'CurriculumBooklet',
  CurriculumByHour = 'CurriculumByHour',
  Interview = 'Interview',
  Other = 'Other',
  Adjustment = 'Adjustment'
}

export type ExtraActivityUpdateInput = {
  id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  dateTime?: Maybe<ExtraActivityTimeInput>;
  units?: Maybe<Scalars['Float']>;
  pay?: Maybe<Scalars['Float']>;
};

export enum Gender {
  Na = 'NA',
  Male = 'Male',
  Female = 'Female'
}

export enum GracePeriodStatus {
  Deactivated = 'Deactivated',
  Activated = 'Activated',
  Submitted = 'Submitted'
}

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['Int'];
  generatedInvoiceId: Scalars['String'];
  type: InvoiceType;
  refundType?: Maybe<RefundType>;
  typeHumanReadable: Scalars['String'];
  isInvoice: Scalars['Boolean'];
  isReceipt: Scalars['Boolean'];
  isRefund: Scalars['Boolean'];
  dateCreated: Scalars['DateTime'];
  dateModified: Scalars['DateTime'];
  invoiceLines: Array<InvoiceLine>;
};

export type InvoiceLine = {
  __typename?: 'InvoiceLine';
  id: Scalars['Int'];
  subject?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dateCreated: Scalars['DateTime'];
  dateModified: Scalars['DateTime'];
  invoice: Invoice;
  invoiceLineWeeks: Array<InvoiceLineWeek>;
};

export type InvoiceLineWeek = {
  __typename?: 'InvoiceLineWeek';
  id: Scalars['Int'];
  attendanceId?: Maybe<Scalars['Int']>;
  weeks: Scalars['Int'];
  term: Scalars['Int'];
  year: Scalars['Int'];
  dateCreated: Scalars['DateTime'];
  dateModified: Scalars['DateTime'];
  invoiceLine: InvoiceLine;
};

export type InvoiceToMessageResponse = {
  __typename?: 'InvoiceToMessageResponse';
  student: Student;
  messageTemplateAttachment: MessageTemplateAttachment;
};

export enum InvoiceType {
  Receipt = 'Receipt',
  Invoice = 'Invoice',
  Refund = 'Refund'
}

export type LinkedEntityInput = {
  student?: Maybe<StudentLinkedEntityInput>;
  teacherId?: Maybe<Scalars['Int']>;
  classId?: Maybe<Scalars['Int']>;
  invoiceId?: Maybe<Scalars['Int']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
};

export type MakeupLesson = {
  __typename?: 'MakeupLesson';
  id: Scalars['Int'];
  classId: Scalars['Int'];
  class: Class;
  week: Week;
  /** Ignore the time */
  lessonDate: Scalars['DateTimeISO'];
  dateCreated: Scalars['DateTime'];
  dateModified: Scalars['DateTime'];
};

export type MakeupLessonCreateInput = {
  classId: Scalars['Int'];
  week: Week;
  /** Ignore the time */
  lessonDate: Scalars['DateTimeISO'];
};

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  id: Scalars['Int'];
  name: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  templateMessage: Scalars['String'];
  isSystemDefault: Scalars['Boolean'];
  dateCreated: Scalars['DateTime'];
  dateModified: Scalars['DateTime'];
  attachments: Array<MessageTemplateAttachment>;
};

export type MessageTemplateAttachment = {
  __typename?: 'MessageTemplateAttachment';
  id: Scalars['Int'];
  filename: Scalars['String'];
  dateCreated: Scalars['DateTime'];
  dateModified: Scalars['DateTime'];
};

export type MessageTemplateNewInput = {
  name: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  templateMessage: Scalars['String'];
  attachments?: Maybe<Array<Scalars['Upload']>>;
};

export type MessageTemplateUpdateInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  templateMessage?: Maybe<Scalars['String']>;
  newAttachments?: Maybe<Array<Scalars['Upload']>>;
  deletedAttachmentIds?: Maybe<Array<Scalars['Int']>>;
};

export type MigrationResult = {
  __typename?: 'MigrationResult';
  database: Scalars['String'];
  timestamp: Scalars['Float'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  resetAccount: Account;
  addProfilePicture: Scalars['Boolean'];
  deleteProfilePicture: Scalars['Boolean'];
  runDatabaseMigrations: Array<MigrationResult>;
  newClass: Class;
  updateClass: Class;
  /** Marks a class and all attendances and teacher links as deleted. Entities are not deleted to prevent people losing money from timesheet history due to deleted classes. Deleted classes are actually deleted at the end of the term. */
  deleteClass: Class;
  createMakeupLesson: MakeupLesson;
  deleteMakeupLesson: Scalars['Boolean'];
  updateApprovalStatus: ExtraActivity;
  newExtraActivity: ExtraActivity;
  updateExtraActivity: ExtraActivity;
  deleteExtraActivity: Scalars['Boolean'];
  linkOrUnlinkInvoiceLineWeekToAttendance: InvoiceLineWeek;
  invoiceToMessage: InvoiceToMessageResponse;
  sendSMS: Array<Scalars['String']>;
  sendEmail: Scalars['Boolean'];
  createMessageTemplate: MessageTemplate;
  updateMessageTemplate: MessageTemplate;
  deleteMessageTemplate: Scalars['Boolean'];
  login: LoginResponse;
  loginWithToken: LoginResponse;
  logout: Scalars['Boolean'];
  newStudent: Student;
  updateStudent: Student;
  deleteStudent: StudentDeletePayload;
  newAttendance: Attendance;
  newTemporaryAttendance: Attendance;
  updateAttendance: Attendance;
  updateAttendanceForEntireClass: Array<Attendance>;
  deleteAttendance: Attendance;
  newTeacher: Teacher;
  updateTeacher: Teacher;
  deleteTeacher: TeacherDeletePayload;
  updatePayRates: TeacherPayRatesPayload;
  newTeacherClassLink: TeacherClassLink;
  updateTeacherClassLink: TeacherClassLink;
  deleteTeacherClassLink: TeacherClassLink;
  resetViewStatus: Array<Teacher>;
  confirmTimesheet: Teacher;
  unconfirmTimesheet: Teacher;
  newAdjustment: ExtraActivity;
};


export type MutationResetAccountArgs = {
  id: Scalars['Int'];
};


export type MutationAddProfilePictureArgs = {
  accountId: Scalars['Int'];
  picture: Scalars['Upload'];
};


export type MutationDeleteProfilePictureArgs = {
  accountId: Scalars['Int'];
};


export type MutationNewClassArgs = {
  input: ClassNewInput;
};


export type MutationUpdateClassArgs = {
  input: ClassUpdateInput;
};


export type MutationDeleteClassArgs = {
  id: Scalars['Int'];
};


export type MutationCreateMakeupLessonArgs = {
  input: MakeupLessonCreateInput;
};


export type MutationDeleteMakeupLessonArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateApprovalStatusArgs = {
  approvalStatus: ExtraActivityApprovalStatus;
  id: Scalars['Int'];
};


export type MutationNewExtraActivityArgs = {
  input: ExtraActivityNewInput;
};


export type MutationUpdateExtraActivityArgs = {
  input: ExtraActivityUpdateInput;
};


export type MutationDeleteExtraActivityArgs = {
  id: Scalars['Int'];
};


export type MutationLinkOrUnlinkInvoiceLineWeekToAttendanceArgs = {
  attendanceId?: Maybe<Scalars['Int']>;
  invoiceLineWeekId: Scalars['Int'];
};


export type MutationInvoiceToMessageArgs = {
  invoiceId: Scalars['Int'];
};


export type MutationSendSmsArgs = {
  input: SendSmsInput;
};


export type MutationSendEmailArgs = {
  input: SendEmailInput;
};


export type MutationCreateMessageTemplateArgs = {
  input: MessageTemplateNewInput;
};


export type MutationUpdateMessageTemplateArgs = {
  input: MessageTemplateUpdateInput;
};


export type MutationDeleteMessageTemplateArgs = {
  id: Scalars['Int'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationLoginWithTokenArgs = {
  jwtToken: Scalars['String'];
};


export type MutationNewStudentArgs = {
  input: StudentNewInput;
};


export type MutationUpdateStudentArgs = {
  input: StudentUpdateInput;
};


export type MutationDeleteStudentArgs = {
  id: Scalars['Int'];
};


export type MutationNewAttendanceArgs = {
  input: AttendanceNewInput;
};


export type MutationNewTemporaryAttendanceArgs = {
  input: AttendanceTemporaryNewInput;
};


export type MutationUpdateAttendanceArgs = {
  input: AttendanceUpdateInput;
};


export type MutationUpdateAttendanceForEntireClassArgs = {
  input: AttendanceUpdateInput;
};


export type MutationDeleteAttendanceArgs = {
  id: Scalars['Int'];
};


export type MutationNewTeacherArgs = {
  input: TeacherNewInput;
};


export type MutationUpdateTeacherArgs = {
  input: TeacherUpdateInput;
};


export type MutationDeleteTeacherArgs = {
  id: Scalars['Int'];
};


export type MutationUpdatePayRatesArgs = {
  input: TeacherPayRatesInput;
  teacherId: Scalars['Int'];
};


export type MutationNewTeacherClassLinkArgs = {
  input: TeacherClassLinkNewInput;
};


export type MutationUpdateTeacherClassLinkArgs = {
  input: TeacherClassLinkUpdateInput;
};


export type MutationDeleteTeacherClassLinkArgs = {
  id: Scalars['Int'];
};


export type MutationResetViewStatusArgs = {
  teacherId?: Maybe<Scalars['Int']>;
};


export type MutationNewAdjustmentArgs = {
  input: AdjustmentNewInput;
};

export enum PaymentStatus {
  NotEnrolled = 'NotEnrolled',
  PaymentOk = 'PaymentOK',
  PaymentOkNoLessonsRemaining = 'PaymentOKNoLessonsRemaining',
  UnpaidLessonsCompleted = 'UnpaidLessonsCompleted',
  NeedsUpdate = 'NeedsUpdate'
}

export type PhoneNumberInput = {
  name: Scalars['String'];
  phone: Scalars['String'];
  linkedEntity?: Maybe<LinkedEntityInput>;
};

export type Query = {
  __typename?: 'Query';
  getAuditLogEntriesForEntity: Array<AuditLogEntry>;
  getAuditLogEntriesForTeacher: Array<AuditLogEntry>;
  getSystemConfiguration: Configuration;
  getTimeMachineDatabases: Array<Scalars['String']>;
  getClasses: Array<Class>;
  getExtraActivities: Array<ExtraActivity>;
  getExtraActivityTypes: Array<ExtraActivityType>;
  getApprovals: Array<ExtraActivity>;
  invoiceToPDF: Scalars['String'];
  getMessageTemplates: Array<MessageTemplate>;
  getStudents: Array<Student>;
  exportAllStudents: Scalars['String'];
  getTeachers: Array<Teacher>;
  getSelf: Teacher;
  getPayRates: TeacherPayRatesPayload;
  exportAllTeachers: Scalars['String'];
  getTimesheets: Array<Timesheet>;
  exportTimesheets: Scalars['String'];
  timesheetSummary: Scalars['String'];
};


export type QueryGetAuditLogEntriesForEntityArgs = {
  input: EntityInput;
};


export type QueryGetAuditLogEntriesForTeacherArgs = {
  teacherId: Scalars['Int'];
};


export type QueryGetClassesArgs = {
  filter?: Maybe<ClassFilterInput>;
};


export type QueryGetExtraActivitiesArgs = {
  weeks: Array<Week>;
};


export type QueryInvoiceToPdfArgs = {
  invoiceId: Scalars['Int'];
};


export type QueryGetStudentsArgs = {
  filter?: Maybe<StudentFilterInput>;
};


export type QueryGetTeachersArgs = {
  filter?: Maybe<TeacherFilterInput>;
};


export type QueryGetPayRatesArgs = {
  teacherId: Scalars['Int'];
};


export type QueryGetTimesheetsArgs = {
  teacherId?: Maybe<Scalars['Int']>;
  weeks: Array<Week>;
};


export type QueryExportTimesheetsArgs = {
  teacherId?: Maybe<Scalars['Int']>;
  weeks: Array<Week>;
};


export type QueryTimesheetSummaryArgs = {
  isShowAll?: Maybe<Scalars['Boolean']>;
  isMarkAsViewed?: Maybe<Scalars['Boolean']>;
  isDeltaMode?: Maybe<Scalars['Boolean']>;
};

export enum RefundType {
  Standard = 'Standard',
  Credit = 'Credit'
}

export type SendEmailInput = {
  emails: Array<EmailAddressInput>;
  subject: Scalars['String'];
  message: Scalars['String'];
  attachments?: Maybe<Array<Scalars['Upload']>>;
  alreadyUploadedAttachments?: Maybe<Array<Scalars['Int']>>;
};

export type SendSmsInput = {
  phones: Array<PhoneNumberInput>;
  message: Scalars['String'];
};

export type SetAttendanceParams = {
  week: Week;
  attendanceData: Scalars['String'];
};

export type SetPayParams = {
  week: Week;
  isPay: Scalars['Boolean'];
};

export type Student = {
  __typename?: 'Student';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  surname: Scalars['String'];
  fullName: Scalars['String'];
  account: Account;
  school: Scalars['String'];
  phone: Scalars['String'];
  phone2: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  isEmailPreferred: Scalars['Boolean'];
  dateOfBirth: Scalars['String'];
  gender: Gender;
  childType: Scalars['String'];
  address: Scalars['String'];
  grade: Scalars['String'];
  languages: Scalars['String'];
  medicalConditions: Scalars['String'];
  parentGuardianName: Scalars['String'];
  parentGuardianRelationship: Scalars['String'];
  parentGuardianPhone: Scalars['String'];
  parentGuardianEmail: Scalars['String'];
  parentGuardianName2: Scalars['String'];
  parentGuardianRelationship2: Scalars['String'];
  parentGuardianPhone2: Scalars['String'];
  parentGuardianEmail2: Scalars['String'];
  notes: Scalars['String'];
  dateCreated: Scalars['DateTime'];
  dateModified: Scalars['DateTime'];
  attendances: Array<Attendance>;
  paymentStatus: PaymentStatus;
  currentInvoices: Array<Invoice>;
};

export type StudentDeletePayload = {
  __typename?: 'StudentDeletePayload';
  ssoAccountId: Scalars['Float'];
  currentDBCortexId: Scalars['Float'];
  otherDBCortexId: Scalars['Float'];
};

export type StudentFilterInput = {
  id?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  school?: Maybe<Scalars['String']>;
  parentGuardianName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  isEmailPreferred?: Maybe<Scalars['Boolean']>;
};

export type StudentLinkedEntityInput = {
  studentId: Scalars['Int'];
  parentNo?: Maybe<Scalars['Int']>;
};

export type StudentNewInput = {
  firstName: Scalars['String'];
  surname: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  school: Scalars['String'];
  phone: Scalars['String'];
  phone2?: Maybe<Scalars['String']>;
  isEmailPreferred?: Maybe<Scalars['Boolean']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  gender: Gender;
  address?: Maybe<Scalars['String']>;
  grade: Scalars['String'];
  languages?: Maybe<Scalars['String']>;
  medicalConditions?: Maybe<Scalars['String']>;
  parentGuardianName?: Maybe<Scalars['String']>;
  parentGuardianRelationship?: Maybe<Scalars['String']>;
  parentGuardianPhone?: Maybe<Scalars['String']>;
  parentGuardianEmail?: Maybe<Scalars['String']>;
  parentGuardianName2?: Maybe<Scalars['String']>;
  parentGuardianRelationship2?: Maybe<Scalars['String']>;
  parentGuardianPhone2?: Maybe<Scalars['String']>;
  parentGuardianEmail2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type StudentUpdateInput = {
  id: Scalars['Int'];
  theBrainNetworkID?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  school?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  isEmailPreferred?: Maybe<Scalars['Boolean']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  address?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  languages?: Maybe<Scalars['String']>;
  medicalConditions?: Maybe<Scalars['String']>;
  parentGuardianName?: Maybe<Scalars['String']>;
  parentGuardianRelationship?: Maybe<Scalars['String']>;
  parentGuardianPhone?: Maybe<Scalars['String']>;
  parentGuardianEmail?: Maybe<Scalars['String']>;
  parentGuardianName2?: Maybe<Scalars['String']>;
  parentGuardianRelationship2?: Maybe<Scalars['String']>;
  parentGuardianPhone2?: Maybe<Scalars['String']>;
  parentGuardianEmail2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export type SystemConfiguration = {
  __typename?: 'SystemConfiguration';
  activeWeeks: Array<Week>;
  currentWeek: Week;
  viewableWeeks: Array<Week>;
  isTimesheetProcessorShowCurrentPeriod: Scalars['Boolean'];
  term: Scalars['Int'];
  year: Scalars['Int'];
  isWeek11Enabled: Scalars['Boolean'];
  numHolidayWeeks: Scalars['Float'];
  firstTimesheetDueDate: Scalars['DateTimeISO'];
  nextTimesheetDueWeek: Week;
  timesheetRanges: Array<TimesheetRange>;
  nextTimesheetDueDateTime: Scalars['DateTimeISO'];
  isTimesheetDueThisWeek: Scalars['Boolean'];
  memos: Scalars['String'];
};

export type Teacher = {
  __typename?: 'Teacher';
  id: Scalars['Int'];
  account: Account;
  firstName: Scalars['String'];
  surname: Scalars['String'];
  fullName: Scalars['String'];
  email: Scalars['String'];
  mobile: Scalars['String'];
  subjects: Scalars['String'];
  academics: Scalars['String'];
  /** Ignore the time */
  dateOfBirth: Scalars['DateTimeISO'];
  wwc: Scalars['String'];
  /** Ignore the time */
  wwcExpiry: Scalars['DateTimeISO'];
  languages: Scalars['String'];
  availabilities: Scalars['String'];
  availabilitiesLastUpdated: Scalars['DateTimeISO'];
  availabilitiesIsValid: Scalars['Boolean'];
  secretNotes: Scalars['String'];
  elevatedPrivileges: ElevatedPrivileges;
  isExtrasApprover: Scalars['Boolean'];
  isManageStudentPaymentInfo: Scalars['Boolean'];
  isConfirmedPreviousPeriodTimesheet: Scalars['Boolean'];
  isConfirmedCurrentPeriodTimesheet: Scalars['Boolean'];
  isConfirmedPreviousPeriodTimesheet_TimesheetProcessorCache: Scalars['Boolean'];
  isConfirmedCurrentPeriodTimesheet_TimesheetProcessorCache: Scalars['Boolean'];
  gracePeriodStatus: GracePeriodStatus;
  isViewedInTimesheetProcessor: Scalars['Boolean'];
  confirmedTimesheets: Array<Week>;
  modifiableWeeks: Array<Week>;
  dateCreated: Scalars['DateTime'];
  dateModified: Scalars['DateTime'];
  extraActivities: Array<ExtraActivity>;
  teacherClassLinks: Array<TeacherClassLink>;
  intervalRates: Array<TeacherIntervalRate>;
  auditLogEntries: Array<AuditLogEntry>;
};


export type TeacherExtraActivitiesArgs = {
  getAdjustments?: Maybe<Scalars['Boolean']>;
};

export type TeacherClassLink = {
  __typename?: 'TeacherClassLink';
  id: Scalars['Int'];
  teachingRole: TeachingRole;
  pays: Array<WeekPayInfo>;
  isDeleted: Scalars['Boolean'];
  dateCreated: Scalars['DateTime'];
  dateModified: Scalars['DateTime'];
  teacher: Teacher;
  class: Class;
};

export type TeacherClassLinkNewInput = {
  teacherId?: Maybe<Scalars['Int']>;
  classId: Scalars['Int'];
  teachingRole: TeachingRole;
};

export type TeacherClassLinkUpdateInput = {
  id: Scalars['Int'];
  setPays: Array<SetPayParams>;
};

export type TeacherDeletePayload = {
  __typename?: 'TeacherDeletePayload';
  ssoAccountId: Scalars['Float'];
  currentDBCortexId: Scalars['Float'];
  otherDBCortexId: Scalars['Float'];
};

export type TeacherFilterInput = {
  id?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  subjects?: Maybe<Scalars['String']>;
  academics?: Maybe<Scalars['String']>;
  wwc?: Maybe<Scalars['String']>;
  languages?: Maybe<Scalars['String']>;
  availabilities?: Maybe<Scalars['String']>;
  isRecentlyWorkedOnly?: Maybe<Scalars['Boolean']>;
};

export type TeacherIntervalRate = {
  __typename?: 'TeacherIntervalRate';
  id: Scalars['Int'];
  teacherId: Scalars['Float'];
  start: Scalars['Float'];
  end: Scalars['Float'];
  rate: Scalars['Float'];
};

export type TeacherIntervalRateInput = {
  start: Scalars['Float'];
  end: Scalars['Float'];
  rate: Scalars['Float'];
};

export type TeacherNewInput = {
  firstName: Scalars['String'];
  surname: Scalars['String'];
  email: Scalars['String'];
};

export type TeacherPayRatesInput = {
  privatePayRate?: Maybe<Scalars['Float']>;
  markingHwHelpRate?: Maybe<Scalars['Float']>;
  trainingRate?: Maybe<Scalars['Float']>;
  classRate?: Maybe<Scalars['Float']>;
  classExtraRate?: Maybe<Scalars['Float']>;
  classExtraNumStudentsThreshold?: Maybe<Scalars['Float']>;
  receptionRate?: Maybe<Scalars['Float']>;
  assistingRate?: Maybe<Scalars['Float']>;
  bookletsHrRate?: Maybe<Scalars['Float']>;
  bookletsBookRate?: Maybe<Scalars['Float']>;
  interviewRate?: Maybe<Scalars['Float']>;
  intervalRates?: Maybe<Array<TeacherIntervalRateInput>>;
};

export type TeacherPayRatesPayload = {
  __typename?: 'TeacherPayRatesPayload';
  id: Scalars['Float'];
  privatePayRate: Scalars['Float'];
  markingHwHelpRate: Scalars['Float'];
  trainingRate: Scalars['Float'];
  classRate: Scalars['Float'];
  classExtraRate: Scalars['Float'];
  classExtraNumStudentsThreshold: Scalars['Float'];
  receptionRate: Scalars['Float'];
  assistingRate: Scalars['Float'];
  bookletsHrRate: Scalars['Float'];
  bookletsBookRate: Scalars['Float'];
  interviewRate: Scalars['Float'];
  intervalRates: Array<TeacherIntervalRate>;
};

export type TeacherUpdateInput = {
  id: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  theBrainNetworkID?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  subjects?: Maybe<Scalars['String']>;
  academics?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTimeISO']>;
  wwc?: Maybe<Scalars['String']>;
  wwcExpiry?: Maybe<Scalars['DateTimeISO']>;
  languages?: Maybe<Scalars['String']>;
  availabilities?: Maybe<Scalars['String']>;
  elevatedPrivileges?: Maybe<ElevatedPrivileges>;
  isExtrasApprover?: Maybe<Scalars['Boolean']>;
  isManageStudentPaymentInfo?: Maybe<Scalars['Boolean']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  secretNotes?: Maybe<Scalars['String']>;
  gracePeriodStatus?: Maybe<GracePeriodStatus>;
  isViewedInTimesheetProcessor?: Maybe<Scalars['Boolean']>;
};

export enum TeachingRole {
  Teaching = 'Teaching',
  Covering = 'Covering',
  Assisting = 'Assisting',
  Training = 'Training'
}

export type TimeRange = {
  /** Pass in an arbitrary date, it will be ignored, as only time is important. */
  startTime?: Maybe<Scalars['DateTimeISO']>;
  /** Pass in an arbitrary date, it will be ignored, as only time is important. */
  endTime?: Maybe<Scalars['DateTimeISO']>;
};

export type Timesheet = {
  __typename?: 'Timesheet';
  week: Week;
  items: Array<TimesheetItem>;
  totalAmount: Scalars['Float'];
  totalHours: Scalars['Float'];
  isConfirmed: Scalars['Boolean'];
};

export type TimesheetItem = {
  __typename?: 'TimesheetItem';
  activity: Scalars['String'];
  category: Scalars['String'];
  dateTime: Scalars['String'];
  /** This is the duration other than Num booklets for Curriculum (Per booklet) and null for other. */
  units?: Maybe<Scalars['Float']>;
  unitsType: UnitsType;
  amount: Scalars['Float'];
  studentsPaymentStatus?: Maybe<Scalars['String']>;
  teachers?: Maybe<Scalars['String']>;
  teachersConflicts: Array<Scalars['String']>;
  errors: Array<Scalars['String']>;
  warnings: Array<Scalars['String']>;
  teacherClassLinkId?: Maybe<Scalars['Int']>;
  classId?: Maybe<Scalars['Int']>;
  adjustmentId?: Maybe<Scalars['Int']>;
  approvalStatus?: Maybe<ExtraActivityApprovalStatus>;
};

export type TimesheetRange = {
  __typename?: 'TimesheetRange';
  week: Week;
  start: Scalars['DateTimeISO'];
  end: Scalars['DateTimeISO'];
};

export enum UnitsType {
  Hours = 'Hours',
  Booklets = 'Booklets',
  None = 'None'
}


export type UserConfiguration = {
  __typename?: 'UserConfiguration';
  modifiableWeeks: Array<Week>;
  isGracePeriodMode: Scalars['Boolean'];
};

export enum Week {
  Wk0 = 'Wk0',
  Wk1 = 'Wk1',
  Wk2 = 'Wk2',
  Wk3 = 'Wk3',
  Wk4 = 'Wk4',
  Wk5 = 'Wk5',
  Wk6 = 'Wk6',
  Wk7 = 'Wk7',
  Wk8 = 'Wk8',
  Wk9 = 'Wk9',
  Wk10 = 'Wk10',
  Wk11 = 'Wk11',
  WkH1 = 'WkH1',
  WkH2 = 'WkH2',
  WkH3 = 'WkH3',
  WkH4 = 'WkH4',
  WkH5 = 'WkH5',
  WkH6 = 'WkH6',
  WkH7 = 'WkH7',
  WkH8 = 'WkH8',
  WkH9 = 'WkH9',
  WkH10 = 'WkH10',
  WkNextTerm = 'WkNextTerm'
}

export type WeekAttendanceInfo = {
  __typename?: 'WeekAttendanceInfo';
  week: Week;
  attendanceData: Scalars['String'];
};

export type WeekPayInfo = {
  __typename?: 'WeekPayInfo';
  week: Week;
  pay: Scalars['Float'];
};

export type LoginWithTokenMutationVariables = Exact<{
  jwtToken: Scalars['String'];
}>;


export type LoginWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { loginWithToken: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken'>
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type SelfQueryVariables = Exact<{ [key: string]: never; }>;


export type SelfQuery = (
  { __typename?: 'Query' }
  & { getSelf: (
    { __typename?: 'Teacher' }
    & TeacherFieldsFragment
  ) }
);

export type ResetAccountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ResetAccountMutation = (
  { __typename?: 'Mutation' }
  & { resetAccount: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
  ) }
);

export type AddProfilePictureMutationVariables = Exact<{
  accountId: Scalars['Int'];
  picture: Scalars['Upload'];
}>;


export type AddProfilePictureMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addProfilePicture'>
);

export type DeleteProfilePictureMutationVariables = Exact<{
  accountId: Scalars['Int'];
}>;


export type DeleteProfilePictureMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProfilePicture'>
);

export type AttendanceFieldsFragment = (
  { __typename?: 'Attendance' }
  & Pick<Attendance, 'id' | 'weeksPaid' | 'weeksPaidComment' | 'paymentStatus' | 'weeksRemaining' | 'isDeleted' | 'dateModified' | 'dateCreated'>
  & { class: (
    { __typename?: 'Class' }
    & ClassFieldsFragment
  ), weekAttendanceInfos: Array<(
    { __typename?: 'WeekAttendanceInfo' }
    & Pick<WeekAttendanceInfo, 'week' | 'attendanceData'>
  )>, student: (
    { __typename?: 'Student' }
    & StudentFieldsFragment
  ), primaryAttendance?: Maybe<(
    { __typename?: 'Attendance' }
    & Pick<Attendance, 'id' | 'weeksPaid' | 'weeksPaidComment' | 'isDeleted' | 'dateModified' | 'dateCreated'>
    & { class: (
      { __typename?: 'Class' }
      & ClassFieldsFragment
    ), weekAttendanceInfos: Array<(
      { __typename?: 'WeekAttendanceInfo' }
      & Pick<WeekAttendanceInfo, 'week' | 'attendanceData'>
    )> }
  )>, tempAttendances: Array<(
    { __typename?: 'Attendance' }
    & Pick<Attendance, 'id' | 'weeksPaid' | 'weeksPaidComment' | 'isDeleted' | 'dateModified' | 'dateCreated'>
    & { class: (
      { __typename?: 'Class' }
      & { teacherClassLinks: Array<(
        { __typename?: 'TeacherClassLink' }
        & { teacher: (
          { __typename?: 'Teacher' }
          & TeacherFieldsFragment
        ) }
        & TeacherClassLinkFieldsFragment
      )> }
      & ClassFieldsFragment
    ), weekAttendanceInfos: Array<(
      { __typename?: 'WeekAttendanceInfo' }
      & Pick<WeekAttendanceInfo, 'week' | 'attendanceData'>
    )> }
  )> }
);

export type NewAttendanceMutationVariables = Exact<{
  input: AttendanceNewInput;
}>;


export type NewAttendanceMutation = (
  { __typename?: 'Mutation' }
  & { newAttendance: (
    { __typename?: 'Attendance' }
    & AttendanceFieldsFragment
  ) }
);

export type NewTemporaryAttendanceMutationVariables = Exact<{
  input: AttendanceTemporaryNewInput;
}>;


export type NewTemporaryAttendanceMutation = (
  { __typename?: 'Mutation' }
  & { newTemporaryAttendance: (
    { __typename?: 'Attendance' }
    & AttendanceFieldsFragment
  ) }
);

export type UpdateAttendanceMutationVariables = Exact<{
  input: AttendanceUpdateInput;
}>;


export type UpdateAttendanceMutation = (
  { __typename?: 'Mutation' }
  & { updateAttendance: (
    { __typename?: 'Attendance' }
    & AttendanceFieldsFragment
  ) }
);

export type UpdateAttendanceForEntireClassMutationVariables = Exact<{
  input: AttendanceUpdateInput;
}>;


export type UpdateAttendanceForEntireClassMutation = (
  { __typename?: 'Mutation' }
  & { updateAttendanceForEntireClass: Array<(
    { __typename?: 'Attendance' }
    & AttendanceFieldsFragment
  )> }
);

export type DeleteAttendanceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAttendanceMutation = (
  { __typename?: 'Mutation' }
  & { deleteAttendance: (
    { __typename?: 'Attendance' }
    & AttendanceFieldsFragment
  ) }
);

export type AuditLogEntryFieldsFragment = (
  { __typename?: 'AuditLogEntry' }
  & Pick<AuditLogEntry, 'id' | 'entityType' | 'entityId' | 'message' | 'dateCreated'>
  & { teacher: (
    { __typename?: 'Teacher' }
    & TeacherFieldsFragment
  ) }
);

export type GetAuditLogEntriesForEntityQueryVariables = Exact<{
  input: EntityInput;
}>;


export type GetAuditLogEntriesForEntityQuery = (
  { __typename?: 'Query' }
  & { getAuditLogEntriesForEntity: Array<(
    { __typename?: 'AuditLogEntry' }
    & AuditLogEntryFieldsFragment
  )> }
);

export type GetAuditLogEntriesForTeacherQueryVariables = Exact<{
  teacherId: Scalars['Int'];
}>;


export type GetAuditLogEntriesForTeacherQuery = (
  { __typename?: 'Query' }
  & { getAuditLogEntriesForTeacher: Array<(
    { __typename?: 'AuditLogEntry' }
    & AuditLogEntryFieldsFragment
  )> }
);

export type ClassFieldsFragment = (
  { __typename?: 'Class' }
  & Pick<Class, 'id' | 'day' | 'type' | 'subject' | 'startTime' | 'endTime' | 'duration' | 'grade' | 'classProgress' | 'comment' | 'isDeleted' | 'dateModified' | 'dateCreated'>
);

export type ClassesQueryVariables = Exact<{
  filter?: Maybe<ClassFilterInput>;
}>;


export type ClassesQuery = (
  { __typename?: 'Query' }
  & { getClasses: Array<(
    { __typename?: 'Class' }
    & ClassFieldsFragment
  )> }
);

export type NewClassMutationVariables = Exact<{
  input: ClassNewInput;
}>;


export type NewClassMutation = (
  { __typename?: 'Mutation' }
  & { newClass: (
    { __typename?: 'Class' }
    & ClassFieldsFragment
  ) }
);

export type UpdateClassMutationVariables = Exact<{
  input: ClassUpdateInput;
}>;


export type UpdateClassMutation = (
  { __typename?: 'Mutation' }
  & { updateClass: (
    { __typename?: 'Class' }
    & ClassFieldsFragment
  ) }
);

export type DeleteClassMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteClassMutation = (
  { __typename?: 'Mutation' }
  & { deleteClass: (
    { __typename?: 'Class' }
    & ClassFieldsFragment
  ) }
);

export type ClassesAttendanceRecordsQueryVariables = Exact<{
  filter?: Maybe<ClassFilterInput>;
}>;


export type ClassesAttendanceRecordsQuery = (
  { __typename?: 'Query' }
  & { getClasses: Array<(
    { __typename?: 'Class' }
    & { attendances: Array<(
      { __typename?: 'Attendance' }
      & AttendanceFieldsFragment
    )>, teacherClassLinks: Array<(
      { __typename?: 'TeacherClassLink' }
      & { teacher: (
        { __typename?: 'Teacher' }
        & TeacherFieldsFragment
      ) }
      & TeacherClassLinkFieldsFragment
    )>, makeupLessons: Array<(
      { __typename?: 'MakeupLesson' }
      & MakeupLessonFieldsFragment
    )> }
    & ClassFieldsFragment
  )> }
);

export type ClassesWithTeachersQueryVariables = Exact<{
  filter?: Maybe<ClassFilterInput>;
}>;


export type ClassesWithTeachersQuery = (
  { __typename?: 'Query' }
  & { getClasses: Array<(
    { __typename?: 'Class' }
    & { teacherClassLinks: Array<(
      { __typename?: 'TeacherClassLink' }
      & { teacher: (
        { __typename?: 'Teacher' }
        & TeacherFieldsFragment
      ) }
      & TeacherClassLinkFieldsFragment
    )> }
    & ClassFieldsFragment
  )> }
);

export type ExtraActivityFieldsFragment = (
  { __typename?: 'ExtraActivity' }
  & Pick<ExtraActivity, 'id' | 'type' | 'description' | 'duration' | 'units' | 'pay' | 'week' | 'approvalStatus' | 'dateModified' | 'dateCreated'>
  & { dateTime: (
    { __typename?: 'DateTimeDTO' }
    & Pick<DateTimeDto, 'startTime' | 'endTime' | 'date' | 'freeText'>
  ) }
);

export type GetExtraActivitiesQueryVariables = Exact<{
  weeks: Array<Week> | Week;
}>;


export type GetExtraActivitiesQuery = (
  { __typename?: 'Query' }
  & { getExtraActivities: Array<(
    { __typename?: 'ExtraActivity' }
    & ExtraActivityFieldsFragment
  )> }
);

export type GetExtraActivityTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExtraActivityTypesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getExtraActivityTypes'>
);

export type GetApprovalsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetApprovalsQuery = (
  { __typename?: 'Query' }
  & { getApprovals: Array<(
    { __typename?: 'ExtraActivity' }
    & { teacher: (
      { __typename?: 'Teacher' }
      & Pick<Teacher, 'fullName'>
    ) }
    & ExtraActivityFieldsFragment
  )> }
);

export type NewExtraActivityMutationVariables = Exact<{
  input: ExtraActivityNewInput;
}>;


export type NewExtraActivityMutation = (
  { __typename?: 'Mutation' }
  & { newExtraActivity: (
    { __typename?: 'ExtraActivity' }
    & ExtraActivityFieldsFragment
  ) }
);

export type UpdateExtraActivityMutationVariables = Exact<{
  input: ExtraActivityUpdateInput;
}>;


export type UpdateExtraActivityMutation = (
  { __typename?: 'Mutation' }
  & { updateExtraActivity: (
    { __typename?: 'ExtraActivity' }
    & ExtraActivityFieldsFragment
  ) }
);

export type UpdateApprovalStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  approvalStatus: ExtraActivityApprovalStatus;
}>;


export type UpdateApprovalStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateApprovalStatus: (
    { __typename?: 'ExtraActivity' }
    & ExtraActivityFieldsFragment
  ) }
);

export type DeleteExtraActivityMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteExtraActivityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteExtraActivity'>
);

export type InvoiceFieldsFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'generatedInvoiceId' | 'type' | 'dateCreated' | 'dateModified'>
  & { invoiceLines: Array<(
    { __typename?: 'InvoiceLine' }
    & InvoiceLineFieldsFragment
  )> }
);

export type InvoiceLineFieldsFragment = (
  { __typename?: 'InvoiceLine' }
  & Pick<InvoiceLine, 'id' | 'subject' | 'description' | 'dateCreated'>
  & { invoiceLineWeeks: Array<(
    { __typename?: 'InvoiceLineWeek' }
    & InvoiceLineWeeksFieldsFragment
  )> }
);

export type InvoiceLineWeeksFieldsFragment = (
  { __typename?: 'InvoiceLineWeek' }
  & Pick<InvoiceLineWeek, 'id' | 'attendanceId' | 'weeks' | 'term' | 'year' | 'dateCreated' | 'dateModified'>
);

export type InvoiceToPdfQueryVariables = Exact<{
  invoiceId: Scalars['Int'];
}>;


export type InvoiceToPdfQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'invoiceToPDF'>
);

export type InvoiceToMessageMutationVariables = Exact<{
  invoiceId: Scalars['Int'];
}>;


export type InvoiceToMessageMutation = (
  { __typename?: 'Mutation' }
  & { invoiceToMessage: (
    { __typename?: 'InvoiceToMessageResponse' }
    & { student: (
      { __typename?: 'Student' }
      & StudentFieldsFragment
    ), messageTemplateAttachment: (
      { __typename?: 'MessageTemplateAttachment' }
      & MessageTemplateAttachmentFieldsFragment
    ) }
  ) }
);

export type LinkOrUnlinkInvoiceLineWeekToAttendanceMutationVariables = Exact<{
  invoiceLineWeekId: Scalars['Int'];
  attendanceId?: Maybe<Scalars['Int']>;
}>;


export type LinkOrUnlinkInvoiceLineWeekToAttendanceMutation = (
  { __typename?: 'Mutation' }
  & { linkOrUnlinkInvoiceLineWeekToAttendance: (
    { __typename?: 'InvoiceLineWeek' }
    & InvoiceLineWeeksFieldsFragment
  ) }
);

export type MakeupLessonFieldsFragment = (
  { __typename?: 'MakeupLesson' }
  & Pick<MakeupLesson, 'id' | 'classId' | 'week' | 'lessonDate' | 'dateCreated' | 'dateModified'>
);

export type CreateMakeupLessonMutationVariables = Exact<{
  input: MakeupLessonCreateInput;
}>;


export type CreateMakeupLessonMutation = (
  { __typename?: 'Mutation' }
  & { createMakeupLesson: (
    { __typename?: 'MakeupLesson' }
    & MakeupLessonFieldsFragment
  ) }
);

export type DeleteMakeupLessonMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteMakeupLessonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMakeupLesson'>
);

export type SendSmsMutationVariables = Exact<{
  input: SendSmsInput;
}>;


export type SendSmsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendSMS'>
);

export type SendEmailMutationVariables = Exact<{
  input: SendEmailInput;
}>;


export type SendEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendEmail'>
);

export type MessageTemplateAttachmentFieldsFragment = (
  { __typename?: 'MessageTemplateAttachment' }
  & Pick<MessageTemplateAttachment, 'id' | 'filename' | 'dateCreated' | 'dateModified'>
);

export type MessageTemplateFieldsFragment = (
  { __typename?: 'MessageTemplate' }
  & Pick<MessageTemplate, 'id' | 'name' | 'subject' | 'templateMessage' | 'isSystemDefault' | 'dateCreated' | 'dateModified'>
  & { attachments: Array<(
    { __typename?: 'MessageTemplateAttachment' }
    & MessageTemplateAttachmentFieldsFragment
  )> }
);

export type GetMessageTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMessageTemplatesQuery = (
  { __typename?: 'Query' }
  & { getMessageTemplates: Array<(
    { __typename?: 'MessageTemplate' }
    & MessageTemplateFieldsFragment
  )> }
);

export type CreateMessageTemplateMutationVariables = Exact<{
  input: MessageTemplateNewInput;
}>;


export type CreateMessageTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createMessageTemplate: (
    { __typename?: 'MessageTemplate' }
    & MessageTemplateFieldsFragment
  ) }
);

export type UpdateMessageTemplateMutationVariables = Exact<{
  input: MessageTemplateUpdateInput;
}>;


export type UpdateMessageTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateMessageTemplate: (
    { __typename?: 'MessageTemplate' }
    & MessageTemplateFieldsFragment
  ) }
);

export type DeleteMessageTemplateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteMessageTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMessageTemplate'>
);

export type StudentFieldsFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'id' | 'firstName' | 'surname' | 'fullName' | 'school' | 'phone' | 'phone2' | 'email' | 'isEmailPreferred' | 'dateOfBirth' | 'gender' | 'address' | 'grade' | 'languages' | 'medicalConditions' | 'parentGuardianName' | 'parentGuardianPhone' | 'parentGuardianEmail' | 'parentGuardianRelationship' | 'parentGuardianName2' | 'parentGuardianPhone2' | 'parentGuardianEmail2' | 'parentGuardianRelationship2' | 'notes' | 'paymentStatus' | 'dateModified' | 'dateCreated'>
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'username' | 'isDisabled'>
  ), currentInvoices: Array<(
    { __typename?: 'Invoice' }
    & InvoiceFieldsFragment
  )> }
);

export type StudentsQueryVariables = Exact<{
  filter?: Maybe<StudentFilterInput>;
}>;


export type StudentsQuery = (
  { __typename?: 'Query' }
  & { getStudents: Array<(
    { __typename?: 'Student' }
    & StudentFieldsFragment
  )> }
);

export type StudentsAttendancesQueryVariables = Exact<{
  filter?: Maybe<StudentFilterInput>;
}>;


export type StudentsAttendancesQuery = (
  { __typename?: 'Query' }
  & { getStudents: Array<(
    { __typename?: 'Student' }
    & { attendances: Array<(
      { __typename?: 'Attendance' }
      & { class: (
        { __typename?: 'Class' }
        & { teacherClassLinks: Array<(
          { __typename?: 'TeacherClassLink' }
          & { teacher: (
            { __typename?: 'Teacher' }
            & TeacherFieldsFragment
          ) }
          & TeacherClassLinkFieldsFragment
        )> }
        & ClassFieldsFragment
      ) }
      & AttendanceFieldsFragment
    )> }
    & StudentFieldsFragment
  )> }
);

export type NewStudentMutationVariables = Exact<{
  input: StudentNewInput;
}>;


export type NewStudentMutation = (
  { __typename?: 'Mutation' }
  & { newStudent: (
    { __typename?: 'Student' }
    & StudentFieldsFragment
  ) }
);

export type UpdateStudentMutationVariables = Exact<{
  input: StudentUpdateInput;
}>;


export type UpdateStudentMutation = (
  { __typename?: 'Mutation' }
  & { updateStudent: (
    { __typename?: 'Student' }
    & StudentFieldsFragment
  ) }
);

export type DeleteStudentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteStudentMutation = (
  { __typename?: 'Mutation' }
  & { deleteStudent: (
    { __typename?: 'StudentDeletePayload' }
    & Pick<StudentDeletePayload, 'ssoAccountId' | 'currentDBCortexId' | 'otherDBCortexId'>
  ) }
);

export type ExportAllStudentsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportAllStudentsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'exportAllStudents'>
);

export type CortexConfigurationQueryVariables = Exact<{ [key: string]: never; }>;


export type CortexConfigurationQuery = (
  { __typename?: 'Query' }
  & { getSystemConfiguration: (
    { __typename?: 'Configuration' }
    & { system: (
      { __typename?: 'SystemConfiguration' }
      & Pick<SystemConfiguration, 'activeWeeks' | 'viewableWeeks' | 'currentWeek' | 'isTimesheetProcessorShowCurrentPeriod' | 'term' | 'year' | 'isWeek11Enabled' | 'numHolidayWeeks' | 'firstTimesheetDueDate' | 'nextTimesheetDueWeek' | 'nextTimesheetDueDateTime' | 'isTimesheetDueThisWeek' | 'memos'>
      & { timesheetRanges: Array<(
        { __typename?: 'TimesheetRange' }
        & Pick<TimesheetRange, 'week' | 'start' | 'end'>
      )> }
    ), user: (
      { __typename?: 'UserConfiguration' }
      & Pick<UserConfiguration, 'modifiableWeeks' | 'isGracePeriodMode'>
    ) }
  ) }
);

export type GetTimeMachineDatabasesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTimeMachineDatabasesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTimeMachineDatabases'>
);

export type TeacherFieldsFragment = (
  { __typename?: 'Teacher' }
  & Pick<Teacher, 'id' | 'firstName' | 'surname' | 'fullName' | 'email' | 'mobile' | 'subjects' | 'academics' | 'dateOfBirth' | 'wwc' | 'wwcExpiry' | 'languages' | 'availabilities' | 'availabilitiesLastUpdated' | 'availabilitiesIsValid' | 'secretNotes' | 'elevatedPrivileges' | 'gracePeriodStatus' | 'isExtrasApprover' | 'isManageStudentPaymentInfo' | 'isConfirmedCurrentPeriodTimesheet' | 'isConfirmedPreviousPeriodTimesheet' | 'isConfirmedCurrentPeriodTimesheet_TimesheetProcessorCache' | 'isConfirmedPreviousPeriodTimesheet_TimesheetProcessorCache' | 'isViewedInTimesheetProcessor' | 'dateCreated' | 'dateModified'>
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'username' | 'profilePictureURL' | 'isDisabled'>
  ) }
);

export type TeachersQueryVariables = Exact<{
  filter?: Maybe<TeacherFilterInput>;
}>;


export type TeachersQuery = (
  { __typename?: 'Query' }
  & { getTeachers: Array<(
    { __typename?: 'Teacher' }
    & TeacherFieldsFragment
  )> }
);

export type UpdateTeacherMutationVariables = Exact<{
  input: TeacherUpdateInput;
}>;


export type UpdateTeacherMutation = (
  { __typename?: 'Mutation' }
  & { updateTeacher: (
    { __typename?: 'Teacher' }
    & TeacherFieldsFragment
  ) }
);

export type NewTeacherMutationVariables = Exact<{
  input: TeacherNewInput;
}>;


export type NewTeacherMutation = (
  { __typename?: 'Mutation' }
  & { newTeacher: (
    { __typename?: 'Teacher' }
    & TeacherFieldsFragment
  ) }
);

export type DeleteTeacherMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTeacherMutation = (
  { __typename?: 'Mutation' }
  & { deleteTeacher: (
    { __typename?: 'TeacherDeletePayload' }
    & Pick<TeacherDeletePayload, 'ssoAccountId' | 'currentDBCortexId' | 'otherDBCortexId'>
  ) }
);

export type PayRatesFieldsFragment = (
  { __typename?: 'TeacherPayRatesPayload' }
  & Pick<TeacherPayRatesPayload, 'id' | 'privatePayRate' | 'markingHwHelpRate' | 'bookletsBookRate' | 'bookletsHrRate' | 'trainingRate' | 'classRate' | 'classExtraRate' | 'classExtraNumStudentsThreshold' | 'receptionRate' | 'assistingRate' | 'interviewRate'>
  & { intervalRates: Array<(
    { __typename?: 'TeacherIntervalRate' }
    & Pick<TeacherIntervalRate, 'start' | 'end' | 'rate'>
  )> }
);

export type PayRatesQueryVariables = Exact<{
  teacherId: Scalars['Int'];
}>;


export type PayRatesQuery = (
  { __typename?: 'Query' }
  & { getPayRates: (
    { __typename?: 'TeacherPayRatesPayload' }
    & PayRatesFieldsFragment
  ) }
);

export type UpdatePayRatesMutationVariables = Exact<{
  teacherId: Scalars['Int'];
  input: TeacherPayRatesInput;
}>;


export type UpdatePayRatesMutation = (
  { __typename?: 'Mutation' }
  & { updatePayRates: (
    { __typename?: 'TeacherPayRatesPayload' }
    & PayRatesFieldsFragment
  ) }
);

export type ExportAllTeachersQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportAllTeachersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'exportAllTeachers'>
);

export type TeacherClassLinkFieldsFragment = (
  { __typename?: 'TeacherClassLink' }
  & Pick<TeacherClassLink, 'id' | 'teachingRole' | 'isDeleted' | 'dateModified' | 'dateCreated'>
  & { pays: Array<(
    { __typename?: 'WeekPayInfo' }
    & Pick<WeekPayInfo, 'week'>
  )> }
);

export type NewTeacherClassLinkMutationVariables = Exact<{
  input: TeacherClassLinkNewInput;
}>;


export type NewTeacherClassLinkMutation = (
  { __typename?: 'Mutation' }
  & { newTeacherClassLink: (
    { __typename?: 'TeacherClassLink' }
    & TeacherClassLinkFieldsFragment
  ) }
);

export type UpdateTeacherClassLinkMutationVariables = Exact<{
  input: TeacherClassLinkUpdateInput;
}>;


export type UpdateTeacherClassLinkMutation = (
  { __typename?: 'Mutation' }
  & { updateTeacherClassLink: (
    { __typename?: 'TeacherClassLink' }
    & TeacherClassLinkFieldsFragment
  ) }
);

export type DeleteTeacherClassLinkMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTeacherClassLinkMutation = (
  { __typename?: 'Mutation' }
  & { deleteTeacherClassLink: (
    { __typename?: 'TeacherClassLink' }
    & TeacherClassLinkFieldsFragment
  ) }
);

export type SelfClassesQueryVariables = Exact<{ [key: string]: never; }>;


export type SelfClassesQuery = (
  { __typename?: 'Query' }
  & { getSelf: (
    { __typename?: 'Teacher' }
    & Pick<Teacher, 'id'>
    & { teacherClassLinks: Array<(
      { __typename?: 'TeacherClassLink' }
      & { class: (
        { __typename?: 'Class' }
        & ClassFieldsFragment
      ) }
      & TeacherClassLinkFieldsFragment
    )> }
  ) }
);

export type TimesheetFieldsFragment = (
  { __typename?: 'Timesheet' }
  & Pick<Timesheet, 'week' | 'totalAmount' | 'totalHours' | 'isConfirmed'>
  & { items: Array<(
    { __typename?: 'TimesheetItem' }
    & Pick<TimesheetItem, 'activity' | 'dateTime' | 'units' | 'unitsType' | 'studentsPaymentStatus' | 'teachers' | 'teachersConflicts' | 'errors' | 'warnings' | 'classId' | 'teacherClassLinkId' | 'adjustmentId' | 'amount' | 'approvalStatus'>
  )> }
);

export type TimesheetsQueryVariables = Exact<{
  weeks: Array<Week> | Week;
}>;


export type TimesheetsQuery = (
  { __typename?: 'Query' }
  & { getTimesheets: Array<(
    { __typename?: 'Timesheet' }
    & TimesheetFieldsFragment
  )> }
);

export type TimesheetSummaryQueryVariables = Exact<{
  isDeltaMode?: Maybe<Scalars['Boolean']>;
  isMarkAsViewed?: Maybe<Scalars['Boolean']>;
  isShowAll?: Maybe<Scalars['Boolean']>;
}>;


export type TimesheetSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'timesheetSummary'>
);

export type ExportTimesheetsQueryVariables = Exact<{
  weeks: Array<Week> | Week;
  teacherId?: Maybe<Scalars['Int']>;
}>;


export type ExportTimesheetsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'exportTimesheets'>
);

export type ResetViewStatusMutationVariables = Exact<{
  teacherId?: Maybe<Scalars['Int']>;
}>;


export type ResetViewStatusMutation = (
  { __typename?: 'Mutation' }
  & { resetViewStatus: Array<(
    { __typename?: 'Teacher' }
    & TeacherFieldsFragment
  )> }
);

export type NewAdjustmentMutationVariables = Exact<{
  input: AdjustmentNewInput;
}>;


export type NewAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & { newAdjustment: (
    { __typename?: 'ExtraActivity' }
    & ExtraActivityFieldsFragment
  ) }
);

export type ConfirmTimesheetMutationVariables = Exact<{ [key: string]: never; }>;


export type ConfirmTimesheetMutation = (
  { __typename?: 'Mutation' }
  & { confirmTimesheet: (
    { __typename?: 'Teacher' }
    & TeacherFieldsFragment
  ) }
);

export type UnconfirmTimesheetMutationVariables = Exact<{ [key: string]: never; }>;


export type UnconfirmTimesheetMutation = (
  { __typename?: 'Mutation' }
  & { unconfirmTimesheet: (
    { __typename?: 'Teacher' }
    & TeacherFieldsFragment
  ) }
);

export const ClassFieldsFragmentDoc = gql`
    fragment ClassFields on Class {
  id
  day
  type
  subject
  startTime
  endTime
  duration
  grade
  classProgress
  comment
  isDeleted
  dateModified
  dateCreated
}
    `;
export const InvoiceLineWeeksFieldsFragmentDoc = gql`
    fragment InvoiceLineWeeksFields on InvoiceLineWeek {
  id
  attendanceId
  weeks
  term
  year
  dateCreated
  dateModified
}
    `;
export const InvoiceLineFieldsFragmentDoc = gql`
    fragment InvoiceLineFields on InvoiceLine {
  id
  subject
  description
  invoiceLineWeeks {
    ...InvoiceLineWeeksFields
  }
  dateCreated
  dateCreated
}
    ${InvoiceLineWeeksFieldsFragmentDoc}`;
export const InvoiceFieldsFragmentDoc = gql`
    fragment InvoiceFields on Invoice {
  id
  generatedInvoiceId
  type
  invoiceLines {
    ...InvoiceLineFields
  }
  dateCreated
  dateModified
}
    ${InvoiceLineFieldsFragmentDoc}`;
export const StudentFieldsFragmentDoc = gql`
    fragment StudentFields on Student {
  id
  firstName
  surname
  fullName
  account {
    id
    username
    isDisabled
  }
  school
  phone
  phone2
  email
  isEmailPreferred
  dateOfBirth
  gender
  address
  grade
  languages
  medicalConditions
  parentGuardianName
  parentGuardianPhone
  parentGuardianEmail
  parentGuardianRelationship
  parentGuardianName2
  parentGuardianPhone2
  parentGuardianEmail2
  parentGuardianRelationship2
  notes
  paymentStatus
  currentInvoices {
    ...InvoiceFields
  }
  dateModified
  dateCreated
}
    ${InvoiceFieldsFragmentDoc}`;
export const TeacherClassLinkFieldsFragmentDoc = gql`
    fragment TeacherClassLinkFields on TeacherClassLink {
  id
  teachingRole
  pays {
    week
  }
  isDeleted
  dateModified
  dateCreated
}
    `;
export const TeacherFieldsFragmentDoc = gql`
    fragment TeacherFields on Teacher {
  id
  firstName
  surname
  fullName
  account {
    id
    username
    profilePictureURL
    isDisabled
  }
  email
  mobile
  subjects
  academics
  dateOfBirth
  wwc
  wwcExpiry
  languages
  availabilities
  availabilitiesLastUpdated
  availabilitiesIsValid
  secretNotes
  elevatedPrivileges
  gracePeriodStatus
  elevatedPrivileges
  isExtrasApprover
  isManageStudentPaymentInfo
  isConfirmedCurrentPeriodTimesheet
  isConfirmedPreviousPeriodTimesheet
  isConfirmedCurrentPeriodTimesheet_TimesheetProcessorCache
  isConfirmedPreviousPeriodTimesheet_TimesheetProcessorCache
  isViewedInTimesheetProcessor
  dateCreated
  dateModified
}
    `;
export const AttendanceFieldsFragmentDoc = gql`
    fragment AttendanceFields on Attendance {
  id
  class {
    ...ClassFields
  }
  weeksPaid
  weeksPaidComment
  paymentStatus
  weeksRemaining
  weekAttendanceInfos {
    week
    attendanceData
  }
  isDeleted
  dateModified
  dateCreated
  student {
    ...StudentFields
  }
  primaryAttendance {
    id
    class {
      ...ClassFields
    }
    weeksPaid
    weeksPaidComment
    weekAttendanceInfos {
      week
      attendanceData
    }
    isDeleted
    dateModified
    dateCreated
  }
  tempAttendances {
    id
    class {
      ...ClassFields
      teacherClassLinks {
        ...TeacherClassLinkFields
        teacher {
          ...TeacherFields
        }
      }
    }
    weeksPaid
    weeksPaidComment
    weekAttendanceInfos {
      week
      attendanceData
    }
    isDeleted
    dateModified
    dateCreated
  }
}
    ${ClassFieldsFragmentDoc}
${StudentFieldsFragmentDoc}
${TeacherClassLinkFieldsFragmentDoc}
${TeacherFieldsFragmentDoc}`;
export const AuditLogEntryFieldsFragmentDoc = gql`
    fragment AuditLogEntryFields on AuditLogEntry {
  id
  teacher {
    ...TeacherFields
  }
  entityType
  entityId
  message
  dateCreated
}
    ${TeacherFieldsFragmentDoc}`;
export const ExtraActivityFieldsFragmentDoc = gql`
    fragment ExtraActivityFields on ExtraActivity {
  id
  type
  description
  dateTime {
    startTime
    endTime
    date
    freeText
  }
  duration
  units
  pay
  week
  approvalStatus
  dateModified
  dateCreated
}
    `;
export const MakeupLessonFieldsFragmentDoc = gql`
    fragment MakeupLessonFields on MakeupLesson {
  id
  classId
  week
  lessonDate
  dateCreated
  dateModified
}
    `;
export const MessageTemplateAttachmentFieldsFragmentDoc = gql`
    fragment MessageTemplateAttachmentFields on MessageTemplateAttachment {
  id
  filename
  dateCreated
  dateModified
}
    `;
export const MessageTemplateFieldsFragmentDoc = gql`
    fragment MessageTemplateFields on MessageTemplate {
  id
  name
  subject
  templateMessage
  isSystemDefault
  dateCreated
  dateModified
  attachments {
    ...MessageTemplateAttachmentFields
  }
}
    ${MessageTemplateAttachmentFieldsFragmentDoc}`;
export const PayRatesFieldsFragmentDoc = gql`
    fragment PayRatesFields on TeacherPayRatesPayload {
  id
  privatePayRate
  markingHwHelpRate
  bookletsBookRate
  bookletsHrRate
  trainingRate
  classRate
  classExtraRate
  classExtraNumStudentsThreshold
  receptionRate
  assistingRate
  interviewRate
  intervalRates {
    start
    end
    rate
  }
}
    `;
export const TimesheetFieldsFragmentDoc = gql`
    fragment TimesheetFields on Timesheet {
  week
  items {
    activity
    dateTime
    units
    unitsType
    studentsPaymentStatus
    teachers
    teachersConflicts
    errors
    warnings
    classId
    teacherClassLinkId
    adjustmentId
    amount
    approvalStatus
  }
  totalAmount
  totalHours
  isConfirmed
}
    `;
export const LoginWithTokenDocument = gql`
    mutation loginWithToken($jwtToken: String!) {
  loginWithToken(jwtToken: $jwtToken) {
    accessToken
  }
}
    `;
export type LoginWithTokenMutationFn = Apollo.MutationFunction<LoginWithTokenMutation, LoginWithTokenMutationVariables>;

/**
 * __useLoginWithTokenMutation__
 *
 * To run a mutation, you first call `useLoginWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithTokenMutation, { data, loading, error }] = useLoginWithTokenMutation({
 *   variables: {
 *      jwtToken: // value for 'jwtToken'
 *   },
 * });
 */
export function useLoginWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<LoginWithTokenMutation, LoginWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithTokenMutation, LoginWithTokenMutationVariables>(LoginWithTokenDocument, options);
      }
export type LoginWithTokenMutationHookResult = ReturnType<typeof useLoginWithTokenMutation>;
export type LoginWithTokenMutationResult = Apollo.MutationResult<LoginWithTokenMutation>;
export type LoginWithTokenMutationOptions = Apollo.BaseMutationOptions<LoginWithTokenMutation, LoginWithTokenMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const SelfDocument = gql`
    query self {
  getSelf {
    ...TeacherFields
  }
}
    ${TeacherFieldsFragmentDoc}`;

/**
 * __useSelfQuery__
 *
 * To run a query within a React component, call `useSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfQuery(baseOptions?: Apollo.QueryHookOptions<SelfQuery, SelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
      }
export function useSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfQuery, SelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
        }
export type SelfQueryHookResult = ReturnType<typeof useSelfQuery>;
export type SelfLazyQueryHookResult = ReturnType<typeof useSelfLazyQuery>;
export type SelfQueryResult = Apollo.QueryResult<SelfQuery, SelfQueryVariables>;
export function refetchSelfQuery(variables?: SelfQueryVariables) {
      return { query: SelfDocument, variables: variables }
    }
export const ResetAccountDocument = gql`
    mutation resetAccount($id: Int!) {
  resetAccount(id: $id) {
    id
  }
}
    `;
export type ResetAccountMutationFn = Apollo.MutationFunction<ResetAccountMutation, ResetAccountMutationVariables>;

/**
 * __useResetAccountMutation__
 *
 * To run a mutation, you first call `useResetAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAccountMutation, { data, loading, error }] = useResetAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResetAccountMutation(baseOptions?: Apollo.MutationHookOptions<ResetAccountMutation, ResetAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetAccountMutation, ResetAccountMutationVariables>(ResetAccountDocument, options);
      }
export type ResetAccountMutationHookResult = ReturnType<typeof useResetAccountMutation>;
export type ResetAccountMutationResult = Apollo.MutationResult<ResetAccountMutation>;
export type ResetAccountMutationOptions = Apollo.BaseMutationOptions<ResetAccountMutation, ResetAccountMutationVariables>;
export const AddProfilePictureDocument = gql`
    mutation addProfilePicture($accountId: Int!, $picture: Upload!) {
  addProfilePicture(accountId: $accountId, picture: $picture)
}
    `;
export type AddProfilePictureMutationFn = Apollo.MutationFunction<AddProfilePictureMutation, AddProfilePictureMutationVariables>;

/**
 * __useAddProfilePictureMutation__
 *
 * To run a mutation, you first call `useAddProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProfilePictureMutation, { data, loading, error }] = useAddProfilePictureMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      picture: // value for 'picture'
 *   },
 * });
 */
export function useAddProfilePictureMutation(baseOptions?: Apollo.MutationHookOptions<AddProfilePictureMutation, AddProfilePictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProfilePictureMutation, AddProfilePictureMutationVariables>(AddProfilePictureDocument, options);
      }
export type AddProfilePictureMutationHookResult = ReturnType<typeof useAddProfilePictureMutation>;
export type AddProfilePictureMutationResult = Apollo.MutationResult<AddProfilePictureMutation>;
export type AddProfilePictureMutationOptions = Apollo.BaseMutationOptions<AddProfilePictureMutation, AddProfilePictureMutationVariables>;
export const DeleteProfilePictureDocument = gql`
    mutation deleteProfilePicture($accountId: Int!) {
  deleteProfilePicture(accountId: $accountId)
}
    `;
export type DeleteProfilePictureMutationFn = Apollo.MutationFunction<DeleteProfilePictureMutation, DeleteProfilePictureMutationVariables>;

/**
 * __useDeleteProfilePictureMutation__
 *
 * To run a mutation, you first call `useDeleteProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfilePictureMutation, { data, loading, error }] = useDeleteProfilePictureMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDeleteProfilePictureMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProfilePictureMutation, DeleteProfilePictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProfilePictureMutation, DeleteProfilePictureMutationVariables>(DeleteProfilePictureDocument, options);
      }
export type DeleteProfilePictureMutationHookResult = ReturnType<typeof useDeleteProfilePictureMutation>;
export type DeleteProfilePictureMutationResult = Apollo.MutationResult<DeleteProfilePictureMutation>;
export type DeleteProfilePictureMutationOptions = Apollo.BaseMutationOptions<DeleteProfilePictureMutation, DeleteProfilePictureMutationVariables>;
export const NewAttendanceDocument = gql`
    mutation newAttendance($input: AttendanceNewInput!) {
  newAttendance(input: $input) {
    ...AttendanceFields
  }
}
    ${AttendanceFieldsFragmentDoc}`;
export type NewAttendanceMutationFn = Apollo.MutationFunction<NewAttendanceMutation, NewAttendanceMutationVariables>;

/**
 * __useNewAttendanceMutation__
 *
 * To run a mutation, you first call `useNewAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newAttendanceMutation, { data, loading, error }] = useNewAttendanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<NewAttendanceMutation, NewAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewAttendanceMutation, NewAttendanceMutationVariables>(NewAttendanceDocument, options);
      }
export type NewAttendanceMutationHookResult = ReturnType<typeof useNewAttendanceMutation>;
export type NewAttendanceMutationResult = Apollo.MutationResult<NewAttendanceMutation>;
export type NewAttendanceMutationOptions = Apollo.BaseMutationOptions<NewAttendanceMutation, NewAttendanceMutationVariables>;
export const NewTemporaryAttendanceDocument = gql`
    mutation newTemporaryAttendance($input: AttendanceTemporaryNewInput!) {
  newTemporaryAttendance(input: $input) {
    ...AttendanceFields
  }
}
    ${AttendanceFieldsFragmentDoc}`;
export type NewTemporaryAttendanceMutationFn = Apollo.MutationFunction<NewTemporaryAttendanceMutation, NewTemporaryAttendanceMutationVariables>;

/**
 * __useNewTemporaryAttendanceMutation__
 *
 * To run a mutation, you first call `useNewTemporaryAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewTemporaryAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newTemporaryAttendanceMutation, { data, loading, error }] = useNewTemporaryAttendanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewTemporaryAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<NewTemporaryAttendanceMutation, NewTemporaryAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewTemporaryAttendanceMutation, NewTemporaryAttendanceMutationVariables>(NewTemporaryAttendanceDocument, options);
      }
export type NewTemporaryAttendanceMutationHookResult = ReturnType<typeof useNewTemporaryAttendanceMutation>;
export type NewTemporaryAttendanceMutationResult = Apollo.MutationResult<NewTemporaryAttendanceMutation>;
export type NewTemporaryAttendanceMutationOptions = Apollo.BaseMutationOptions<NewTemporaryAttendanceMutation, NewTemporaryAttendanceMutationVariables>;
export const UpdateAttendanceDocument = gql`
    mutation updateAttendance($input: AttendanceUpdateInput!) {
  updateAttendance(input: $input) {
    ...AttendanceFields
  }
}
    ${AttendanceFieldsFragmentDoc}`;
export type UpdateAttendanceMutationFn = Apollo.MutationFunction<UpdateAttendanceMutation, UpdateAttendanceMutationVariables>;

/**
 * __useUpdateAttendanceMutation__
 *
 * To run a mutation, you first call `useUpdateAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttendanceMutation, { data, loading, error }] = useUpdateAttendanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAttendanceMutation, UpdateAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAttendanceMutation, UpdateAttendanceMutationVariables>(UpdateAttendanceDocument, options);
      }
export type UpdateAttendanceMutationHookResult = ReturnType<typeof useUpdateAttendanceMutation>;
export type UpdateAttendanceMutationResult = Apollo.MutationResult<UpdateAttendanceMutation>;
export type UpdateAttendanceMutationOptions = Apollo.BaseMutationOptions<UpdateAttendanceMutation, UpdateAttendanceMutationVariables>;
export const UpdateAttendanceForEntireClassDocument = gql`
    mutation updateAttendanceForEntireClass($input: AttendanceUpdateInput!) {
  updateAttendanceForEntireClass(input: $input) {
    ...AttendanceFields
  }
}
    ${AttendanceFieldsFragmentDoc}`;
export type UpdateAttendanceForEntireClassMutationFn = Apollo.MutationFunction<UpdateAttendanceForEntireClassMutation, UpdateAttendanceForEntireClassMutationVariables>;

/**
 * __useUpdateAttendanceForEntireClassMutation__
 *
 * To run a mutation, you first call `useUpdateAttendanceForEntireClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttendanceForEntireClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttendanceForEntireClassMutation, { data, loading, error }] = useUpdateAttendanceForEntireClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAttendanceForEntireClassMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAttendanceForEntireClassMutation, UpdateAttendanceForEntireClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAttendanceForEntireClassMutation, UpdateAttendanceForEntireClassMutationVariables>(UpdateAttendanceForEntireClassDocument, options);
      }
export type UpdateAttendanceForEntireClassMutationHookResult = ReturnType<typeof useUpdateAttendanceForEntireClassMutation>;
export type UpdateAttendanceForEntireClassMutationResult = Apollo.MutationResult<UpdateAttendanceForEntireClassMutation>;
export type UpdateAttendanceForEntireClassMutationOptions = Apollo.BaseMutationOptions<UpdateAttendanceForEntireClassMutation, UpdateAttendanceForEntireClassMutationVariables>;
export const DeleteAttendanceDocument = gql`
    mutation deleteAttendance($id: Int!) {
  deleteAttendance(id: $id) {
    ...AttendanceFields
  }
}
    ${AttendanceFieldsFragmentDoc}`;
export type DeleteAttendanceMutationFn = Apollo.MutationFunction<DeleteAttendanceMutation, DeleteAttendanceMutationVariables>;

/**
 * __useDeleteAttendanceMutation__
 *
 * To run a mutation, you first call `useDeleteAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttendanceMutation, { data, loading, error }] = useDeleteAttendanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAttendanceMutation, DeleteAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAttendanceMutation, DeleteAttendanceMutationVariables>(DeleteAttendanceDocument, options);
      }
export type DeleteAttendanceMutationHookResult = ReturnType<typeof useDeleteAttendanceMutation>;
export type DeleteAttendanceMutationResult = Apollo.MutationResult<DeleteAttendanceMutation>;
export type DeleteAttendanceMutationOptions = Apollo.BaseMutationOptions<DeleteAttendanceMutation, DeleteAttendanceMutationVariables>;
export const GetAuditLogEntriesForEntityDocument = gql`
    query getAuditLogEntriesForEntity($input: EntityInput!) {
  getAuditLogEntriesForEntity(input: $input) {
    ...AuditLogEntryFields
  }
}
    ${AuditLogEntryFieldsFragmentDoc}`;

/**
 * __useGetAuditLogEntriesForEntityQuery__
 *
 * To run a query within a React component, call `useGetAuditLogEntriesForEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditLogEntriesForEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditLogEntriesForEntityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAuditLogEntriesForEntityQuery(baseOptions: Apollo.QueryHookOptions<GetAuditLogEntriesForEntityQuery, GetAuditLogEntriesForEntityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuditLogEntriesForEntityQuery, GetAuditLogEntriesForEntityQueryVariables>(GetAuditLogEntriesForEntityDocument, options);
      }
export function useGetAuditLogEntriesForEntityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuditLogEntriesForEntityQuery, GetAuditLogEntriesForEntityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuditLogEntriesForEntityQuery, GetAuditLogEntriesForEntityQueryVariables>(GetAuditLogEntriesForEntityDocument, options);
        }
export type GetAuditLogEntriesForEntityQueryHookResult = ReturnType<typeof useGetAuditLogEntriesForEntityQuery>;
export type GetAuditLogEntriesForEntityLazyQueryHookResult = ReturnType<typeof useGetAuditLogEntriesForEntityLazyQuery>;
export type GetAuditLogEntriesForEntityQueryResult = Apollo.QueryResult<GetAuditLogEntriesForEntityQuery, GetAuditLogEntriesForEntityQueryVariables>;
export function refetchGetAuditLogEntriesForEntityQuery(variables?: GetAuditLogEntriesForEntityQueryVariables) {
      return { query: GetAuditLogEntriesForEntityDocument, variables: variables }
    }
export const GetAuditLogEntriesForTeacherDocument = gql`
    query getAuditLogEntriesForTeacher($teacherId: Int!) {
  getAuditLogEntriesForTeacher(teacherId: $teacherId) {
    ...AuditLogEntryFields
  }
}
    ${AuditLogEntryFieldsFragmentDoc}`;

/**
 * __useGetAuditLogEntriesForTeacherQuery__
 *
 * To run a query within a React component, call `useGetAuditLogEntriesForTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditLogEntriesForTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditLogEntriesForTeacherQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetAuditLogEntriesForTeacherQuery(baseOptions: Apollo.QueryHookOptions<GetAuditLogEntriesForTeacherQuery, GetAuditLogEntriesForTeacherQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuditLogEntriesForTeacherQuery, GetAuditLogEntriesForTeacherQueryVariables>(GetAuditLogEntriesForTeacherDocument, options);
      }
export function useGetAuditLogEntriesForTeacherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuditLogEntriesForTeacherQuery, GetAuditLogEntriesForTeacherQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuditLogEntriesForTeacherQuery, GetAuditLogEntriesForTeacherQueryVariables>(GetAuditLogEntriesForTeacherDocument, options);
        }
export type GetAuditLogEntriesForTeacherQueryHookResult = ReturnType<typeof useGetAuditLogEntriesForTeacherQuery>;
export type GetAuditLogEntriesForTeacherLazyQueryHookResult = ReturnType<typeof useGetAuditLogEntriesForTeacherLazyQuery>;
export type GetAuditLogEntriesForTeacherQueryResult = Apollo.QueryResult<GetAuditLogEntriesForTeacherQuery, GetAuditLogEntriesForTeacherQueryVariables>;
export function refetchGetAuditLogEntriesForTeacherQuery(variables?: GetAuditLogEntriesForTeacherQueryVariables) {
      return { query: GetAuditLogEntriesForTeacherDocument, variables: variables }
    }
export const ClassesDocument = gql`
    query classes($filter: ClassFilterInput) {
  getClasses(filter: $filter) {
    ...ClassFields
  }
}
    ${ClassFieldsFragmentDoc}`;

/**
 * __useClassesQuery__
 *
 * To run a query within a React component, call `useClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useClassesQuery(baseOptions?: Apollo.QueryHookOptions<ClassesQuery, ClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassesQuery, ClassesQueryVariables>(ClassesDocument, options);
      }
export function useClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassesQuery, ClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassesQuery, ClassesQueryVariables>(ClassesDocument, options);
        }
export type ClassesQueryHookResult = ReturnType<typeof useClassesQuery>;
export type ClassesLazyQueryHookResult = ReturnType<typeof useClassesLazyQuery>;
export type ClassesQueryResult = Apollo.QueryResult<ClassesQuery, ClassesQueryVariables>;
export function refetchClassesQuery(variables?: ClassesQueryVariables) {
      return { query: ClassesDocument, variables: variables }
    }
export const NewClassDocument = gql`
    mutation newClass($input: ClassNewInput!) {
  newClass(input: $input) {
    ...ClassFields
  }
}
    ${ClassFieldsFragmentDoc}`;
export type NewClassMutationFn = Apollo.MutationFunction<NewClassMutation, NewClassMutationVariables>;

/**
 * __useNewClassMutation__
 *
 * To run a mutation, you first call `useNewClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newClassMutation, { data, loading, error }] = useNewClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewClassMutation(baseOptions?: Apollo.MutationHookOptions<NewClassMutation, NewClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewClassMutation, NewClassMutationVariables>(NewClassDocument, options);
      }
export type NewClassMutationHookResult = ReturnType<typeof useNewClassMutation>;
export type NewClassMutationResult = Apollo.MutationResult<NewClassMutation>;
export type NewClassMutationOptions = Apollo.BaseMutationOptions<NewClassMutation, NewClassMutationVariables>;
export const UpdateClassDocument = gql`
    mutation updateClass($input: ClassUpdateInput!) {
  updateClass(input: $input) {
    ...ClassFields
  }
}
    ${ClassFieldsFragmentDoc}`;
export type UpdateClassMutationFn = Apollo.MutationFunction<UpdateClassMutation, UpdateClassMutationVariables>;

/**
 * __useUpdateClassMutation__
 *
 * To run a mutation, you first call `useUpdateClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassMutation, { data, loading, error }] = useUpdateClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClassMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClassMutation, UpdateClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClassMutation, UpdateClassMutationVariables>(UpdateClassDocument, options);
      }
export type UpdateClassMutationHookResult = ReturnType<typeof useUpdateClassMutation>;
export type UpdateClassMutationResult = Apollo.MutationResult<UpdateClassMutation>;
export type UpdateClassMutationOptions = Apollo.BaseMutationOptions<UpdateClassMutation, UpdateClassMutationVariables>;
export const DeleteClassDocument = gql`
    mutation deleteClass($id: Int!) {
  deleteClass(id: $id) {
    ...ClassFields
  }
}
    ${ClassFieldsFragmentDoc}`;
export type DeleteClassMutationFn = Apollo.MutationFunction<DeleteClassMutation, DeleteClassMutationVariables>;

/**
 * __useDeleteClassMutation__
 *
 * To run a mutation, you first call `useDeleteClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassMutation, { data, loading, error }] = useDeleteClassMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClassMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClassMutation, DeleteClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClassMutation, DeleteClassMutationVariables>(DeleteClassDocument, options);
      }
export type DeleteClassMutationHookResult = ReturnType<typeof useDeleteClassMutation>;
export type DeleteClassMutationResult = Apollo.MutationResult<DeleteClassMutation>;
export type DeleteClassMutationOptions = Apollo.BaseMutationOptions<DeleteClassMutation, DeleteClassMutationVariables>;
export const ClassesAttendanceRecordsDocument = gql`
    query classesAttendanceRecords($filter: ClassFilterInput) {
  getClasses(filter: $filter) {
    ...ClassFields
    attendances {
      ...AttendanceFields
    }
    teacherClassLinks {
      ...TeacherClassLinkFields
      teacher {
        ...TeacherFields
      }
    }
    makeupLessons {
      ...MakeupLessonFields
    }
  }
}
    ${ClassFieldsFragmentDoc}
${AttendanceFieldsFragmentDoc}
${TeacherClassLinkFieldsFragmentDoc}
${TeacherFieldsFragmentDoc}
${MakeupLessonFieldsFragmentDoc}`;

/**
 * __useClassesAttendanceRecordsQuery__
 *
 * To run a query within a React component, call `useClassesAttendanceRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassesAttendanceRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassesAttendanceRecordsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useClassesAttendanceRecordsQuery(baseOptions?: Apollo.QueryHookOptions<ClassesAttendanceRecordsQuery, ClassesAttendanceRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassesAttendanceRecordsQuery, ClassesAttendanceRecordsQueryVariables>(ClassesAttendanceRecordsDocument, options);
      }
export function useClassesAttendanceRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassesAttendanceRecordsQuery, ClassesAttendanceRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassesAttendanceRecordsQuery, ClassesAttendanceRecordsQueryVariables>(ClassesAttendanceRecordsDocument, options);
        }
export type ClassesAttendanceRecordsQueryHookResult = ReturnType<typeof useClassesAttendanceRecordsQuery>;
export type ClassesAttendanceRecordsLazyQueryHookResult = ReturnType<typeof useClassesAttendanceRecordsLazyQuery>;
export type ClassesAttendanceRecordsQueryResult = Apollo.QueryResult<ClassesAttendanceRecordsQuery, ClassesAttendanceRecordsQueryVariables>;
export function refetchClassesAttendanceRecordsQuery(variables?: ClassesAttendanceRecordsQueryVariables) {
      return { query: ClassesAttendanceRecordsDocument, variables: variables }
    }
export const ClassesWithTeachersDocument = gql`
    query classesWithTeachers($filter: ClassFilterInput) {
  getClasses(filter: $filter) {
    ...ClassFields
    teacherClassLinks {
      ...TeacherClassLinkFields
      teacher {
        ...TeacherFields
      }
    }
  }
}
    ${ClassFieldsFragmentDoc}
${TeacherClassLinkFieldsFragmentDoc}
${TeacherFieldsFragmentDoc}`;

/**
 * __useClassesWithTeachersQuery__
 *
 * To run a query within a React component, call `useClassesWithTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassesWithTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassesWithTeachersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useClassesWithTeachersQuery(baseOptions?: Apollo.QueryHookOptions<ClassesWithTeachersQuery, ClassesWithTeachersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassesWithTeachersQuery, ClassesWithTeachersQueryVariables>(ClassesWithTeachersDocument, options);
      }
export function useClassesWithTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassesWithTeachersQuery, ClassesWithTeachersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassesWithTeachersQuery, ClassesWithTeachersQueryVariables>(ClassesWithTeachersDocument, options);
        }
export type ClassesWithTeachersQueryHookResult = ReturnType<typeof useClassesWithTeachersQuery>;
export type ClassesWithTeachersLazyQueryHookResult = ReturnType<typeof useClassesWithTeachersLazyQuery>;
export type ClassesWithTeachersQueryResult = Apollo.QueryResult<ClassesWithTeachersQuery, ClassesWithTeachersQueryVariables>;
export function refetchClassesWithTeachersQuery(variables?: ClassesWithTeachersQueryVariables) {
      return { query: ClassesWithTeachersDocument, variables: variables }
    }
export const GetExtraActivitiesDocument = gql`
    query getExtraActivities($weeks: [Week!]!) {
  getExtraActivities(weeks: $weeks) {
    ...ExtraActivityFields
  }
}
    ${ExtraActivityFieldsFragmentDoc}`;

/**
 * __useGetExtraActivitiesQuery__
 *
 * To run a query within a React component, call `useGetExtraActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtraActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtraActivitiesQuery({
 *   variables: {
 *      weeks: // value for 'weeks'
 *   },
 * });
 */
export function useGetExtraActivitiesQuery(baseOptions: Apollo.QueryHookOptions<GetExtraActivitiesQuery, GetExtraActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExtraActivitiesQuery, GetExtraActivitiesQueryVariables>(GetExtraActivitiesDocument, options);
      }
export function useGetExtraActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExtraActivitiesQuery, GetExtraActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExtraActivitiesQuery, GetExtraActivitiesQueryVariables>(GetExtraActivitiesDocument, options);
        }
export type GetExtraActivitiesQueryHookResult = ReturnType<typeof useGetExtraActivitiesQuery>;
export type GetExtraActivitiesLazyQueryHookResult = ReturnType<typeof useGetExtraActivitiesLazyQuery>;
export type GetExtraActivitiesQueryResult = Apollo.QueryResult<GetExtraActivitiesQuery, GetExtraActivitiesQueryVariables>;
export function refetchGetExtraActivitiesQuery(variables?: GetExtraActivitiesQueryVariables) {
      return { query: GetExtraActivitiesDocument, variables: variables }
    }
export const GetExtraActivityTypesDocument = gql`
    query getExtraActivityTypes {
  getExtraActivityTypes
}
    `;

/**
 * __useGetExtraActivityTypesQuery__
 *
 * To run a query within a React component, call `useGetExtraActivityTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtraActivityTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtraActivityTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExtraActivityTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetExtraActivityTypesQuery, GetExtraActivityTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExtraActivityTypesQuery, GetExtraActivityTypesQueryVariables>(GetExtraActivityTypesDocument, options);
      }
export function useGetExtraActivityTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExtraActivityTypesQuery, GetExtraActivityTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExtraActivityTypesQuery, GetExtraActivityTypesQueryVariables>(GetExtraActivityTypesDocument, options);
        }
export type GetExtraActivityTypesQueryHookResult = ReturnType<typeof useGetExtraActivityTypesQuery>;
export type GetExtraActivityTypesLazyQueryHookResult = ReturnType<typeof useGetExtraActivityTypesLazyQuery>;
export type GetExtraActivityTypesQueryResult = Apollo.QueryResult<GetExtraActivityTypesQuery, GetExtraActivityTypesQueryVariables>;
export function refetchGetExtraActivityTypesQuery(variables?: GetExtraActivityTypesQueryVariables) {
      return { query: GetExtraActivityTypesDocument, variables: variables }
    }
export const GetApprovalsDocument = gql`
    query getApprovals {
  getApprovals {
    ...ExtraActivityFields
    teacher {
      fullName
    }
  }
}
    ${ExtraActivityFieldsFragmentDoc}`;

/**
 * __useGetApprovalsQuery__
 *
 * To run a query within a React component, call `useGetApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApprovalsQuery(baseOptions?: Apollo.QueryHookOptions<GetApprovalsQuery, GetApprovalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalsQuery, GetApprovalsQueryVariables>(GetApprovalsDocument, options);
      }
export function useGetApprovalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalsQuery, GetApprovalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalsQuery, GetApprovalsQueryVariables>(GetApprovalsDocument, options);
        }
export type GetApprovalsQueryHookResult = ReturnType<typeof useGetApprovalsQuery>;
export type GetApprovalsLazyQueryHookResult = ReturnType<typeof useGetApprovalsLazyQuery>;
export type GetApprovalsQueryResult = Apollo.QueryResult<GetApprovalsQuery, GetApprovalsQueryVariables>;
export function refetchGetApprovalsQuery(variables?: GetApprovalsQueryVariables) {
      return { query: GetApprovalsDocument, variables: variables }
    }
export const NewExtraActivityDocument = gql`
    mutation newExtraActivity($input: ExtraActivityNewInput!) {
  newExtraActivity(input: $input) {
    ...ExtraActivityFields
  }
}
    ${ExtraActivityFieldsFragmentDoc}`;
export type NewExtraActivityMutationFn = Apollo.MutationFunction<NewExtraActivityMutation, NewExtraActivityMutationVariables>;

/**
 * __useNewExtraActivityMutation__
 *
 * To run a mutation, you first call `useNewExtraActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewExtraActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newExtraActivityMutation, { data, loading, error }] = useNewExtraActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewExtraActivityMutation(baseOptions?: Apollo.MutationHookOptions<NewExtraActivityMutation, NewExtraActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewExtraActivityMutation, NewExtraActivityMutationVariables>(NewExtraActivityDocument, options);
      }
export type NewExtraActivityMutationHookResult = ReturnType<typeof useNewExtraActivityMutation>;
export type NewExtraActivityMutationResult = Apollo.MutationResult<NewExtraActivityMutation>;
export type NewExtraActivityMutationOptions = Apollo.BaseMutationOptions<NewExtraActivityMutation, NewExtraActivityMutationVariables>;
export const UpdateExtraActivityDocument = gql`
    mutation updateExtraActivity($input: ExtraActivityUpdateInput!) {
  updateExtraActivity(input: $input) {
    ...ExtraActivityFields
  }
}
    ${ExtraActivityFieldsFragmentDoc}`;
export type UpdateExtraActivityMutationFn = Apollo.MutationFunction<UpdateExtraActivityMutation, UpdateExtraActivityMutationVariables>;

/**
 * __useUpdateExtraActivityMutation__
 *
 * To run a mutation, you first call `useUpdateExtraActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExtraActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExtraActivityMutation, { data, loading, error }] = useUpdateExtraActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExtraActivityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExtraActivityMutation, UpdateExtraActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExtraActivityMutation, UpdateExtraActivityMutationVariables>(UpdateExtraActivityDocument, options);
      }
export type UpdateExtraActivityMutationHookResult = ReturnType<typeof useUpdateExtraActivityMutation>;
export type UpdateExtraActivityMutationResult = Apollo.MutationResult<UpdateExtraActivityMutation>;
export type UpdateExtraActivityMutationOptions = Apollo.BaseMutationOptions<UpdateExtraActivityMutation, UpdateExtraActivityMutationVariables>;
export const UpdateApprovalStatusDocument = gql`
    mutation updateApprovalStatus($id: Int!, $approvalStatus: ExtraActivityApprovalStatus!) {
  updateApprovalStatus(id: $id, approvalStatus: $approvalStatus) {
    ...ExtraActivityFields
  }
}
    ${ExtraActivityFieldsFragmentDoc}`;
export type UpdateApprovalStatusMutationFn = Apollo.MutationFunction<UpdateApprovalStatusMutation, UpdateApprovalStatusMutationVariables>;

/**
 * __useUpdateApprovalStatusMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalStatusMutation, { data, loading, error }] = useUpdateApprovalStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      approvalStatus: // value for 'approvalStatus'
 *   },
 * });
 */
export function useUpdateApprovalStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApprovalStatusMutation, UpdateApprovalStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApprovalStatusMutation, UpdateApprovalStatusMutationVariables>(UpdateApprovalStatusDocument, options);
      }
export type UpdateApprovalStatusMutationHookResult = ReturnType<typeof useUpdateApprovalStatusMutation>;
export type UpdateApprovalStatusMutationResult = Apollo.MutationResult<UpdateApprovalStatusMutation>;
export type UpdateApprovalStatusMutationOptions = Apollo.BaseMutationOptions<UpdateApprovalStatusMutation, UpdateApprovalStatusMutationVariables>;
export const DeleteExtraActivityDocument = gql`
    mutation deleteExtraActivity($id: Int!) {
  deleteExtraActivity(id: $id)
}
    `;
export type DeleteExtraActivityMutationFn = Apollo.MutationFunction<DeleteExtraActivityMutation, DeleteExtraActivityMutationVariables>;

/**
 * __useDeleteExtraActivityMutation__
 *
 * To run a mutation, you first call `useDeleteExtraActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExtraActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExtraActivityMutation, { data, loading, error }] = useDeleteExtraActivityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExtraActivityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteExtraActivityMutation, DeleteExtraActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteExtraActivityMutation, DeleteExtraActivityMutationVariables>(DeleteExtraActivityDocument, options);
      }
export type DeleteExtraActivityMutationHookResult = ReturnType<typeof useDeleteExtraActivityMutation>;
export type DeleteExtraActivityMutationResult = Apollo.MutationResult<DeleteExtraActivityMutation>;
export type DeleteExtraActivityMutationOptions = Apollo.BaseMutationOptions<DeleteExtraActivityMutation, DeleteExtraActivityMutationVariables>;
export const InvoiceToPdfDocument = gql`
    query invoiceToPDF($invoiceId: Int!) {
  invoiceToPDF(invoiceId: $invoiceId)
}
    `;

/**
 * __useInvoiceToPdfQuery__
 *
 * To run a query within a React component, call `useInvoiceToPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceToPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceToPdfQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useInvoiceToPdfQuery(baseOptions: Apollo.QueryHookOptions<InvoiceToPdfQuery, InvoiceToPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceToPdfQuery, InvoiceToPdfQueryVariables>(InvoiceToPdfDocument, options);
      }
export function useInvoiceToPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceToPdfQuery, InvoiceToPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceToPdfQuery, InvoiceToPdfQueryVariables>(InvoiceToPdfDocument, options);
        }
export type InvoiceToPdfQueryHookResult = ReturnType<typeof useInvoiceToPdfQuery>;
export type InvoiceToPdfLazyQueryHookResult = ReturnType<typeof useInvoiceToPdfLazyQuery>;
export type InvoiceToPdfQueryResult = Apollo.QueryResult<InvoiceToPdfQuery, InvoiceToPdfQueryVariables>;
export function refetchInvoiceToPdfQuery(variables?: InvoiceToPdfQueryVariables) {
      return { query: InvoiceToPdfDocument, variables: variables }
    }
export const InvoiceToMessageDocument = gql`
    mutation invoiceToMessage($invoiceId: Int!) {
  invoiceToMessage(invoiceId: $invoiceId) {
    student {
      ...StudentFields
    }
    messageTemplateAttachment {
      ...MessageTemplateAttachmentFields
    }
  }
}
    ${StudentFieldsFragmentDoc}
${MessageTemplateAttachmentFieldsFragmentDoc}`;
export type InvoiceToMessageMutationFn = Apollo.MutationFunction<InvoiceToMessageMutation, InvoiceToMessageMutationVariables>;

/**
 * __useInvoiceToMessageMutation__
 *
 * To run a mutation, you first call `useInvoiceToMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceToMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceToMessageMutation, { data, loading, error }] = useInvoiceToMessageMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useInvoiceToMessageMutation(baseOptions?: Apollo.MutationHookOptions<InvoiceToMessageMutation, InvoiceToMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvoiceToMessageMutation, InvoiceToMessageMutationVariables>(InvoiceToMessageDocument, options);
      }
export type InvoiceToMessageMutationHookResult = ReturnType<typeof useInvoiceToMessageMutation>;
export type InvoiceToMessageMutationResult = Apollo.MutationResult<InvoiceToMessageMutation>;
export type InvoiceToMessageMutationOptions = Apollo.BaseMutationOptions<InvoiceToMessageMutation, InvoiceToMessageMutationVariables>;
export const LinkOrUnlinkInvoiceLineWeekToAttendanceDocument = gql`
    mutation linkOrUnlinkInvoiceLineWeekToAttendance($invoiceLineWeekId: Int!, $attendanceId: Int) {
  linkOrUnlinkInvoiceLineWeekToAttendance(
    invoiceLineWeekId: $invoiceLineWeekId
    attendanceId: $attendanceId
  ) {
    ...InvoiceLineWeeksFields
  }
}
    ${InvoiceLineWeeksFieldsFragmentDoc}`;
export type LinkOrUnlinkInvoiceLineWeekToAttendanceMutationFn = Apollo.MutationFunction<LinkOrUnlinkInvoiceLineWeekToAttendanceMutation, LinkOrUnlinkInvoiceLineWeekToAttendanceMutationVariables>;

/**
 * __useLinkOrUnlinkInvoiceLineWeekToAttendanceMutation__
 *
 * To run a mutation, you first call `useLinkOrUnlinkInvoiceLineWeekToAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkOrUnlinkInvoiceLineWeekToAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkOrUnlinkInvoiceLineWeekToAttendanceMutation, { data, loading, error }] = useLinkOrUnlinkInvoiceLineWeekToAttendanceMutation({
 *   variables: {
 *      invoiceLineWeekId: // value for 'invoiceLineWeekId'
 *      attendanceId: // value for 'attendanceId'
 *   },
 * });
 */
export function useLinkOrUnlinkInvoiceLineWeekToAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<LinkOrUnlinkInvoiceLineWeekToAttendanceMutation, LinkOrUnlinkInvoiceLineWeekToAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LinkOrUnlinkInvoiceLineWeekToAttendanceMutation, LinkOrUnlinkInvoiceLineWeekToAttendanceMutationVariables>(LinkOrUnlinkInvoiceLineWeekToAttendanceDocument, options);
      }
export type LinkOrUnlinkInvoiceLineWeekToAttendanceMutationHookResult = ReturnType<typeof useLinkOrUnlinkInvoiceLineWeekToAttendanceMutation>;
export type LinkOrUnlinkInvoiceLineWeekToAttendanceMutationResult = Apollo.MutationResult<LinkOrUnlinkInvoiceLineWeekToAttendanceMutation>;
export type LinkOrUnlinkInvoiceLineWeekToAttendanceMutationOptions = Apollo.BaseMutationOptions<LinkOrUnlinkInvoiceLineWeekToAttendanceMutation, LinkOrUnlinkInvoiceLineWeekToAttendanceMutationVariables>;
export const CreateMakeupLessonDocument = gql`
    mutation createMakeupLesson($input: MakeupLessonCreateInput!) {
  createMakeupLesson(input: $input) {
    ...MakeupLessonFields
  }
}
    ${MakeupLessonFieldsFragmentDoc}`;
export type CreateMakeupLessonMutationFn = Apollo.MutationFunction<CreateMakeupLessonMutation, CreateMakeupLessonMutationVariables>;

/**
 * __useCreateMakeupLessonMutation__
 *
 * To run a mutation, you first call `useCreateMakeupLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMakeupLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMakeupLessonMutation, { data, loading, error }] = useCreateMakeupLessonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMakeupLessonMutation(baseOptions?: Apollo.MutationHookOptions<CreateMakeupLessonMutation, CreateMakeupLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMakeupLessonMutation, CreateMakeupLessonMutationVariables>(CreateMakeupLessonDocument, options);
      }
export type CreateMakeupLessonMutationHookResult = ReturnType<typeof useCreateMakeupLessonMutation>;
export type CreateMakeupLessonMutationResult = Apollo.MutationResult<CreateMakeupLessonMutation>;
export type CreateMakeupLessonMutationOptions = Apollo.BaseMutationOptions<CreateMakeupLessonMutation, CreateMakeupLessonMutationVariables>;
export const DeleteMakeupLessonDocument = gql`
    mutation deleteMakeupLesson($id: Int!) {
  deleteMakeupLesson(id: $id)
}
    `;
export type DeleteMakeupLessonMutationFn = Apollo.MutationFunction<DeleteMakeupLessonMutation, DeleteMakeupLessonMutationVariables>;

/**
 * __useDeleteMakeupLessonMutation__
 *
 * To run a mutation, you first call `useDeleteMakeupLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMakeupLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMakeupLessonMutation, { data, loading, error }] = useDeleteMakeupLessonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMakeupLessonMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMakeupLessonMutation, DeleteMakeupLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMakeupLessonMutation, DeleteMakeupLessonMutationVariables>(DeleteMakeupLessonDocument, options);
      }
export type DeleteMakeupLessonMutationHookResult = ReturnType<typeof useDeleteMakeupLessonMutation>;
export type DeleteMakeupLessonMutationResult = Apollo.MutationResult<DeleteMakeupLessonMutation>;
export type DeleteMakeupLessonMutationOptions = Apollo.BaseMutationOptions<DeleteMakeupLessonMutation, DeleteMakeupLessonMutationVariables>;
export const SendSmsDocument = gql`
    mutation sendSMS($input: SendSMSInput!) {
  sendSMS(input: $input)
}
    `;
export type SendSmsMutationFn = Apollo.MutationFunction<SendSmsMutation, SendSmsMutationVariables>;

/**
 * __useSendSmsMutation__
 *
 * To run a mutation, you first call `useSendSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsMutation, { data, loading, error }] = useSendSmsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendSmsMutation(baseOptions?: Apollo.MutationHookOptions<SendSmsMutation, SendSmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSmsMutation, SendSmsMutationVariables>(SendSmsDocument, options);
      }
export type SendSmsMutationHookResult = ReturnType<typeof useSendSmsMutation>;
export type SendSmsMutationResult = Apollo.MutationResult<SendSmsMutation>;
export type SendSmsMutationOptions = Apollo.BaseMutationOptions<SendSmsMutation, SendSmsMutationVariables>;
export const SendEmailDocument = gql`
    mutation sendEmail($input: SendEmailInput!) {
  sendEmail(input: $input)
}
    `;
export type SendEmailMutationFn = Apollo.MutationFunction<SendEmailMutation, SendEmailMutationVariables>;

/**
 * __useSendEmailMutation__
 *
 * To run a mutation, you first call `useSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailMutation, { data, loading, error }] = useSendEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailMutation, SendEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument, options);
      }
export type SendEmailMutationHookResult = ReturnType<typeof useSendEmailMutation>;
export type SendEmailMutationResult = Apollo.MutationResult<SendEmailMutation>;
export type SendEmailMutationOptions = Apollo.BaseMutationOptions<SendEmailMutation, SendEmailMutationVariables>;
export const GetMessageTemplatesDocument = gql`
    query getMessageTemplates {
  getMessageTemplates {
    ...MessageTemplateFields
  }
}
    ${MessageTemplateFieldsFragmentDoc}`;

/**
 * __useGetMessageTemplatesQuery__
 *
 * To run a query within a React component, call `useGetMessageTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMessageTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>(GetMessageTemplatesDocument, options);
      }
export function useGetMessageTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>(GetMessageTemplatesDocument, options);
        }
export type GetMessageTemplatesQueryHookResult = ReturnType<typeof useGetMessageTemplatesQuery>;
export type GetMessageTemplatesLazyQueryHookResult = ReturnType<typeof useGetMessageTemplatesLazyQuery>;
export type GetMessageTemplatesQueryResult = Apollo.QueryResult<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>;
export function refetchGetMessageTemplatesQuery(variables?: GetMessageTemplatesQueryVariables) {
      return { query: GetMessageTemplatesDocument, variables: variables }
    }
export const CreateMessageTemplateDocument = gql`
    mutation createMessageTemplate($input: MessageTemplateNewInput!) {
  createMessageTemplate(input: $input) {
    ...MessageTemplateFields
  }
}
    ${MessageTemplateFieldsFragmentDoc}`;
export type CreateMessageTemplateMutationFn = Apollo.MutationFunction<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>;

/**
 * __useCreateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageTemplateMutation, { data, loading, error }] = useCreateMessageTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>(CreateMessageTemplateDocument, options);
      }
export type CreateMessageTemplateMutationHookResult = ReturnType<typeof useCreateMessageTemplateMutation>;
export type CreateMessageTemplateMutationResult = Apollo.MutationResult<CreateMessageTemplateMutation>;
export type CreateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>;
export const UpdateMessageTemplateDocument = gql`
    mutation updateMessageTemplate($input: MessageTemplateUpdateInput!) {
  updateMessageTemplate(input: $input) {
    ...MessageTemplateFields
  }
}
    ${MessageTemplateFieldsFragmentDoc}`;
export type UpdateMessageTemplateMutationFn = Apollo.MutationFunction<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>;

/**
 * __useUpdateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageTemplateMutation, { data, loading, error }] = useUpdateMessageTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>(UpdateMessageTemplateDocument, options);
      }
export type UpdateMessageTemplateMutationHookResult = ReturnType<typeof useUpdateMessageTemplateMutation>;
export type UpdateMessageTemplateMutationResult = Apollo.MutationResult<UpdateMessageTemplateMutation>;
export type UpdateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>;
export const DeleteMessageTemplateDocument = gql`
    mutation deleteMessageTemplate($id: Int!) {
  deleteMessageTemplate(id: $id)
}
    `;
export type DeleteMessageTemplateMutationFn = Apollo.MutationFunction<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>;

/**
 * __useDeleteMessageTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageTemplateMutation, { data, loading, error }] = useDeleteMessageTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>(DeleteMessageTemplateDocument, options);
      }
export type DeleteMessageTemplateMutationHookResult = ReturnType<typeof useDeleteMessageTemplateMutation>;
export type DeleteMessageTemplateMutationResult = Apollo.MutationResult<DeleteMessageTemplateMutation>;
export type DeleteMessageTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>;
export const StudentsDocument = gql`
    query students($filter: StudentFilterInput) {
  getStudents(filter: $filter) {
    ...StudentFields
  }
}
    ${StudentFieldsFragmentDoc}`;

/**
 * __useStudentsQuery__
 *
 * To run a query within a React component, call `useStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStudentsQuery(baseOptions?: Apollo.QueryHookOptions<StudentsQuery, StudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentsQuery, StudentsQueryVariables>(StudentsDocument, options);
      }
export function useStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentsQuery, StudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentsQuery, StudentsQueryVariables>(StudentsDocument, options);
        }
export type StudentsQueryHookResult = ReturnType<typeof useStudentsQuery>;
export type StudentsLazyQueryHookResult = ReturnType<typeof useStudentsLazyQuery>;
export type StudentsQueryResult = Apollo.QueryResult<StudentsQuery, StudentsQueryVariables>;
export function refetchStudentsQuery(variables?: StudentsQueryVariables) {
      return { query: StudentsDocument, variables: variables }
    }
export const StudentsAttendancesDocument = gql`
    query studentsAttendances($filter: StudentFilterInput) {
  getStudents(filter: $filter) {
    ...StudentFields
    attendances {
      ...AttendanceFields
      class {
        ...ClassFields
        teacherClassLinks {
          ...TeacherClassLinkFields
          teacher {
            ...TeacherFields
          }
        }
      }
    }
  }
}
    ${StudentFieldsFragmentDoc}
${AttendanceFieldsFragmentDoc}
${ClassFieldsFragmentDoc}
${TeacherClassLinkFieldsFragmentDoc}
${TeacherFieldsFragmentDoc}`;

/**
 * __useStudentsAttendancesQuery__
 *
 * To run a query within a React component, call `useStudentsAttendancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentsAttendancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentsAttendancesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStudentsAttendancesQuery(baseOptions?: Apollo.QueryHookOptions<StudentsAttendancesQuery, StudentsAttendancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentsAttendancesQuery, StudentsAttendancesQueryVariables>(StudentsAttendancesDocument, options);
      }
export function useStudentsAttendancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentsAttendancesQuery, StudentsAttendancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentsAttendancesQuery, StudentsAttendancesQueryVariables>(StudentsAttendancesDocument, options);
        }
export type StudentsAttendancesQueryHookResult = ReturnType<typeof useStudentsAttendancesQuery>;
export type StudentsAttendancesLazyQueryHookResult = ReturnType<typeof useStudentsAttendancesLazyQuery>;
export type StudentsAttendancesQueryResult = Apollo.QueryResult<StudentsAttendancesQuery, StudentsAttendancesQueryVariables>;
export function refetchStudentsAttendancesQuery(variables?: StudentsAttendancesQueryVariables) {
      return { query: StudentsAttendancesDocument, variables: variables }
    }
export const NewStudentDocument = gql`
    mutation newStudent($input: StudentNewInput!) {
  newStudent(input: $input) {
    ...StudentFields
  }
}
    ${StudentFieldsFragmentDoc}`;
export type NewStudentMutationFn = Apollo.MutationFunction<NewStudentMutation, NewStudentMutationVariables>;

/**
 * __useNewStudentMutation__
 *
 * To run a mutation, you first call `useNewStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newStudentMutation, { data, loading, error }] = useNewStudentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewStudentMutation(baseOptions?: Apollo.MutationHookOptions<NewStudentMutation, NewStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewStudentMutation, NewStudentMutationVariables>(NewStudentDocument, options);
      }
export type NewStudentMutationHookResult = ReturnType<typeof useNewStudentMutation>;
export type NewStudentMutationResult = Apollo.MutationResult<NewStudentMutation>;
export type NewStudentMutationOptions = Apollo.BaseMutationOptions<NewStudentMutation, NewStudentMutationVariables>;
export const UpdateStudentDocument = gql`
    mutation updateStudent($input: StudentUpdateInput!) {
  updateStudent(input: $input) {
    ...StudentFields
  }
}
    ${StudentFieldsFragmentDoc}`;
export type UpdateStudentMutationFn = Apollo.MutationFunction<UpdateStudentMutation, UpdateStudentMutationVariables>;

/**
 * __useUpdateStudentMutation__
 *
 * To run a mutation, you first call `useUpdateStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentMutation, { data, loading, error }] = useUpdateStudentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStudentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStudentMutation, UpdateStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStudentMutation, UpdateStudentMutationVariables>(UpdateStudentDocument, options);
      }
export type UpdateStudentMutationHookResult = ReturnType<typeof useUpdateStudentMutation>;
export type UpdateStudentMutationResult = Apollo.MutationResult<UpdateStudentMutation>;
export type UpdateStudentMutationOptions = Apollo.BaseMutationOptions<UpdateStudentMutation, UpdateStudentMutationVariables>;
export const DeleteStudentDocument = gql`
    mutation deleteStudent($id: Int!) {
  deleteStudent(id: $id) {
    ssoAccountId
    currentDBCortexId
    otherDBCortexId
  }
}
    `;
export type DeleteStudentMutationFn = Apollo.MutationFunction<DeleteStudentMutation, DeleteStudentMutationVariables>;

/**
 * __useDeleteStudentMutation__
 *
 * To run a mutation, you first call `useDeleteStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudentMutation, { data, loading, error }] = useDeleteStudentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStudentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStudentMutation, DeleteStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStudentMutation, DeleteStudentMutationVariables>(DeleteStudentDocument, options);
      }
export type DeleteStudentMutationHookResult = ReturnType<typeof useDeleteStudentMutation>;
export type DeleteStudentMutationResult = Apollo.MutationResult<DeleteStudentMutation>;
export type DeleteStudentMutationOptions = Apollo.BaseMutationOptions<DeleteStudentMutation, DeleteStudentMutationVariables>;
export const ExportAllStudentsDocument = gql`
    query exportAllStudents {
  exportAllStudents
}
    `;

/**
 * __useExportAllStudentsQuery__
 *
 * To run a query within a React component, call `useExportAllStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportAllStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportAllStudentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportAllStudentsQuery(baseOptions?: Apollo.QueryHookOptions<ExportAllStudentsQuery, ExportAllStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportAllStudentsQuery, ExportAllStudentsQueryVariables>(ExportAllStudentsDocument, options);
      }
export function useExportAllStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportAllStudentsQuery, ExportAllStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportAllStudentsQuery, ExportAllStudentsQueryVariables>(ExportAllStudentsDocument, options);
        }
export type ExportAllStudentsQueryHookResult = ReturnType<typeof useExportAllStudentsQuery>;
export type ExportAllStudentsLazyQueryHookResult = ReturnType<typeof useExportAllStudentsLazyQuery>;
export type ExportAllStudentsQueryResult = Apollo.QueryResult<ExportAllStudentsQuery, ExportAllStudentsQueryVariables>;
export function refetchExportAllStudentsQuery(variables?: ExportAllStudentsQueryVariables) {
      return { query: ExportAllStudentsDocument, variables: variables }
    }
export const CortexConfigurationDocument = gql`
    query cortexConfiguration {
  getSystemConfiguration {
    system {
      activeWeeks
      viewableWeeks
      currentWeek
      isTimesheetProcessorShowCurrentPeriod
      term
      year
      isWeek11Enabled
      numHolidayWeeks
      timesheetRanges {
        week
        start
        end
      }
      firstTimesheetDueDate
      nextTimesheetDueWeek
      nextTimesheetDueDateTime
      isTimesheetDueThisWeek
      memos
    }
    user {
      modifiableWeeks
      isGracePeriodMode
    }
  }
}
    `;

/**
 * __useCortexConfigurationQuery__
 *
 * To run a query within a React component, call `useCortexConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCortexConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCortexConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useCortexConfigurationQuery(baseOptions?: Apollo.QueryHookOptions<CortexConfigurationQuery, CortexConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CortexConfigurationQuery, CortexConfigurationQueryVariables>(CortexConfigurationDocument, options);
      }
export function useCortexConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CortexConfigurationQuery, CortexConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CortexConfigurationQuery, CortexConfigurationQueryVariables>(CortexConfigurationDocument, options);
        }
export type CortexConfigurationQueryHookResult = ReturnType<typeof useCortexConfigurationQuery>;
export type CortexConfigurationLazyQueryHookResult = ReturnType<typeof useCortexConfigurationLazyQuery>;
export type CortexConfigurationQueryResult = Apollo.QueryResult<CortexConfigurationQuery, CortexConfigurationQueryVariables>;
export function refetchCortexConfigurationQuery(variables?: CortexConfigurationQueryVariables) {
      return { query: CortexConfigurationDocument, variables: variables }
    }
export const GetTimeMachineDatabasesDocument = gql`
    query getTimeMachineDatabases {
  getTimeMachineDatabases
}
    `;

/**
 * __useGetTimeMachineDatabasesQuery__
 *
 * To run a query within a React component, call `useGetTimeMachineDatabasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeMachineDatabasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeMachineDatabasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTimeMachineDatabasesQuery(baseOptions?: Apollo.QueryHookOptions<GetTimeMachineDatabasesQuery, GetTimeMachineDatabasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimeMachineDatabasesQuery, GetTimeMachineDatabasesQueryVariables>(GetTimeMachineDatabasesDocument, options);
      }
export function useGetTimeMachineDatabasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimeMachineDatabasesQuery, GetTimeMachineDatabasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimeMachineDatabasesQuery, GetTimeMachineDatabasesQueryVariables>(GetTimeMachineDatabasesDocument, options);
        }
export type GetTimeMachineDatabasesQueryHookResult = ReturnType<typeof useGetTimeMachineDatabasesQuery>;
export type GetTimeMachineDatabasesLazyQueryHookResult = ReturnType<typeof useGetTimeMachineDatabasesLazyQuery>;
export type GetTimeMachineDatabasesQueryResult = Apollo.QueryResult<GetTimeMachineDatabasesQuery, GetTimeMachineDatabasesQueryVariables>;
export function refetchGetTimeMachineDatabasesQuery(variables?: GetTimeMachineDatabasesQueryVariables) {
      return { query: GetTimeMachineDatabasesDocument, variables: variables }
    }
export const TeachersDocument = gql`
    query teachers($filter: TeacherFilterInput) {
  getTeachers(filter: $filter) {
    ...TeacherFields
  }
}
    ${TeacherFieldsFragmentDoc}`;

/**
 * __useTeachersQuery__
 *
 * To run a query within a React component, call `useTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeachersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeachersQuery(baseOptions?: Apollo.QueryHookOptions<TeachersQuery, TeachersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeachersQuery, TeachersQueryVariables>(TeachersDocument, options);
      }
export function useTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeachersQuery, TeachersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeachersQuery, TeachersQueryVariables>(TeachersDocument, options);
        }
export type TeachersQueryHookResult = ReturnType<typeof useTeachersQuery>;
export type TeachersLazyQueryHookResult = ReturnType<typeof useTeachersLazyQuery>;
export type TeachersQueryResult = Apollo.QueryResult<TeachersQuery, TeachersQueryVariables>;
export function refetchTeachersQuery(variables?: TeachersQueryVariables) {
      return { query: TeachersDocument, variables: variables }
    }
export const UpdateTeacherDocument = gql`
    mutation updateTeacher($input: TeacherUpdateInput!) {
  updateTeacher(input: $input) {
    ...TeacherFields
  }
}
    ${TeacherFieldsFragmentDoc}`;
export type UpdateTeacherMutationFn = Apollo.MutationFunction<UpdateTeacherMutation, UpdateTeacherMutationVariables>;

/**
 * __useUpdateTeacherMutation__
 *
 * To run a mutation, you first call `useUpdateTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeacherMutation, { data, loading, error }] = useUpdateTeacherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeacherMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeacherMutation, UpdateTeacherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeacherMutation, UpdateTeacherMutationVariables>(UpdateTeacherDocument, options);
      }
export type UpdateTeacherMutationHookResult = ReturnType<typeof useUpdateTeacherMutation>;
export type UpdateTeacherMutationResult = Apollo.MutationResult<UpdateTeacherMutation>;
export type UpdateTeacherMutationOptions = Apollo.BaseMutationOptions<UpdateTeacherMutation, UpdateTeacherMutationVariables>;
export const NewTeacherDocument = gql`
    mutation newTeacher($input: TeacherNewInput!) {
  newTeacher(input: $input) {
    ...TeacherFields
  }
}
    ${TeacherFieldsFragmentDoc}`;
export type NewTeacherMutationFn = Apollo.MutationFunction<NewTeacherMutation, NewTeacherMutationVariables>;

/**
 * __useNewTeacherMutation__
 *
 * To run a mutation, you first call `useNewTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newTeacherMutation, { data, loading, error }] = useNewTeacherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewTeacherMutation(baseOptions?: Apollo.MutationHookOptions<NewTeacherMutation, NewTeacherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewTeacherMutation, NewTeacherMutationVariables>(NewTeacherDocument, options);
      }
export type NewTeacherMutationHookResult = ReturnType<typeof useNewTeacherMutation>;
export type NewTeacherMutationResult = Apollo.MutationResult<NewTeacherMutation>;
export type NewTeacherMutationOptions = Apollo.BaseMutationOptions<NewTeacherMutation, NewTeacherMutationVariables>;
export const DeleteTeacherDocument = gql`
    mutation deleteTeacher($id: Int!) {
  deleteTeacher(id: $id) {
    ssoAccountId
    currentDBCortexId
    otherDBCortexId
  }
}
    `;
export type DeleteTeacherMutationFn = Apollo.MutationFunction<DeleteTeacherMutation, DeleteTeacherMutationVariables>;

/**
 * __useDeleteTeacherMutation__
 *
 * To run a mutation, you first call `useDeleteTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeacherMutation, { data, loading, error }] = useDeleteTeacherMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeacherMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeacherMutation, DeleteTeacherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeacherMutation, DeleteTeacherMutationVariables>(DeleteTeacherDocument, options);
      }
export type DeleteTeacherMutationHookResult = ReturnType<typeof useDeleteTeacherMutation>;
export type DeleteTeacherMutationResult = Apollo.MutationResult<DeleteTeacherMutation>;
export type DeleteTeacherMutationOptions = Apollo.BaseMutationOptions<DeleteTeacherMutation, DeleteTeacherMutationVariables>;
export const PayRatesDocument = gql`
    query payRates($teacherId: Int!) {
  getPayRates(teacherId: $teacherId) {
    ...PayRatesFields
  }
}
    ${PayRatesFieldsFragmentDoc}`;

/**
 * __usePayRatesQuery__
 *
 * To run a query within a React component, call `usePayRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayRatesQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function usePayRatesQuery(baseOptions: Apollo.QueryHookOptions<PayRatesQuery, PayRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayRatesQuery, PayRatesQueryVariables>(PayRatesDocument, options);
      }
export function usePayRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayRatesQuery, PayRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayRatesQuery, PayRatesQueryVariables>(PayRatesDocument, options);
        }
export type PayRatesQueryHookResult = ReturnType<typeof usePayRatesQuery>;
export type PayRatesLazyQueryHookResult = ReturnType<typeof usePayRatesLazyQuery>;
export type PayRatesQueryResult = Apollo.QueryResult<PayRatesQuery, PayRatesQueryVariables>;
export function refetchPayRatesQuery(variables?: PayRatesQueryVariables) {
      return { query: PayRatesDocument, variables: variables }
    }
export const UpdatePayRatesDocument = gql`
    mutation updatePayRates($teacherId: Int!, $input: TeacherPayRatesInput!) {
  updatePayRates(teacherId: $teacherId, input: $input) {
    ...PayRatesFields
  }
}
    ${PayRatesFieldsFragmentDoc}`;
export type UpdatePayRatesMutationFn = Apollo.MutationFunction<UpdatePayRatesMutation, UpdatePayRatesMutationVariables>;

/**
 * __useUpdatePayRatesMutation__
 *
 * To run a mutation, you first call `useUpdatePayRatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayRatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayRatesMutation, { data, loading, error }] = useUpdatePayRatesMutation({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayRatesMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePayRatesMutation, UpdatePayRatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePayRatesMutation, UpdatePayRatesMutationVariables>(UpdatePayRatesDocument, options);
      }
export type UpdatePayRatesMutationHookResult = ReturnType<typeof useUpdatePayRatesMutation>;
export type UpdatePayRatesMutationResult = Apollo.MutationResult<UpdatePayRatesMutation>;
export type UpdatePayRatesMutationOptions = Apollo.BaseMutationOptions<UpdatePayRatesMutation, UpdatePayRatesMutationVariables>;
export const ExportAllTeachersDocument = gql`
    query exportAllTeachers {
  exportAllTeachers
}
    `;

/**
 * __useExportAllTeachersQuery__
 *
 * To run a query within a React component, call `useExportAllTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportAllTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportAllTeachersQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportAllTeachersQuery(baseOptions?: Apollo.QueryHookOptions<ExportAllTeachersQuery, ExportAllTeachersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportAllTeachersQuery, ExportAllTeachersQueryVariables>(ExportAllTeachersDocument, options);
      }
export function useExportAllTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportAllTeachersQuery, ExportAllTeachersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportAllTeachersQuery, ExportAllTeachersQueryVariables>(ExportAllTeachersDocument, options);
        }
export type ExportAllTeachersQueryHookResult = ReturnType<typeof useExportAllTeachersQuery>;
export type ExportAllTeachersLazyQueryHookResult = ReturnType<typeof useExportAllTeachersLazyQuery>;
export type ExportAllTeachersQueryResult = Apollo.QueryResult<ExportAllTeachersQuery, ExportAllTeachersQueryVariables>;
export function refetchExportAllTeachersQuery(variables?: ExportAllTeachersQueryVariables) {
      return { query: ExportAllTeachersDocument, variables: variables }
    }
export const NewTeacherClassLinkDocument = gql`
    mutation newTeacherClassLink($input: TeacherClassLinkNewInput!) {
  newTeacherClassLink(input: $input) {
    ...TeacherClassLinkFields
  }
}
    ${TeacherClassLinkFieldsFragmentDoc}`;
export type NewTeacherClassLinkMutationFn = Apollo.MutationFunction<NewTeacherClassLinkMutation, NewTeacherClassLinkMutationVariables>;

/**
 * __useNewTeacherClassLinkMutation__
 *
 * To run a mutation, you first call `useNewTeacherClassLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewTeacherClassLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newTeacherClassLinkMutation, { data, loading, error }] = useNewTeacherClassLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewTeacherClassLinkMutation(baseOptions?: Apollo.MutationHookOptions<NewTeacherClassLinkMutation, NewTeacherClassLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewTeacherClassLinkMutation, NewTeacherClassLinkMutationVariables>(NewTeacherClassLinkDocument, options);
      }
export type NewTeacherClassLinkMutationHookResult = ReturnType<typeof useNewTeacherClassLinkMutation>;
export type NewTeacherClassLinkMutationResult = Apollo.MutationResult<NewTeacherClassLinkMutation>;
export type NewTeacherClassLinkMutationOptions = Apollo.BaseMutationOptions<NewTeacherClassLinkMutation, NewTeacherClassLinkMutationVariables>;
export const UpdateTeacherClassLinkDocument = gql`
    mutation updateTeacherClassLink($input: TeacherClassLinkUpdateInput!) {
  updateTeacherClassLink(input: $input) {
    ...TeacherClassLinkFields
  }
}
    ${TeacherClassLinkFieldsFragmentDoc}`;
export type UpdateTeacherClassLinkMutationFn = Apollo.MutationFunction<UpdateTeacherClassLinkMutation, UpdateTeacherClassLinkMutationVariables>;

/**
 * __useUpdateTeacherClassLinkMutation__
 *
 * To run a mutation, you first call `useUpdateTeacherClassLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeacherClassLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeacherClassLinkMutation, { data, loading, error }] = useUpdateTeacherClassLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeacherClassLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeacherClassLinkMutation, UpdateTeacherClassLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeacherClassLinkMutation, UpdateTeacherClassLinkMutationVariables>(UpdateTeacherClassLinkDocument, options);
      }
export type UpdateTeacherClassLinkMutationHookResult = ReturnType<typeof useUpdateTeacherClassLinkMutation>;
export type UpdateTeacherClassLinkMutationResult = Apollo.MutationResult<UpdateTeacherClassLinkMutation>;
export type UpdateTeacherClassLinkMutationOptions = Apollo.BaseMutationOptions<UpdateTeacherClassLinkMutation, UpdateTeacherClassLinkMutationVariables>;
export const DeleteTeacherClassLinkDocument = gql`
    mutation deleteTeacherClassLink($id: Int!) {
  deleteTeacherClassLink(id: $id) {
    ...TeacherClassLinkFields
  }
}
    ${TeacherClassLinkFieldsFragmentDoc}`;
export type DeleteTeacherClassLinkMutationFn = Apollo.MutationFunction<DeleteTeacherClassLinkMutation, DeleteTeacherClassLinkMutationVariables>;

/**
 * __useDeleteTeacherClassLinkMutation__
 *
 * To run a mutation, you first call `useDeleteTeacherClassLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeacherClassLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeacherClassLinkMutation, { data, loading, error }] = useDeleteTeacherClassLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeacherClassLinkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeacherClassLinkMutation, DeleteTeacherClassLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeacherClassLinkMutation, DeleteTeacherClassLinkMutationVariables>(DeleteTeacherClassLinkDocument, options);
      }
export type DeleteTeacherClassLinkMutationHookResult = ReturnType<typeof useDeleteTeacherClassLinkMutation>;
export type DeleteTeacherClassLinkMutationResult = Apollo.MutationResult<DeleteTeacherClassLinkMutation>;
export type DeleteTeacherClassLinkMutationOptions = Apollo.BaseMutationOptions<DeleteTeacherClassLinkMutation, DeleteTeacherClassLinkMutationVariables>;
export const SelfClassesDocument = gql`
    query selfClasses {
  getSelf {
    id
    teacherClassLinks {
      ...TeacherClassLinkFields
      class {
        ...ClassFields
      }
    }
  }
}
    ${TeacherClassLinkFieldsFragmentDoc}
${ClassFieldsFragmentDoc}`;

/**
 * __useSelfClassesQuery__
 *
 * To run a query within a React component, call `useSelfClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfClassesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfClassesQuery(baseOptions?: Apollo.QueryHookOptions<SelfClassesQuery, SelfClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfClassesQuery, SelfClassesQueryVariables>(SelfClassesDocument, options);
      }
export function useSelfClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfClassesQuery, SelfClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfClassesQuery, SelfClassesQueryVariables>(SelfClassesDocument, options);
        }
export type SelfClassesQueryHookResult = ReturnType<typeof useSelfClassesQuery>;
export type SelfClassesLazyQueryHookResult = ReturnType<typeof useSelfClassesLazyQuery>;
export type SelfClassesQueryResult = Apollo.QueryResult<SelfClassesQuery, SelfClassesQueryVariables>;
export function refetchSelfClassesQuery(variables?: SelfClassesQueryVariables) {
      return { query: SelfClassesDocument, variables: variables }
    }
export const TimesheetsDocument = gql`
    query timesheets($weeks: [Week!]!) {
  getTimesheets(weeks: $weeks) {
    ...TimesheetFields
  }
}
    ${TimesheetFieldsFragmentDoc}`;

/**
 * __useTimesheetsQuery__
 *
 * To run a query within a React component, call `useTimesheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetsQuery({
 *   variables: {
 *      weeks: // value for 'weeks'
 *   },
 * });
 */
export function useTimesheetsQuery(baseOptions: Apollo.QueryHookOptions<TimesheetsQuery, TimesheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimesheetsQuery, TimesheetsQueryVariables>(TimesheetsDocument, options);
      }
export function useTimesheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimesheetsQuery, TimesheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimesheetsQuery, TimesheetsQueryVariables>(TimesheetsDocument, options);
        }
export type TimesheetsQueryHookResult = ReturnType<typeof useTimesheetsQuery>;
export type TimesheetsLazyQueryHookResult = ReturnType<typeof useTimesheetsLazyQuery>;
export type TimesheetsQueryResult = Apollo.QueryResult<TimesheetsQuery, TimesheetsQueryVariables>;
export function refetchTimesheetsQuery(variables?: TimesheetsQueryVariables) {
      return { query: TimesheetsDocument, variables: variables }
    }
export const TimesheetSummaryDocument = gql`
    query timesheetSummary($isDeltaMode: Boolean, $isMarkAsViewed: Boolean, $isShowAll: Boolean) {
  timesheetSummary(
    isDeltaMode: $isDeltaMode
    isMarkAsViewed: $isMarkAsViewed
    isShowAll: $isShowAll
  )
}
    `;

/**
 * __useTimesheetSummaryQuery__
 *
 * To run a query within a React component, call `useTimesheetSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetSummaryQuery({
 *   variables: {
 *      isDeltaMode: // value for 'isDeltaMode'
 *      isMarkAsViewed: // value for 'isMarkAsViewed'
 *      isShowAll: // value for 'isShowAll'
 *   },
 * });
 */
export function useTimesheetSummaryQuery(baseOptions?: Apollo.QueryHookOptions<TimesheetSummaryQuery, TimesheetSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimesheetSummaryQuery, TimesheetSummaryQueryVariables>(TimesheetSummaryDocument, options);
      }
export function useTimesheetSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimesheetSummaryQuery, TimesheetSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimesheetSummaryQuery, TimesheetSummaryQueryVariables>(TimesheetSummaryDocument, options);
        }
export type TimesheetSummaryQueryHookResult = ReturnType<typeof useTimesheetSummaryQuery>;
export type TimesheetSummaryLazyQueryHookResult = ReturnType<typeof useTimesheetSummaryLazyQuery>;
export type TimesheetSummaryQueryResult = Apollo.QueryResult<TimesheetSummaryQuery, TimesheetSummaryQueryVariables>;
export function refetchTimesheetSummaryQuery(variables?: TimesheetSummaryQueryVariables) {
      return { query: TimesheetSummaryDocument, variables: variables }
    }
export const ExportTimesheetsDocument = gql`
    query exportTimesheets($weeks: [Week!]!, $teacherId: Int) {
  exportTimesheets(weeks: $weeks, teacherId: $teacherId)
}
    `;

/**
 * __useExportTimesheetsQuery__
 *
 * To run a query within a React component, call `useExportTimesheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportTimesheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportTimesheetsQuery({
 *   variables: {
 *      weeks: // value for 'weeks'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useExportTimesheetsQuery(baseOptions: Apollo.QueryHookOptions<ExportTimesheetsQuery, ExportTimesheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportTimesheetsQuery, ExportTimesheetsQueryVariables>(ExportTimesheetsDocument, options);
      }
export function useExportTimesheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportTimesheetsQuery, ExportTimesheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportTimesheetsQuery, ExportTimesheetsQueryVariables>(ExportTimesheetsDocument, options);
        }
export type ExportTimesheetsQueryHookResult = ReturnType<typeof useExportTimesheetsQuery>;
export type ExportTimesheetsLazyQueryHookResult = ReturnType<typeof useExportTimesheetsLazyQuery>;
export type ExportTimesheetsQueryResult = Apollo.QueryResult<ExportTimesheetsQuery, ExportTimesheetsQueryVariables>;
export function refetchExportTimesheetsQuery(variables?: ExportTimesheetsQueryVariables) {
      return { query: ExportTimesheetsDocument, variables: variables }
    }
export const ResetViewStatusDocument = gql`
    mutation resetViewStatus($teacherId: Int) {
  resetViewStatus(teacherId: $teacherId) {
    ...TeacherFields
  }
}
    ${TeacherFieldsFragmentDoc}`;
export type ResetViewStatusMutationFn = Apollo.MutationFunction<ResetViewStatusMutation, ResetViewStatusMutationVariables>;

/**
 * __useResetViewStatusMutation__
 *
 * To run a mutation, you first call `useResetViewStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetViewStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetViewStatusMutation, { data, loading, error }] = useResetViewStatusMutation({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useResetViewStatusMutation(baseOptions?: Apollo.MutationHookOptions<ResetViewStatusMutation, ResetViewStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetViewStatusMutation, ResetViewStatusMutationVariables>(ResetViewStatusDocument, options);
      }
export type ResetViewStatusMutationHookResult = ReturnType<typeof useResetViewStatusMutation>;
export type ResetViewStatusMutationResult = Apollo.MutationResult<ResetViewStatusMutation>;
export type ResetViewStatusMutationOptions = Apollo.BaseMutationOptions<ResetViewStatusMutation, ResetViewStatusMutationVariables>;
export const NewAdjustmentDocument = gql`
    mutation newAdjustment($input: AdjustmentNewInput!) {
  newAdjustment(input: $input) {
    ...ExtraActivityFields
  }
}
    ${ExtraActivityFieldsFragmentDoc}`;
export type NewAdjustmentMutationFn = Apollo.MutationFunction<NewAdjustmentMutation, NewAdjustmentMutationVariables>;

/**
 * __useNewAdjustmentMutation__
 *
 * To run a mutation, you first call `useNewAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newAdjustmentMutation, { data, loading, error }] = useNewAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewAdjustmentMutation(baseOptions?: Apollo.MutationHookOptions<NewAdjustmentMutation, NewAdjustmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewAdjustmentMutation, NewAdjustmentMutationVariables>(NewAdjustmentDocument, options);
      }
export type NewAdjustmentMutationHookResult = ReturnType<typeof useNewAdjustmentMutation>;
export type NewAdjustmentMutationResult = Apollo.MutationResult<NewAdjustmentMutation>;
export type NewAdjustmentMutationOptions = Apollo.BaseMutationOptions<NewAdjustmentMutation, NewAdjustmentMutationVariables>;
export const ConfirmTimesheetDocument = gql`
    mutation confirmTimesheet {
  confirmTimesheet {
    ...TeacherFields
  }
}
    ${TeacherFieldsFragmentDoc}`;
export type ConfirmTimesheetMutationFn = Apollo.MutationFunction<ConfirmTimesheetMutation, ConfirmTimesheetMutationVariables>;

/**
 * __useConfirmTimesheetMutation__
 *
 * To run a mutation, you first call `useConfirmTimesheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmTimesheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmTimesheetMutation, { data, loading, error }] = useConfirmTimesheetMutation({
 *   variables: {
 *   },
 * });
 */
export function useConfirmTimesheetMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmTimesheetMutation, ConfirmTimesheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmTimesheetMutation, ConfirmTimesheetMutationVariables>(ConfirmTimesheetDocument, options);
      }
export type ConfirmTimesheetMutationHookResult = ReturnType<typeof useConfirmTimesheetMutation>;
export type ConfirmTimesheetMutationResult = Apollo.MutationResult<ConfirmTimesheetMutation>;
export type ConfirmTimesheetMutationOptions = Apollo.BaseMutationOptions<ConfirmTimesheetMutation, ConfirmTimesheetMutationVariables>;
export const UnconfirmTimesheetDocument = gql`
    mutation unconfirmTimesheet {
  unconfirmTimesheet {
    ...TeacherFields
  }
}
    ${TeacherFieldsFragmentDoc}`;
export type UnconfirmTimesheetMutationFn = Apollo.MutationFunction<UnconfirmTimesheetMutation, UnconfirmTimesheetMutationVariables>;

/**
 * __useUnconfirmTimesheetMutation__
 *
 * To run a mutation, you first call `useUnconfirmTimesheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnconfirmTimesheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unconfirmTimesheetMutation, { data, loading, error }] = useUnconfirmTimesheetMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnconfirmTimesheetMutation(baseOptions?: Apollo.MutationHookOptions<UnconfirmTimesheetMutation, UnconfirmTimesheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnconfirmTimesheetMutation, UnconfirmTimesheetMutationVariables>(UnconfirmTimesheetDocument, options);
      }
export type UnconfirmTimesheetMutationHookResult = ReturnType<typeof useUnconfirmTimesheetMutation>;
export type UnconfirmTimesheetMutationResult = Apollo.MutationResult<UnconfirmTimesheetMutation>;
export type UnconfirmTimesheetMutationOptions = Apollo.BaseMutationOptions<UnconfirmTimesheetMutation, UnconfirmTimesheetMutationVariables>;