import { Button, message, Popconfirm, Tooltip } from "antd";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import styled from "styled-components";
import {
  ClassesAttendanceRecordsQuery,
  Week,
} from "../../../generated/graphql";
import { DATE_NO_YEAR_FORMAT } from "../../../util/constants";
import { InitiatedContext } from "../ClassAttendanceCard";
import { BookOutlined } from "@ant-design/icons";
import { DeleteOutlined } from "@ant-design/icons";
import { StatusText } from "../CommonStyles/week";

export interface Column extends ColumnType<Column> {
  dataIndex: string;
  editable?: boolean;
}

const commonColumn: ColumnType<Column> = {
  width: 125,
};

export const columns: Column[] = [
  {
    ...commonColumn,
    title: "Student",
    dataIndex: "student",
    fixed: "left",
  },
  {
    ...commonColumn,
    title: "Class",
    dataIndex: "class",
    fixed: "left",
    width: 300,
  },
  {
    ...commonColumn,
    title: "Payment",
    dataIndex: "payment",
    fixed: "left",
    width: undefined,
  },
  {
    ...commonColumn,
    title: "Holiday lessons brought forward",
    dataIndex: Week.Wk0,
  },
  {
    ...commonColumn,
    title: "Week 1",
    dataIndex: Week.Wk1,
  },
  {
    ...commonColumn,
    title: "Week 2",
    dataIndex: Week.Wk2,
  },
  {
    ...commonColumn,
    title: "Week 3",
    dataIndex: Week.Wk3,
  },
  {
    ...commonColumn,
    title: "Week 4",
    dataIndex: Week.Wk4,
  },
  {
    ...commonColumn,
    title: "Week 5",
    dataIndex: Week.Wk5,
  },
  {
    ...commonColumn,
    title: "Week 6",
    dataIndex: Week.Wk6,
  },
  {
    ...commonColumn,
    title: "Week 7",
    dataIndex: Week.Wk7,
  },
  {
    ...commonColumn,
    title: "Week 8",
    dataIndex: Week.Wk8,
  },
  {
    ...commonColumn,
    title: "Week 9",
    dataIndex: Week.Wk9,
  },
  {
    ...commonColumn,
    title: "Week 10",
    dataIndex: Week.Wk10,
  },
  {
    ...commonColumn,
    title: "Week 11",
    dataIndex: Week.Wk11,
  },
  {
    ...commonColumn,
    title: "Week H1",
    dataIndex: Week.WkH1,
  },
  {
    ...commonColumn,
    title: "Week H2",
    dataIndex: Week.WkH2,
  },
  {
    ...commonColumn,
    title: "Week H3",
    dataIndex: Week.WkH3,
  },
  {
    ...commonColumn,
    title: "Week H4",
    dataIndex: Week.WkH4,
  },
  {
    ...commonColumn,
    title: "Week H5",
    dataIndex: Week.WkH5,
  },
  {
    ...commonColumn,
    title: "Week H6",
    dataIndex: Week.WkH6,
  },
  {
    ...commonColumn,
    title: "Week H7",
    dataIndex: Week.WkH7,
  },
  {
    ...commonColumn,
    title: "Week H8",
    dataIndex: Week.WkH8,
  },
  {
    ...commonColumn,
    title: "Week H9",
    dataIndex: Week.WkH9,
  },
  {
    ...commonColumn,
    title: "Week H10",
    dataIndex: Week.WkH10,
  },
  {
    ...commonColumn,
    title: "Holiday lessons for next term",
    dataIndex: Week.WkNextTerm,
  },
  {
    title: "Actions",
    dataIndex: "actions",
    fixed: "right",
  },
];

export type TeacherClassLinks =
  ClassesAttendanceRecordsQuery["getClasses"][0]["teacherClassLinks"];

export type MakeupLessons =
  ClassesAttendanceRecordsQuery["getClasses"][0]["makeupLessons"];

const TaughtClassTeachersText = styled.span`
  font-size: 11px;
`;

const MakeupLessonText = styled.span`
  font-size: 11px;
`;

export const getTableColumns = (
  isClass: boolean,
  viewableWeeks: Week[],
  modifiableWeeks: Week[],
  initiatedContext: InitiatedContext,
  depth: number,
  pastWeeksWithNoAttendances: Week[],
  onCreateMakeupLesson: (week: Week) => void,
  deleteMakeupLessons: (ids: number[]) => Promise<unknown>,
  teacherClassLinks?: TeacherClassLinks,
  makeupLessons?: MakeupLessons
): Column[] =>
  columns
    // Display either the student or class for each row depending on the context the table is shown in.
    .filter((column) => column.dataIndex !== (isClass ? "class" : "student"))
    // Remove columns for the weeks which are not viewable.
    .filter((column) => {
      const week = Object.values(Week).find(
        (week) => week === column.dataIndex
      );
      return (
        !week || viewableWeeks.find((viewableWeek) => viewableWeek === week)
      );
    })
    // Add the teachers who taught the class to the title of the columns for the weeks.
    .map((column) => {
      const week = Object.values(Week).find(
        (week) => week === column.dataIndex
      );
      const newColumn = { ...column };
      if (week) {
        const isActive =
          depth == 0 &&
          initiatedContext === InitiatedContext.Standard &&
          modifiableWeeks.find((activeWeek) => activeWeek === week);
        if (isActive) {
          // Mark week as active if on attendance page and week is active
          newColumn.title = (
            <>
              {newColumn.title} <StatusText color="green">ACTIVE</StatusText>
            </>
          );
        }

        const makeupLessonsForWeek =
          makeupLessons?.filter((makeupLesson) => makeupLesson.week === week) ??
          [];
        if (makeupLessonsForWeek.length > 0) {
          const makeupLessonDateText = makeupLessonsForWeek
            .map((makeupLessonForWeek) =>
              moment(makeupLessonForWeek.lessonDate).format(DATE_NO_YEAR_FORMAT)
            )
            .join(", ");

          newColumn.title = (
            <>
              {newColumn.title}
              <br />
              <MakeupLessonText>
                Makeup: {makeupLessonDateText}{" "}
                <Tooltip title="Delete makeup lesson">
                  <Popconfirm
                    title="Are you really sure you want to delete the makeup lesson for this week?"
                    okButtonProps={{ danger: true }}
                    onConfirm={async (): Promise<void> => {
                      try {
                        await deleteMakeupLessons(
                          makeupLessonsForWeek.map(({ id }) => id)
                        );
                        message.success("Successfully deleted makeup lesson");
                      } catch (error) {
                        message.error(
                          `Failed to delete makeup lesson: ${error.message}`,
                          10
                        );
                      }
                    }}
                  >
                    <Button size="small" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </MakeupLessonText>
            </>
          );
        } else if (
          pastWeeksWithNoAttendances.includes(week) &&
          week !== Week.Wk0 &&
          week !== Week.WkNextTerm &&
          !week.includes("H")
        ) {
          newColumn.title = (
            <>
              {newColumn.title}
              <br />
              <Tooltip title="If the class was cancelled and it was due to teacher absence or all students were absent with notice, a makeup lesson should be conducted. Makeup lessons must be scheduled in advance.">
                <Button
                  size="small"
                  icon={<BookOutlined />}
                  onClick={() => onCreateMakeupLesson(week)}
                >
                  Makeup
                </Button>
              </Tooltip>
            </>
          );
        }

        const teachersTaughtClass =
          teacherClassLinks
            ?.filter((teacherClassLink) =>
              teacherClassLink.pays.find((pay) => pay.week === week)
            )
            .map((teacherClassLink) => teacherClassLink.teacher.firstName) ??
          [];
        if (teachersTaughtClass.length > 0) {
          // Add teachers who taught class to the table headings for the week
          newColumn.title = (
            <>
              {newColumn.title}
              <br />
              <TaughtClassTeachersText>
                {teachersTaughtClass.join(", ")}
              </TaughtClassTeachersText>
            </>
          );
        }
      }
      return newColumn;
    });
