import {
  GlobalSessionState,
  GlobalSessionTypes,
  GlobalSessionActionTypes,
} from "./types";

const initialState: GlobalSessionState = {
  accessToken: null,
};

const globalSessionReducer = (
  state = initialState,
  action: GlobalSessionActionTypes
): GlobalSessionState => {
  switch (action.type) {
    case GlobalSessionTypes.SetAccessToken:
      return { ...state, accessToken: action.payload.accessToken };
    case GlobalSessionTypes.Logout:
      return initialState;
    default:
      return state;
  }
};

export default globalSessionReducer;
