import { ColumnType } from "antd/lib/table";

interface Column extends ColumnType<Column> {
  dataIndex: string;
}

export const columns: Column[] = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "Description / Subject",
    dataIndex: "description",
    width: 400,
  },
  {
    title: "Date / Time",
    dataIndex: "dateTime",
  },
  {
    title: "Week",
    dataIndex: "week",
  },
  {
    title: "Units",
    dataIndex: "units",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Approval",
    dataIndex: "approval",
  },
  {
    title: "Actions",
    dataIndex: "actions",
  },
];
