/**
 * Rounds a floating point number to 2 decimal places.
 * @param num the number
 */
export const round = (num: number): number =>
  Math.round(num * 100 + Number.EPSILON) / 100;

/**
 * Rounds a potentially null number, and then converts that to a fixed decimal point string representation.
 * If the number is null, returns the string "N/A".
 * @param num the number
 * @param dp decimal places
 * @return the final rounded value in string format.
 */
export const roundAndFix = (num?: number | null, dp = 2): string =>
  num !== undefined && num !== null ? round(num).toFixed(dp) : "N/A";
