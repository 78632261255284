import { DatePicker, Button, Tooltip } from "antd";
import { DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Moment } from "moment";
import styled from "styled-components";
import { addToAttendanceData, getParts } from "./attendanceData";
import moment from "moment";
import { DATE_NO_YEAR_FORMAT } from "../../../../../util/constants";
import {
  TimesheetRange,
  useCortexConfigurationQuery,
} from "../../../../../generated/graphql";
import { isDisabledDate } from "../../../../../util/date";

const Row = styled.div`
  display: flex;
`;

const DatePickerInRow = styled(DatePicker)`
  margin-bottom: 5px;
  flex: 1;
`;

const AttendanceInnerDateRow: React.FC<{
  value?: string; // Must be defined, but ? exists to make compiler happy.
  onChange?: (value: string | null) => void; // Must be defined, but ? exists to make compiler happy.
  onRemove: () => void;
  isDisabled?: boolean;
  timesheetRange: TimesheetRange;
}> = ({ value, onChange, onRemove, isDisabled, timesheetRange }) => {
  const { data: cortexConfiguration } = useCortexConfigurationQuery({
    fetchPolicy: "cache-first",
  });

  if (!value || !onChange) {
    // This is not possible as value and onChange are always passed in,
    // but this is to make the parent Form compile.
    return null;
  }

  const {
    attendanceData: [{ extraMins, momentDate }],
  } = getParts(value);

  const onChangeDate = (date: Moment | null) => {
    if (date) {
      onChange(date.format(DATE_NO_YEAR_FORMAT));
    }
  };

  const onAdd30Mins = () => onChange(addToAttendanceData(value, 30));
  const onSubtract30Mins = () => onChange(addToAttendanceData(value, -30));

  // Guessing that the year must be last year if the date is after the current date.
  if (
    cortexConfiguration?.getSystemConfiguration.system.term === 4 &&
    momentDate.isAfter(moment.now())
  ) {
    momentDate.subtract(1, "year");
  }

  return (
    <Row>
      <DatePickerInRow
        allowClear={false}
        format={DATE_NO_YEAR_FORMAT}
        placeholder={DATE_NO_YEAR_FORMAT}
        value={momentDate}
        onChange={onChangeDate}
        disabled={isDisabled}
        disabledDate={(date) => isDisabledDate(date, timesheetRange)}
      />
      <div>
        &nbsp;
        <Tooltip title="Subtract 30 mins">
          <Button
            icon={<MinusOutlined />}
            onClick={onSubtract30Mins}
            disabled={isDisabled}
          />
        </Tooltip>
        &nbsp; {extraMins >= 0 ? "+" : "-"} {Math.abs(extraMins)} mins &nbsp;
        <Tooltip title="Add 30 mins">
          <Button
            icon={<PlusOutlined />}
            onClick={onAdd30Mins}
            disabled={isDisabled}
          />
        </Tooltip>
        &nbsp;
        <Tooltip title="Delete date">
          <Button
            icon={<DeleteOutlined />}
            onClick={onRemove}
            disabled={isDisabled}
          />
        </Tooltip>
        <br />
      </div>
    </Row>
  );
};

export default AttendanceInnerDateRow;
