import { FetchResult } from "@apollo/client";
import { Form, Input, message, Modal, Select, Radio, Alert } from "antd";

import {
  ClassType,
  NewClassMutation,
  useNewClassMutation,
  useNewTeacherClassLinkMutation,
} from "../../../generated/graphql";
import {
  daysSelectOptions,
  teacherRoleSelectOptions,
} from "../../../util/selectOptions";
import { TIME_DISPLAY_FORMAT } from "../../../util/constants";
import TimePickerAutoAccept from "../Time/TimePickerAutoAccept";

const { Option } = Select;

const NewClassModal: React.FC<{
  isVisible: boolean;
  createWithLoggedInTeacher?: boolean;
  onSuccessfulCreate: (klass: FetchResult<NewClassMutation>) => void;
  onCancel: () => void;
}> = ({
  isVisible,
  createWithLoggedInTeacher,
  onSuccessfulCreate,
  onCancel,
}) => {
  const [newClass, { loading }] = useNewClassMutation();
  const [newTeacherClassLink] = useNewTeacherClassLinkMutation();
  const [form] = Form.useForm();

  return (
    <Modal
      visible={isVisible}
      title="New Class"
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={async (): Promise<void> => {
        try {
          const values = await form.validateFields();
          const input = {
            day: values.day,
            type: values.type,
            subject: values.subject,
            startTime: values.startTime,
            endTime: values.endTime,
            grade: values.grade,
            comment: values.comment,
          };
          const klass = await newClass({
            variables: { input },
          });
          if (createWithLoggedInTeacher) {
            if (!klass.data) {
              throw Error(
                "The data received from the new class response was empty. Unable to link teacher to class."
              );
            }
            const role = values.role;
            await newTeacherClassLink({
              variables: {
                input: { classId: klass.data.newClass.id, teachingRole: role },
              },
            });
          }
          onSuccessfulCreate(klass);
          form.resetFields();
        } catch (error) {
          message.error(`Server failed to create class: ${error.message}`, 10);
        }
      }}
    >
      <Form form={form} layout="vertical">
        {createWithLoggedInTeacher && (
          <Form.Item>
            <Alert
              message="Create new class makes a new class, completely from scratch with you as the initial teacher (you can change this later). If you are supposed to be covering, assisting or training with an existing class, or you just became the teacher of an existing class, then please use 'Add existing class' instead."
              type="warning"
              showIcon
            />
          </Form.Item>
        )}
        {createWithLoggedInTeacher && (
          <Form.Item
            name="role"
            label="Your role"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              {teacherRoleSelectOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.displayName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="day"
          label="Day (put any day if the class runs multiple times in a week)"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            {daysSelectOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.displayName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group>
            <Radio value={ClassType.Private}>Private</Radio>
            <Radio value={ClassType.Class}>Class</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="subject"
          label="Subject"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="startTime"
          label="Start Time"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TimePickerAutoAccept
            format={TIME_DISPLAY_FORMAT}
            placeholder={TIME_DISPLAY_FORMAT}
          />
        </Form.Item>
        <Form.Item
          name="endTime"
          label="End Time"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TimePickerAutoAccept
            format={TIME_DISPLAY_FORMAT}
            placeholder={TIME_DISPLAY_FORMAT}
          />
        </Form.Item>
        <Form.Item
          name="grade"
          label="Grade (number only - e.g. 9 for normal class, 9/10 for mixed class)"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="comment" label="Comment (e.g. Sydney Tech)">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewClassModal;
