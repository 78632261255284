import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { DATE_TIME_DISPLAY_FORMAT } from "../../../../../util/constants";
import { FieldType } from "../../../../components/SearchBar";

export interface Column extends ColumnType<Column> {
  dataIndex: string;
  searchBarType?: FieldType;
  editable?: boolean;
}

const commonColumn: ColumnType<Column> = {
  width: 170,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SortData = any;

export const columns: Column[] = [
  {
    ...commonColumn,
    title: "First Name",
    dataIndex: "firstName",
    fixed: "left",
    searchBarType: FieldType.Text,
    editable: true,
    sorter: (a: SortData, b: SortData): number =>
      a.firstName.props.value.localeCompare(b.firstName.props.value),
  },
  {
    ...commonColumn,
    title: "Surname",
    dataIndex: "surname",
    fixed: "left",
    searchBarType: FieldType.Text,
    sorter: (a: SortData, b: SortData): number =>
      a.surname.props.value.localeCompare(b.surname.props.value),
  },
  {
    title: "Profile Picture",
    dataIndex: "profilePicture",
  },
  {
    ...commonColumn,
    title: "The Brain Network ID",
    dataIndex: "theBrainNetworkID",
  },
  {
    ...commonColumn,
    title: "Email",
    dataIndex: "email",
    searchBarType: FieldType.Text,
  },
  {
    ...commonColumn,
    title: "Mobile",
    dataIndex: "mobile",
    searchBarType: FieldType.Text,
  },
  {
    ...commonColumn,
    title: "Subjects",
    dataIndex: "subjects",
    searchBarType: FieldType.Text,
  },
  {
    ...commonColumn,
    title: "Academic Background",
    dataIndex: "academics",
    searchBarType: FieldType.Text,
  },
  {
    ...commonColumn,
    title: "Date of Birth",
    dataIndex: "dateOfBirth",
  },
  {
    ...commonColumn,
    title: "WWC",
    dataIndex: "wwc",
    searchBarType: FieldType.Text,
  },
  {
    ...commonColumn,
    title: "WWC Expiry",
    dataIndex: "wwcExpiry",
  },
  {
    ...commonColumn,
    title: "Languages",
    dataIndex: "languages",
    searchBarType: FieldType.Text,
  },
  {
    ...commonColumn,
    title: "Availabilities",
    dataIndex: "availabilities",
    searchBarType: FieldType.Text,
  },
  {
    ...commonColumn,
    title: "Elevated Privileges",
    dataIndex: "elevatedPrivileges",
  },
  {
    ...commonColumn,
    title: "Secret Notes",
    dataIndex: "secretNotes",
  },
  {
    ...commonColumn,
    title: "Timesheet",
    dataIndex: "timesheetStatus",
  },
  {
    ...commonColumn,
    title: "Grace Period",
    dataIndex: "gracePeriodStatus",
  },
  {
    ...commonColumn,
    title: "Disabled",
    dataIndex: "disableAccount",
  },
  {
    ...commonColumn,
    title: "Date Created",
    dataIndex: "dateCreated",
    sorter: (a: SortData, b: SortData): number =>
      moment(a.dateCreated, DATE_TIME_DISPLAY_FORMAT).unix() -
      moment(b.dateCreated, DATE_TIME_DISPLAY_FORMAT).unix(),
  },
  {
    ...commonColumn,
    title: "Date Modified",
    dataIndex: "dateModified",
    sorter: (a: SortData, b: SortData): number =>
      moment(a.dateModified, DATE_TIME_DISPLAY_FORMAT).unix() -
      moment(b.dateModified, DATE_TIME_DISPLAY_FORMAT).unix(),
  },
  {
    width: 100,
    title: "Actions",
    dataIndex: "actions",
    fixed: "right",
  },
];

export interface SearchFilterField extends Column {
  currentValue: string | null;
}
