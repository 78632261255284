import { TimePicker } from "antd";
import moment, { Moment } from "moment";
import EditableBase from "./components/EditableBase";

const { RangePicker } = TimePicker;

const EditableTimeRange: React.FC<{
  onSave: (newValue: [Moment, Moment]) => void;
  value?: [Moment, Moment] | null;
  timeFormat: string;
  required?: boolean;
  disabled?: boolean;
}> = ({ onSave, value, required, disabled, timeFormat }) => (
  <EditableBase<string>
    onSave={(newValue: string): void => {
      const [startStr, endStr] = newValue.split(" - ");
      const start = moment(startStr, timeFormat);
      const end = moment(endStr, timeFormat);
      onSave([start, end]);
    }}
    value={
      value
        ? value[0].format(timeFormat) + " - " + value[1].format(timeFormat)
        : undefined
    }
    required={required}
    disabled={disabled}
  >
    {(inputRef, save, inputValue): React.ReactNode => {
      const [startStr, endStr] = inputValue?.split(" - ") ?? [
        undefined,
        undefined,
      ];
      const start = moment(startStr, timeFormat);
      const end = moment(endStr, timeFormat);

      return (
        <RangePicker
          showNow={false}
          allowClear={false}
          open={true}
          format={timeFormat}
          ref={inputRef}
          value={[start, end]}
          onChange={(values) => {
            if (!values || !values[0] || !values[1]) {
              return save(null);
            } else {
              return save(
                values[0].format(timeFormat) +
                  " - " +
                  values[1].format(timeFormat)
              );
            }
          }}
          onBlur={() => save()}
        />
      );
    }}
  </EditableBase>
);

export default EditableTimeRange;
