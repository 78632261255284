import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import { useCortexConfigurationQuery } from "../../../generated/graphql";
import EditableBase from "./components/EditableBase";

const EditableDate: React.FC<{
  onSave: (newValue: moment.Moment) => void;
  value?: moment.Moment | null;
  dateFormat: string;
  required?: boolean;
  disabled?: boolean;
  guessYear?: boolean;
  disabledDate?: (date: Moment) => boolean;
}> = ({
  onSave,
  value,
  required,
  disabled,
  dateFormat,
  guessYear,
  disabledDate,
}) => {
  const { data: cortexConfiguration } = useCortexConfigurationQuery({
    fetchPolicy: "cache-first",
  });

  return (
    <EditableBase<string>
      onSave={(newValue: string): void => onSave(moment(newValue, dateFormat))}
      value={value?.format(dateFormat)}
      required={required}
      disabled={disabled}
    >
      {(inputRef, save, inputValue): React.ReactNode => {
        const value = moment(inputValue, dateFormat);
        if (
          guessYear &&
          cortexConfiguration?.getSystemConfiguration.system.term === 4 &&
          value.isAfter(moment.now())
        ) {
          value.subtract(1, "year");
        }

        return (
          <DatePicker
            allowClear={false}
            open={true}
            format={dateFormat}
            placeholder={dateFormat}
            ref={inputRef}
            value={value}
            onChange={(value) => save(value?.format(dateFormat))}
            onBlur={() => save()}
            disabledDate={disabledDate}
          />
        );
      }}
    </EditableBase>
  );
};
export default EditableDate;
