import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Menu } from "antd";
import {
  UserOutlined,
  SettingOutlined,
  AccountBookOutlined,
  IdcardOutlined,
  TeamOutlined,
  DollarOutlined,
  FieldTimeOutlined,
  ReconciliationOutlined,
  ReadOutlined,
  LikeOutlined,
  MessageOutlined,
  PlusSquareOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import Teachers from "./subpages/Teachers";
import CortexLayout from "../../components/CortexLayout";
import Students from "./subpages/Students";
import Classes from "./subpages/Classes";
import TimeMachine from "./subpages/TimeMachine";
import TimesheetProcessor from "./subpages/Timesheets/Processor";
import TimesheetSummary from "./subpages/Timesheets/Summary";
import { ElevatedPrivileges, useSelfQuery } from "../../../generated/graphql";
import useCortexPath from "../../../state/cortexPath";
import InvoicingServiceConnectorPDF from "./subpages/InvoicingServiceConnector/pdf";
import InvoicingServiceConnectorMessage from "./subpages/InvoicingServiceConnector/message";
import Approvals from "./subpages/Approvals";
import { useState } from "react";
import MessageComposeModal, {
  MessageComposeInput,
} from "../../components/Messaging/Compose";
import MessagingTemplates from "./subpages/Messaging/Templates";

const { SubMenu } = Menu;

const Admin: React.FC = () => {
  const cortexPath = useCortexPath();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const database = cortexPath?.params.database;
  const cortexLocation = database?.includes("burwood")
    ? "burwood"
    : "hurstville";
  const { data: self } = useSelfQuery({
    fetchPolicy: "cache-first",
  });
  const [messagingModalInput, setMessagingModalInput] = useState<
    MessageComposeInput | undefined
  >();

  if (!self) {
    return null;
  }

  if (location.pathname === match.url) {
    history.replace(`${match.url}/teachers`);
  }

  const isShowFullPage =
    self.getSelf.elevatedPrivileges !== ElevatedPrivileges.None &&
    !cortexPath?.params.impersonateTeacherId;

  return (
    <CortexLayout
      leftSiderMenuItems={
        isShowFullPage
          ? [
              <Menu.Item
                key={`${match.url}/teachers`}
                icon={<IdcardOutlined />}
                onClick={() => history.push(`${match.url}/teachers`)}
              >
                Teachers
              </Menu.Item>,
              <Menu.Item
                key={`${match.url}/students`}
                icon={<UserOutlined />}
                onClick={() => history.push(`${match.url}/students`)}
              >
                Students
              </Menu.Item>,
              <Menu.Item
                key={`${match.url}/classes`}
                icon={<TeamOutlined />}
                onClick={() => history.push(`${match.url}/classes`)}
              >
                Classes
              </Menu.Item>,
              <Menu.Item
                key={`${match.url}/invoicing`}
                icon={<DollarOutlined />}
                onClick={(): Window | null =>
                  window.open(
                    `${process.env.REACT_APP_LEGACY_SERVICE_URL}/TheBrainNetworkServicesManager/?Cortex${cortexLocation}=thth7246_thebrain_${database}&redirectTo=admin/invoicing/build`,
                    "_blank"
                  )
                }
              >
                Invoicing
              </Menu.Item>,
              <SubMenu
                key={`${match.url}/messaging`}
                icon={<MessageOutlined />}
                title="Messaging"
              >
                <Menu.Item
                  key={`${match.url}/messaging/compose`}
                  icon={<PlusSquareOutlined />}
                  onClick={() =>
                    setMessagingModalInput({
                      phones: [],
                      emails: [],
                      attachments: [],
                    })
                  }
                >
                  Compose
                </Menu.Item>
                ,
                <Menu.Item
                  key={`${match.url}/messaging/templates`}
                  icon={<CopyOutlined />}
                  onClick={() =>
                    history.push(`${match.url}/messaging/templates`)
                  }
                >
                  Templates
                </Menu.Item>
              </SubMenu>,
              <SubMenu
                key={`${match.url}/reports`}
                icon={<AccountBookOutlined />}
                title="Timesheets"
              >
                <Menu.Item
                  key={`${match.url}/timesheets/processor`}
                  icon={<ReconciliationOutlined />}
                  onClick={() =>
                    history.push(`${match.url}/timesheets/processor`)
                  }
                >
                  Processor
                </Menu.Item>
                <Menu.Item
                  key={`${match.url}/timesheets/summary`}
                  icon={<ReadOutlined />}
                  onClick={() =>
                    history.push(`${match.url}/timesheets/summary`)
                  }
                >
                  Summary
                </Menu.Item>
              </SubMenu>,
              <Menu.Item
                key={`${match.url}/approvals`}
                icon={<LikeOutlined />}
                onClick={() => history.push(`${match.url}/approvals`)}
              >
                Approvals
              </Menu.Item>,
              <Menu.Item
                key={`${match.url}/timeMachine`}
                icon={<FieldTimeOutlined />}
                onClick={() => history.push(`${match.url}/timeMachine`)}
              >
                Time Machine
              </Menu.Item>,
              <Menu.Item
                key={`${match.url}/settings`}
                icon={<SettingOutlined />}
                onClick={(): Window | null =>
                  window.open(
                    `${process.env.REACT_APP_LEGACY_SERVICE_URL}/TheBrainNetworkServicesManager/?Cortex${cortexLocation}=thth7246_thebrain_${database}&redirectTo=timesheet/timesheetManager.php`,
                    "_blank"
                  )
                }
              >
                Settings
              </Menu.Item>,
            ]
          : undefined
      }
    >
      <MessageComposeModal
        input={messagingModalInput}
        onClose={() => setMessagingModalInput(undefined)}
      />
      <Switch>
        <Route path={`${match.url}/teachers`} exact component={Teachers} />
        <Route path={`${match.url}/approvals`} exact component={Approvals} />
        {isShowFullPage && (
          <>
            <Route
              path={`${match.url}/timesheets/processor`}
              exact
              component={TimesheetProcessor}
            />
            <Route
              path={`${match.url}/timesheets/summary`}
              exact
              component={TimesheetSummary}
            />
            <Route
              path={`${match.url}/invoicing-service-connector/pdf/:invoiceId`}
              exact
              component={InvoicingServiceConnectorPDF}
            />
            <Route
              path={`${match.url}/invoicing-service-connector/message/:invoiceId`}
              exact
              component={InvoicingServiceConnectorMessage}
            />
            <Route path={`${match.url}/students`} exact component={Students} />
            <Route path={`${match.url}/classes`} exact component={Classes} />
            <Route
              path={`${match.url}/timeMachine`}
              exact
              component={TimeMachine}
            />
            <Route
              path={`${match.url}/messaging/templates`}
              exact
              component={MessagingTemplates}
            />
          </>
        )}
      </Switch>
    </CortexLayout>
  );
};

export default Admin;
