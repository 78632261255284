import moment, { Moment } from "moment";
import {
  AttendanceFieldsFragment,
  Week,
} from "../../../../../generated/graphql";

export interface AttendanceDataParts {
  attendanceData: AttendanceData[];
  comment: string;
}

export interface AttendanceData {
  date: string;
  adjustedDate: string;
  momentDate: Moment;
  subtractCnt: number;
  addCnt: number;
  extraCnt: number;
  extraMins: number;
}

/**
 * Split attendance data string into various useful parts. Assumes string is in valid next-gen format.
 * @param attendanceData attendance data string in the form of something like "23/04, 23/04 | Comment"
 */
export const getParts = (attendanceData: string): AttendanceDataParts => {
  const [fullDateStr, comment] = attendanceData.split("|");

  let result: AttendanceData[] = [];
  if (fullDateStr.trim()) {
    result = fullDateStr.split(",").map((dateStr) => {
      const dateStrTrim = dateStr.trim();
      const dateOnly = dateStrTrim.replace(/[*+]/g, "");
      const momentDate = moment(dateOnly.trim(), "D/M");
      const subtractCnt = dateStrTrim.replace(/[^*]/g, "").length;
      const addCnt = dateStrTrim.replace(/[^+]/g, "").length;
      const extraCnt = addCnt - subtractCnt;

      return {
        date: dateOnly,
        adjustedDate: dateStrTrim,
        momentDate,
        subtractCnt,
        addCnt,
        extraCnt,
        extraMins: extraCnt * 30,
      };
    });
  }

  return { attendanceData: result, comment: comment?.trim() ?? "" };
};

/**
 * Removes * and + from attendanceData string
 * @param attendanceData attendance data string in the form of something like "23/04, 23/04 | Comment"
 * @param minutes minutes to add, must be a multiple of 30
 */
export const addToAttendanceData = (
  attendanceData: string,
  minutes: number
): string => {
  const cnt = minutes / 30;
  if (minutes % 30 !== 0) {
    throw Error("Minutes must be a multiple of 30");
  }
  const {
    attendanceData: [{ date, extraCnt }],
  } = getParts(attendanceData);
  const finalCnt = extraCnt + cnt;

  if (finalCnt > 0) {
    return "+".repeat(finalCnt) + date.trim();
  } else {
    return "*".repeat(-finalCnt) + date.trim();
  }
};

/**
 * Returns a string to be displayed in the attendance table for visited classes
 * Looks like: [Visited classes: 23/04 (Comment1), 24/04 (Comment2)]
 * @param tempAttendances Re
 * @param week
 * @param classDuration
 */
export const getTempAttendanceDataDisplayValue = (
  tempAttendances: AttendanceFieldsFragment["tempAttendances"],
  week: Week,
  classDuration: number
): string => {
  const tempAttendancesStr = tempAttendances
    .map((tempAttendance) => {
      const attendanceDataStr = tempAttendance.weekAttendanceInfos.find(
        (weekAttendanceInfo) => weekAttendanceInfo.week === week
      )!.attendanceData;

      const { attendanceData, comment } = getParts(attendanceDataStr);

      if (attendanceData.length === 0) {
        return null;
      }

      const classDurationDiff =
        (tempAttendance.class.duration - classDuration) * 60;

      const result: string[] = [];
      attendanceData.forEach((attendance) => {
        let value = "";
        if (attendance.adjustedDate.length > 0) {
          value = addToAttendanceData(
            attendance.adjustedDate,
            classDurationDiff
          );
        }
        if (comment.length > 0) {
          if (attendance.adjustedDate.length > 0) {
            value += ` (${comment})`;
          } else {
            value += ` ${comment}`;
          }
        }
        result.push(value);
      });

      return result.join(", ");
    })
    .filter(Boolean)
    .join(", ");

  return tempAttendancesStr.length > 0
    ? `[Visited other: ${tempAttendancesStr}]`
    : "";
};
