import { Button, PageHeader, Typography } from "antd";
import styled from "styled-components";
import { useGetTimeMachineDatabasesQuery } from "../../../../../generated/graphql";

const { Title } = Typography;

const WarningText = styled.div`
  color: red;
`;

const TimeMachine: React.FC = () => {
  const {
    data: timeMachineDatabases,
    loading,
    error,
  } = useGetTimeMachineDatabasesQuery();

  if (loading) {
    return null;
  }

  return (
    <PageHeader title={<Title level={2}>Time Machine</Title>}>
      Cortex Time Machine will allow you to look at databases from previous
      terms on any Cortex instance. Please select a database to switch to. Upon
      switching, a new Cortex tab will open which connects to the selected
      database.
      <br />
      <br />
      <WarningText>
        WARNING: Cortex Invoicing retains invoices across multiple terms for
        approximately 2 years. Please use the current term&apos;s Cortex
        Invoicing to look at or modify invoices from previous terms. DO NOT use
        Cortex Time Machine if you wish to look or modify an invoice from
        previous term(s).
      </WarningText>
      <br />
      Please select a database:
      <br />
      <br />
      {error
        ? error.message
        : timeMachineDatabases?.getTimeMachineDatabases.map((database) => (
            <>
              <Button
                key={database}
                href={`/cortex/${database}`}
                target="_blank"
                type="primary"
              >
                {database}
              </Button>
              <br />
              <br />
            </>
          ))}
    </PageHeader>
  );
};

export default TimeMachine;
