import { useEffect } from "react";
import { Table, message, Radio } from "antd";
import moment from "moment";
import {
  ClassFilterInput,
  UpdateClassMutationVariables,
  useUpdateClassMutation,
  Day,
  ClassType,
  useClassesAttendanceRecordsLazyQuery,
  ClassesAttendanceRecordsQuery,
} from "../../../generated/graphql";
import { columns } from "./columns";
import EditableInput from "../Editable/EditableInput";
import SearchBar, { Field, FieldType } from "../SearchBar";
import EditableDropdown from "../Editable/EditableDropdown";
import EditableTimeRange from "../Editable/EditableTimeRange";
import { daysSelectOptions } from "../../../util/selectOptions";
import {
  DATE_TIME_DISPLAY_FORMAT,
  TIME_DISPLAY_FORMAT,
} from "../../../util/constants";
import styled from "styled-components";

const LimitedHeightDiv = styled.div`
  max-height: 100px;
  overflow: auto;
`;

const ClassesNavigator: React.FC<{
  filter?: ClassFilterInput;
  onSelectRow?: (
    selectedClasses: ClassesAttendanceRecordsQuery["getClasses"][0][]
  ) => void;
  actions?: (
    klass: ClassesAttendanceRecordsQuery["getClasses"][0],
    refetch: () => void
  ) => React.ReactNode;
}> = ({ filter, onSelectRow, actions }) => {
  const [
    getClasses,
    { data, loading, error, variables },
  ] = useClassesAttendanceRecordsLazyQuery();
  const [updateClassMutation] = useUpdateClassMutation();

  useEffect(() => {
    if (error) {
      message.error(`Connection failed: ${error.message}`, 10);
    }
  }, [error]);

  useEffect(() => {
    if (filter) {
      getClasses({ variables: { filter } });
    }
  }, [filter]);

  const saveToGraphQL = async <
    T extends keyof UpdateClassMutationVariables["input"]
  >(
    id: number,
    field: T,
    value: UpdateClassMutationVariables["input"][T]
  ): Promise<void> => {
    try {
      await updateClassMutation({
        variables: { input: { id, [field]: value } },
      });
      message.success("Automatically saved");
    } catch (error) {
      message.error(`Automatic save failed: ${error.message}`, 10);
    }
  };

  const classRows = (data?.getClasses ?? []).map((klass) => ({
    id: klass.id,
    dataIndex: String(klass.id),
    key: klass.id,
    day: (
      <EditableDropdown
        onSave={(value): Promise<void> =>
          saveToGraphQL(klass.id, "day", Day[value as keyof typeof Day])
        }
        value={klass.day}
        selectOptions={daysSelectOptions}
      />
    ),
    time: (
      <EditableTimeRange
        onSave={(value): Promise<void> =>
          saveToGraphQL(klass.id, "time", {
            startTime: value[0],
            endTime: value[1],
          })
        }
        timeFormat={TIME_DISPLAY_FORMAT}
        value={[moment(klass.startTime), moment(klass.endTime)]}
      />
    ),
    grade: (
      <EditableInput
        required
        onSave={(value): Promise<void> =>
          saveToGraphQL(klass.id, "grade", value)
        }
        value={klass.grade}
      />
    ),
    subject: (
      <EditableInput
        required
        onSave={(value): Promise<void> =>
          saveToGraphQL(klass.id, "subject", value)
        }
        value={klass.subject}
      />
    ),
    type: (
      <Radio.Group
        value={klass.type}
        onChange={(event): Promise<void> =>
          saveToGraphQL(klass.id, "type", event.target.value)
        }
      >
        <Radio value={ClassType.Private}>Private</Radio>
        <Radio value={ClassType.Class}>Class</Radio>
      </Radio.Group>
    ),
    comment: (
      <EditableInput
        onSave={(value): Promise<void> =>
          saveToGraphQL(klass.id, "comment", value)
        }
        value={klass.comment}
      />
    ),
    teachers: (
      <LimitedHeightDiv>
        {klass.teacherClassLinks
          .filter((teacherClassLink) => !teacherClassLink.isDeleted)
          .sort((a, b) => a.teacher.fullName.localeCompare(b.teacher.fullName))
          .map(
            (teacherClassLink) =>
              `${teacherClassLink.teacher.fullName} (${teacherClassLink.teachingRole})`
          )
          .join(", ")}
      </LimitedHeightDiv>
    ),
    students: (
      <LimitedHeightDiv>
        {klass.attendances
          .filter((attendance) => !attendance.isDeleted)
          .sort((a, b) => a.student.fullName.localeCompare(b.student.fullName))
          .map((attendance) => attendance.student.fullName)
          .join(", ")}
      </LimitedHeightDiv>
    ),
    dateCreated: moment(klass.dateCreated).format(DATE_TIME_DISPLAY_FORMAT),
    dateModified: moment(klass.dateModified).format(DATE_TIME_DISPLAY_FORMAT),
    actions: actions
      ? actions(klass, () => getClasses({ variables }))
      : undefined,
  }));

  return (
    <>
      <SearchBar
        isLoading={loading}
        fields={columns
          .filter((column) => column.searchBarType !== undefined)
          .map(
            (column): Field => ({
              type: column.searchBarType!,
              name: column.dataIndex,
              displayName: column.title?.toString(),
              selectOptions: column.selectOptions,
            })
          )
          .concat([
            {
              type: FieldType.Text,
              name: "studentName",
              displayName: "Student's Name",
            },
            {
              type: FieldType.Text,
              name: "teacherName",
              displayName: "Teacher's Name",
            },
          ])}
        onSearch={(fieldValues): void => {
          const filter: ClassFilterInput = fieldValues.reduce(
            (variables, fieldValue) => ({
              ...variables,
              [fieldValue.field.name]: fieldValue.value,
            }),
            {}
          );
          getClasses({ variables: { filter } });
        }}
      />
      <Table
        size="small"
        columns={columns.filter(
          (column) =>
            (column.dataIndex !== "actions" || actions != null) &&
            column.dataIndex !== "startTime" &&
            column.dataIndex !== "endTime"
        )}
        dataSource={classRows}
        loading={loading ? { size: "large" } : undefined}
        pagination={false}
        sticky={true}
        scroll={{ x: "max-content" }}
        rowSelection={
          onSelectRow
            ? {
                type: "checkbox",
                onChange: (selectedRowKeys) =>
                  onSelectRow(
                    selectedRowKeys.map(
                      (classId) =>
                        (data?.getClasses ?? []).find(
                          (klass) => klass.id === classId
                        )!
                    )
                  ),
              }
            : undefined
        }
      />
    </>
  );
};

export default ClassesNavigator;
