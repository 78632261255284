import { Modal } from "antd";
import { EntityInput } from "../../../../generated/graphql";
import { wideModalWidth } from "../../CommonStyles/page";
import AuditLogEntryTable from "./components/AuditLogEntryTable";

interface Props {
  entity?: EntityInput;
  teacherId?: number;
  onClose: () => void;
}

const AuditLogViewerModal: React.FC<Props> = ({
  entity,
  teacherId,
  onClose,
}) => {
  const isVisible = entity != null || teacherId != null;

  return (
    <Modal
      width={wideModalWidth}
      visible={isVisible}
      title="Audit Log"
      confirmLoading={false}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      {isVisible && (
        <AuditLogEntryTable entity={entity} teacherId={teacherId} />
      )}
    </Modal>
  );
};

export default AuditLogViewerModal;
