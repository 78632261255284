import styled from "styled-components";
import { Layout, Menu } from "antd";
import { useLocation } from "react-router-dom";

const { Sider } = Layout;

const LeftSider = styled(Sider)`
  overflow: auto;
  left: 0;
  background: #fff;
`;

const LeftSiderMenu: React.FC = (props): JSX.Element => {
  const { children } = props;
  const location = useLocation();

  return (
    <LeftSider collapsible width={210}>
      <Menu mode="inline" selectedKeys={[location.pathname]}>
        {children}
      </Menu>
    </LeftSider>
  );
};

export default LeftSiderMenu;
