import { Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import EditableBase from "./components/EditableBase";
import { CheckOutlined } from "@ant-design/icons";

const EditableInput: React.FC<{
  onSave: (newValue: string) => void;
  value?: string | null;
  required?: boolean;
  disabled?: boolean;
  rows?: number;
  isFocused?: boolean;
}> = ({ onSave, value, required, disabled, rows, isFocused }) => (
  <EditableBase
    onSave={onSave}
    value={value}
    required={required}
    disabled={disabled}
    isFocused={isFocused}
  >
    {(inputRef, save, inputValue, setInputValue): React.ReactNode => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <TextArea
          ref={inputRef}
          onPressEnter={() => save()}
          onBlur={() => save()}
          rows={rows ?? 1}
          value={inputValue ?? ""}
          onChange={(event) => setInputValue(event.currentTarget.value)}
        />
        <Button size="small">
          <CheckOutlined />
        </Button>
      </div>
    )}
  </EditableBase>
);

export default EditableInput;
