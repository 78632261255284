import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { DATE_TIME_DISPLAY_FORMAT } from "../../../../../util/constants";
import { gradeSelectOptions } from "../../../../../util/selectOptions";
import { FieldType, SelectOption } from "../../../../components/SearchBar";

export interface Column extends ColumnType<Column> {
  dataIndex: string;
  searchBarType?: FieldType;
  selectOptions?: SelectOption[];
  editable?: boolean;
}

const commonColumn: ColumnType<Column> = {
  width: 170,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SortData = any;

export const columns: Column[] = [
  {
    ...commonColumn,
    title: "First Name",
    dataIndex: "firstName",
    fixed: "left",
    searchBarType: FieldType.Text,
    editable: true,
    sorter: (a: SortData, b: SortData): number =>
      a.firstName.props.value.localeCompare(b.firstName.props.value),
  },
  {
    ...commonColumn,
    title: "Surname",
    dataIndex: "surname",
    fixed: "left",
    searchBarType: FieldType.Text,
    sorter: (a: SortData, b: SortData): number =>
      a.surname.props.value.localeCompare(b.surname.props.value),
  },
  {
    ...commonColumn,
    title: "The Brain Network ID",
    dataIndex: "theBrainNetworkID",
  },

  {
    ...commonColumn,
    title: "Payment Status",
    dataIndex: "paymentStatus",
    sorter: (a: SortData, b: SortData): number =>
      a.paymentStatus.localeCompare(b.paymentStatus),
  },
  {
    ...commonColumn,
    title: "Gender",
    dataIndex: "gender",
  },
  {
    ...commonColumn,
    title: "Date of Birth",
    dataIndex: "dateOfBirth",
  },
  {
    ...commonColumn,
    title: "Address",
    dataIndex: "address",
  },
  {
    ...commonColumn,
    title: "Grade",
    dataIndex: "grade",
    searchBarType: FieldType.Select,
    selectOptions: gradeSelectOptions,
  },
  {
    ...commonColumn,
    title: "School",
    dataIndex: "school",
    searchBarType: FieldType.Text,
  },
  {
    ...commonColumn,
    title: "Phone",
    dataIndex: "phone",
    searchBarType: FieldType.Text,
  },
  {
    ...commonColumn,
    title: "Phone 2",
    dataIndex: "phone2",
  },
  {
    ...commonColumn,
    title: "Email",
    dataIndex: "email",
    searchBarType: FieldType.Text,
  },
  {
    ...commonColumn,
    title: "Languages",
    dataIndex: "languages",
  },
  {
    ...commonColumn,
    title: "Medical Conditions",
    dataIndex: "medicalConditions",
  },
  {
    ...commonColumn,
    title: "Parent/Guardian's Name",
    dataIndex: "parentGuardianName",
    searchBarType: FieldType.Text,
  },
  {
    ...commonColumn,
    title: "Parent/Guardian's Relationship",
    dataIndex: "parentGuardianRelationship",
  },
  {
    ...commonColumn,
    title: "Parent/Guardian's Phone",
    dataIndex: "parentGuardianPhone",
  },
  {
    ...commonColumn,
    title: "Parent/Guardian's Email",
    dataIndex: "parentGuardianEmail",
  },
  {
    ...commonColumn,
    title: "Parent/Guardian's Name 2",
    dataIndex: "parentGuardianName2",
  },
  {
    ...commonColumn,
    title: "Parent/Guardian's Relationship 2",
    dataIndex: "parentGuardianRelationship2",
  },
  {
    ...commonColumn,
    title: "Parent/Guardian's Phone 2",
    dataIndex: "parentGuardianPhone2",
  },
  {
    ...commonColumn,
    title: "Parent/Guardian's Email 2",
    dataIndex: "parentGuardianEmail2",
  },
  {
    ...commonColumn,
    title: "Notes",
    dataIndex: "notes",
  },
  {
    ...commonColumn,
    title: "Email invoices preferred",
    dataIndex: "isEmailPreferred",
  },
  {
    ...commonColumn,
    title: "Disabled",
    dataIndex: "disableAccount",
  },
  {
    ...commonColumn,
    title: "Date Created",
    dataIndex: "dateCreated",
    sorter: (a: SortData, b: SortData): number =>
      moment(a.dateCreated, DATE_TIME_DISPLAY_FORMAT).unix() -
      moment(b.dateCreated, DATE_TIME_DISPLAY_FORMAT).unix(),
  },
  {
    ...commonColumn,
    title: "Date Modified",
    dataIndex: "dateModified",
    sorter: (a: SortData, b: SortData): number =>
      moment(a.dateModified, DATE_TIME_DISPLAY_FORMAT).unix() -
      moment(b.dateModified, DATE_TIME_DISPLAY_FORMAT).unix(),
  },
  {
    width: 100,
    title: "Actions",
    dataIndex: "actions",
    fixed: "right",
  },
];

export interface SearchFilterField extends Column {
  currentValue: string | null;
}
