import { useEffect, useState } from "react";
import {
  PageHeader,
  Button,
  Typography,
  Checkbox,
  Tooltip,
  Table,
  Popconfirm,
  message,
} from "antd";
import {
  DeleteOutlined,
  RollbackOutlined,
  ExportOutlined,
  UserOutlined,
  PlusOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import moment from "moment";

import {
  UpdateStudentMutationVariables,
  useResetAccountMutation,
  useStudentsLazyQuery,
  useUpdateStudentMutation,
  StudentFilterInput,
  Gender,
  useDeleteStudentMutation,
  NewStudentMutation,
  StudentFieldsFragment,
  useExportAllStudentsLazyQuery,
} from "../../../../../generated/graphql";
import { columns } from "./columns";
import EditableInput from "../../../../components/Editable/EditableInput";
import SearchBar, { FieldType, Field } from "../../../../components/SearchBar";
import EditableDropdown from "../../../../components/Editable/EditableDropdown";
import EditableDate from "../../../../components/Editable/EditableDate";
import NewStudentModal from "./components/NewStudentModal";
import { FetchResult } from "@apollo/client";
import StudentAttendanceModal from "../../../../components/StudentAttendanceModal";
import { InitiatedContext } from "../../../../components/ClassAttendanceCard";
import {
  genderSelectOptions,
  gradeSelectOptions,
} from "../../../../../util/selectOptions";
import {
  DATE_DISPLAY_FORMAT,
  DATE_TIME_DISPLAY_FORMAT,
} from "../../../../../util/constants";
import { paymentStatusDescriptions } from "../../../../../util/descriptions";
import MessageComposeModal, {
  MessageComposeInput,
} from "../../../../components/Messaging/Compose";
import { mapStudentToMessagingModalInput } from "./util";
import download from "downloadjs";

const { Title } = Typography;

const Students: React.FC = () => {
  const [isShowNewStudentModal, setIsShowNewStudentModal] = useState(false);
  const [studentAttendanceModalId, setStudentAttendanceModalId] = useState<
    number | undefined
  >();
  const [messagingModalInput, setMessagingModalInput] = useState<
    MessageComposeInput | undefined
  >();
  const [selectedStudents, setSelectedStudents] = useState<
    StudentFieldsFragment[]
  >([]);
  const [showIsActiveOnly, setShowIsActiveOnly] = useState(true);

  const [
    getStudents,
    { data, loading, error, variables },
  ] = useStudentsLazyQuery();
  const [
    exportAllStudents,
    {
      data: exportStudentsData,
      loading: exportStudentsLoading,
      error: exportStudentsError,
    },
  ] = useExportAllStudentsLazyQuery();
  const [resetAccount] = useResetAccountMutation();
  const [deleteStudent] = useDeleteStudentMutation();
  const [updateStudentMutation] = useUpdateStudentMutation();

  useEffect(() => {
    if (error) {
      message.error(`Connection failed: ${error.message}`, 10);
    }
  }, [error]);

  useEffect(() => {
    if (exportStudentsData) {
      const base64file = exportStudentsData.exportAllStudents;
      download(base64file, "cortex-students.csv");
    }
  }, [exportStudentsData]);

  useEffect(() => {
    if (exportStudentsError) {
      message.error(
        `Export all students failed: ${exportStudentsError.message}`,
        10
      );
    }
  }, [exportStudentsError]);

  const saveToGraphQL = async <
    T extends keyof UpdateStudentMutationVariables["input"]
  >(
    id: number,
    field: T,
    value: UpdateStudentMutationVariables["input"][T]
  ): Promise<void> => {
    try {
      await updateStudentMutation({
        variables: { input: { id, [field]: value } },
      });
      message.success("Automatically saved");
    } catch (error) {
      message.error(`Automatic save failed: ${error.message}`, 10);
    }
  };

  const studentRows = (data?.getStudents ?? [])
    .filter((student) => !showIsActiveOnly || !student.account.isDisabled)
    .map((student) => ({
      id: student.id,
      dataIndex: String(student.id),
      key: student.id,
      firstName: (
        <EditableInput
          required
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "firstName", value)
          }
          value={student.firstName}
        />
      ),
      surname: (
        <EditableInput
          required
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "surname", value)
          }
          value={student.surname}
        />
      ),
      theBrainNetworkID: (
        <EditableInput
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "theBrainNetworkID", value)
          }
          disabled={!student.account.username}
          value={student.account.username}
        />
      ),
      paymentStatus: paymentStatusDescriptions[student.paymentStatus],
      gender: (
        <EditableDropdown
          onSave={(value): Promise<void> => {
            const enumValue =
              value === "Male"
                ? Gender.Male
                : value === "Female"
                ? Gender.Female
                : Gender.Na;
            return saveToGraphQL(student.id, "gender", enumValue);
          }}
          value={student.gender === Gender.Na ? "N/A" : student.gender}
          selectOptions={genderSelectOptions}
        />
      ),
      dateOfBirth: (
        <EditableDate
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "dateOfBirth", value.format("YYYY-MM-DD"))
          }
          dateFormat={DATE_DISPLAY_FORMAT}
          value={moment(student.dateOfBirth, "YYYY-MM-DD")}
        />
      ),
      address: (
        <EditableInput
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "address", value)
          }
          value={student.address}
        />
      ),
      grade: (
        <EditableDropdown
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "grade", value)
          }
          value={student.grade}
          selectOptions={gradeSelectOptions}
        />
      ),
      school: (
        <EditableInput
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "school", value)
          }
          value={student.school}
        />
      ),
      phone: (
        <EditableInput
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "phone", value)
          }
          value={student.phone}
        />
      ),
      phone2: (
        <EditableInput
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "phone2", value)
          }
          value={student.phone2}
        />
      ),
      email: (
        <EditableInput
          required
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "email", value)
          }
          value={student.email}
        />
      ),
      languages: (
        <EditableInput
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "languages", value)
          }
          value={student.languages}
        />
      ),
      medicalConditions: (
        <EditableInput
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "medicalConditions", value)
          }
          value={student.medicalConditions}
        />
      ),
      parentGuardianName: (
        <EditableInput
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "parentGuardianName", value)
          }
          value={student.parentGuardianName}
        />
      ),
      parentGuardianRelationship: (
        <EditableInput
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "parentGuardianRelationship", value)
          }
          value={student.parentGuardianRelationship}
        />
      ),
      parentGuardianPhone: (
        <EditableInput
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "parentGuardianPhone", value)
          }
          value={student.parentGuardianPhone}
        />
      ),
      parentGuardianEmail: (
        <EditableInput
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "parentGuardianEmail", value)
          }
          value={student.parentGuardianEmail}
        />
      ),
      parentGuardianName2: (
        <EditableInput
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "parentGuardianName2", value)
          }
          value={student.parentGuardianName2}
        />
      ),
      parentGuardianRelationship2: (
        <EditableInput
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "parentGuardianRelationship2", value)
          }
          value={student.parentGuardianRelationship2}
        />
      ),
      parentGuardianPhone2: (
        <EditableInput
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "parentGuardianPhone2", value)
          }
          value={student.parentGuardianPhone2}
        />
      ),
      parentGuardianEmail2: (
        <EditableInput
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "parentGuardianEmail2", value)
          }
          value={student.parentGuardianEmail2}
        />
      ),
      notes: (
        <EditableInput
          onSave={(value): Promise<void> =>
            saveToGraphQL(student.id, "notes", value)
          }
          value={student.notes}
        />
      ),
      isEmailPreferred: (
        <Checkbox
          checked={student.isEmailPreferred}
          onChange={(event): Promise<void> =>
            saveToGraphQL(student.id, "isEmailPreferred", event.target.checked)
          }
        />
      ),
      disableAccount: (
        <Checkbox
          checked={student.account.isDisabled}
          onChange={(event): Promise<void> =>
            saveToGraphQL(student.id, "isDisabled", event.target.checked)
          }
        />
      ),
      dateCreated: moment(student.dateCreated).format(DATE_TIME_DISPLAY_FORMAT),
      dateModified: moment(student.dateModified).format(
        DATE_TIME_DISPLAY_FORMAT
      ),
      actions: (
        <>
          <Tooltip title="Attendance">
            <Button
              icon={<UserOutlined />}
              type="primary"
              onClick={(): void => setStudentAttendanceModalId(student.id)}
            />
          </Tooltip>
          <Tooltip title="Message">
            <Button
              icon={<MessageOutlined />}
              onClick={() =>
                setMessagingModalInput(mapStudentToMessagingModalInput(student))
              }
            />
          </Tooltip>
          <Tooltip title="Reset Account">
            <Popconfirm
              title="Are you sure you want to reset this account? The user can reactivate their account again via the login page."
              onConfirm={async (): Promise<void> => {
                try {
                  await resetAccount({
                    variables: { id: student.account.id },
                  });
                  getStudents({ variables });
                  message.success("Student's account successfully reset");
                } catch (error) {
                  message.error(
                    `Failed to reset student's account: ${error.message}`,
                    10
                  );
                }
              }}
            >
              <Button icon={<RollbackOutlined />} />
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Delete Account">
            <Popconfirm
              title="Are you sure you want to delete this account? The account will be completely deleted from The Brain Network."
              okButtonProps={{ danger: true }}
              onConfirm={async (): Promise<void> => {
                try {
                  await deleteStudent({
                    variables: { id: student.id },
                  });
                  getStudents({ variables });
                  message.success("Student's account successfully deleted");
                } catch (error) {
                  message.error(
                    `Failed to delete student's account: ${error.message}`,
                    10
                  );
                }
              }}
            >
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    }));

  return (
    <>
      <PageHeader
        title={<Title level={2}>Students</Title>}
        extra={[
          <Button
            key="1"
            icon={<ExportOutlined />}
            onClick={() => exportAllStudents()}
            loading={exportStudentsLoading}
          >
            Export all students
          </Button>,
          <Button
            key="2"
            icon={<MessageOutlined />}
            onClick={() => {
              const finalMessagingModalInput: MessageComposeInput = {
                attachments: [],
                phones: [],
                emails: [],
              };

              selectedStudents
                .map(mapStudentToMessagingModalInput)
                .forEach((messagingModalInput) => {
                  finalMessagingModalInput.attachments.push(
                    ...messagingModalInput.attachments
                  );
                  finalMessagingModalInput.emails.push(
                    ...messagingModalInput.emails
                  );
                  finalMessagingModalInput.phones.push(
                    ...messagingModalInput.phones
                  );
                });

              setMessagingModalInput(finalMessagingModalInput);
            }}
          >
            Message selected
          </Button>,
          <Button
            key="3"
            icon={<PlusOutlined />}
            type="primary"
            onClick={(): void => setIsShowNewStudentModal(true)}
          >
            New
          </Button>,
        ]}
      >
        <MessageComposeModal
          input={messagingModalInput}
          onClose={() => setMessagingModalInput(undefined)}
        />
        <NewStudentModal
          isVisible={isShowNewStudentModal}
          onSuccessfulCreate={(
            student: FetchResult<NewStudentMutation>
          ): void => {
            const newStudent = student.data?.newStudent;
            if (!newStudent) {
              throw Error("Something went wrong creating student's account");
            }

            const filter = {
              firstName: newStudent.firstName,
              surname: newStudent.surname,
              email: newStudent.email,
              school: newStudent.school,
              phone: newStudent.phone,
              grade: newStudent.grade,
            };
            getStudents({ variables: { filter } });
            setIsShowNewStudentModal(false);
            message.success("Student's account successfully created");
          }}
          onCancel={(): void => setIsShowNewStudentModal(false)}
        />
        <StudentAttendanceModal
          studentId={studentAttendanceModalId}
          onClose={(): void => setStudentAttendanceModalId(undefined)}
          initiatedContext={InitiatedContext.Admin}
        />
        <SearchBar
          isLoading={loading}
          fields={columns
            .filter((column) => column.searchBarType !== undefined)
            .map(
              (column): Field => ({
                type: column.searchBarType!,
                name: column.dataIndex,
                displayName: column.title?.toString(),
                selectOptions: column.selectOptions,
              })
            )
            .concat(
              [
                {
                  type: FieldType.Toggle,
                  name: "isEmailPreferred",
                  displayName: "Show those who prefer email invoices only",
                },
              ],
              [
                {
                  type: FieldType.Toggle,
                  name: "isActive",
                  displayName: "Show active students only",
                  default: true,
                },
              ]
            )}
          onSearch={(fieldValues) => {
            let showIsActive = false;
            const filter: StudentFilterInput = fieldValues.reduce(
              (variables, fieldValue) => {
                if (fieldValue.field.name === "isActive") {
                  showIsActive = Boolean(fieldValue.value);
                  return variables;
                }

                return {
                  ...variables,
                  [fieldValue.field.name]: fieldValue.value,
                };
              },
              {}
            );
            setShowIsActiveOnly(showIsActive);
            getStudents({ variables: { filter } });
          }}
        />
        <Table
          size="small"
          columns={columns}
          dataSource={studentRows}
          loading={loading ? { size: "large" } : undefined}
          pagination={false}
          sticky={true}
          scroll={{ x: "max-content" }}
          rowSelection={{
            type: "checkbox",
            onChange: (selectedRowKeys) =>
              setSelectedStudents(
                selectedRowKeys.map(
                  (studentId) =>
                    (data?.getStudents ?? []).find(
                      (student) => student.id === studentId
                    )!
                )
              ),
          }}
        />
      </PageHeader>
    </>
  );
};

export default Students;
