import { FetchResult } from "@apollo/client";
import { Form, Input, message, Modal, InputNumber, Alert } from "antd";

import {
  NewAdjustmentMutation,
  useNewAdjustmentMutation,
  Week,
} from "../../../../generated/graphql";

const NewAdjustmentModal: React.FC<{
  teacherClassLinkId?: number;
  week: Week;
  onSuccessfulCreate: (klass: FetchResult<NewAdjustmentMutation>) => void;
  onCancel: () => void;
}> = ({ teacherClassLinkId, week, onSuccessfulCreate, onCancel }) => {
  const [newAdjustment, { loading }] = useNewAdjustmentMutation();
  const [form] = Form.useForm();

  return (
    <Modal
      visible={teacherClassLinkId !== undefined}
      title="New Adjustment"
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={async (): Promise<void> => {
        try {
          if (!teacherClassLinkId) {
            return;
          }

          const values = await form.validateFields();
          const input = {
            hoursToSubtract: values.hoursToSubtract,
            reason: values.reason,
            oldAttendanceData: values.datesAndTimesTaught,
            teacherClassLinkId,
            week,
          };

          const adjustment = await newAdjustment({
            variables: { input },
          });
          onSuccessfulCreate(adjustment);

          form.resetFields();
          message.success("Successfully created adjustment");
        } catch (error) {
          message.error(
            `Server failed to create adjustment: ${error.message}`,
            10
          );
        }
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item>
          <Alert
            message={
              <>
                The selected timesheet item should be adjusted if and only if:
                <br />
                <br />➤ You didn&apos;t teach a class for the entire duration
                (e.g. 1hr instead of 2hrs) OR
                <br />➤ Multiple lessons occurred in the week and you
                didn&apos;t teach all of them.
                <br />
                <br /> (When we say &apos;teach&apos; this also includes
                assisting, training etc.)
              </>
            }
            type="warning"
            showIcon
          />
        </Form.Item>
        <Form.Item
          name="hoursToSubtract"
          label="Hours to subtract"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            placeholder="Any real number up to 2 d.p. will suffice."
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          name="reason"
          label="Reason"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Reason for adjusting timesheet." />
        </Form.Item>
        <Form.Item
          name="datesAndTimesTaught"
          label="ALL Dates AND times YOU taught the class this week"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Be careful and input this accurately!" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewAdjustmentModal;
