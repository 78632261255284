import { Transfer, Table } from "antd";
import _ from "lodash";
import { TableRowSelection } from "antd/lib/table/interface";
import { TransferItem, TransferProps } from "antd/lib/transfer";

interface Column {
  dataIndex: string;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: React.FC<any>;
}

interface Props extends TransferProps<TransferItem> {
  leftColumns: Column[];
  rightColumns: Column[];
  loading?: boolean;
}

const TransferTable: React.FC<Props> = ({
  leftColumns,
  rightColumns,
  loading,
  ...restProps
}) => (
  <Transfer {...restProps} showSelectAll={false}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }): React.ReactNode => {
      const columns = direction === "left" ? leftColumns : rightColumns;

      const rowSelection: TableRowSelection<TransferItem> = {
        getCheckboxProps: (item) => ({
          disabled: listDisabled || item.disabled,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key!);
          const diffKeys = selected
            ? _.difference(treeSelectedKeys, listSelectedKeys)
            : _.difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          if (key) {
            onItemSelect(key, selected);
          }
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          loading={loading}
          dataSource={filteredItems}
          size="small"
          style={{ pointerEvents: listDisabled ? "none" : undefined }}
          onRow={({
            key,
            disabled: itemDisabled,
          }): { onClick: () => void } => ({
            onClick: (): void => {
              if (itemDisabled || listDisabled || !key) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

export default TransferTable;
