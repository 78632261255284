import { Button, message, PageHeader, Typography } from "antd";
import { useEffect } from "react";
import download from "downloadjs";
import { useTimesheetSummaryLazyQuery } from "../../../../../../generated/graphql";
import { ExportOutlined } from "@ant-design/icons";

const { Title } = Typography;

const TimesheetSummary: React.FC = () => {
  const [
    getTimesheetSummary,
    {
      loading: timesheetSummaryLoading,
      data: timesheetSummaryData,
      error: timesheetSummaryError,
    },
  ] = useTimesheetSummaryLazyQuery({ variables: { isShowAll: true } });

  useEffect(() => {
    if (timesheetSummaryError) {
      message.error(timesheetSummaryError.message, 10);
    }
  }, [timesheetSummaryError]);

  useEffect(() => {
    if (timesheetSummaryData) {
      const base64file = timesheetSummaryData.timesheetSummary;
      download(base64file, "cortex-nextgen-timesheet-summary.xlsx");
    }
  }, [timesheetSummaryData]);

  return (
    <PageHeader
      className="site-page-header"
      title={<Title level={2}>Timesheet Summary</Title>}
    >
      Press Export to generate a summary for the entire term for all employees
      who have recently worked at The Brain. The generated file will contain all
      timesheets for the entire term along with an overall summary of timesheets
      and expenses.
      <br />
      <br />
      <Button
        type="primary"
        loading={timesheetSummaryLoading}
        icon={<ExportOutlined />}
        onClick={() => {
          getTimesheetSummary();
        }}
      >
        Export
      </Button>
    </PageHeader>
  );
};

export default TimesheetSummary;
