import styled from "styled-components";

export const TopRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  float: right;
  max-width: 50vw;
`;

export const Body = styled.div`
  text-align: center;
`;

export const HEADER_BG_COLOUR = "rgb(29, 165, 122, 0.2)";
export const HEADER_DB_COLOUR_DELETED = "#ffcccb";
