import { useState } from "react";
import {
  PageHeader,
  Button,
  Typography,
  Tooltip,
  Popconfirm,
  message,
} from "antd";
import {
  DeleteOutlined,
  UserOutlined,
  PlusOutlined,
  MessageOutlined,
} from "@ant-design/icons";

import {
  ClassesAttendanceRecordsQuery,
  ClassFilterInput,
  NewClassMutation,
  useDeleteClassMutation,
} from "../../../../../generated/graphql";
import { FetchResult } from "@apollo/client";
import NewClassModal from "../../../../components/NewClassModal";
import ClassesNavigator from "../../../../components/ClassesNavigator";
import ClassViewerModal from "../../../../components/ClassViewerModal";
import { InitiatedContext } from "../../../../components/ClassAttendanceCard";
import MessageComposeModal, {
  MessageComposeInput,
} from "../../../../components/Messaging/Compose";
import { mapClassToMessagingModalInput } from "./util";

const { Title } = Typography;

const Classes: React.FC = () => {
  const [filter, setFilter] = useState<ClassFilterInput | undefined>();
  const [classViewerModalId, setClassViewerModalId] = useState<
    number | undefined
  >();
  const [isShowNewClassModal, setIsShowNewClassModal] = useState(false);
  const [deleteClass] = useDeleteClassMutation();
  const [messagingModalInput, setMessagingModalInput] = useState<
    MessageComposeInput | undefined
  >();
  const [selectedClasses, setSelectedClasses] = useState<
    ClassesAttendanceRecordsQuery["getClasses"][0][]
  >([]);

  return (
    <>
      <PageHeader
        title={<Title level={2}>Classes</Title>}
        extra={[
          <Button
            key="1"
            icon={<MessageOutlined />}
            onClick={() => {
              const finalMessagingModalInput: MessageComposeInput = {
                attachments: [],
                phones: [],
                emails: [],
              };

              selectedClasses
                .map(mapClassToMessagingModalInput)
                .forEach((messagingModalInput) => {
                  finalMessagingModalInput.attachments.push(
                    ...messagingModalInput.attachments
                  );
                  finalMessagingModalInput.emails.push(
                    ...messagingModalInput.emails
                  );
                  finalMessagingModalInput.phones.push(
                    ...messagingModalInput.phones
                  );
                });

              setMessagingModalInput(finalMessagingModalInput);
            }}
          >
            Message selected
          </Button>,
          <Button
            key="2"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsShowNewClassModal(true)}
          >
            New
          </Button>,
        ]}
      >
        <MessageComposeModal
          input={messagingModalInput}
          onClose={() => setMessagingModalInput(undefined)}
        />
        <ClassViewerModal
          classId={classViewerModalId}
          onClose={() => setClassViewerModalId(undefined)}
          initiatedContext={InitiatedContext.Admin}
        />
        <NewClassModal
          isVisible={isShowNewClassModal}
          onSuccessfulCreate={(student: FetchResult<NewClassMutation>) => {
            const newClass = student.data?.newClass;
            if (!newClass) {
              throw Error("Something went wrong creating class");
            }

            const filter = {
              day: newClass.day,
              type: newClass.type,
              subject: newClass.subject,
              startTime: newClass.startTime,
              endTime: newClass.endTime,
              grade: newClass.grade,
              comment: newClass.comment,
            };
            setFilter(filter);
            setIsShowNewClassModal(false);
            message.success("Class successfully created");
          }}
          onCancel={() => setIsShowNewClassModal(false)}
        />
        <ClassesNavigator
          filter={filter}
          onSelectRow={setSelectedClasses}
          actions={(klass, refetch): React.ReactNode => (
            <>
              <Tooltip title="View">
                <Button
                  icon={<UserOutlined />}
                  type="primary"
                  onClick={() => setClassViewerModalId(klass.id)}
                />
              </Tooltip>
              <Tooltip title="Message">
                <Button
                  icon={<MessageOutlined />}
                  onClick={() => {
                    setMessagingModalInput(
                      mapClassToMessagingModalInput(klass)
                    );
                  }}
                />
              </Tooltip>
              {!klass.isDeleted ? (
                <Tooltip title="Delete">
                  <Popconfirm
                    title="Are you really sure you want to delete this class? Any teacher(s) and student(s) will be deassociated with this class and all attendance information deleted."
                    okButtonProps={{ danger: true }}
                    onConfirm={async (): Promise<void> => {
                      try {
                        await deleteClass({
                          variables: { id: klass.id },
                        });
                        refetch();
                        message.success("Class deleted successfully");
                      } catch (error) {
                        message.error(
                          `Failed to delete class: ${error.message}`,
                          10
                        );
                      }
                    }}
                  >
                    <Button icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              ) : (
                <>&nbsp;Deleted</>
              )}
            </>
          )}
        />
      </PageHeader>
    </>
  );
};

export default Classes;
