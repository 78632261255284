import { message, Table } from "antd";
import _ from "lodash";
import moment from "moment";
import { useEffect } from "react";
import {
  EntityInput,
  useGetAuditLogEntriesForEntityLazyQuery,
  useGetAuditLogEntriesForTeacherLazyQuery,
} from "../../../../../../generated/graphql";
import { DATE_TIME_DISPLAY_FORMAT } from "../../../../../../util/constants";
import { columns } from "./columns";

interface Props {
  entity?: EntityInput;
  teacherId?: number;
}

const AuditLogEntryTable: React.FC<Props> = ({ entity, teacherId }) => {
  const [
    getEntityTypeAuditLogEntries,
    {
      data: entityTypeAuditLogData,
      loading: entityTypeAuditLogLoading,
      error: entityTypeAuditLogError,
    },
  ] = useGetAuditLogEntriesForEntityLazyQuery();

  const [
    getTeacherAuditLogEntries,
    {
      data: teacherAuditLogData,
      loading: teacherAuditLogLoading,
      error: teacherAuditLogError,
    },
  ] = useGetAuditLogEntriesForTeacherLazyQuery();

  const auditLogEntries = _.uniqBy(
    [
      ...(entityTypeAuditLogData?.getAuditLogEntriesForEntity ?? []),
      ...(teacherAuditLogData?.getAuditLogEntriesForTeacher ?? []),
    ],
    "id"
  );
  const auditLogLoading = entityTypeAuditLogLoading || teacherAuditLogLoading;
  const auditLogError = entityTypeAuditLogError || teacherAuditLogError;

  useEffect(() => {
    if (entity) {
      getEntityTypeAuditLogEntries({ variables: { input: entity } });
    }
  }, [entity?.entityId, entity?.entityType]);

  useEffect(() => {
    if (teacherId) {
      getTeacherAuditLogEntries({ variables: { teacherId } });
    }
  }, [teacherId]);

  useEffect(() => {
    if (auditLogError) {
      message.error(`Connection failed: ${auditLogError.message}`, 10);
    }
  }, [auditLogError]);

  const auditLogEntryRows = auditLogEntries.map((auditLogEntry) => ({
    id: auditLogEntry.id,
    dataIndex: String(auditLogEntry.id),
    key: auditLogEntry.id,
    teacher: auditLogEntry.teacher.fullName,
    entityType: auditLogEntry.entityType,
    entityId: auditLogEntry.entityId,
    message: auditLogEntry.message,
    timestamp: moment(auditLogEntry.dateCreated).format(
      DATE_TIME_DISPLAY_FORMAT
    ),
  }));

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={auditLogEntryRows}
      loading={auditLogLoading ? { size: "large" } : undefined}
      pagination={false}
      sticky={true}
      scroll={{ x: "max-content" }}
    />
  );
};

export default AuditLogEntryTable;
